<template>
  <div class="wrap subPage">
    <div class="container">
        <!-- sub title -->
        <div class="subTitAr">
            <h1 class='notranslate'>{{$t('trans.t12_16')}}</h1>
            <span class="subTit fW-L">통합회원 가입으로 와이드모바일의 모든 서비스를 편리하게 이용하세요.</span>            
        </div>
        <!-- 서브페이지 컨텐츠 -->
        <div class="subPageAr">
            <div class="memberJoin">
                <div class="joinResult">
                    <span class="resultTxt notranslate">{{$t('trans.t99_99')}}</span>
                    <span class="resultTxt"><strong>{{mail}}</strong> 입니다.</span>
                    <span class="resultTxt">지금 로그인 후 더 편리한 통신 생활을 경험해보세요!</span>
                    {{decryptMail}}
                </div>
                <div class="btnWp">
                    <button class="memberJoinBtn" type="button" @click="$router.push({name:'LoginView'})">로그인</button>
                    <button class="memberJoinBtn gray" type="button" @click="$router.push('/')">메인</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data(){
        return {
            mail:"",

        }
    },
    created(){
        this.mail = this.$route.query.success
    }

}
</script>

<style>

</style>
