<template>
  <div class="wrap subPage">
    <div class="container">
        <!-- sub title -->
        <div class="subTitAr fW-R">
            <h1 class="notranslate">{{$t('trans.t18_19')}}</h1>
            <span class="subTit fW-L">와이드모바일에서 제공하는 모든 서비스에서 탈퇴처리가 진행됩니다.</span>
        </div>
        <!-- 서브페이지 컨텐츠 -->
        <div class="subPageAr">
            <div class="mypageAr">
                <div class="infoMod">
                    <!-- 회원탈퇴안내 -->
                    <span class="modTit">회원탈퇴안내</span>
                    <div class="infoModcon">                        
                        <div class="noteAr">
                            <span class="withdrawTit">사용하고 계신 아이디(메일)은 탈퇴 후 재사용 및 복구가 불가능합니다.</span>
                            <span class="withdrawTxt">탈퇴한 아이디는 영구적으로 사용이 중지되며, 본인과 타인 모두 사용하실 수 없습니다.</span>
                            <span class="withdrawTxt">추후 가입을 원하실 경우 새로운 아이디로만 재가입이 가능합니다.</span>
                            <span class="withdrawTit">회원탈퇴 후 판/구매자 권익 보호를 위해 일정기간 회원정보가 보존됩니다.</span>
                            <span class="withdrawTxt">관계 법령에 의거, 보존이 필요한 경우에 한하여 아래 기간 경과 후 안전하게 영구 파기됩니다.</span>
                            <span class="withdrawTxt">1. 계약 및 청약철회 등에 관한 기록 : 5년</span>
                            <span class="withdrawTxt">2. 대금결제 및 재화 등의 공급에 관한 기록 : 5년</span>
                            <span class="withdrawTxt">3. 소비자의 불만 또는 분쟁처리에 관한 기록 : 5년</span>
                            <span class="withdrawTxt">4. 부정이용 등에 관한 기록 : 1년</span>
                        </div>
                    </div>
                    <!-- 탈퇴사유 -->
                    <span class="modTit">탈퇴사유</span>
                    <div class="infoModcon">                        
                        <div class="withdrawAr">
                            <div class="selectZone">
                                <input type="radio" id="reason1" name="reason" value="1" checked>
                                <label for="reason1">상품 불만족</label>
                                <input type="radio" id="reason2" name="reason" value="2">
                                <label for="reason2">서비스 불만족</label>
                                <input type="radio" id="reason3" name="reason" value="3">
                                <label for="reason3">가격 불만족</label>
                                <input type="radio" id="reason4" name="reason" value="4">
                                <label for="reason4">정보부족</label>
                            </div>
                            <div class="selectZone">
                                <input type="radio" id="reason5" name="reason" value="5" checked>
                                <label for="reason5">회원혜택부족</label>
                                <input type="radio" id="reason6" name="reason" value="6">
                                <label for="reason6">사이트 이용 불편</label>
                                <input type="radio" id="reason7" name="reason" value="7">
                                <label for="reason7" class="extend">사이트 이용 빈도 부족</label>
                            </div>
                            <div class="selectZone">
                                <input type="radio" id="reason5" name="reason" value="8" checked>
                                <label for="reason5" class="short">기타 (직접입력)</label>
                                <input type="text" class="reasonTxt" placeholder="최대 50자까지 입력 가능합니다.">
                            </div>
                        </div>
                    </div>
                    <div class="chkAr">
                        <input type="checkbox" id="withdrawCheck" name="withdrawchk">
                        <label for="withdrawCheck" class="withdrawCheckLabel"></label>
                        <span class="withdrawAgree">안내사항을 모두 확인하였으며 탈퇴에 동의합니다.</span>
                    </div>
                </div>
                <div class="btnWp">
                    <button class="btnCom fW-R gray notranslate">{{$t('trans.t13_06')}}</button>
                    <button class="btnCom fW-R" type="button" @click="Draw">회원탈퇴</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    methods:{
        Draw(){
            this.$router.push({name:"MemberDrawCompleteView"})
        }
    }

}
</script>

<style>

</style>