<template>
    <div>
        <div class="wrap subPage">
            <div class="container">
                <!-- sub title -->
                <div class="subTitAr fW-R">
                    <h1 class="notranslate">{{ $t('trans.t01_08') }}</h1>

                    <span class="subTit fW-L notranslate">{{ $t('trans.t04_02') }}</span>
                </div>
                <!-- 가입신청 컨텐츠 -->
                <div class="subPageAr">
                    <!-- 가입유형 -->
                    <div class="prod_option">
                        <div class="prod_subject">
                            <span class="subjectTit fW-R notranslate">{{ $t('trans.t04_03') }}</span>
                            <span class="subjectLine"></span>
                        </div>
                        <div class="prod_content">
                            <ul>
                                <li>
                                    <input
                                        type="radio"
                                        id="adm1-2"
                                        name="adm"
                                        v-model.number="joinType"
                                        value="1"
                                        @click="onJoinType(1)"
                                    />
                                    <label for="adm1-2" class="checkAr">
                                        <div class="lt full notranslate" v-html="$t('trans.t04_05')"></div>
                                    </label>
                                </li>
                                <li>
                                    <input
                                        type="radio"
                                        id="adm1-1"
                                        name="adm"
                                        value="2"
                                        v-model.number="joinType"
                                        @click="onJoinType(2)"
                                    />
                                    <label for="adm1-1" class="checkAr">
                                        <div class="lt full notranslate" v-html="$t('trans.t04_04')"></div>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- 요금제 -->
                    <div class="prod_option">
                        <div class="prod_subject">
                            <span class="subjectTit fW-R notranslate">{{ $t('trans.t04_06') }}</span>
                            <span class="subjectLine"></span>
                        </div>
                        <div class="prod_content">
                            <span class="inputNote">{{ $t('trans.t02_07') }}</span>
                            <div style="clear: both"></div>
                            <ul>
                                <li>
                                    <input type="checkbox" id="adm2-1" />
                                    <label for="adm2-1">
                                        <div class="lt traffic fW-R">
                                            <em class="notranslate" v-if="Object.keys(selectedRate).length === 0">{{
                                                $t('trans.t04_07')
                                            }}</em>
                                            <em v-else>{{ selectedRate.name }}</em>
                                            <em v-if="Object.keys(selectedRate).length > 0" class="fW-L"
                                                >데이터 {{ selectedRate.data_text }}/ 음성
                                                {{ selectedRate.voice_text }}/ 문자 {{ selectedRate.sms_text }}</em
                                            >
                                            <!--                      <span class="selPeriod fW-L">사용기간 선택</span>
                      <select class="selPbox">
                        <option>1회차 (30일)</option>
                      </select>-->
                                        </div>
                                        <div class="rt traffic">
                                            <button
                                                class="priceSelect fW-R notranslate"
                                                @click="
                                                    isRatePop = true;
                                                    isDim = true;
                                                "
                                            >
                                                {{ $t('trans.t04_08') }}<i class="xi-angle-right-thin pc-con"></i>
                                            </button>
                                        </div>
                                    </label>
                                </li>
                                <li class="admAds">
                                    <img src="https://wmstgbb001.blob.core.windows.net/wm-images/dosirakmobile/ban/discount_ban.jpg" />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- 유심타입 -->
                    <div class="prod_option" v-if="arrUsim.length > 0">
                        <div class="prod_subject">
                            <span class="subjectTit fW-R notranslate">{{ $t('trans.t09_05') }}</span>
                            <span class="subjectLine"></span>
                        </div>
                        <div class="prod_content">
                            <ul>
                                <li v-for="(usim, index) in arrUsim" :key="index">
                                    <input
                                        type="radio"
                                        :id="'adm3-1' + usim.simtype_cd"
                                        v-model="selectedUsimType"
                                        :value="usim"
                                        name="usim"
                                        @click="forceRender"
                                    />
                                    <label :for="'adm3-1' + usim.simtype_cd" class="checkAr">
                                        <div class="lt">
                                            <em class="mgl-50">{{ usim.simtype }}</em>
                                            <em class="mgl-50 fW-L" v-html="usim.desc"></em>
                                        </div>
                                        <div class="rt" v-if="usim.sim_amount === 0">
                                            <a class="priceBtn"
                                                ><span v-if="usim.sim_amount === 0" class="btnTxt">무료</span></a
                                            >
                                        </div>
                                        <div class="rt" v-else>
                                            <a class="priceBtn"
                                                ><span class="btnTxt">{{ usim.sim_amount | currency }}원</span></a
                                            >
                                        </div>
                                    </label>
                                    <!-- nfc 유심 선택시 노출 -->
                                    <div class="promotion fW-L" v-if="usim.simtype_cd === 2">
                                        ★오픈기념 한정수량★ NFC유심 7,700원 → 0원!
                                    </div>
                                    <!-- nfc 유심 선택시 노출 end -->
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- 베트남 -->
                    <!-- <div class="prod_option">
            <div class="prod_subject">
              <span class="subjectTit fW-R notranslate"
                >Nhập mã code
              </span>
              <span class="subjectLine"></span>
            </div>
            <div class="prod_content document">
              <div class="inputAr">
                <span class="inputNotice notranslate"
                  >Mời bạn bè tham gia để nhận thêm thật nhiều ưu
                  đãi!</span
                >
                <input
                  type="text"
                  maxlength="5"
                  placeholder="Vui lòng nhập mã code."
                  v-model="promotionCode"
                  class="name"
                />
                <a
                  style="cursor: pointer;"
                  @click="$router.push({ name: 'VietNamView' })"
                  target="_blank"
                  class="inputNotice notranslate"
                  >▶ Thông tin chi tiết và hướng dẫn chương trình mời
                  bạn bè nhận ưu đãi
                </a>
              </div>
            </div>
          </div> -->

                    <!-- 부가서비스 -->
                    <div class="prod_option">
                        <div class="prod_subject">
                            <span class="subjectTit fW-R notranslate">{{ $t('trans.t04_09') }}</span>
                            <span class="subjectLine"></span>
                        </div>
                        <div class="prod_content">
                            <div class="additionalTab-contentWrap">
                                <div class="additionalTab-content current" id="add1">
                                    <ul>
                                        <li
                                            v-for="(body, index) in extraServices.filter((x) => x.categorySeqnum != 5)"
                                            :key="index"
                                        >
                                            <input
                                                type="checkbox"
                                                :id="'adm4-1' + body.seqnum"
                                                name="additional"
                                                :value="body"
                                                v-model="selectedExtraServices"
                                                @click="forceRender"
                                            />
                                            <label :for="'adm4-1' + body.seqnum" class="checkAr">
                                                <div class="lt opt">
                                                    <em class="mgl-50">{{ body.extraservice }}</em>
                                                    <em class="mgl-50 fW-L">{{ body.reference }}</em>
                                                </div>
                                                <div class="rt">
                                                    <a class="priceBtn hFull"
                                                        ><span class="btnTxt">월{{ body.amount | currency }}원</span
                                                        ><span class="arrow-down pc-con"></span
                                                    ></a>
                                                </div>
                                                <div class="clearfix"></div>
                                                <div
                                                    v-if="
                                                        selectedExtraServices.filter((x) => x.seqnum === body.seqnum)
                                                            .length > 0
                                                    "
                                                    class="addDetail"
                                                    id="adm01"
                                                    v-html="body.detail_info"
                                                >
                                                    <!-- {{ body.detail_info }} -->
                                                </div>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                                <div class="additionalTab-content" id="add2">
                                    분류1
                                </div>
                                <div class="additionalTab-content" id="add3">
                                    분류2
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- 약관동의 210326 수정 -->
                    <div class="prod_option">
                        <div class="prod_subject">
                            <span class="subjectTit fW-R notranslate">{{ $t('trans.t04_12') }}</span>
                            <span class="subjectLine"></span>
                        </div>
                        <div class="prod_content">
                            <ul class="termsAr">
                                <li>
                                    <span class="termsCheck">
                                        <input
                                            type="checkbox"
                                            id="clauseCheckAll"
                                            name="addchk"
                                            v-model="clauseAllCheck"
                                            v-on:change="onAllCheck"
                                        />
                                        <label for="clauseCheckAll" class="termsCheckLabel"></label>
                                    </span>
                                    <span class="termsTit fW-L chkAll notranslate">
                                        {{ $t('trans.t04_13') }}
                                    </span>
                                </li>
                                <li>
                                    <span class="termsCheck">
                                        <input
                                            type="checkbox"
                                            v-model="agreeService"
                                            :value="1"
                                            id="clauseCheck1"
                                            name="addchk"
                                        />
                                        <label for="clauseCheck1" class="termsCheckLabel"></label>
                                    </span>
                                    <span
                                        class="termsTit fW-L notranslate"
                                        @click="agreeServiceShow = !agreeServiceShow"
                                    >
                                        {{ $t('trans.t04_14') }}
                                    </span>
                                </li>

                                <!-- 서비스약관 -->
                                <div class="termsSub" v-show="agreeServiceShow">
                                    <span class="termsTxt">
                                        <!-- 서비스이용약관 -->
                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">제<span lang="EN-US">1</span>장 총칙</span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">1</span>조<span lang="EN-US">&nbsp;(</span
                                                >목적<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                이 약관은 주식회사 와이드모바일<span lang="EN-US">(</span>이하<span
                                                    lang="EN-US"
                                                    >&nbsp;</span
                                                >“회사”<span lang="EN-US">)</span>이 제공하는 재판매서비스인
                                                도시락모바일<span lang="EN-US">(</span>이하<span lang="EN-US"
                                                    >&quot;</span
                                                >서비스<span lang="EN-US">&quot;</span>라 한다<span lang="EN-US">)</span
                                                >과 이용고객<span lang="EN-US">(</span>이하<span lang="EN-US"
                                                    >&quot;</span
                                                >회원<span lang="EN-US">&quot;</span>이라 한다<span lang="EN-US">)</span
                                                >간의 서비스 이용 및 제공 등에 관한 제반 사항의 규정을 목적으로
                                                합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">2</span>조<span lang="EN-US">&nbsp;(</span>약관의
                                                효력 및 변경<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                본 약관은 회사가 운영하는 웹사이트의 초기 서비스 화면을 통해
                                                게시함으로써 효력이 발생합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 본 약관의 내용과 회사의 상호<span lang="EN-US">,&nbsp;</span
                                                >영업소 소재지<span lang="EN-US">,&nbsp;</span>대표자 성명<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >사업자 등록번호<span lang="EN-US">,&nbsp;</span>연락처<span
                                                    lang="EN-US"
                                                    >(</span
                                                >전화<span lang="EN-US">,&nbsp;</span>팩스<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >전자우편주소 등<span lang="EN-US">)</span>등을 고객이 알 수 있도록
                                                서비스 초기 화면에 게시합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 약관의 규제에 관한 전기통신사업법<span lang="EN-US">,&nbsp;</span
                                                >전자거래기본법<span lang="EN-US">,&nbsp;</span>전자서명법<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >정보통신망이용촉진 및 정보보호 등에 관한 법률<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >소비자 기본법<span lang="EN-US">,&nbsp;</span>전자상거래 등에서의
                                                소비자보호에 관한 법률 등 관련법령을 위반하지 않는 범위에서 본 약관을
                                                변경할 수 있으며<span lang="EN-US">,&nbsp;</span>이 경우에는 적용일자 및
                                                개정사유<span lang="EN-US">,&nbsp;</span>변경되는 내용을 명시하여 초기
                                                서비스 화면에 그 적용일자<span lang="EN-US">&nbsp;7</span>일 전부터
                                                적용일자 전일까지 공지합니다<span lang="EN-US">.&nbsp;</span>단<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >이용자에게 불리한 내용으로 변경하는 경우에는 그 적용일자<span
                                                    lang="EN-US"
                                                    >&nbsp;30</span
                                                >일 전부터 이를 공지합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4.&nbsp;</span
                                            ><span style="color: black">
                                                제<span lang="EN-US">3</span>항에 의거<span lang="EN-US">,&nbsp;</span
                                                >변경된 약관은 제<span lang="EN-US">1</span>항과 같은 방법을 통해 효력이
                                                발생합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">3</span>조<span lang="EN-US">&nbsp;(</span>약관 외
                                                준칙<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                본 약관에 명시되지 않은 사항에 대하여는 관계 법령 또는 개별
                                                이용계약서<span lang="EN-US">(</span>가입신청서<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >개인정보활용동의서<span lang="EN-US">,&nbsp;</span>약정서 등<span
                                                    lang="EN-US"
                                                    >)</span
                                                >를 적용합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                제<span lang="EN-US">&nbsp;1</span>항에서 정한 개별 이용계약서에는
                                                서비스 이용계약에 관하여 고객의 이익을 침해하거나 부당한 내용은 규정할
                                                수 없습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">4</span>조<span lang="EN-US">&nbsp;(</span
                                                >정의<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black"
                                                >이 약관에서 사용하는 용어의 정의는 다음과 같습니다<span lang="EN-US"
                                                    >.</span
                                                ></span
                                            >
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.</span
                                            ><span style="color: black">
                                                “서비스”라 함은 전기통신사업법 제<span lang="EN-US">21</span>조<span
                                                    lang="EN-US"
                                                    >(</span
                                                >별정통신사업자의등록<span lang="EN-US">)</span>에 의거 회사가
                                                별정통신사업자로서 망제공사업자의 전기통신회선설비 등을 이용하여
                                                별정통신사업 방식으로 제공하는 무선재판매서비스를 말합니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2. &quot;</span
                                            ><span style="color: black">
                                                이용고객<span lang="EN-US">&quot;</span>이라 함은 회사와 서비스의 이용에
                                                관한 계약을 체결한 고객으로 규정합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3.</span
                                            ><span style="color: black">
                                                “요금제”라 함은 망제공사업자의 별정통신 요금정책에 의거하여 회사가 정한
                                                무선재판매서비스의 과금 정책입니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4.</span
                                            ><span style="color: black">
                                                “이용계약서”라 함은 서비스의 이용을 위하여 회사가 정한 개별
                                                이용계약서<span lang="EN-US">(</span>고객동의확인서<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >약정서 등<span lang="EN-US">)</span>로 계약서와 동일한 효력을
                                                가집니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">5. &quot;</span
                                            ><span style="color: black">
                                                운영자<span lang="EN-US">&quot;&nbsp;</span>서비스의 전반적인 관리와
                                                원활한 운영을 위하여 회사가 선정한 자를 말합니다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">6. &quot;</span
                                            ><span style="color: black">
                                                상품<span lang="EN-US">&quot;</span>이라 함은 회사에서 제공하는 유심 및
                                                단말기를 말합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black"
                                                >제<span lang="EN-US">2</span>장 서비스 이용계약 및 회원관리</span
                                            >
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">5</span>조<span lang="EN-US">&nbsp;(</span>계약의
                                                종류 및 성립<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">계약의 종류</span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                선불이용계약<span lang="EN-US">&nbsp;:&nbsp;</span>이용요금을 선불로
                                                지급하는 방식으로 납부키로 하고 체결하는 선불통화서비스<span
                                                    lang="EN-US"
                                                    >(Pre Paid Service : PPS)&nbsp;</span
                                                >이용계약
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">계약의 성립</span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1)&nbsp;</span
                                            ><span style="color: black">
                                                회사가 정한 가입절차 또는 계약에 관한 이용계약서<span lang="EN-US"
                                                    >(</span
                                                >가입신청서<span lang="EN-US">,&nbsp;</span>개인정보활용동의서<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >약정서 등<span lang="EN-US">)</span>에 자격을 취득 후 상품의 대가 지불
                                                후 회사의 승낙에 의하여 성립됩니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2)&nbsp;</span
                                            ><span style="color: black">
                                                이용계약을 대리인이 신청할 경우 회사는 필요한 경우 고객 본인에게 위임
                                                여부를 전화<span lang="EN-US">,&nbsp;</span>서면<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >방문확인 등의 방법으로 확인할 수 있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3)&nbsp;</span
                                            ><span style="color: black">
                                                미성년자 회원과의 계약을 체결할 경우 회원의 법정대리인의 동의가 필요하며
                                                법정대리인은 계약의 내용을 확인하고<span lang="EN-US">,&nbsp;</span
                                                >계약에 의하여 발생하는 모든 채무를 연대하여 이행할 것을 동의하여야
                                                합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4)&nbsp;</span
                                            ><span style="color: black">
                                                요금납부를 타인 명의의 계좌로 자동이체하는 경우<span lang="EN-US"
                                                    >(</span
                                                >신용카드 결제 포함<span lang="EN-US">)</span>에는 예금주<span
                                                    lang="EN-US"
                                                    >(</span
                                                >회원<span lang="EN-US">)</span>의 신분증 사본을 제출하여야 하며<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >이 경우 회사는 예금주<span lang="EN-US">(</span>회원<span lang="EN-US"
                                                    >)</span
                                                >에게 전화<span lang="EN-US">,&nbsp;</span>서면 등의 방법으로 동의
                                                여부를 확인합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">5)&nbsp;</span
                                            ><span style="color: black">
                                                회사는 다음 각 호에 해당하는 이용신청에 대하여는 이를 승낙하지
                                                아니하거나 사후에 이용계약을 해지할 수 있습니다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ① 타인의 명의 신청 또는 도용하거나<span lang="EN-US">,&nbsp;</span
                                                >이용자 정보의 허위<span lang="EN-US">,&nbsp;</span>기재누락<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >오기가 있는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black"
                                                >② 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우</span
                                            >
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ③ 대포폰의 매개 또는 개통<span lang="EN-US">,&nbsp;</span>불법 복제와
                                                관련된 사실이 있거나 처벌받은경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ④ 선<span lang="EN-US">,</span>후불 포함하여 개인명의로<span
                                                    lang="EN-US"
                                                    >&nbsp;3</span
                                                >대 초과하여 개통하는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ⑤ 외국인 체류 자격 코드 중 회사가 정한 특정 코드 가입자가 일반<span
                                                    lang="EN-US"
                                                    >(</span
                                                >후불제<span lang="EN-US">)</span>이용계약을 신청하는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ⑥ 합법적인 국내 체류기간의 만료일까지 남은 기간이<span lang="EN-US"
                                                    >&nbsp;30</span
                                                >일 이내인 외국인이 이용을 신청하는 경우<span lang="EN-US">&nbsp;(</span
                                                >단<span lang="EN-US">,&nbsp;</span>선불이용계약은 가능<span
                                                    lang="EN-US"
                                                    >)</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black"
                                                >⑦ 이용을 신청한 고객이 요금 등을 납부하지 아니한 경우</span
                                            >
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ⑧ 신용정보의 이용 및 보호에 관한 법률 제<span lang="EN-US">25</span>조의
                                                신용정보집중기관에 통신서비스 요금 또는 불법스팸 전송에 따른 과태료 등을
                                                체납하여 연체자로 등록되어 있거나 휴대폰 대출 등 부정사용이 우려되어
                                                이용정지자로 등록되어 있는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">⑨ 기술상 서비스 제공이 불가능한 경우</span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ⑩ 서비스 이용목적 또는 이용행위가 법령에 위반되거나 제<span lang="EN-US"
                                                    >3</span
                                                >자의 권리를 침해할 우려가 있는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black"
                                                >⑪ 악성 프로그램 및 버그를 이용하거나 시스템 취약점을 악용하는 등 부정한
                                                방법을 서비스에 사용한 경우</span
                                            >
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black"
                                                >⑫ 타인의 명의를 도용한 사실이 있거나 처벌받은 경우 또는 명의도용을 상습
                                                허위 신고한 사실이 있는 경우</span
                                            >
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ⑬ 기타 회사가 정한 이용신청 요건이 만족되지 않아 서비스 제공을 계속하는
                                                것이 현저히 부적절하다고 판단되는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">6)&nbsp;</span
                                            ><span style="color: black">
                                                회사는 이용신청이 다음 각호의 하나에 해당하는 경우에는 그 신청에 대한
                                                승낙 제한 사유가 해소될 때까지는 승낙을 하지 아니합니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black"
                                                >① 이용을 신청한 고객이 요금 등을 납부하지 아니한 경우</span
                                            >
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ② 신용정보의 이용 및 보호에 관한 법률 제<span lang="EN-US">25</span>조의
                                                신용정보집중기관에 통신서비스 요금 또는 불법스팸 전송에 따른 과태료 등을
                                                체납하여 연체자로 등록되어 있거나 휴대폰 대출 등 부정사용이 우려되어
                                                이용정지자로 등록되어 있는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black"
                                                >③ 본인의 요청에 의하여 가입제한을 신청한 경우</span
                                            >
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ④ 신용정보의 이용과 보호에 관한 법률 제<span lang="EN-US">25</span>조의
                                                신용정보집중기관에 명의도용<span lang="EN-US">,&nbsp;</span>대포폰<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >불법복제 등 통신시장의 질서를 문란케하여 정보통신상거래질서문란자로
                                                등록되어 있는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">7)&nbsp;</span
                                            ><span style="color: black">
                                                회사는 각호에 해당하는 경우는 이용 신청을 승낙하지 않을 수 있습니다<span
                                                    lang="EN-US"
                                                    >.(</span
                                                >단<span lang="EN-US">,&nbsp;</span>명의도용 등에 의한 선의의 피해자는
                                                확인 후 계약을 승낙합니다<span lang="EN-US">.)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ① 이용 신청일을 포함하여 과거<span lang="EN-US">&nbsp;1</span>년<span
                                                    lang="EN-US"
                                                    >(365</span
                                                >일<span lang="EN-US">)&nbsp;</span>이내에 스팸<span lang="EN-US"
                                                    >(</span
                                                >불법스팸 포함<span lang="EN-US">)</span>발송 사유로이용정지 또는 해지
                                                이력이 있는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ② 대량으로 메시지<span lang="EN-US">(SMS, MMS&nbsp;</span>포함<span
                                                    lang="EN-US"
                                                    >)/</span
                                                >음성<span lang="EN-US">(</span>영상<span lang="EN-US">)</span>통화호
                                                등을 전송하여 타이용자의 통화장애 또는 시스템 장애를 야기하거나 야기할
                                                우려가 있는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ③ 약관과 다르게 또는 회사와 계약 또는 동의 없이 사업의 영위<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >경제적인 이득 또는 요금 할인 등의 목적으로 통화호 중계<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >통화호 재판매 사업<span lang="EN-US">,&nbsp;</span>착신전환 등을
                                                이용하는 경우 및 이를 활용한 서비스를 이용하는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ④ 이동전화로 수신되는 통화 혹은 메시지를 착신전환 등 부가서비스를
                                                이용하여<span lang="EN-US">&nbsp;2</span>회 이상 망내<span lang="EN-US"
                                                    >/</span
                                                >외 여러 단계를 경유하도록 연결하는 행위
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">8)&nbsp;</span
                                            ><span style="color: black">
                                                회사는 회원이 이용계약을 체결한 후에라도 회원의 자격에 따른 서비스
                                                이용을 제한할 수 있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">6</span>조<span lang="EN-US">&nbsp;(</span>번호부여
                                                및 변경<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 부여 가능한 전화번호 중에서 고객이 선택하는 번호를 부여하거나
                                                또는 추첨을 통해 부여하며<span lang="EN-US">,&nbsp;</span>번호 관리
                                                기준에 대한 세부사항에 대해서는 별도로 정하여 고객이 확인할 수 있도록
                                                공지합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 다음 항목에 하나라도 해당하는 경우 고객의 서비스 번호를 변경할 수
                                                있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1)&nbsp;</span
                                            ><span style="color: black">
                                                공익목적 수행상 서비스 번호의 통일을 필요로 하는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2)&nbsp;</span
                                            ><span style="color: black">
                                                수용구역 변경 등 회사의 기술상 부득이한 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3.&nbsp;</span
                                            ><span style="color: black">
                                                제<span lang="EN-US">&nbsp;2</span>항의 규정에 의한 변경시에는
                                                변경예정일<span lang="EN-US">&nbsp;30</span>일 전까지 서비스번호의
                                                변경사유<span lang="EN-US">,&nbsp;</span>변경예정번호 및 변경예정일을
                                                해당 고객에게 통보하여야 합니다<span lang="EN-US">.&nbsp;</span
                                                >다만<span lang="EN-US">,&nbsp;</span>고객의 책임 있는 사유로 인하여
                                                통보할 수 없을 때에는 홈페이지에 게시함으로써 통보한 것으로
                                                간주합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4.&nbsp;</span
                                            ><span style="color: black">
                                                이용자의 요청에 의한 번호변경시 회사는 고객에게 본인여부를 확인할 의무가
                                                있으며<span lang="EN-US">,&nbsp;</span>변경가능 횟수는 회사가 정한
                                                기준에 따릅니다<span lang="EN-US">.&nbsp;</span>다만<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >단말기 분실로 확인된 경우 또는 스토킹 등으로 인해 번호변경이
                                                불가피하다고 회사가 인정한 경우에는 번호변경 제한회수에 포함하지 않을 수
                                                있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">5.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 번호이동 신청 고객에 대한 변경전 회사의 통신요금<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >단말기 할부금 등 미납액을 고지하고<span lang="EN-US">,&nbsp;</span
                                                >번호이동 고객은 변경전 사업자의 통신요금<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >단말기할부금 등 미납금액을 다음과 같이 납부하여야 합니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1)&nbsp;</span
                                            ><span style="color: black">
                                                청구된 이용요금 등<span lang="EN-US">&nbsp;:&nbsp;</span
                                                >변경전사업자에게 납부
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2)&nbsp;</span
                                            ><span style="color: black">
                                                청구되지 않은 이용요금 등<span lang="EN-US">&nbsp;:&nbsp;</span
                                                >변경후사업자에게 납부
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3)&nbsp;</span
                                            ><span style="color: black">
                                                제<span lang="EN-US">5</span>항<span lang="EN-US">&nbsp;1</span>호
                                                및<span lang="EN-US">&nbsp;2</span>호에도 불구하고 사업자간 번호이동시
                                                후불에서 선불<span lang="EN-US">(PPS)</span>서비스로 전환하는 경우<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >변경전 사업자에게서 발생한 이용요금 등은 청구되었는지 여부와 관계없이
                                                변경 전 사업자에게 납부
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">6.&nbsp;</span
                                            ><span style="color: black">
                                                부여 받은 전기통신번호가 전기통신번호 판매를 중계하는 서비스를 통해
                                                매매되는 것으로 확인되는 등 실제 번호사용 의사가 없는 이용자<span
                                                    lang="EN-US"
                                                    >(</span
                                                >계약자<span lang="EN-US">)</span>에게 번호가 제공된 경우 번호가 회수될
                                                수 있습니다<span lang="EN-US">.(</span>과학기술정보통신부 회수 요청
                                                등<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">7.&nbsp;</span
                                            ><span style="color: black">
                                                기타 약관에 명시되지 않은 변경 사유의 경우 관련 법령 또는 회사의 정책에
                                                위배되지 않을 경우 번호 변경이 가능합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">8.&nbsp;</span
                                            ><span style="color: black">
                                                해지된 전기통신번호의 재부여는 해지일 포함<span lang="EN-US"
                                                    >&nbsp;29</span
                                                >일간 제한합니다<span lang="EN-US">. (</span>단<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >해지 당일 철회할 경우 본인부여 가능<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">7</span>조<span lang="EN-US">&nbsp;(</span>회원정보
                                                및 계약사항의 변경<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                회원은<span lang="EN-US">&nbsp;</span>“회원”정보관리화면을 통하여
                                                언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                정책상 서비스 관리를 위하여 실명<span lang="EN-US">, ID&nbsp;</span>등은
                                                수정이 불가능하나<span lang="EN-US">,&nbsp;</span>개명 등에 따른 실명
                                                변경 시 회사의 고객센터를 통해 이를 신고하고 필요로 하는 증빙자료를
                                                제출하여야 합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3.&nbsp;</span
                                            ><span style="color: black">
                                                회원은 이용 신청 시 기재한 사항이 변경되었을 경우<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >고객센터를 통해 이를 통보해야하며 회사는 필요 시 증빙자료를 요구할 수
                                                있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4.&nbsp;</span
                                            ><span style="color: black">
                                                고객은 다음 각 호의 하나에 해당하는 이용계약사항을 변경하고자 할
                                                경우에는 변경신청서와 회사에서 정하는 구비서류를 제출하여야 합니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1)&nbsp;</span
                                            ><span style="color: black">
                                                서비스종류 또는 단말기를 변경하고자 하는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2)&nbsp;</span
                                            ><span style="color: black">
                                                고객이 제<span lang="EN-US">3</span>자에게 이용권을 양도 또는 승계하고자
                                                하는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3)&nbsp;</span
                                            ><span style="color: black">
                                                고객이 번호를 변경하고자 하는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4)&nbsp;</span
                                            ><span style="color: black">
                                                기타 변경이 필요한 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">5.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 고객의 변경신청에 대하여 다음 각 호의 하나에 해당하는 경우에는
                                                응하지 아니할 수 있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1)&nbsp;</span
                                            ><span style="color: black">
                                                고객이 요금 등을 미납하였을 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2)&nbsp;</span
                                            ><span style="color: black">
                                                압류<span lang="EN-US">,&nbsp;</span>가압류 또는 가처분된 단말기인 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3)&nbsp;</span
                                            ><span style="color: black">
                                                개별 약정사항을 위반하였을 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">6.&nbsp;</span
                                            ><span style="color: black">
                                                명의변경은 원칙적으로 금지하고<span lang="EN-US">,&nbsp;</span>아래의
                                                경우에는 요건에 따라 해당 번호에 대한 이용권의 승계를 예외적으로
                                                허용합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1)&nbsp;</span
                                            ><span style="color: black">가족간 명의변경</span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2)&nbsp;</span
                                            ><span style="color: black">
                                                법인간 사업 양·수도 및 인수·합병 등 기타 사업의 연속성이 확인되는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3)&nbsp;</span
                                            ><span style="color: black">
                                                법인 입·퇴사 후 법인 또는 개인 명의로 변경
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4)&nbsp;</span
                                            ><span style="color: black">
                                                개인 명의를 법인 명의로 변경한 후 다시 동일 개인 명의로 변경<span
                                                    lang="EN-US"
                                                    >.&nbsp;</span
                                                >단<span lang="EN-US">,&nbsp;</span>최근<span lang="EN-US">&nbsp;3</span
                                                >개월간 연속해서 통화량이 없는 경우 명의변경이 불가하고<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >개인과 법인 상호간 명의변경 횟수는<span lang="EN-US">&nbsp;3</span
                                                >개월에<span lang="EN-US">&nbsp;1</span>회로 제한합니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">7.&nbsp;</span
                                            ><span style="color: black">
                                                회사는<span lang="EN-US">&nbsp;</span>“회원”이 변경사항을 알리지
                                                아니하여 발생한 손해에 대하여 책임지지 않습니다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">8</span>조<span lang="EN-US">&nbsp;(</span>이용계약
                                                등록사항의 증명·열람<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                이용계약 등록사항에 대하여 회원 본인이나 그로부터 위임 받은 자 또는
                                                법원의 확정판결서나 공정증서를 제시한 이해관계인은 회사에 대하여 증명
                                                또는 열람 청구를 할 수 있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black"
                                                >제<span lang="EN-US">3</span>장 서비스 이용에 관한 일반사항</span
                                            >
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">9</span>조<span lang="EN-US">&nbsp;(</span>장애 및
                                                고장 등<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                유심 상품의 경우 구매 후<span lang="EN-US">&nbsp;1</span>년 이내 불량
                                                발생 시 상품의 불량이 확인된 경우 유심 무상 교환 처리되며<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >분실 및 파손의 경우 유료 재발급이 가능합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">10</span>조<span lang="EN-US">&nbsp;(</span
                                                >서비스의 변경 및 취소<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나
                                                중지할 수 있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1)&nbsp;</span
                                            ><span style="color: black">
                                                정보통신설비의 보수점검 교체 및 고장<span lang="EN-US">,&nbsp;</span
                                                >통신의 두절 등의 사유가 발생한 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2)&nbsp;</span
                                            ><span style="color: black">
                                                서비스를 위한 설비의 보수 등 공사로 인해 부득이한 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3)&nbsp;</span
                                            ><span style="color: black">
                                                서비스 업그레이드 및 사이트 유지보수 등을 위해 필요한 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4)&nbsp;</span
                                            ><span style="color: black">
                                                정전<span lang="EN-US">,&nbsp;</span>제반 설비의 장애 또는 이용량의 폭주
                                                등으로 정상적인 서비스 이용에 지장이 있는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">5)&nbsp;</span
                                            ><span style="color: black">
                                                고객이 회사의 영업활동을 방해하는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">6)&nbsp;</span
                                            ><span style="color: black">
                                                서비스 제공업자와의 계약종료 등 회사의 제반 사정으로 서비스를 유지할 수
                                                없는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">7)&nbsp;</span
                                            ><span style="color: black">
                                                기타 천재지변<span lang="EN-US">,&nbsp;</span>국가비상사태 등 불가항력적
                                                사유가 있는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                본 조 제<span lang="EN-US">1</span>항에 의한 서비스 중단의 경우에는
                                                회사가 정한 방법으로 이용자에게 통지합니다<span lang="EN-US"
                                                    >.&nbsp;</span
                                                >단<span lang="EN-US">,&nbsp;</span>회사가 통제할 수 없는 사유로 인한
                                                서비스의 중단<span lang="EN-US">(</span>운영자의 고의나과실이 없는
                                                디스크 장애<span lang="EN-US">,&nbsp;</span>시스템 장애 등<span
                                                    lang="EN-US"
                                                    >)</span
                                                >으로 인하여 사전 통지가 불가능한 경우에는 그러하지 아니합니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 본 조 제<span lang="EN-US">1</span>항의 사유로 서비스의 제공이
                                                일시적으로 중단됨으로 인하여 이용자 또는 제<span lang="EN-US">3</span
                                                >자가 입은 손해에 대해 배상하지 않음을 원칙으로 합니다<span lang="EN-US"
                                                    >.&nbsp;</span
                                                >단<span lang="EN-US">,&nbsp;</span>회사에 고의 또는 중과실이 있는
                                                경우에는 그러하지 아니합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4.&nbsp;</span
                                            ><span style="color: black">
                                                결제가 완료된 경우에도 고객이 요청한 상품의 재고 상황에 따라 취소 될 수
                                                있으며<span lang="EN-US">,&nbsp;</span>이 경우 고객의 결제 금액은 즉시
                                                환불됩니다<span lang="EN-US">.(</span>카드 결제의 경우 카드사의 처리
                                                기간에 따라 상이함<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black"
                                                >제<span lang="EN-US">4</span>장 의무와 책임</span
                                            >
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">11</span>조<span lang="EN-US">&nbsp;(</span>회사의
                                                의무<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                가입계약 체결 시 회사는 고객이 선택한 요금제<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >부가서비스<span lang="EN-US">,&nbsp;</span>고객불만 처리부서 및
                                                전화번호<span lang="EN-US">,&nbsp;</span>요금감면대상<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >이용정지 및 직권 해지 기준 등 계약의 주요내용 및 서비스 이용과 관련하여
                                                고객이 반드시 알아야 할 사항을 고지하여야 합니다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 고객과 가입계약을 체결함에 있어 실제 사용자와 서비스 이용계약을
                                                체결하여야 하며<span lang="EN-US">,&nbsp;</span>신규 가입을 신청한
                                                고객에게 타인 명의로 기개통된 단말기를 명의변경 방법 등을 통해 판매하지
                                                않습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 고객이 해지를 신청할 경우 해지처리를 완료하여야 합니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 고객이 이용계약서 등을 통해 동의한 개인정보의 이용범위를 초과하여
                                                이용하거나 제<span lang="EN-US">&nbsp;3</span>자에게 제공하지
                                                않으며<span lang="EN-US">,&nbsp;</span>부득이하게 제공하고자 하는
                                                경우에는 미리 해당 고객에게 동의를 받아야 합니다<span lang="EN-US"
                                                    >.&nbsp;</span
                                                >이 경우 고객은 회사의 동의 요청을 거절할 수 있습니다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">5.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 요금연체와 관련하여 이용자를 신용정보의 이용 및 보호에 관한 법률
                                                제<span lang="EN-US">17</span>조의 신용정보집중기관 등 관계기관 등에
                                                채무불이행정보 등록 요청할 경우 등록요청 대상자에 대해 본인여부 등
                                                필요한 확인절차를 거쳐야 합니다<span lang="EN-US">.&nbsp;</span>단<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >이용자의 책임 있는 사유로 인해 회사의 고지사실을 확인하지 못하였을 경우
                                                이용자가 확인한 것으로 간주합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">6.&nbsp;</span
                                            ><span style="color: black">
                                                사업자는 이동전화서비스 번호이동과 관련하여 이동전화서비스 번호이동성
                                                시행에 관한 고시 등을 준수합니다<span lang="EN-US">.&nbsp;</span
                                                >또한<span lang="EN-US">,&nbsp;</span>회사는 다른 사업자로 번호를
                                                이동하고자 하는 고객에 대한 업무처리를 특별한 사유 없이 지연하지
                                                않으며<span lang="EN-US">,&nbsp;</span>다른 회사에 가입되어 있는
                                                번호이동 가입자에 대한 호처리 등 통화품질을 자사의 가입자와 차별하지
                                                않습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">7.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 고객의 선택하여 이용중인 서비스와 관련한 중요한 약관사항<span
                                                    lang="EN-US"
                                                    >(</span
                                                >요금제<span lang="EN-US">,&nbsp;</span>부가서비스<span lang="EN-US"
                                                    >)</span
                                                >이 고객에게 불리하게 변경되는 경우에는 변경 전에 해당 고객에게<span
                                                    lang="EN-US"
                                                >
                                                    &nbsp;SMS, E-mail&nbsp; </span
                                                >등을 통해 고지하고 홈페이지 에 변경내용을 게시합니다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">8.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 고객과의 이용계약 체결 시 고객<span lang="EN-US">(</span>이하
                                                대리가입 시에는 대리인 포함<span lang="EN-US">)</span>이 제시한 신분증
                                                및 증서 등을 통해 본인인지 여부를 확인하여야 하며<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >본인여부 확인 소홀로 인한 피해발생시 선의의 제<span lang="EN-US"
                                                    >3</span
                                                >자에게 일체의 요금청구 행위를 할 수 없습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">9.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 이용자가 발신번호를 임의적으로 변경하여 음성전화를 발신하거나
                                                문자메시지를 발송한 경우 해당 음성전화 및 문자메시지를 차단할 수
                                                있습니다<span lang="EN-US">.&nbsp;</span>다만<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >전기통신사업법 제<span lang="EN-US">84</span>조의<span lang="EN-US"
                                                    >2</span
                                                >제<span lang="EN-US">2</span>항 단서로 규정된 발신번호 변경이 가능한
                                                정당한 사유인 경우에는 예외로 합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">10.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 변작된 발신번호로 전송된 음성전화 및 문자메시지를 수신한 이용자가
                                                한국인터넷진흥원을 통해 원발신 사업자 확인을 요청한 경우<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >발신 사업자를 확인하여 제공합니다<span lang="EN-US">.&nbsp;</span
                                                >다만<span lang="EN-US">,&nbsp;</span>기술적 사유로 추적이 불가한
                                                경우에는 제공하지 못할 수 있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">11.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 한국인터넷진흥원에 제출하기 위한 목적으로 차단된 음성전화 또는
                                                문자메시지에 관한 자료<span lang="EN-US">(</span>변작된 발신번호<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >차단시각<span lang="EN-US">,&nbsp;</span>발신사업자명 등<span
                                                    lang="EN-US"
                                                    >)</span
                                                >를<span lang="EN-US">&nbsp;1</span>년간 보관·관리할 수 있습니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">12.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 이용자의 요금연체 정보를 신용정보법 제<span lang="EN-US"
                                                    >&nbsp;15</span
                                                >조의 신용정보회사등에 제공하기 전에 이용자 본인<span lang="EN-US"
                                                    >(</span
                                                >법인 고객은 제외<span lang="EN-US">)</span>에게 연체정보제공 사실을
                                                알립니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">13.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 이용자가 발송한 인터넷발송 문자메시지 및 문자재판매사업자로부터
                                                수신한 인터넷발송 문자메시지의 발신번호가 변작번호 차단목록에 포함된
                                                경우 해당 문자메시지의 발송 또는 전달을 차단할 수 있으며<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >해당 조치에 대하여 이용자에게 통지하여야 합니다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">14.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 이용자가 발신번호 변경서비스를 제공받고자 하는 경우<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >원 번호와 변경 번호의 명의인이 동일인인지 확인하기 위하여
                                                전기통신사업법 등 관련 법령 및 이용약관에 제시된 방법으로 이용자에게
                                                본인 확인을 요청할 수 있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">15.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 제공목적에 맞는 서비스 이용여부를 확인<span lang="EN-US">(</span
                                                >불법스팸 등<span lang="EN-US">)</span>하기 위하여 상시적으로 모니터링을
                                                실시할 수 있습니다<span lang="EN-US">&nbsp;.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">16.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 이용자 대상으로 사기목적의 전화번호를 변작하는 행위를 예방하기
                                                위해 아래와 같이 기술적인 조치를 취할 수 있습니다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">-&nbsp;</span
                                            ><span style="color: black">
                                                본인전화번호와 발신전화번호 비교 확인 시스템 구축
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">-&nbsp;</span
                                            ><span style="color: black">
                                                문자발송사이트의 경우 본인의 전화번호만 등록하여 이용하는 기능
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">-&nbsp;</span
                                            ><span style="color: black">
                                                국제전화의 경우<span lang="EN-US">&nbsp;`</span>국제전화입니다<span
                                                    lang="EN-US"
                                                    >`&nbsp;</span
                                                >안내 메시지 송출<span lang="EN-US">(</span>또는 국제전화 식별번호
                                                표기<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">-&nbsp;</span
                                            ><span style="color: black">
                                                국외에서 오는 문자의 경우<span lang="EN-US">&nbsp;[</span>국제발신<span
                                                    lang="EN-US"
                                                    >]&nbsp;</span
                                                >문구 표시
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">-&nbsp;</span
                                            ><span style="color: black">
                                                웹 사이트 발신 문자의 경우<span lang="EN-US">&nbsp;[Web&nbsp;</span
                                                >발신<span lang="EN-US">]&nbsp;</span>문구 표시
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">12</span>조<span lang="EN-US">&nbsp;(</span>고객의
                                                의무<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                고객은 서비스 이용계약에 따라 요금을 지정된 기일까지 납입하여야
                                                합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                고객은 서비스 계약 및 이용에 필요한 개인 정보 등을 회사에 허위로
                                                제공하여서는 안되며<span lang="EN-US">,&nbsp;</span>정보 변경시 지체없이
                                                회사에 통보하여 갱신하여야 합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3.&nbsp;</span
                                            ><span style="color: black">
                                                고객은<span lang="EN-US">&nbsp;`</span
                                                >정보통신망이용촉진및정보보호등에관한법률<span lang="EN-US">`</span>의
                                                광고성 정보 전송시 의무사항 및 회사의 이용약관을 준수하여야 합니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4.&nbsp;</span
                                            ><span style="color: black">
                                                고객은 스팸 또는 불법스팸을 전송함으로써 발생하는 모든 민·형사상의
                                                책임을 부담합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">5.&nbsp;</span
                                            ><span style="color: black">
                                                고객은 본 약관에서 정한 부정한 목적으로 금지하는 행위를 시도하여서는
                                                안되며<span lang="EN-US">,&nbsp;</span>회사는 금지행위 발견 시 계약을
                                                해지할 수 있으며 이에 따라 발생하는 모든 민·형사상의 책임은 고객이
                                                부담합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">6.&nbsp;</span
                                            ><span style="color: black">
                                                고객이 신규가입<span lang="EN-US">,&nbsp;</span>기기변경<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >통화내역 제공 또는 통화도용 조사 등을 신청하는 경우 회사는 고객이
                                                가지고 있는 이동전화 단말기의 복제여부를 확인하기 위하여 고객에게
                                                단말기의 제시를 요구할 수 있고<span lang="EN-US">,&nbsp;</span>이 때
                                                고객은 이에 응해야 합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">7.&nbsp;</span
                                            ><span style="color: black">
                                                동조<span lang="EN-US">&nbsp;6</span>항에 의한 회사의 요청에 응하지 아니
                                                하거나<span lang="EN-US">,&nbsp;</span>복제된 이동전화 단말기로
                                                동조<span lang="EN-US">&nbsp;6</span>항의 업무를 신청하는 경우 회사는
                                                이를 거절할 수 있습니다
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">8.&nbsp;</span
                                            ><span style="color: black">
                                                고객은 회사의 서비스 제공 목적외의 용도로 서비스를 이용하여서는
                                                안되며<span lang="EN-US">,&nbsp;</span>제<span lang="EN-US">3</span
                                                >자에게 임의로 해당 서비스를 임대하여서도 안됩니다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">9. `</span
                                            ><span style="color: black">
                                                정보통신망이용촉진및정보보호등에관한법률<span lang="EN-US">`</span>등
                                                관련법령에서 금지하고 있는스팸<span lang="EN-US">(</span>불법스팸<span
                                                    lang="EN-US"
                                                    >)</span
                                                >을 방지하기 위하여 고객은 회선당<span lang="EN-US">&nbsp;1</span
                                                >일<span lang="EN-US">&nbsp;500</span>건을 초과하는 메시지<span
                                                    lang="EN-US"
                                                    >(SMS, MMS</span
                                                >포함<span lang="EN-US">)</span>를 전송할 수 없습니다<span lang="EN-US"
                                                    >. 1</span
                                                >일<span lang="EN-US">&nbsp;500</span>건을 초과하여 메시지를 전송할 경우
                                                회사는<span lang="EN-US">&nbsp;1</span>개월 이내의 기간을 정하여
                                                메시지발송을 제한 할 수 있습니다<span lang="EN-US">.&nbsp;</span
                                                >다만<span lang="EN-US">,&nbsp;</span>고객이 제출하는 증빙서류 등을 통해
                                                회사가 불법스팸이 아님을 확인할 수 있는 경우에는<span lang="EN-US"
                                                    >&nbsp;1</span
                                                >일<span lang="EN-US">&nbsp;500</span>건을 초과하여 전송할 수
                                                있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">10.&nbsp;</span
                                            ><span style="color: black">
                                                고객은 회사의 동의 또는 계약 없이 물리적 장치 또는 자동발송프로그램을
                                                이용하여 메시지<span lang="EN-US">(SMS, MMS,&nbsp;</span>음성<span
                                                    lang="EN-US"
                                                    >/</span
                                                >영상통화호<span lang="EN-US">)</span>를 송부할 수 없으며<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >이에 위반한 경우<span lang="EN-US">(</span>이에 위반하였다고 객관적으로
                                                추정이 되는 경우 포함<span lang="EN-US">)&nbsp;</span>회사는 메시지<span
                                                    lang="EN-US"
                                                    >(SMS, MMS,&nbsp;</span
                                                >음성<span lang="EN-US">/</span>영상통화호<span lang="EN-US">)</span
                                                >발송을 제한할 수 있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">11.&nbsp;</span
                                            ><span style="color: black">
                                                고객은 휴대폰 대출 등 금전 또는 기타 부정한 이익을 얻을 목적으로 휴대폰
                                                및 이동전화 서비스를 다른 사람에게 제공하여서는 안됩니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">12.&nbsp;</span
                                            ><span style="color: black">
                                                전기통신사업법 시행령 제<span lang="EN-US">2</span>조 제<span
                                                    lang="EN-US"
                                                    >2</span
                                                >항 제<span lang="EN-US">3</span>호에 따른 요금감면 전화서비스
                                                이용고객은 해당 자격이 변동되어 감면 자격이 상실된 경우 지체없이 회사에
                                                통보하여야 합니다<span lang="EN-US">(</span>회사는 요금감면 전화서비스
                                                이용자격 여부를 한국정보통신진흥협회 및 행정안전부<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >보건복지부<span lang="EN-US">,&nbsp;</span>국가보훈처 등에서 상시적으로
                                                확인하고 자격상실<span lang="EN-US">,&nbsp;</span>무자격 등의 경우
                                                혜택을 중단함<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">13.&nbsp;</span
                                            ><span style="color: black">
                                                발신번호 변경서비스를 제공받고자 하는 이용자는 회사에서 요청하는
                                                본인확인절차를 충실히 이행하여야 합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">13</span>조<span lang="EN-US">&nbsp;(</span
                                                >변경주소 등 통지의무<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                고객은 이용금액을 완납하기 전에 주소<span lang="EN-US">,&nbsp;</span
                                                >결제할 신용카드등 지급수단과 관련한 사항과 잔여 이용금액에 대한
                                                청구내역을 수신할 이동전화번호가 변경되는 경우 그 변경사항을 즉시 회사에
                                                통보하여야 하며<span lang="EN-US">,&nbsp;</span>이를 미이행하여 받는
                                                불이익에 대해서는 이의할 수 없습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">14</span>조<span lang="EN-US">&nbsp;(</span>수수료
                                                등의 부당한 산정금지<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                회사는 이동전화 서비스와 관련하여 계약관계 등을 갖고 있는 자에게
                                                요금<span lang="EN-US">,&nbsp;</span>수수료<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >결제 조건 등의 거래내용을 정상적인 상관행에 비추어 현저하게 유리하거나
                                                불리하게 제공할 수 없습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">15</span>조<span lang="EN-US">&nbsp;(</span
                                                >아이디와 비밀번호관리에 대한 의무<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                회원의<span lang="EN-US">&nbsp;</span>“아이디”와<span lang="EN-US"
                                                    >&nbsp;</span
                                                >“비밀번호”의 관리 소홀<span lang="EN-US">,&nbsp;</span>부정 사용에
                                                의하여 발생하는 모든 결과에 대한 책임은<span lang="EN-US">&nbsp;</span
                                                >“회원”<span lang="EN-US">&nbsp;</span>본인에게 있으며<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >시스템 고장 등<span lang="EN-US">&nbsp;</span>“회사”의 책임 있는 사유로
                                                발생하는 문제에 대해서는<span lang="EN-US">&nbsp;</span>“회사”가 책임을
                                                집니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                회원은 본인의<span lang="EN-US">&nbsp;</span>“아이디”<span lang="EN-US"
                                                    >&nbsp;</span
                                                >및<span lang="EN-US">&nbsp;</span>“비밀번호”를 제<span lang="EN-US"
                                                    >3</span
                                                >자에게 노출하거나 이용하도록 해서는 안되며<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >노출 및 도용 시 제<span lang="EN-US">3</span>자의 이용에 따라 개인정보
                                                노출<span lang="EN-US">,&nbsp;</span>부정 사용에 따른 민·형사 책임 및
                                                이용 요금 부과 등이 발생할 수 있으므로<span lang="EN-US">, </span
                                                >“회원”은<span lang="EN-US">&nbsp;</span>“회원”<span lang="EN-US"
                                                    >&nbsp;</span
                                                >아이디 및 비밀번호 관리를 철저히 하여야 합니다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3.</span
                                            ><span style="color: black">
                                                “아이디”<span lang="EN-US">&nbsp;</span>및<span lang="EN-US"
                                                    >&nbsp;</span
                                                >“비밀번호”를 도난 당하거나 제<span lang="EN-US">3</span>자가 사용하고
                                                있음을 인지하는 경우에는 바로<span lang="EN-US">&nbsp;</span>“회사”에
                                                통지하고<span lang="EN-US">&nbsp;</span>“회사”의 안내가 있는 경우 그에
                                                따라야 합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4.&nbsp;</span
                                            ><span style="color: black">
                                                제<span lang="EN-US">&nbsp;3&nbsp;</span>항의 경우에 해당<span
                                                    lang="EN-US"
                                                    >&nbsp;</span
                                                >“회원”이<span lang="EN-US">&nbsp;</span>“회사”에 그 사실을 통지하지
                                                않거나<span lang="EN-US">,&nbsp;</span>통지한 경우에도<span lang="EN-US"
                                                    >&nbsp;</span
                                                >“회사”의 안내에 따르지 않아 발생한 불이익에 대하여<span lang="EN-US"
                                                    >&nbsp;</span
                                                >“회사”는 책임지지 않습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">5.&nbsp;</span
                                            ><span style="color: black">
                                                회사는<span lang="EN-US">&nbsp;&quot;</span>회원<span lang="EN-US"
                                                    >&quot;</span
                                                >의<span lang="EN-US">&nbsp;&quot;</span>아이디<span lang="EN-US"
                                                    >&quot;</span
                                                >가 개인정보 유출 우려가 있거나<span lang="EN-US">,&nbsp;</span>반사회적
                                                또는 미풍양속에 어긋나거나<span lang="EN-US">&nbsp;&quot;</span
                                                >회사<span lang="EN-US">&quot;&nbsp;</span>및<span lang="EN-US"
                                                    >&nbsp;&quot;</span
                                                >회사<span lang="EN-US">&quot;</span>의 운영자로 오인할 우려가 있는
                                                경우<span lang="EN-US">,&nbsp;</span>해당<span lang="EN-US"
                                                    >&nbsp;&quot;</span
                                                >아이디<span lang="EN-US">&quot;</span>의 이용을 제한할 수 있습니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">16</span>조<span lang="EN-US">&nbsp;(</span>고객에
                                                대한 통지<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 회원이 회사에 제출한<span lang="EN-US">&nbsp;e-mail</span>주소
                                                또는<span lang="EN-US">&nbsp;SMS&nbsp;</span>등 기타 통신수단을 이용하여
                                                회원에 대한 통지를 할 수 있습니다<span lang="EN-US">.&nbsp;</span
                                                >회원이<span lang="EN-US">&nbsp;e-mail</span>주소 또는 전화번호 등의
                                                정보를 잘못 제출하여 통지가 이루어지지 않는 경우 회사는 회원이 제출한
                                                정보로 발송한 때에 회원에게 도달한 것으로 봅니다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 전체 회원 및 불특정다수 회원에 대한 통지의 경우 회사의 게시판
                                                등에 게시함으로써 개별 통지를 갈음할 수 있습니다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black"
                                                >제<span lang="EN-US">5</span>장 서비스 제공 및 이용</span
                                            >
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">17</span>조<span lang="EN-US">&nbsp;(</span>계약의
                                                발효 및 종료에 대한 이용금액의 일할계산<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                기본료<span lang="EN-US">(</span>월정액<span lang="EN-US">)</span>는
                                                서비스 개통일로부터 산정합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                국내통화료 및 국제통화료는 통화시간에 의하여 산정합니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3.&nbsp;</span
                                            ><span style="color: black">
                                                후불서비스의 월정액으로 납입하는 요금 등의 경우 변동사유 발생일이
                                                요금월의 중도에 있는 때에는 실제 사용일수로 일할 계산합니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4.&nbsp;</span
                                            ><span style="color: black">
                                                일수계산에 있어서는 그 날이<span lang="EN-US">&nbsp;24</span>시간
                                                미만이더라도 이를<span lang="EN-US">&nbsp;1</span>일로 계산합니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">5.&nbsp;</span
                                            ><span style="color: black">
                                                일할요금 계산시 적용되는 요금부과일수<span lang="EN-US">(</span
                                                >사용일수<span lang="EN-US">,</span>정지일수 등<span lang="EN-US"
                                                    >)</span
                                                >는 다음 각호와 같이 산정합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1)&nbsp;</span
                                            ><span style="color: black">
                                                각 사유발생시 사유발생 당일부터 산입
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2)&nbsp;</span
                                            ><span style="color: black">
                                                각 사유해제시 사유해제 전일까지 산입
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">6.&nbsp;</span
                                            ><span style="color: black">
                                                선불서비스를 중도에 가입계약의 해지를 할 경우에는 남은 기간의 제공량에
                                                대한 부분과 잔여 충전금액은 환불하지 않습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">7.&nbsp;</span
                                            ><span style="color: black">
                                                선불서비스의 경우 사용 유효기간은 개통 후<span lang="EN-US"
                                                    >&nbsp;30</span
                                                >일 단위로 이용 가능하며 정액요금의 경우 요금 납부시에 일괄적으로
                                                제공량이 제공됩니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">8.&nbsp;</span
                                            ><span style="color: black">
                                                선불서비스의 부가서비스 요금 등은 일단위로<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >기본 제공량 외 초과되는 음성<span lang="EN-US">/</span>영상통화료 및
                                                문자서비스는 사용시 정산됩니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">18</span>조<span lang="EN-US">&nbsp;(</span>요금
                                                등의 납입 청구<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 고객에게 회사가 지정하는 기일에 요금 등을 납입하도록
                                                청구합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 요금 등의 청구 업무를 위해 전문 업체를 통해 위탁 업무를 수행 할
                                                수 있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">19</span>조<span lang="EN-US">&nbsp;(</span>주문 및
                                                개통 취소<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                다음 각 호에 해당하는 경우 주문이 취소될 수 있습니다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1)&nbsp;</span
                                            ><span style="color: black">
                                                가입신청서 작성 및 서류송부가 완료되지 않은 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2)&nbsp;</span
                                            ><span style="color: black">
                                                신용불량<span lang="EN-US">,&nbsp;</span>보증보험사고자<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >체납<span lang="EN-US">,&nbsp;</span>번호이동 인증 불일치<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >해피콜 불가능 등의 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3)&nbsp;</span
                                            ><span style="color: black">
                                                신청한 상품의 재고가 소진되어 서비스를 제공할 수 없는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                단순변심의 사유로는 개통 취소가 불가능하며<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >도시락모바일 이용약관 및 유심 호환 불가의 사유로<span lang="EN-US"
                                                    >&nbsp;(</span
                                                >개통 후<span lang="EN-US">&nbsp;14</span>일 이내<span lang="EN-US"
                                                    >)&nbsp;</span
                                                >취소 요청을 할 수 있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">20</span>조<span lang="EN-US">&nbsp;(</span>배송 및
                                                개통안내<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                가입한 상품의 배송처리 방법은 가입 시 신청서에 기입한 주소로 택배
                                                발송됩니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                택배 발송 시 상품의 개통처리 여부는 다음과 같습니다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1)&nbsp;</span
                                            ><span style="color: black">
                                                번호이동<span lang="EN-US">&nbsp;:&nbsp;</span>택배 수령 후 개통 신청을
                                                통해 가능
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2)&nbsp;</span
                                            ><span style="color: black">
                                                신규가입<span lang="EN-US">&nbsp;:&nbsp;</span>개통 완료 후 택배를 통해
                                                발송
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">21</span>조<span lang="EN-US">&nbsp;(</span
                                                >체납요금 징수 등<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 요금 등의 납부통지를 받은 고객이 회사가 정한 납부기한까지 요금
                                                등을 납부하지 아니한 경우에 그 미납요금을 익월에 합산하여
                                                청구합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                요금 등을 지정한 기일까지 납입하지 아니한 때에는 그 요금의<span
                                                    lang="EN-US"
                                                    >&nbsp;100</span
                                                >분의<span lang="EN-US">&nbsp;2</span>에 상당하는 가산금을
                                                부과합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">22</span>조<span lang="EN-US">&nbsp;(</span
                                                >이용금액 등의 이의신청<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                고객은 청구된 이용금액 등에 대하여 이의가 있는 경우 청구일로부터<span
                                                    lang="EN-US"
                                                    >&nbsp;6</span
                                                >개월 이내에 이의신청을 할 수 있습니다<span lang="EN-US">.&nbsp;</span
                                                >단<span lang="EN-US">,&nbsp;</span>회사의 귀책사유로 과<span
                                                    lang="EN-US"
                                                    >&nbsp;</span
                                                >·<span lang="EN-US">&nbsp;</span>오납이 발생한 경우<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >고객은 본문에 관계없이 청구된 이용금액 등에 대하여 이의신청을 할 수
                                                있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 제<span lang="EN-US">1</span>항의 이의신청에 대하여 접수 후 즉시
                                                타당성 여부를 조사하고<span lang="EN-US">,&nbsp;</span>그 결과를<span
                                                    lang="EN-US"
                                                    >&nbsp;10</span
                                                >일 이내에 고객 또는 그 대리인에게 통지합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3.&nbsp;</span
                                            ><span style="color: black">
                                                과금정보 보유근거는 회사와 가입자간에 분쟁이 발생할 가능성이 있는 경우
                                                입증자료활용 및 고객의 요청에 의해 열람하고자 할 경우 자료를 제공하기
                                                위함입니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4.&nbsp;</span
                                            ><span style="color: black">
                                                과금정보로 보관하는 항목은 발신통화번호<span lang="EN-US">,&nbsp;</span
                                                >통화시각<span lang="EN-US">,&nbsp;</span>요금종류<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >사용시간<span lang="EN-US">,&nbsp;</span>도수<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >통화청구금액<span lang="EN-US">,&nbsp;</span>통화할인전금액 등
                                                입니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">5.&nbsp;</span
                                            ><span style="color: black">
                                                과금정보의 보유기간은 제<span lang="EN-US">1</span>항의 이의신청 기간과
                                                동일합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">23</span>조<span lang="EN-US">&nbsp;(</span
                                                >이용금액 등의 반환<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 이용금액 등을 반환하여야 할 회원에게 미납 이용금액 등이 있을
                                                경우에는 반환해야 할 이용금액 등에서 이를 우선 공제하고 반환할 수
                                                있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                회사의 귀책사유로 이용금액 등의 과<span lang="EN-US">&nbsp;</span>·<span
                                                    lang="EN-US"
                                                    >&nbsp;</span
                                                >오납이 발생한 경우에는 그 과<span lang="EN-US">&nbsp;</span>·<span
                                                    lang="EN-US"
                                                    >&nbsp;</span
                                                >오납된 금액을 반환합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                회사의 귀책사유로 발생한 경우에는 고객과 합의한 적정이율 또는 적정이율에
                                                대한 합의가 되지 않을 경우에는 상법상의 법정이율을 부가하여
                                                반환합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                단<span lang="EN-US">,&nbsp;</span>고객이 동의하거나 회사의 반환통지에
                                                대하여 응하지 아니하는 경우에는 새로이 발생하는 이용금액 등에서 해당
                                                금액을 차감하여 청구할 수 있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 고객이 그의 책임이 없는 사유로 인하여 서비스를 제공받지 못한
                                                경우로서 그 뜻을 회사에 통지한 때<span lang="EN-US">(</span>그 전에
                                                회사가 그 뜻을 안 때에는 그 알게 된 때<span lang="EN-US">)</span>로부터
                                                계속<span lang="EN-US">&nbsp;3</span>시간 이상 서비스를 제공받지
                                                못하거나<span lang="EN-US">&nbsp;1</span>개월 동안의 서비스 장애발생
                                                누적시간이<span lang="EN-US">&nbsp;12</span>시간을 초과한 경우에는
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                그 서비스를 제공받지 못한 일수에 따라 월정요금을 일할계산하여
                                                반환합니다<span lang="EN-US">.&nbsp;</span>다만<span lang="EN-US"
                                                    >, 1</span
                                                >회<span lang="EN-US">&nbsp;3</span>시간 미만 장애발생에 대하여는 실제
                                                장애시간을 누적한 시간을<span lang="EN-US">&nbsp;1</span>일 단위로
                                                계산하고<span lang="EN-US">&nbsp;2</span>일에 거쳐 장애가 발생하더라도
                                                장애발생 누적시간이<span lang="EN-US">&nbsp;24</span>시간 미만일
                                                경우에는<span lang="EN-US">&nbsp;1</span>일로 계산하여 적용합니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">24</span>조<span lang="EN-US">&nbsp;(</span
                                                >개인정보관리<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 관계 법령이 정하는 바에 따라서 회원의 개인정보를 보호하기 위해
                                                노력합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                회원의 개인정보<span lang="EN-US">&nbsp;DB</span>는 이용금액<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >중도해지<span lang="EN-US">&nbsp;</span>·<span lang="EN-US"
                                                    >&nbsp;</span
                                                >미반납 변상금 등의 정산 등을 목적으로 계약종료 후<span lang="EN-US"
                                                    >&nbsp;6</span
                                                >개월간 보관합니다<span lang="EN-US">.&nbsp;</span>단<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >전자상거래법 대금결제 및 재화 등의 공급에 관한 법령에 따라 계약종료
                                                후<span lang="EN-US">&nbsp;5</span>년까지 보존할 수 있습니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3.&nbsp;</span
                                            ><span style="color: black">
                                                회사 내의 데이터베이스에 보존된 자료와 청구 기관 측의 데이터베이스에
                                                보존된 자료의 기재내용이 상이하여 회사가 회원에 대하여 자료 기재 내용의
                                                확인을 요구할 경우에는 회원은 이에 응해야 합니다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4.&nbsp;</span
                                            ><span style="color: black">
                                                회원이 요금명세서를 이메일로 추가 요청할 경우 최근 청구일로부터<span
                                                    lang="EN-US"
                                                    >&nbsp;6</span
                                                >개월까지 제공합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">5.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 수집된 개인정보의 취급 및 관리 등의 업무<span lang="EN-US"
                                                    >(</span
                                                >이하<span lang="EN-US">&nbsp;&quot;</span>업무<span lang="EN-US"
                                                    >&quot;)</span
                                                >를 스스로 수행함을 원칙으로 하나<span lang="EN-US">,&nbsp;</span>필요한
                                                경우 업무의 일부 또는 전부를 회사가 선정한<span lang="EN-US"
                                                    >&nbsp;</span
                                                >“위탁사”에 위탁할 수 있습니다<span lang="EN-US">.&nbsp;</span>회원의
                                                개인 정보 위탁에 대해서는 회사의 개인정보처리방침이 적용됩니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">6.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 회원의 귀책 사유로 인해 노출된 정보에 대해서 일체의 책임을 지지
                                                않습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">25</span>조<span lang="EN-US">&nbsp;(</span
                                                >회원탈퇴 및 휴면<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                회원이 웹 서비스 회원탈퇴를 원할 경우 본 사이트의 회원탈퇴 신청 또는
                                                회사가 정한 별도의 이용방법으로 회사에 해지신청을 하여야 합니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 회원의 회원탈퇴 신청 시 관련법 등이 정하는 바에 따라 이를 즉시
                                                처리하여야 합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3.&nbsp;</span
                                            ><span style="color: black">
                                                회원이 도시락 렌탈 서비스를 이용중인 경우 회원 탈퇴가 불가합니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4.&nbsp;</span
                                            ><span style="color: black">
                                                회원 탈퇴 시 관련법 및 개인정보취급방침에 따라 회사가 회원 정보를
                                                보유하는 경우를 제외하고는 탈퇴 시 회원의 모든 데이터는 소멸되며<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >회사에서 정하는 일정 기간 동안 회원 가입 제한을 받을 수 있습니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">5.&nbsp;</span
                                            ><span style="color: black">
                                                회사는<span lang="EN-US">&nbsp;ID</span>를 통한 사이트의 최종 접속
                                                후<span lang="EN-US">&nbsp;12</span>개월간 접속 기록이 없는 회원에 대해
                                                서비스 미이용 기간<span lang="EN-US">(</span>최종 접속 후<span
                                                    lang="EN-US"
                                                    >&nbsp;1</span
                                                >년<span lang="EN-US">)&nbsp;</span>만료 후 개인정보가 파기된다는 사실을
                                                통지하고<span lang="EN-US">,&nbsp;</span>회원이 통지일로부터<span
                                                    lang="EN-US"
                                                    >&nbsp;1</span
                                                >개월 내에 재접속을 하지 않을 경우 서비스를 해지하며 회원의 개인정보를
                                                파기합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                또한<span lang="EN-US">,&nbsp;</span>회원의<span lang="EN-US"
                                                    >&nbsp;e-mail&nbsp;</span
                                                >또는 이동전화 연락처의 부재·변경·오류 등으로 개별 통지가 어려운 경우
                                                서비스 미이용 기간 만료 후 개인정보 파기 조치 등에 대한 사전 통지 없이
                                                본 조항에 의거 최종 접속 후<span lang="EN-US">&nbsp;1</span>년간 접속
                                                기록이 없는 회원에 대하여 서비스를 해지하고 개인정보를 파기합니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black"
                                                >제<span lang="EN-US">6</span>장 계약사항 변경 및 해지 등</span
                                            >
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">26</span>조<span lang="EN-US">(</span>계약사항의
                                                변경<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                고객은 다음 각 호의 하나에 해당하는 이용계약사항을 변경하고자 할
                                                경우에는 변경신청서와 회사에서 정하는 구비서류를 제출하여야 합니다<span
                                                    lang="EN-US"
                                                    >.&nbsp;</span
                                                >단<span lang="EN-US">,&nbsp;</span>주소변경<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >번호변경<span lang="EN-US">,&nbsp;</span>단말기변경은 고객센터를 통해
                                                가능합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1)&nbsp;</span
                                            ><span style="color: black">
                                                서비스종류 또는 단말기를 변경하고자 하는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2)&nbsp;</span
                                            ><span style="color: black">
                                                고객이 제<span lang="EN-US">3</span>자에게 이용권을 양도 또는 승계하고자
                                                하는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3)&nbsp;</span
                                            ><span style="color: black">
                                                고객이 번호를 변경하고자 하는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4)&nbsp;</span
                                            ><span style="color: black">
                                                기타 변경이 필요한 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                고객은 다음 각 호의 하나에 해당하는 이용계약사항을 변경하고자 할
                                                경우에는 방문 외에 전화나 팩스 등으로도 신청할 수 있습니다<span
                                                    lang="EN-US"
                                                    >.(</span
                                                >이 경우 고객 기본정보 확인<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1)&nbsp;</span
                                            ><span style="color: black">주소의 변경</span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2)&nbsp;</span
                                            ><span style="color: black">
                                                부가서비스의 신청<span lang="EN-US">,&nbsp;</span>변경 및 해지
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3)&nbsp;</span
                                            ><span style="color: black">전화번호의 변경</span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3.&nbsp;</span
                                            ><span style="color: black">
                                                제<span lang="EN-US">1</span>항과 제<span lang="EN-US">2</span>항<span
                                                    lang="EN-US"
                                                    >(</span
                                                >부가서비스의 해지 제외<span lang="EN-US">)</span>의 규정에 의한 고객의
                                                변경신청에 대하여 다음 각 호의 하나에 해당하는 경우에는 응하지 아니할 수
                                                있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1)&nbsp;</span
                                            ><span style="color: black">
                                                고객이 요금 등을 미납하였을 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2).&nbsp;</span
                                            ><span style="color: black">
                                                압류<span lang="EN-US">,&nbsp;</span>가압류 또는 가처분된 단말기인 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3)&nbsp;</span
                                            ><span style="color: black">
                                                개별 약정사항을 위반하였을 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4)&nbsp;</span
                                            ><span style="color: black">
                                                제<span lang="EN-US">1</span>항<span lang="EN-US">&nbsp;1</span>호
                                                및<span lang="EN-US">&nbsp;2</span>호의 경우로서 고객 본인이 아니거나
                                                본인 여부확인 거부 및 제시한 신분증 및 증서의 진위가 확인되지 않은 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4.&nbsp;</span
                                            ><span style="color: black">
                                                명의변경은 원칙적으로 금지하고<span lang="EN-US">,&nbsp;</span>아래의
                                                경우에는 회사에서 요청하는 구비서류 요건에 따라 지점 또는 대리점에 직접
                                                방문하여 해당 번호에 대한 이용권의 승계를 예외적으로 허용합니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1)&nbsp;</span
                                            ><span style="color: black">가족간 명의변경</span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2)&nbsp;</span
                                            ><span style="color: black">
                                                법인간 사업 양·수도 및 인수·합병 등 기타 사업의 연속성이 확인되는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3)&nbsp;</span
                                            ><span style="color: black">
                                                법인 입·퇴사 후 법인 또는 개인 명의로 변경
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4)&nbsp;</span
                                            ><span style="color: black">
                                                개인 명의를 법인 명의로 변경한 후 다시 동일 개인 명의로 변경
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                단<span lang="EN-US">,&nbsp;</span>최근<span lang="EN-US">&nbsp;3</span
                                                >개월간 연속해서 통화량이 없는 경우 명의변경이 불가하고<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >개인과 법인 상호간 명의변경 횟수는<span lang="EN-US">&nbsp;3</span
                                                >개월에<span lang="EN-US">&nbsp;1</span>회로 제한합니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ※ 구비서류<span lang="EN-US">(</span>개인기준<span lang="EN-US">
                                                    ) :&nbsp; </span
                                                >양도인<span lang="EN-US">/</span>양수인 신분증<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >가족관계 증명서 등<span lang="EN-US">(</span>양도인<span lang="EN-US"
                                                    >/</span
                                                >양수인 동의 통화 필수<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">27</span>조<span lang="EN-US">(</span>이용정지<span
                                                    lang="EN-US"
                                                    >)</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 고객이 다음의 하나에 해당하는 경우에는 즉시 이용을 정지 또는
                                                제한<span lang="EN-US">(</span>이하 통칭하여<span lang="EN-US"
                                                    >&nbsp;</span
                                                >“이용정지등”이라 합니다<span lang="EN-US">)&nbsp;</span>할 수
                                                있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1)&nbsp;</span
                                            ><span style="color: black">
                                                정보통신망 이용촉진 및 정보보호 등에 관한 법률 제<span lang="EN-US"
                                                    >&nbsp;44</span
                                                >조의<span lang="EN-US">&nbsp;7(</span>불법정보의 유통금지 등<span
                                                    lang="EN-US"
                                                    >)&nbsp;</span
                                                >위반시
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2)&nbsp;</span
                                            ><span style="color: black">
                                                전기통신사업법 제<span lang="EN-US">30</span>조<span lang="EN-US"
                                                    >(</span
                                                >타인사용의 제한<span lang="EN-US">)&nbsp;</span>위반시 및 제<span
                                                    lang="EN-US"
                                                    >32</span
                                                >조의<span lang="EN-US">&nbsp;3(</span>전기통신 역무 제공의 제한<span
                                                    lang="EN-US"
                                                    >)</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3)&nbsp;</span
                                            ><span style="color: black">
                                                전파법 제<span lang="EN-US">19</span>조<span lang="EN-US">(</span
                                                >무선국의 개설<span lang="EN-US">)&nbsp;</span>위반시
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4)&nbsp;</span
                                            ><span style="color: black">
                                                타인명의를 도용하여 가입하거나<span lang="EN-US">,&nbsp;</span>타인 예금
                                                계좌나 신용카드를 도용한 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">5)&nbsp;</span
                                            ><span style="color: black">
                                                이동전화 불법복제 방지를 위해 운용중인 불법복제적발시스템을 통해 적발된
                                                불법복제 사용자에 대해 필요하다고 판단되는 경우<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >명의도용 또는 휴대폰 대출 등 부정사용이 우려되는 경우<span lang="EN-US"
                                                    >(</span
                                                >단기간 과다한 요금 발생 등<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">6)&nbsp;</span
                                            ><span style="color: black">
                                                문자<span lang="EN-US">,&nbsp;</span>음성<span lang="EN-US">/</span
                                                >영상통화<span lang="EN-US">(</span>메시지<span lang="EN-US"
                                                    >)&nbsp;</span
                                                >및<span lang="EN-US">&nbsp;MMS(</span>멀티미디어 메세지<span
                                                    lang="EN-US"
                                                    >)&nbsp;</span
                                                >등의 방법을 통해 스팸메시지를 발송하는 경우 다음 각목의 번호를
                                                이용정지할 수 있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">① 스팸발송한 번호</span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">② 스팸발송시 이용된 콜백 번호</span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">7)&nbsp;</span
                                            ><span style="color: black">
                                                스팸발송 및 이용된 콜백 번호가 착신전환을 이용한 경우 해당 착신 지정번호
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">8)&nbsp;</span
                                            ><span style="color: black">
                                                다른 이용자가 수신을 원하지 아니하는 광고성 정보를 발송하는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">9)&nbsp;</span
                                            ><span style="color: black">
                                                제공된 서비스를 불법 광고성 정보 전송에 이용하는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">10)&nbsp;</span
                                            ><span style="color: black">
                                                대량으로 메시지<span lang="EN-US">(SMS, MMS&nbsp;</span>포함<span
                                                    lang="EN-US"
                                                    >)/</span
                                                >음성<span lang="EN-US">(</span>영상<span lang="EN-US">)</span>통화호
                                                등을 전송하여 타이용자의 통화장애 또는 시스템 장애를 야기하거나 야기할
                                                우려가 있는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">11)&nbsp;</span
                                            ><span style="color: black">
                                                방송통신위원회 또는 한국인터넷진흥원이 스팸<span lang="EN-US">(</span
                                                >불법스팸 포함<span lang="EN-US">)&nbsp;</span>전송사실을 확인하여
                                                이용정지를 요청하는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">12)&nbsp;</span
                                            ><span style="color: black">
                                                스팸<span lang="EN-US">(</span>불법스팸 포함<span lang="EN-US"
                                                    >)&nbsp;</span
                                                >발송자가 이용중인 다른 번호가 스팸에 악용되고 있는 객관적 정황이 있는
                                                경우에는 그 다른 번호도 대상으로 함
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">13)&nbsp;</span
                                            ><span style="color: black">
                                                제<span lang="EN-US">12</span>항의 규정에 의한 고객의 의무인
                                                요금납부를<span lang="EN-US">&nbsp;2</span>회이상 연속 미납한 경우<span
                                                    lang="EN-US"
                                                    >(</span
                                                >단<span lang="EN-US">, 1</span>회 미납액이<span lang="EN-US"
                                                    >&nbsp;7</span
                                                >만원 이상인 경우에는<span lang="EN-US">&nbsp;1</span>회 미납 시<span
                                                    lang="EN-US"
                                                    >)</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">14)&nbsp;</span
                                            ><span style="color: black">
                                                법인명의로 가입된 회선으로 폐업법인으로 확인된 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">15)&nbsp;</span
                                            ><span style="color: black">
                                                약관과 다르게 또는 회사와 계약 또는 동의 없이 사업의 영위<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >경제적인 이득 또는 요금 할인 등의 목적으로 통화호 중계<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >통화호 재판매 사업<span lang="EN-US">,&nbsp;</span>착신전환 등을
                                                이용하는 경우 및 이를 활용한 서비스를 이용하는 고객
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">16)&nbsp;</span
                                            ><span style="color: black">
                                                이동전화로 수신되는 통화 혹은 메시지를 착신전환 등 부가서비스를
                                                이용하여<span lang="EN-US">&nbsp;2</span>회 이상 망내<span lang="EN-US"
                                                    >/</span
                                                >외 여러 단계를 경유하도록 연결하는 행위
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">17)&nbsp;</span
                                            ><span style="color: black">
                                                외국인 명의로 가입된 회선으로 합법 체류기간이 만료된 경우<span
                                                    lang="EN-US"
                                                    >&nbsp;(</span
                                                >단<span lang="EN-US">,&nbsp;</span>회사가 정한 우량외국인이거나<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >합법체류기간이 연장되었음을 증빙할 수 있는 서류 제출시 제외하며<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >체류기간 연장 심사 중인 경우<span lang="EN-US">,&nbsp;</span>접수증
                                                제출 시 이용정지를 유예할 수 있으며 사유를 해소하지 못하는 경우 해지할
                                                수 있음<span lang="EN-US">.)&nbsp;</span>또는 외국인 가입자의 사망 및
                                                외국인등록번호<span lang="EN-US">(</span>또는 국내거소신고번호<span
                                                    lang="EN-US"
                                                    >)</span
                                                >가 유효하지 않거나 외국인가입자가 가입 당시 회사에 고지한 국적 등
                                                고객정보의 변동을 회사에 통보 및 갱신하지 않는 경우<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">18)&nbsp;</span
                                            ><span style="color: black">
                                                외국인의<span lang="EN-US">&nbsp;(</span>글로벌<span lang="EN-US"
                                                    >)&nbsp;</span
                                                >로밍서비스 이용요금이 일<span lang="EN-US">&nbsp;11</span>만원<span
                                                    lang="EN-US"
                                                    >(VAT</span
                                                >포함<span lang="EN-US">)&nbsp;</span>또는 누적<span lang="EN-US"
                                                    >&nbsp;55</span
                                                >만원<span lang="EN-US">&nbsp;(VAT</span>포함<span lang="EN-US">)</span
                                                >이상인 경우 회사는 모니터링 활동을 통해 고객의 과거 및 현재
                                                신용상태<span lang="EN-US">(</span>요금납부<span lang="EN-US">?</span
                                                >연체 이력 등<span lang="EN-US">),&nbsp;</span>가입기간<span
                                                    lang="EN-US"
                                                    >(150</span
                                                >일 내<span lang="EN-US">),</span>잔여 체류기간<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >연락가능 여부<span lang="EN-US">(3</span>회 이상 통화 시도<span
                                                    lang="EN-US"
                                                    >),&nbsp;</span
                                                >비정상적 통화패턴 등을 고려하여 국제전화 및 로밍서비스를 제한할 수
                                                있습니다
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">19)&nbsp;</span
                                            ><span style="color: black">
                                                서비스 제공 목적 외 사용<span lang="EN-US">,&nbsp;</span>제<span
                                                    lang="EN-US"
                                                    >3</span
                                                >자에 임의로 임대한 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">20)&nbsp;</span
                                            ><span style="color: black">
                                                광고성정보 등 영리목적 이용<span lang="EN-US">,&nbsp;</span>물리적 장치
                                                등을 이용한 문자 전송 또는 통화를 유발한 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">21)&nbsp;</span
                                            ><span style="color: black">
                                                청소년보호법 제<span lang="EN-US">&nbsp;19</span>조 제<span lang="EN-US"
                                                    >&nbsp;1</span
                                                >항<span lang="EN-US">,&nbsp;</span>성매매알선 등 행위의 처벌에 관한
                                                법률 제<span lang="EN-US">&nbsp;4</span>조<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >대부업 등의 등록 및 금융이용자 보호에 관한 법률 제<span lang="EN-US"
                                                    >&nbsp;9</span
                                                >조의<span lang="EN-US">2</span>를 위반하여 수사권한이 있는 행정기관
                                                또는 수사기관에서 특정한 전화번호에 대한 전기통신서비스의 이용정지를
                                                요청한 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">22)&nbsp;</span
                                            ><span style="color: black">
                                                불법 대부광고에 이용된 전화번호는 이용계약 해지일로부터<span
                                                    lang="EN-US"
                                                    >&nbsp;3</span
                                                >개월간 이용 당사자는 물론<span lang="EN-US">,&nbsp;</span>다른
                                                이용자에게 재부여하지 않도록 기술적 또는 관리적 조치를 하여야 함
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">23)&nbsp;</span
                                            ><span style="color: black">
                                                대부업 등의 등록 및 금융이용자 보호에 관한 법률 제<span lang="EN-US"
                                                    >9</span
                                                >조 및 제<span lang="EN-US">9</span>조의<span lang="EN-US">2</span>를
                                                위반하여과학기술정보통신부장관이 전기통신사업법 제<span lang="EN-US"
                                                    >32</span
                                                >조의<span lang="EN-US">3</span>에 따라 특정한 전화번호에 대한
                                                전기통신서비스의 이용정지를 명한 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">24)&nbsp;</span
                                            ><span style="color: black">
                                                복지감면 대상 회선을 상업적 목적으로 이용하는 행위
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">25)&nbsp;</span
                                            ><span style="color: black">
                                                개인고객이 사망말소자로 확인된 경우<span lang="EN-US">&nbsp;(</span
                                                >단<span lang="EN-US">,&nbsp;</span>재난 및 안전관리기본법에서 지정하는
                                                국가적 재난으로 사망한 피해자의 회선은 유족들의 회선유지 요청이 있을
                                                경우 수신에 한해서 유지할 수 있습니다<span lang="EN-US">.)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">26)&nbsp;</span
                                            ><span style="color: black">
                                                고객이 가입 시 회사에 제시한 신분증 및 증서가 유효하지 않은 것으로
                                                확인된 경우<span lang="EN-US">,&nbsp;</span>이 경우 회사는 고객에게
                                                신분증 및 증서 등의 유효 여부를 추가로 확인 요청할 수 있습니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">27)&nbsp;</span
                                            ><span style="color: black">
                                                과학기술정보통신부 또는 한국인터넷진흥원에서 발신번호 변작과 관련하여
                                                이용정지를 요청하는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">28)&nbsp;</span
                                            ><span style="color: black">
                                                발신번호가 한국인터넷진흥원에서 제공하는 변작번호 차단목록에 포함된 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">29)&nbsp;</span
                                            ><span style="color: black">
                                                발신번호 변작으로 위법에 따른 조치를 받게 된 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">30)&nbsp;</span
                                            ><span style="color: black">
                                                인터넷발송 문자메시지의 발신번호 변작이 확인되거나 기타 서비스에서
                                                발신번호 변작이 확인된 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">31)&nbsp;</span
                                            ><span style="color: black">
                                                본인 명의가 아닌 번호를 부정하게 사용하는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">32)&nbsp;</span
                                            ><span style="color: black">
                                                전기통신번호 매매와 관련하여<span lang="EN-US">2</span>회 이상 적발된
                                                회수대상 번호인 경우<span lang="EN-US">(</span>과학기술정보통신부 요청
                                                등<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">33)&nbsp;</span
                                            ><span style="color: black">
                                                수사권한이 있는 행정기관이 보이스피싱에 이용중인 사실을 확인하여
                                                이용정지를 요청하는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">34)&nbsp;</span
                                            ><span style="color: black">
                                                한국인터넷진흥원이 악성앱에 포함된 가로채기 전화번호를 확인하여 이용
                                                정지를 요청하는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 이용정지등 조치를 취한 경우 고객에게 그 사유<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >일시 및 기간을 명시하여 요금청구서나<span lang="EN-US">&nbsp;SMS(</span
                                                >단문메시지<span lang="EN-US">),&nbsp;</span>전화 등의 방법으로
                                                통지합니다<span lang="EN-US">.&nbsp;</span>다만<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >해당 고객의 책임 있는 사유로 통지할 수 없는 경우는 통지된 것으로
                                                간주합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1)&nbsp;</span
                                            ><span style="color: black">
                                                제<span lang="EN-US">&nbsp;1</span>항<span lang="EN-US">&nbsp;13</span
                                                >호 고객의 의무인 요금납부를<span lang="EN-US">&nbsp;2</span>회이상 연속
                                                미납한 경우는 이용정지<span lang="EN-US">&nbsp;7</span>일 전까지
                                                통지합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2)&nbsp;</span
                                            ><span style="color: black">
                                                제<span lang="EN-US">&nbsp;1</span>항<span lang="EN-US">&nbsp;21</span
                                                >호의 경우는 이용정지<span lang="EN-US">&nbsp;2</span>일 이내에<span
                                                    lang="EN-US"
                                                    >(</span
                                                >공휴일<span lang="EN-US">,&nbsp;</span>토요일 제외<span lang="EN-US"
                                                    >)&nbsp;</span
                                                >통지합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3.&nbsp;</span
                                            ><span style="color: black">
                                                제<span lang="EN-US">2</span>항의 규정에 의하여 이용정지의 통지를 받은
                                                고객은 그 이용정지에 대하여 이의가 있을 때에는 방문<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >전화<span lang="EN-US">,&nbsp;</span>팩스 등으로 이의를 제기할 수
                                                있습니다<span lang="EN-US">. (</span>단<span lang="EN-US">,&nbsp;</span
                                                >본조<span lang="EN-US">&nbsp;1</span>항<span lang="EN-US"
                                                    >&nbsp;21</span
                                                >호의 경우 이용정지의 통지를 받은 고객은 요청기관의 이용정지 결정에
                                                대하여 이의가 있을 시 이용정지 요청기관에 이의를 제기하여야 합니다<span
                                                    lang="EN-US"
                                                    >.) 1.&nbsp;</span
                                                >본조 제<span lang="EN-US">1</span>항<span lang="EN-US">&nbsp;32</span
                                                >호의 경우<span lang="EN-US">,&nbsp;</span>이용정지 후<span lang="EN-US"
                                                    >&nbsp;1</span
                                                >개월 이내에 이의를 제기하여야 합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1)&nbsp;</span
                                            ><span style="color: black">
                                                본조 제<span lang="EN-US">1</span>항<span lang="EN-US">&nbsp;32</span
                                                >호의 경우<span lang="EN-US">,&nbsp;</span>이용정지 후<span lang="EN-US"
                                                    >&nbsp;1</span
                                                >개월 이내에 이의를 제기하여야 합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 이용정지등의 원인이 된 사유가 완전히 해소되기 전까지는 이용정지등
                                                조치를 해제하지 아니할 수 있습니다
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1)&nbsp;</span
                                            ><span style="color: black">
                                                스팸<span lang="EN-US">(</span>불법 스팸 포함<span lang="EN-US">)</span
                                                >관련정지 사유 또는 고객의무사항인 요금납부를<span lang="EN-US"
                                                    >&nbsp;2</span
                                                >회 이상 연속 미납한 경우에는<span lang="EN-US">(</span>단<span
                                                    lang="EN-US"
                                                    >, 77,000</span
                                                >원<span lang="EN-US">(VAT</span>포함<span lang="EN-US">)&nbsp;</span
                                                >이상의 경우<span lang="EN-US">&nbsp;1</span>회 미납시<span lang="EN-US"
                                                    >)&nbsp;</span
                                                >만<span lang="EN-US">&nbsp;5</span>개월 이내의 이용정지를 실시할 수
                                                있습니다<span lang="EN-US">. (</span>단<span lang="EN-US">,&nbsp;</span
                                                >요금 미납후 고객과 연락이 안되는 경우 회사는 이용정지기간을 만<span
                                                    lang="EN-US"
                                                    >&nbsp;3</span
                                                >개월로 정할 수 있음<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2)&nbsp;</span
                                            ><span style="color: black">
                                                본조<span lang="EN-US">&nbsp;1</span>항<span lang="EN-US">&nbsp;21</span
                                                >호의 경우는<span lang="EN-US">&nbsp;3</span>개월 이내로 이용정지를
                                                실시할 수 있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3)&nbsp;</span
                                            ><span style="color: black">
                                                본조<span lang="EN-US">&nbsp;1</span>항<span lang="EN-US">&nbsp;17</span
                                                >호<span lang="EN-US"> , 25 </span>호의 경우 이용정지기간을<span
                                                    lang="EN-US"
                                                    >&nbsp;3</span
                                                >개월 이내로 합니다<span lang="EN-US">.&nbsp;</span>단<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >일시정지 중인 회선 중에서 제<span lang="EN-US">32</span>조<span
                                                    lang="EN-US"
                                                    >(</span
                                                >일시정지<span lang="EN-US">)</span>제<span lang="EN-US">2</span>항의
                                                회사가 인정하는 정당한 사유에 해당하는 경우에는 예외로 할 수
                                                있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">28</span>조<span lang="EN-US">(</span>일시정지<span
                                                    lang="EN-US"
                                                    >)</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                고객은 일정기간동안 서비스를 제공 받지 아니할 사유가 발생한 경우에는
                                                회사에 일시정지를 신청할 수 있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                일시정지는<span lang="EN-US">&nbsp;1</span>회당<span lang="EN-US"
                                                    >&nbsp;90</span
                                                >일의 범위 내에서 신청할 수 있으며<span lang="EN-US">,&nbsp;</span
                                                >일시정지를 처음 신청한 날로부터 기산해서<span lang="EN-US"
                                                    >&nbsp;1</span
                                                >년에<span lang="EN-US">&nbsp;2</span>회에 한해 허용됩니다<span
                                                    lang="EN-US"
                                                    >.&nbsp;</span
                                                >다만<span lang="EN-US">,&nbsp;</span>군입대<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >해외장기체류<span lang="EN-US">,&nbsp;</span>형집행중<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >행방불명 등<span lang="EN-US">&nbsp;(</span>구비서류 첨부 시<span
                                                    lang="EN-US"
                                                    >)&nbsp;</span
                                                >및 단말기 분실 등 회사가 인정하는 정당한 사유가 있을 경우에는 그러하지
                                                아니합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3.&nbsp;</span
                                            ><span style="color: black">
                                                일시정지를 신청하고<span lang="EN-US">&nbsp;90</span>일이 경과하여도
                                                고객이 별도의 신청을 하지 아니하면 서비스가 정상상태로 환원되고
                                                이용요금도 정상 부과됩니다<span lang="EN-US">.&nbsp;</span>이 경우에
                                                회사는 사전에 요금청구서나<span lang="EN-US">&nbsp;SMS(</span
                                                >단문메시지<span lang="EN-US">)&nbsp;</span>등의 방법으로<span
                                                    lang="EN-US"
                                                    >&nbsp;7</span
                                                >일 전까지 고객에게 통보하고 일시정지를 해제합니다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4.&nbsp;</span
                                            ><span style="color: black">
                                                일시정지시 정지 후<span lang="EN-US">&nbsp;30</span>일까지는
                                                착신서비스가 가능하나 그 기간이 경과하면 서비스가 제공되지 않습니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">5.&nbsp;</span
                                            ><span style="color: black">
                                                본조 제<span lang="EN-US">2</span>항의 회사가 인정하는 일시정지 기간
                                                동안 폐업<span lang="EN-US">,&nbsp;</span>완전출국<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >체류기간 경과의 신분변동이 확인되는 경우<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >재이용하기 위해서는 본인확인 절차를 거쳐야 합니다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">6.&nbsp;</span
                                            ><span style="color: black">
                                                회사의<span lang="EN-US">&nbsp;&quot;</span>선불형 서비스<span
                                                    lang="EN-US"
                                                    >&quot;</span
                                                >에 가입한 고객이 일시정지를 신청한 경우 기존 선납한 금액에 상응하는
                                                제공량은 당회까지만 유효하며 회차 종료 시 초기화 됩니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">29</span>조<span lang="EN-US">(</span>계약의
                                                해지<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                고객 또는 대리인이 이용계약을 해지하고자 할 때에는 고객센터에 연락 또는
                                                방문하여 신청할 수 있으며 이에 따라 별도의 구비서류를 요청할 수
                                                있습니다<span lang="EN-US">.&nbsp;</span>또한 전화<span lang="EN-US"
                                                    >/FAX/</span
                                                >우편의 경우 회사가 직영하는 전지역센터<span lang="EN-US">(</span>영업
                                                및 고객센터<span lang="EN-US">)</span>에 신청할 수 있습니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                다만<span lang="EN-US">,&nbsp;</span>전화<span lang="EN-US"
                                                    >/FAX/&nbsp;</span
                                                >우편에 의한 해지신청시<span lang="EN-US">,&nbsp;</span>해지신청일까지의
                                                요금은 해지신청 당일 입금되어야 하고<span lang="EN-US">,&nbsp;</span
                                                >구비서류 및 무통장입금증 등은 해지신청일로부터<span lang="EN-US"
                                                    >&nbsp;14</span
                                                >일이내 회사에 접수 되어야만 해지를 할 수 있습니다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                구비서류가 도착하기 이전에는 일시정지<span lang="EN-US">(</span>발ㆍ착신
                                                정지<span lang="EN-US">)&nbsp;</span>상태를 유지하며 별도 과금하지
                                                않습니다<span lang="EN-US">.&nbsp;</span>단 해지신청 당일 요금입금이
                                                되지 않거나 신청일로부터<span lang="EN-US">&nbsp;14</span>일 이내에
                                                구비서류가 도착하지 않을 경우 서비스는 정상 이용상태로 복귀됩니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 다음 각 호의 하나에 해당하는 경우 이용계약을 해지할 수
                                                있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1)&nbsp;</span
                                            ><span style="color: black">
                                                타인 명의를 사용하거나 허위서류를 첨부한 청약임이 확인된 때
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2)&nbsp;</span
                                            ><span style="color: black">
                                                이용요금 미납으로 이용이 정지된 후 이용정지 기간내에 이용정지 사유를
                                                해소하지 아니한 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3)&nbsp;</span
                                            ><span style="color: black">
                                                불특정 다수를 대상으로 문자<span lang="EN-US">,&nbsp;</span>음성<span
                                                    lang="EN-US"
                                                    >(</span
                                                >영상<span lang="EN-US">)</span>통화<span lang="EN-US">(</span
                                                >메시지<span lang="EN-US">)&nbsp;</span>및<span lang="EN-US"
                                                    >&nbsp;MMS</span
                                                >등의 방법을 통해 무차별적인 스팸메시지 발송이 확인된 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4)&nbsp;</span
                                            ><span style="color: black">
                                                이용정지 기간 경과 이후에도 지속적으로 스팸<span lang="EN-US">(</span
                                                >불법 스팸 포함<span lang="EN-US">)</span>을 전송하는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">5)&nbsp;</span
                                            ><span style="color: black">
                                                스팸<span lang="EN-US">(</span>불법스팸 포함<span lang="EN-US">)</span
                                                >관련하여 방송통신위원회 또는 한국인터넷진흥원에서 계약 해지를 요청하는
                                                경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">6)&nbsp;</span
                                            ><span style="color: black">
                                                회사가 한국인터넷진흥원에 스팸<span lang="EN-US">(</span>불법 스팸
                                                포함<span lang="EN-US">)&nbsp;</span>유무 확인을 요청하여 스팸<span
                                                    lang="EN-US"
                                                    >(</span
                                                >불법스팸 포함<span lang="EN-US">)</span>임이 확인 된 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">7)&nbsp;</span
                                            ><span style="color: black">
                                                스팸<span lang="EN-US">(</span>불법스팸 포함<span lang="EN-US">)</span
                                                >관련하여 년<span lang="EN-US">&nbsp;2</span>회 이상 이용정지가 된 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">8)&nbsp;</span
                                            ><span style="color: black">
                                                회사의 서비스 제공 목적외의 용도로 서비스를 이용하거나<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >제<span lang="EN-US">3</span>자에게 임의로 해당 서비스를 임대한 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">9)&nbsp;</span
                                            ><span style="color: black">
                                                제<span lang="EN-US">27</span>조<span lang="EN-US">&nbsp;1</span>항<span
                                                    lang="EN-US"
                                                    >&nbsp;10</span
                                                >호<span lang="EN-US">, 15</span>호에 해당하는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">10)&nbsp;</span
                                            ><span style="color: black">
                                                제<span lang="EN-US">&nbsp;27</span>조<span lang="EN-US">&nbsp;1</span
                                                >항<span lang="EN-US">&nbsp;16</span>호에 해당되는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">11)&nbsp;</span
                                            ><span style="color: black">
                                                광고성정보 등 영리목적 이용<span lang="EN-US">,&nbsp;</span>물리적 장치
                                                등을 이용한 문자 전송 또는 통화 유발을 위반한 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">12)&nbsp;</span
                                            ><span style="color: black">
                                                제<span lang="EN-US">&nbsp;27</span>조<span lang="EN-US">&nbsp;1</span
                                                >항<span lang="EN-US">&nbsp;14</span>호<span lang="EN-US">, 17</span
                                                >호<span lang="EN-US">, 21</span>호<span lang="EN-US">, 25</span>호<span
                                                    lang="EN-US"
                                                    >, 26</span
                                                >호<span lang="EN-US">, 27</span>호<span lang="EN-US">, 28</span>호<span
                                                    lang="EN-US"
                                                    >, 29</span
                                                >호<span lang="EN-US">, 30</span>호<span lang="EN-US">, 31</span>호<span
                                                    lang="EN-US"
                                                    >, 32</span
                                                >호에 해당되고 이용정지 기간 동안 이용정지 사유에 대한 소명 등의 해소가
                                                되지 않는 경우<span lang="EN-US">(</span>사전통지 없이 해지 가능<span
                                                    lang="EN-US"
                                                    >)</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">13)&nbsp;</span
                                            ><span style="color: black">
                                                명의도용<span lang="EN-US">,&nbsp;</span>대출사기 등 수사기관에 의해
                                                범죄행위로 확인된 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">14)&nbsp;</span
                                            ><span style="color: black">
                                                제<span lang="EN-US">27</span>조<span lang="EN-US">1</span>항<span
                                                    lang="EN-US"
                                                    >&nbsp;27</span
                                                >호<span lang="EN-US">, 28</span>호<span lang="EN-US">, 29</span>호<span
                                                    lang="EN-US"
                                                    >, 30</span
                                                >호<span lang="EN-US">, 31</span>호의 규정에 의하여 이용정지를 당한
                                                이후<span lang="EN-US">&nbsp;1</span>년 이내에 이용정지 사유가 재발한
                                                경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">15)&nbsp;</span
                                            ><span style="color: black">
                                                부여받은 전기통신번호가 전기통신번호 판매를 중계하는 서비스를 통해
                                                매매되는 것으로 확인되는 등 실제 번호사용 의사가 없는 이용자에게 제공된
                                                것으로 간주되는 경우<span lang="EN-US">(</span>과학기술정보통신부 요청
                                                등<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">16)&nbsp;</span
                                            ><span style="color: black">
                                                과학기술정보통신부 또는 한국인터넷진흥원이 발신번호 변작과 관련하여
                                                계약해지를 요청하는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">17) (</span
                                            ><span style="color: black">
                                                선불형서비스<span lang="EN-US">)&nbsp;</span>아래의 기간과 같이 선납이
                                                이루어지지 않을 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black"
                                                >① 가입 후<span lang="EN-US">&nbsp;7</span>일 이내에 선납하지 않는
                                                경우</span
                                            >
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ② 회사의<span lang="EN-US">&nbsp;&quot;</span>선불형 서비스<span
                                                    lang="EN-US"
                                                    >&quot;</span
                                                >에 가입한 고객이 당월 선납 후 사용 만료일 내 요금을 충전하지 않은
                                                경우<span lang="EN-US">&nbsp;15</span>일간 발신이 불가능<span
                                                    lang="EN-US"
                                                    >(</span
                                                >수신<span lang="EN-US">/</span>착신은 가능<span lang="EN-US">)</span
                                                >하며<span lang="EN-US">&nbsp;15</span>일 이후에도 선납하지 않은 경우
                                                회사에서 직권으로 해지할 수 있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ③ 직권해지된 번호의 재사용 가능 기간은 해지 당월 內<span lang="EN-US"
                                                    >&nbsp;+2</span
                                                >일 동안 직권해지 취소 신청이 가능하며<span lang="EN-US">,&nbsp;</span
                                                >이 경우 미납금액이 남아있거나<span lang="EN-US">&nbsp;KAIT</span>체납
                                                대상자 또는 가입회선 초과 대상자인 경우 직권해지취소가 불가 합니다<span
                                                    lang="EN-US"
                                                    >.(</span
                                                >미납금 수납한 경우에도 직권해지 취소가 불가 할 수 있습니다<span
                                                    lang="EN-US"
                                                    >.)</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 제<span lang="EN-US">&nbsp;2</span>항의 규정에 의하여 이용계약을
                                                해지하고자 하는 때에는 해지일<span lang="EN-US">&nbsp;5</span>일 범위
                                                내에서 해당 고객에게 해지 사유를 통보합니다<span lang="EN-US"
                                                    >.&nbsp;</span
                                                >다만<span lang="EN-US">,&nbsp;</span>고객의 책임있는 사유로 통보할 수
                                                없는 경우에는 통보된 것으로 간주하며 제<span lang="EN-US">2</span
                                                >항<span lang="EN-US">&nbsp;3~8</span>호 및<span lang="EN-US"
                                                    >&nbsp;11</span
                                                >호에 의한 해지시<span lang="EN-US">,&nbsp;</span>미리 통지하는 것이
                                                어려운 경우는 선조치 후 통지할 수 있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1)&nbsp;</span
                                            ><span style="color: black">
                                                동조 제<span lang="EN-US">2</span>항<span lang="EN-US">&nbsp;15</span
                                                >호의 경우<span lang="EN-US">,&nbsp;</span>회사는 게시물 삭제 기한 및
                                                번호 회수 예정일을 명기하여 이용자에 해지<span lang="EN-US"
                                                    >&nbsp;7</span
                                                >일전까지<span lang="EN-US">&nbsp;SMS&nbsp;</span>및<span lang="EN-US"
                                                    >&nbsp;TM</span
                                                >으로 고지합니다<span lang="EN-US">.&nbsp;</span>정지<span lang="EN-US"
                                                    >/</span
                                                >해지 중인 이용자 등<span lang="EN-US">&nbsp;SMS</span>나<span
                                                    lang="EN-US"
                                                    >&nbsp;TM&nbsp;</span
                                                >수신이 불가능한 이용자는 내용증명 발송합니다<span lang="EN-US"
                                                    >. (</span
                                                >단<span lang="EN-US">,&nbsp;</span>에이징 중인 회선은 고지 없이 즉시
                                                해지 가능<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2)&nbsp;</span
                                            ><span style="color: black">
                                                제<span lang="EN-US">27</span>조 제<span lang="EN-US">1</span>항 제<span
                                                    lang="EN-US"
                                                    >17</span
                                                >호의 경우<span lang="EN-US">,&nbsp;</span>회사는 이용 정지된
                                                상태에서<span lang="EN-US">&nbsp;1</span>개월간 명의변경<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >체류기간 갱신안내<span lang="EN-US">&nbsp;SMS&nbsp;</span>등을
                                                고객에게<span lang="EN-US">&nbsp;2</span>회 발송하고<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >고객이 연락이 없는 경우 총 이용정지 기간이<span lang="EN-US"
                                                    >&nbsp;3</span
                                                >개월이 된 날에 이용계약을 해지합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 다음 각 호에 해당하는 이용계약을 체결한 경우에는 그 이해당사자가
                                                해지를 요구할 경우 이에 반드시 응하여야 하며<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >이 경우 정당한 이해당사자에 대하여는 미납요금을 청구할 수 없고 기
                                                납부한 요금<span lang="EN-US">(</span>가입비 포함<span lang="EN-US"
                                                    >)</span
                                                >을 환불합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1)&nbsp;</span
                                            ><span style="color: black">
                                                미성년자 가입시 법정대리인의 동의<span lang="EN-US">(</span>동의서 및
                                                인감증명서 등의 구비서류<span lang="EN-US">)</span>를 받지 아니하고
                                                체결한 이용계약
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2)&nbsp;</span
                                            ><span style="color: black">
                                                미성년자가 타인<span lang="EN-US">(</span>부모<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >친인척<span lang="EN-US">,&nbsp;</span>지인관계 인사 등<span
                                                    lang="EN-US"
                                                    >)</span
                                                >의 명의를 도용하여 체결한 이용계약
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">5.&nbsp;</span
                                            ><span style="color: black">
                                                고객의 의무를 이행하지 않음에 따라 이용정지된 고객이 이용요금을 완납하지
                                                못하였다 하더라도 회사에 직권해지를 요청할 수 있고<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >회사는 이에 응해야 합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">6.&nbsp;</span
                                            ><span style="color: black">
                                                번호이동가입자의 변경 전 이용계약은 변경 후 사업자의 번호이동 신청과
                                                동시에 자동해지 됩니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">7.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 다음 각 호에 의하여 해지고객에 대한 개인정보를 보호합니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1)&nbsp;</span
                                            ><span style="color: black">
                                                해지고객의 개인정보 보유근거<span lang="EN-US">&nbsp;(</span>국세기본법
                                                제<span lang="EN-US">85</span>조의<span lang="EN-US">&nbsp;3&nbsp;</span
                                                >제<span lang="EN-US">2</span>항 장부 등의 비치 및 보존<span
                                                    lang="EN-US"
                                                    >) :&nbsp;</span
                                                >모든 거래에 관한 장부 및 증빙서류는 그 거래사실이 속하는 과세기간에
                                                대한 당해 국세의 법정신고기한이 경과한 날부터<span lang="EN-US"
                                                    >&nbsp;5</span
                                                >년간 보존하여야 한다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2)&nbsp;</span
                                            ><span style="color: black">
                                                회사는 요금의 정산<span lang="EN-US">,&nbsp;</span>요금 과<span
                                                    lang="EN-US"
                                                    >,</span
                                                >오납 등 분쟁 발생시 입증을 위하여 해지 후 고객 정보<span lang="EN-US"
                                                    >(</span
                                                >과금정보 포함<span lang="EN-US">)</span>를<span lang="EN-US"
                                                    >&nbsp;6</span
                                                >개월간 보관할 수 있으며<span lang="EN-US">,&nbsp;</span>국세 기본법
                                                제<span lang="EN-US">85</span>조의<span lang="EN-US">3</span>의 규정에
                                                따라 거래사실에 관한 고객의 개인정보<span lang="EN-US">(DB</span>및
                                                서류<span lang="EN-US">)</span>를 해지 후<span lang="EN-US"
                                                    >&nbsp;5</span
                                                >년간 별도 해지고객 데이터 베이스에서 보관할 수 있습니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3)&nbsp;</span
                                            ><span style="color: black">
                                                해지고객이라 함은 해지 신청을 하고 채권채무관계<span lang="EN-US"
                                                    >(</span
                                                >잔고<span lang="EN-US">)</span>가<span lang="EN-US">&nbsp;`0`</span>인
                                                고객을 말하며<span lang="EN-US">,&nbsp;</span>채권채무관계가<span
                                                    lang="EN-US"
                                                    >&nbsp;`0`</span
                                                >이 아닐 경우에는 회사 고객이므로 개인정보 보관 기간에 구애 받지
                                                않습니다
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4)&nbsp;</span
                                            ><span style="color: black">
                                                해지고객의 개인정보 파기항목
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ① 가입자의 기본정보<span lang="EN-US">&nbsp;:&nbsp;</span>주<span
                                                    lang="EN-US"
                                                    >/</span
                                                >거소<span lang="EN-US">,&nbsp;</span>자택<span lang="EN-US">/</span
                                                >직장 전화번호<span lang="EN-US">,&nbsp;</span>생일 및 기념일<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >직업<span lang="EN-US">/</span>직종<span lang="EN-US">,&nbsp;</span
                                                >이메일주소<span lang="EN-US">,&nbsp;</span>고객<span lang="EN-US"
                                                    >(</span
                                                >신용<span lang="EN-US">)</span>등급<span lang="EN-US">,&nbsp;</span
                                                >요금납부자의 전화번호<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ② 요금납부 등의 관련정보<span lang="EN-US">&nbsp;:&nbsp;</span
                                                >예금주명<span lang="EN-US">,&nbsp;</span>예금계좌<span lang="EN-US"
                                                    >(</span
                                                >카드<span lang="EN-US">)</span>번호<span lang="EN-US">,&nbsp;</span
                                                >은행명<span lang="EN-US">,&nbsp;</span>납부방법<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >납부일자<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ③ 단말기 정보<span lang="EN-US">&nbsp;:&nbsp;</span>모델명<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >일련번호 등
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ④ 기타 정보<span lang="EN-US">&nbsp;:&nbsp;</span>요금과 관련 없는
                                                부가서비스 등
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">5)&nbsp;</span
                                            ><span style="color: black">
                                                해지고객의 개인정보 파기방법
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ① 서면에 기재된 개인정보<span lang="EN-US">&nbsp;:&nbsp;</span>분쇄기로
                                                분쇄하거나 소각
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ② 전자적 파일형태로 저장된 개인정보<span lang="EN-US"
                                                    >&nbsp;:&nbsp;</span
                                                >기록을 재생할 수 없는 기술적 방법을 사용하여 삭제
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">6)&nbsp;</span
                                            ><span style="color: black">
                                                해지고객의 가입계약서<span lang="EN-US">(</span>해지신청서<span
                                                    lang="EN-US"
                                                    >)</span
                                                >의 보관 및 관리<span lang="EN-US">:&nbsp;</span>회사는 본사에 별도
                                                보안구역을 마련하여 보관<span lang="EN-US">(</span>전자문서<span
                                                    lang="EN-US"
                                                    >)&nbsp;</span
                                                >및 관리합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">7)&nbsp;</span
                                            ><span style="color: black">
                                                회사는 스팸<span lang="EN-US">(</span>불법스팸 포함<span lang="EN-US"
                                                    >)</span
                                                >발송 또는 제<span lang="EN-US">&nbsp;27</span>조<span lang="EN-US"
                                                    >&nbsp;1</span
                                                >항<span lang="EN-US">&nbsp;21</span>호로 인하여 계약 해지된 고객의
                                                성명<span lang="EN-US">,&nbsp;</span>주민번호<span lang="EN-US">(</span
                                                >법인번호<span lang="EN-US">,</span>사업자등록번호<span lang="EN-US"
                                                    >),&nbsp;</span
                                                >전화번호<span lang="EN-US">,&nbsp;</span>해지사유 등 필요한 정보를<span
                                                    lang="EN-US"
                                                    >&nbsp;12</span
                                                >개월간 보관 및 이용<span lang="EN-US">(KAIT</span>제공<span
                                                    lang="EN-US"
                                                    >)</span
                                                >할 수 있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black"
                                                >제<span lang="EN-US">7</span>장 광고성 정보 및 스팸 전송 방지</span
                                            >
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">30</span>조<span lang="EN-US">(</span>용어
                                                정의<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                일반적으로<span lang="EN-US">&nbsp;</span>“스팸”이란 정보통신망을 통해
                                                이용자가 원하지 않는데도 불구하고 일방적으로 전송되는 영리목적의 광고성
                                                정보를 말합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.</span
                                            ><span style="color: black">
                                                “불법스팸”이란 정보통신망 이용촉진 및 정보보호 등에 관한 법률<span
                                                    lang="EN-US"
                                                    >(</span
                                                >이하“정보통신망법”이라함<span lang="EN-US">)</span>을 위반하여 전송
                                                또는 게시되는 영리목적의 광고성 정보를 말합니다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3.</span
                                            ><span style="color: black">
                                                “전자적 전송매체”란 정보통신망을 통하여 부호<span lang="EN-US">?</span
                                                >문자<span lang="EN-US">?</span>음성<span lang="EN-US">?</span>화상 또는
                                                영상 등을 수신자에게 전자문서 등의 전자적 형태로 전송하는 매체를
                                                말한다<span lang="EN-US">.(</span>예<span lang="EN-US">:&nbsp;</span
                                                >인터넷 웹브라우저<span lang="EN-US">,&nbsp;</span>스마트폰 앱<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >사설문자발송장비 등을 사용하여 발송된 문자메시지 등<span lang="EN-US"
                                                    >).</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4.</span
                                            ><span style="color: black">
                                                ‘부정송신자’라 함은 이동전화 서비스 또는 이동전화 번호를 스팸 및
                                                불법스팸 발송에 이용하 거나 수신인을 기망 또는 폭언
                                                <span lang="EN-US">∙</span>협박 <span lang="EN-US">∙</span>희롱 등의
                                                위해를 입힐 목적으로 발신번호를 변작하는 등 거짓으로 표시하여 음성전화를
                                                발신 또는 문자메시지를 발송하는 자를 말합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">31</span>조<span lang="EN-US">(</span>스팸 관련
                                                개인정보 수집항목 및 이용<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                회사는 개인정보보호방침에서 규정하고 있는 수집항목 및 이용목적 외에,
                                                불법스팸 전송 및 발신번호 변작 등으로 서비스 이용정지 또는 계약해지 된
                                                고객의 서비스 이용신청에 대한 승낙을 유보(가입 제한)하기 위하여 성명,
                                                고유식별번호
                                                <span lang="EN-US">(</span>법인등록번호<span lang="EN-US">•</span
                                                >사업자등록번호 <span lang="EN-US">),</span> 전화번호, 정지 및 해지 사유
                                                등의 정보를 수집하여 한국정보통신진흥협회(KAIT)에 제공하거나
                                                <span lang="EN-US">1</span>년 간 보관할 수 있습니다.
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">32</span>조<span lang="EN-US">(</span>이용신청에
                                                대한 승낙의 제한<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                회사는 스팸 전송과 관련하여 다음 각 호의 하나에 해당하는 것으로 확인되는
                                                경우 이용신청에 대하여는 승낙을 하지 아니합니다<span lang="EN-US"
                                                    >.(</span
                                                >단<span lang="EN-US">,&nbsp;</span>명의도용 등으로 인한 선의의 피해나
                                                사유가 타당할 경우 회사는 이를 심사하여 계약을 승낙할 수 있습니다<span
                                                    lang="EN-US"
                                                    >)</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                가입통신사를 불문하고 전기통신사업법 제<span lang="EN-US">32</span
                                                >조의<span lang="EN-US">3</span> 제 <span lang="EN-US">1</span>항 각
                                                호에 따라 이용정지 또는 계약해지를 당한 이후<span lang="EN-US"
                                                    >&nbsp;1</span
                                                >
                                                년이 경과하지 않은 개인 또는 법인(법인대표자 포함)
                                                <span lang="EN-US">.&nbsp;</span>
                                                단 선불폰 <span lang="EN-US">&nbsp;1</span>회선 개통의 경우나 이용정지
                                                또는 계약해지가 타인의 명의도용 등 당사자의 과실에 의하지 않은 것으로
                                                확인된 경우는 제외함
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                서비스 개설 본래의 목적을 위반하여 대포폰<span lang="EN-US">(</span
                                                >자신의 신분을 감추기 위해 다른 사람 명의로 등록해 사용하는
                                                휴대전화<span lang="EN-US">)</span>을 매개 또는 개통<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >이용한 사실이 있거나 처벌받은 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3.&nbsp;</span
                                            ><span style="color: black">
                                                타인의 명의를 도용한 사실이 있거나 이로 인해 처벌받은 경우 또는
                                                명의도용을 허위 신고한 사실이 있는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4.&nbsp;</span
                                            ><span style="color: black">
                                                불법 복제와 관련된 사실이 있거나 처벌 받은 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">5.&nbsp;</span
                                            ><span style="color: black">
                                                불법스팸 전송 등으로 과태료 처분을 받은 자가 질서위반행위규제법 제<span
                                                    lang="EN-US"
                                                    >53</span
                                                >조에 따라 신용정보집중기관에 과태료 체납자로 등록 된 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">6.&nbsp;</span
                                            ><span style="color: black">
                                                휴대전화를 개통하여 불법스팸을 전송하는 자에게 이를 임대한 적이 있는
                                                경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">7.&nbsp;</span
                                            ><span style="color: black">
                                                방송통신위원회 또는 한국인터넷진흥원으로부터 스팸 또는 불법스팸 전송자로
                                                확인되어 이용정지 또는 해지를 요청받았던 개인<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >법인<span lang="EN-US">(</span>법인대표자 포함<span lang="EN-US"
                                                    >)</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">8.&nbsp;</span
                                            ><span style="color: black">
                                                불법 스팸 전송을 사유로 이용정지를 받은 상태에서 본인이 서비스를 해지 후
                                                동일 전화번호로<span lang="EN-US">&nbsp;1</span>개월 이내에 재신청한
                                                경우 및 불법 스팸 전송을 사유로 계약해지가 된 이용자가 동일
                                                전화번호로<span lang="EN-US">&nbsp;1</span>개월 이내에 재신청<span
                                                    lang="EN-US"
                                                    >(</span
                                                >타인명의로 재신청하는 것이 파악될 경우도 포함<span lang="EN-US"
                                                    >)&nbsp;</span
                                                >하는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">33</span>조<span lang="EN-US">(</span>고객의
                                                의무<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                고객은 이동통신서비스를 이용하여 영리목적의 광고성 정보를 전송하는 경우
                                                정보통신망법 및 회사의 이용약관을 준수하여야 합니다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                고객은 회사의 서비스 제공 목적 외의 용도로 서비스를 이용하여서는
                                                안되며<span lang="EN-US">,&nbsp;</span>제<span lang="EN-US">3</span
                                                >자에게 임의로 해당 서비스를 임대하여서는 안됩니다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3.&nbsp;</span
                                            ><span style="color: black">
                                                고객은 스팸 또는 불법스팸을 전송함으로써 발생하는 모든 민<span
                                                    lang="EN-US"
                                                    >?</span
                                                >형사상의 책임을 부담합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4.&nbsp;</span
                                            ><span style="color: black">
                                                정보통신망법에서 금지하는 대량의 불법스팸 전송을 방지하기 위하여 고객은
                                                회선당<span lang="EN-US">&nbsp;1</span>일<span lang="EN-US"
                                                    >&nbsp;500</span
                                                >건을 초과하는 메시지<span lang="EN-US">(SMS, MMS,&nbsp;</span
                                                >채팅서비스 포함<span lang="EN-US">),&nbsp;</span>또는<span lang="EN-US"
                                                    >&nbsp;1</span
                                                >일<span lang="EN-US">&nbsp;500</span>명을 초과하는 수신처에
                                                채팅발신<span lang="EN-US">(Text,&nbsp;</span>스티커<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >파일 등<span lang="EN-US">),&nbsp;</span>또는<span lang="EN-US"
                                                    >&nbsp;1</span
                                                >일<span lang="EN-US">&nbsp;1,000</span>건을 초과하는 음성호<span
                                                    lang="EN-US"
                                                    >(</span
                                                >원링 및 불완료호 등<span lang="EN-US">)</span>를 전송할 수
                                                없습니다<span lang="EN-US">.</span>이를 위반하는 경우 회사는<span
                                                    lang="EN-US"
                                                    >&nbsp;1</span
                                                >개월 이내의 기간을 정하여 메시지<span lang="EN-US"
                                                    >(SMS, MMS,&nbsp;</span
                                                >채팅서비스 포함<span lang="EN-US">)&nbsp;</span>및 음성호 전송을 제한
                                                할 수 있습니다<span lang="EN-US">.&nbsp;</span>단<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >고객이 제출하는 증빙서류 등을 통해 회사가 불법스팸 전송이 아님을 확인할
                                                수 있는 경우 초과 전송이 가능합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">5.&nbsp;</span
                                            ><span style="color: black">
                                                고객은 문자 할인 서비스<span lang="EN-US">,&nbsp;</span>문자 무제한
                                                요금제 또는 부가서비스 등에 가입하여 영리목적의 광고성 정보 전송에
                                                이용하거나<span lang="EN-US">,&nbsp;</span>물리적 장치 또는 자동전송
                                                프로그램 등을 이용하여 메시지<span lang="EN-US"> (SMS, MMS,&nbsp; </span
                                                >채팅서비스 포함<span lang="EN-US">)</span>를 전송 또는 통화를
                                                유발하여서는 안됩니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">6.&nbsp;</span
                                            ><span style="color: black">
                                                고객은 회사의 동의 또는 계약 없이 물리적 장치 또는 자동전송프로그램을
                                                이용하여 메시지<span lang="EN-US">(SMS, MMS,&nbsp;</span>채팅서비스
                                                포함<span lang="EN-US">)</span>나 음성호 및 영상통화호를 전송할 수
                                                없으며<span lang="EN-US">,&nbsp;</span>이에 위반한 경우<span
                                                    lang="EN-US"
                                                    >(</span
                                                >이에 위반하였다고 객관적으로 추정이 되는 경우 포함<span lang="EN-US"
                                                    >)&nbsp;</span
                                                >회사는 메시지<span lang="EN-US">(SMS, MMS,&nbsp;</span>채팅서비스
                                                포함<span lang="EN-US">)</span>나 음성호 및 영상통화호 전송을 제한 할 수
                                                있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">7.&nbsp;</span
                                            ><span style="color: black">
                                                일<span lang="EN-US">&nbsp;3,000&nbsp;</span>건 이상의 문자 메시지<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >채팅 발송은 물리적 장치 또는 자동전송 프로그램 등을 이용한 것으로
                                                간주하여 서비스 이용이 제한될 수 있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">8.&nbsp;</span
                                            ><span style="color: black">
                                                고객은 서비스 계약에 필요한 개인신상정보 등을 회사에 허위로 제공해선
                                                안되며<span lang="EN-US">,&nbsp;</span>정보변경 시 지체 없이 회사에
                                                통보하여 갱신하여야 합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">9.&nbsp;</span
                                            ><span style="color: black">
                                                회사에서 운영하는 웹사이트를 통해 웹투폰 메시지를 전송하는 경우 스팸
                                                관련 정보통신망법 및 본 이용약관 등을 준수하여야 합니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">10.&nbsp;</span
                                            ><span style="color: black">
                                                정보통신망이용촉진및정보보호등에관한법률 등 관련법령에서 금지하고 있는
                                                스팸<span lang="EN-US">(</span>불법스팸<span lang="EN-US">)</span>을
                                                방지하기 위하여 고객은 회사에서 운영하는 웹사이트<span lang="EN-US"
                                                    >(</span
                                                >예<span lang="EN-US">&nbsp;:&nbsp;</span>회사 홈페이지 포함한 사이트
                                                등<span lang="EN-US">)</span>를 통해 웹투폰 문자<span lang="EN-US"
                                                    >(SMS, MMS)</span
                                                >를 발송할 경우 다음 각 호를 준수해야 합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">11.&nbsp;</span
                                            ><span style="color: black">
                                                웹투폰 문자 발송시 회사가 인증절차를 요청하는 경우 인증 절차를 수행해야
                                                합니다<span lang="EN-US">.&nbsp;</span>일<span lang="EN-US"
                                                    >&nbsp;50</span
                                                >건 및 월 누적<span lang="EN-US">&nbsp;50</span>건 이상 발송을 기준으로
                                                하되 웹투폰 발송 서비스에 따라 기준을 달리할 수도 있습니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">12.&nbsp;</span
                                            ><span style="color: black">
                                                일<span lang="EN-US">&nbsp;500</span>건 및 월<span lang="EN-US"
                                                    >&nbsp;5,000</span
                                                >건 이상 발송 금지<span lang="EN-US">(</span>회사와 별도의 계약을
                                                체결하거나 회사의 사전 동의를 얻은 경우 또는 스팸이 아님을 증빙할 수
                                                있는 서류를 제출하는 경우 회사는 판단 후 발송을 허용할 수 있습니다<span
                                                    lang="EN-US"
                                                    >.&nbsp;</span
                                                >단<span lang="EN-US">,&nbsp;</span>회사가 확인을 요청 시마다 고객은
                                                이에 응해야 하며 응하지 않는 경우 이용건수를 제한할 수 있습니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">13.&nbsp;</span
                                            ><span style="color: black">
                                                스팸 금칙어<span lang="EN-US">(</span>한국인터넷진흥원 또는 회사로
                                                신고된 스팸정보 중 스팸 문자에 자주 사용되는 단어 또는 문구<span
                                                    lang="EN-US"
                                                    >)&nbsp;</span
                                                >사용금지
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">14.&nbsp;</span
                                            ><span style="color: black">
                                                본 약관 제<span lang="EN-US">39</span>조 제<span lang="EN-US">1</span
                                                >항에 따라 서비스의 제공이 중지된 이용자가 이의신청을 하려면 서비스
                                                제공이 중지된 날부터<span lang="EN-US">&nbsp;30</span>일 내에 다음 각
                                                호의 사항을 적은 문서를 회사에 제출하여야 합니다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">15.&nbsp;</span
                                            ><span style="color: black">
                                                이의신청인의 명칭 또는 성명<span lang="EN-US">,&nbsp;</span>주소<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >연락처<span lang="EN-US">,&nbsp;</span>이의신청 사유<span lang="EN-US"
                                                    >(</span
                                                >서비스 제공이 중지된 날 포함<span lang="EN-US">)&nbsp;</span>및 관련
                                                증빙자료 등이 포함된<span lang="EN-US">&nbsp;`</span>스팸<span
                                                    lang="EN-US"
                                                    >/</span
                                                >불법스팸<span lang="EN-US">/</span>대량발송 이용정지 해제소명
                                                신청서<span lang="EN-US">`</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">34</span>조<span lang="EN-US">(</span>회사의 의무
                                                및 권한<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 서비스를 이용하는 고객에게 불법 스팸이 전송되는 경우 고객에게
                                                통보 없이 해당 문자 및 음성호가 수신되지 않도록 차단할 수 있습니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 광고성 정보의 전송 또는 수신으로 역무의 제공에 장애가 일어나거나
                                                일어날 우려가 있는 경우<span lang="EN-US">,&nbsp;</span>이용자가 광고성
                                                정보의 수신을 원하지 않는 경우 해당 서비스 제공을 거부하는 조치를 취할
                                                수 있으며 조치 사실을 서비스를 제공받는 이용자 등 이해관계인에게 알려야
                                                합니다<span lang="EN-US">.&nbsp;</span>다만 미리 알리는 것이 곤란한
                                                경우에는 차단조치 후 지체 없이 알려야 합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 이용계약을 통하여 회사가 이용자에게 제공하는 서비스가
                                                정보통신망법 제<span lang="EN-US">50</span>조 또는 제<span lang="EN-US"
                                                    >50</span
                                                >조의<span lang="EN-US">&nbsp;8</span>을 위반하여 영리목적의 광고성
                                                정보의 전송에 이용되고 있는 경우 해당 서비스 제공을 거부하는 등의 조치를
                                                할 수 있으며<span lang="EN-US">,&nbsp;</span>조치 사실을 이용자 등
                                                이해관계인에게 알려야 합니다<span lang="EN-US">.&nbsp;</span>다만 미리
                                                알리는 것이 곤란한 경우에는 차단조치 후 지체 없이 알려야 합니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 불법스팸 전송및 발신번호 변작 등으로 이용정지 또는 계약 해지된
                                                이용자 정보를 해당 이용자의 신규가입<span lang="EN-US">,&nbsp;</span
                                                >기기변경<span lang="EN-US">,&nbsp;</span>명의변경 시의 동의에 따라 스팸
                                                전송자 적발<span lang="EN-US">,&nbsp;</span>스팸 감축 등의 목적으로
                                                한국정보통신진흥협회에 제공하여 다른 통신사에서 조회하도록 할 수
                                                있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">5.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 스팸 전송자 적발<span lang="EN-US">,&nbsp;</span>스팸 차단 및
                                                감축 등을 통해 이용자의 스팸문자 수신 피해를 최소화하기 위해 자동으로
                                                스팸을 차단해주는<span lang="EN-US">&nbsp;`</span>스팸차단<span
                                                    lang="EN-US"
                                                    >(</span
                                                >필터링<span lang="EN-US">)`&nbsp;</span>부가서비스를 통해 차단된 문자의
                                                내용<span lang="EN-US">,&nbsp;</span>스팸 발송 사업자 정보 등을
                                                한국인터넷진흥원에 제공할 수 있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">6.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 고객이 불법스팸을 전송한 사실을 확인한 경우<span lang="EN-US"
                                                    >(</span
                                                >또는 스팸 발송으로 추정되는 경우<span lang="EN-US">)&nbsp;</span
                                                >한국인터넷진흥원 불법스팸대응센터에 관련 자료를 첨부하여 신고할 수
                                                있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">7.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 정보통신망법 제<span lang="EN-US">64</span>조제<span lang="EN-US"
                                                    >2</span
                                                >항 및 제<span lang="EN-US">65</span>조제<span lang="EN-US">3</span>항에
                                                따라 방송통신위원회 및 한국인터넷진흥원에게 스팸 전송자의 성명<span
                                                    lang="EN-US"
                                                    >?</span
                                                >주소<span lang="EN-US">?</span>주민등록번호<span lang="EN-US">?</span
                                                >이용기간<span lang="EN-US">?</span>연락처 등에 대한 자료의 제출 요청이
                                                있는 경우에 해당 자료를 제공할 수 있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">8.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 한국인터넷진흥원으로부터 스팸신고가 접수된 경우 서비스 제공목적에
                                                맞는 서비스 이용 여부를 확인하기 위하여 대량문자 발송여부 및 서비스
                                                변경이력을 확인할 수 있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">9.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 고객이 고객의 의무를 이행하지 않는 경우 서비스 이용을 제한할 수
                                                있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">10.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 서비스 제공목적에 맞는 서비스 이용 여부를 확인하기 위하여
                                                상시적으로 모니터링을 실시할 수 있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">11.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 서비스를 이용하는 고객이 불법스팸을 전송하거나 발신번호를
                                                변작하여 문자 및 음성전화를 송신하는 경우 이용자에게 통보 없이 서비스
                                                이용을 제한할 수 있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">35</span>조<span lang="EN-US">(</span
                                                >이용정지등<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                회사는 계약자가 다음 중 하나에 해당하는 경우<span lang="EN-US"
                                                    >&nbsp;3</span
                                                >개월 이내의 기간을 정하여 당해 서비스의 이용을 즉시 정지<span
                                                    lang="EN-US"
                                                    >(</span
                                                >일부 또는 전체에 대하여<span lang="EN-US">)</span>할 수 있습니다<span
                                                    lang="EN-US"
                                                    >.&nbsp;</span
                                                >다만 이용정지등의 원인이 된 사유가 완전히 해소되기 전까지는 이용정지등
                                                조치를 해제하지 아니할 수 있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                방송통신위원회 또는 한국인터넷진흥원이 불법스팸 전송사실을 확인하여
                                                이용정지를 요청하는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.</span
                                            ><span style="color: black">
                                                전송한 광고성 정보가 불법스팸임이 확인된 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3.</span
                                            ><span style="color: black">
                                                불법스팸 전송으로 과태료가 부과된 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4.</span
                                            ><span style="color: black">
                                                대량으로 스팸 메시지<span lang="EN-US">(SMS, MMS,&nbsp;</span>채팅서비스
                                                포함<span lang="EN-US">)&nbsp;</span>또는 음성<span lang="EN-US">(</span
                                                >영상<span lang="EN-US">)&nbsp;</span>통화호 등을 전송하여 회사의 서비스
                                                제공<span lang="EN-US">(</span>시스템<span lang="EN-US">)</span>에
                                                장애를 야기하거나 야기할 우려가 있는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">5.</span
                                            ><span style="color: black">
                                                회사가 계약자에게 제공하는 서비스가 불법스팸 전송에 이용되고 있는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">6.</span
                                            ><span style="color: black">
                                                회사에게 증빙서류등을 통해 불법스팸 전송이 아님을 확인하여주지 않고<span
                                                    lang="EN-US"
                                                    >&nbsp;1</span
                                                >일<span lang="EN-US">&nbsp;500</span>건을 초과하는 메시지<span
                                                    lang="EN-US"
                                                    >(SMS, MMS,&nbsp;</span
                                                >채팅서비스 포함<span lang="EN-US">),&nbsp;</span>또는<span lang="EN-US"
                                                    >&nbsp;1</span
                                                >일<span lang="EN-US">&nbsp;500</span>명을 초과하는 수신처에 채팅
                                                발신<span lang="EN-US">(Text,&nbsp;</span>스티커<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >파일 등<span lang="EN-US">),&nbsp;</span>또는<span lang="EN-US"
                                                    >&nbsp;1</span
                                                >일<span lang="EN-US">&nbsp;1,000</span>건을 초과하여 음성호를 전송하는
                                                경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">7.</span
                                            ><span style="color: black">
                                                회사와 별도의 계약 또는 동의 없이 서비스 제공 목적 외로 계약자에게
                                                제공하는 서비스<span lang="EN-US">(</span>문자 할인 서비스<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >문자 무제한 요금제 또는 부가서비스 등<span lang="EN-US">)</span>를
                                                영리목적의 광고성 정보 전송<span lang="EN-US">(</span>스팸 전송<span
                                                    lang="EN-US"
                                                    >)</span
                                                >에 이용하는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">8.</span
                                            ><span style="color: black">
                                                문자<span lang="EN-US">,&nbsp;</span>음성<span lang="EN-US">/</span
                                                >영상통화<span lang="EN-US">(</span>메시지<span lang="EN-US"
                                                    >)&nbsp;</span
                                                >및<span lang="EN-US">&nbsp;MMS(</span>멀티미디어 메시지<span
                                                    lang="EN-US"
                                                    >),&nbsp;</span
                                                >채팅<span lang="EN-US">(Text,&nbsp;</span>스티커<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >파일 등<span lang="EN-US">)&nbsp;</span>등의 방법을 통해 스팸메시지를
                                                발송하는 경우 다음 각목의 번호를 이용정지할 수 있습니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">① 스팸발송한 번호</span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black"
                                                >② 스팸발송시 이용된<span lang="EN-US">&nbsp;Call-Back&nbsp;</span
                                                >번호</span
                                            >
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ③<span lang="EN-US">&nbsp;&quot;</span>①<span lang="EN-US"
                                                    >&quot;&nbsp;</span
                                                >및<span lang="EN-US">&nbsp;&quot;</span>②<span lang="EN-US"
                                                    >&quot;</span
                                                >항목의 번호가 착신전환을 이용한 경우 해당 착신 지정번호
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">9.</span
                                            ><span style="color: black">
                                                회사 일반 가입자들에 대해 한국인터넷진흥원에서 통지했거나 회사
                                                고객센터로 신고된 스팸내용을 통해 스팸발송자가 파악된 경우 해당
                                                스팸회선<span lang="EN-US">(</span>타사가입자<span lang="EN-US">)</span
                                                >으로부터 전송되는<span lang="EN-US">&nbsp;SMS&nbsp;</span>또는<span
                                                    lang="EN-US"
                                                    >&nbsp;MMS,&nbsp;</span
                                                >채팅에 대해 타 이동통신사 또는 유선사업자가 해당 스팸회선을 이용정지
                                                시킬때까지 수신 차단이 필요한 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">10.</span
                                            ><span style="color: black">
                                                스팸 발송에 이용되거나 악성앱에 감염되어 스팸을 전송한 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">11.</span
                                            ><span style="color: black">
                                                해당 광고를 수신한 자가 수신거부를 요청하였으나 지속 재전송한 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">12.</span
                                            ><span style="color: black">
                                                해당 광고를 수신한 자가 스팸으로 신고한 경우<span lang="EN-US">(</span
                                                >문자 내용 및 신고 빈도 등을 통해 스팸 수신으로 인한 고객 피해가
                                                우려된다고 판단되는 경우에 한하며<span lang="EN-US">,&nbsp;</span
                                                >이용제한에 대한 이용자의 적절한 소명이 있을 시 제한을 해제할 수
                                                있음<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">13.</span
                                            ><span style="color: black">
                                                릴레이로 이용되거나 수신한 자가 수신거부를 요청하였으나 지속 재전송한
                                                경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">14.</span
                                            ><span style="color: black">
                                                본인 명의가 아닌 번호를 부정하게 사용하는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ㅇ 회사는 불법스팸을 전송하였다고 확인된 계약자가 이용 중인 다른 번호가
                                                불법스팸에 악용되고 있거나 악용될 우려가 있다는 객관적 정황이 있는
                                                경우에는 그 다른 번호도 이용 정지 할 수 있습니다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ㅇ 회사는 제<span lang="EN-US">1</span>항 및 제<span lang="EN-US"
                                                    >2</span
                                                >항의 규정에 의하여 서비스 이용을 정지하는 경우<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >그 사실을 당해 계약자에게 통지합니다<span lang="EN-US">.&nbsp;</span
                                                >다만 미리 통지하는 것이 곤란한 경우에는 이용정지 조치 후 통지할 수
                                                있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ㅇ 제<span lang="EN-US">1</span>항의 규정에 따라 이용정지 된 이용자는
                                                서비스 제공이 중지된 날부터<span lang="EN-US">&nbsp;30</span>일 내에
                                                이의신청을 할 수 있으며<span lang="EN-US">,&nbsp;</span>다음 각 호의
                                                사항을 적은 문서를 회사에 제출하여야 합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">-&nbsp;</span
                                            ><span style="color: black">
                                                이의신청인의 명칭 또는 성명<span lang="EN-US">,&nbsp;</span>주소<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >연락처<span lang="EN-US">,&nbsp;</span>이의신청 사유<span lang="EN-US"
                                                    >(</span
                                                >서비스 제공이 중지된 날 포함<span lang="EN-US">)&nbsp;</span>및 관련
                                                증빙자료 등이 포함된<span lang="EN-US">&nbsp;`</span>스팸<span
                                                    lang="EN-US"
                                                    >/</span
                                                >불법스팸<span lang="EN-US">/</span>대량발송 이용정지 해제소명
                                                신청서<span lang="EN-US">`</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ㅇ 회사는 이용자의 이의신청에 대하여 심사할 수 있으며<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >신청사유가 정당하다고 인정될 때 회사는 해당 서비스 제공의 중지를
                                                해제하여야 하며 조치 결과를<span lang="EN-US">&nbsp;15</span>일 내에
                                                신청인에게 통지하여야 합니다<span lang="EN-US">.&nbsp;</span>다만<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >불가피한 경우<span lang="EN-US">&nbsp;15</span>일 범위에서 그 기간을
                                                연장할 수 있으며<span lang="EN-US">,&nbsp;</span>연장 사유 및 기간을
                                                이의신청인에게 통지해야 합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">36</span>조<span lang="EN-US">(</span>계약해지<span
                                                    lang="EN-US"
                                                    >)</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                회사는 계약자가 다음 중 하나에 해당하는 경우 당해 서비스 계약을 해지할
                                                수 있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                타인의 명의로 계약하였거나 계약 시 제출한 자료 및 정보가 허위 또는
                                                누락되었음이 확인된 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                회사의 서비스 제공목적 외의 용도로 서비스를 이용하거나<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >제<span lang="EN-US">3</span>자에게 임의로 서비스를 임대한 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3.&nbsp;</span
                                            ><span style="color: black">
                                                방송통신위원회 또는 한국인터넷진흥원이 불법스팸 전송과 관련하여
                                                계약해지를 요청하는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4.&nbsp;</span
                                            ><span style="color: black">
                                                불특정 다수<span lang="EN-US">(</span>수신처를 임의로 생성한 경우
                                                포함<span lang="EN-US">)</span>를 대상으로 메시지<span lang="EN-US"
                                                    >(SMS, MMS&nbsp;</span
                                                >포함<span lang="EN-US">),&nbsp;</span>채팅<span lang="EN-US"
                                                    >(Text,&nbsp;</span
                                                >스티커<span lang="EN-US">,&nbsp;</span>파일 등<span lang="EN-US"
                                                    >),&nbsp;</span
                                                >음성통화<span lang="EN-US">(</span>영상통화<span lang="EN-US">
                                                    , ARS&nbsp; </span
                                                >포함<span lang="EN-US">)&nbsp;</span>등의 방법을 통해 무차별적인 스팸
                                                전송한 사실이 확인된 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">5.&nbsp;</span
                                            ><span style="color: black">
                                                계약자가 전송한 광고성 정보에 대해 회사가 한국인터넷진흥원에 불법스팸
                                                유무확인을 요청하여 불법스팸임이 확인된 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">6.&nbsp;</span
                                            ><span style="color: black">
                                                계약자에게 제공하는 서비스<span lang="EN-US">(</span>문자 할인
                                                서비스<span lang="EN-US">,&nbsp;</span>문자 무제한 요금제 또는
                                                부가서비스 등<span lang="EN-US">)</span>를 영리목적의 광고성 정보
                                                전송<span lang="EN-US">(</span>스팸 전송<span lang="EN-US">)</span>에
                                                이용하거나 물리적 장치 또는 자동전송 프로그램 등을 이용하여 메시지<span
                                                    lang="EN-US"
                                                >
                                                    (SMS, MMS&nbsp; </span
                                                >포함<span lang="EN-US">),&nbsp;</span>채팅<span lang="EN-US"
                                                    >(Text,&nbsp;</span
                                                >스티커<span lang="EN-US">,&nbsp;</span>파일 등<span lang="EN-US"
                                                    >)</span
                                                >을 전송 또는 통화를 유발하는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">7.&nbsp;</span
                                            ><span style="color: black">
                                                서비스를 이용하여 불완료호<span lang="EN-US">,&nbsp;</span>즉<span
                                                    lang="EN-US"
                                                    >&nbsp;`</span
                                                >원링<span lang="EN-US">(one-ring)`</span>과 같이 수신자를 기망하여
                                                회신을 유도하는 행위를 하는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">8.&nbsp;</span
                                            ><span style="color: black">
                                                제<span lang="EN-US">39</span>조의 규정에 의하여 이용정지를 당한
                                                이후<span lang="EN-US">&nbsp;1</span>년 이내에 이용정지 사유가 재발한
                                                경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">9.&nbsp;</span
                                            ><span style="color: black">
                                                제<span lang="EN-US">39</span>조의 규정에 따른 이용정지 기간 내에
                                                이용정지 사유를 해소하지 않고<span lang="EN-US">&nbsp;1</span>개월이
                                                경과한 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">10.&nbsp;</span
                                            ><span style="color: black">
                                                전송자를 확인하지 못하게 할 목적으로 발신번호를 변작하여 영리목적의
                                                광고성 정보를 전송한 사실이 확인된 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">11.&nbsp;</span
                                            ><span style="color: black">
                                                회사와 별도의 계약 또는 동의 없이 서비스 제공 목적 외로 계약자에게
                                                제공하는 서비스<span lang="EN-US">(</span>문자 할인 서비스<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >문자 무제한 요금제 또는 부가서비스 등<span lang="EN-US">)</span>를
                                                영리목적의 광고성 정보 전송<span lang="EN-US">(</span>스팸 전송<span
                                                    lang="EN-US"
                                                    >)</span
                                                >에 이용하는 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">12.&nbsp;</span
                                            ><span style="color: black">
                                                수신처를 임의로 생성하여 불특정 다수에게 광고성 메시지<span lang="EN-US"
                                                    >(SMS, MMS&nbsp;</span
                                                >포함<span lang="EN-US">),&nbsp;</span>음성전화<span lang="EN-US"
                                                    >(ARS</span
                                                >포함<span lang="EN-US">)</span>를 전송한 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">13.&nbsp;</span
                                            ><span style="color: black">
                                                스팸<span lang="EN-US">(</span>불법스팸 포함<span lang="EN-US"
                                                    >)&nbsp;</span
                                                >관련하여 년<span lang="EN-US">2</span>회 이상 이용정지가 된 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ㅇ 회사는 불법스팸을 전송하였다고 확인된 계약자가 이용 중인 다른 번호가
                                                불법스팸에 악용되고 있거나 악용될 우려가 있다는 객관적 정황이 있는
                                                경우에는 그 다른 번호도 이용 해지 할 수 있습니다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ㅇ 회사는 규정에 의하여 서비스 계약을 해지하는 경우<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >그 사실을 당해 계약자에게<span lang="EN-US">&nbsp;5</span>일 범위
                                                내에서 통지합니다<span lang="EN-US">.&nbsp;</span>고객의 책임 있는
                                                사유로 통지할 수 없는 경우에는 통지된 것으로 간주됩니다<span
                                                    lang="EN-US"
                                                    >.&nbsp;</span
                                                >미리 통지하는 것이 곤란한 경우에는 계약해지 조치 후 통지할 수
                                                있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">37</span>조<span lang="EN-US">(</span>음성<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >문자<span lang="EN-US">,&nbsp;</span>대량<span lang="EN-US">(</span
                                                >다량<span lang="EN-US">)</span>발송자 조치<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">LTE</span
                                            ><span style="color: black">
                                                요금제는 가입자 간 개인적 통화 제공 목적으로 만든 요금제이며<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >과도한 사용에 따른 문제점을 방지하기 위하여 아래의 경우 회사는
                                                이용정지<span lang="EN-US">,&nbsp;</span>해지<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >이용제한<span lang="EN-US">,&nbsp;</span>정상요금 부과<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >월정액 기준 유사 타<span lang="EN-US">&nbsp;LTE&nbsp;</span>요금제로
                                                변경 등을 할 수 있습니다<span lang="EN-US">.(</span>회사는 아래와 같은
                                                사용이 의심되는 경우 고객의 전화번호로 문자 또는 연락을 취할 수
                                                있고<span lang="EN-US">,&nbsp;</span>해당 내용을 조사할 수 있으며<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >요금 부과<span lang="EN-US">,&nbsp;</span>경고 조치 및 요금제 변경 등을
                                                통보할 수 있음<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                서비스 제공용도 외 사용<span lang="EN-US">,&nbsp;</span>제<span
                                                    lang="EN-US"
                                                    >&nbsp;3</span
                                                >자 임의 임대
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                물리적 장치 또는 자동 발송 프로그램으로 음성통화 발신
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3.&nbsp;</span
                                            ><span style="color: black">스팸 발송</span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4.&nbsp;</span
                                            ><span style="color: black">
                                                통화호 중계<span lang="EN-US">,&nbsp;</span>재판매
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">5.&nbsp;</span
                                            ><span style="color: black">
                                                음성통화량이 일<span lang="EN-US">&nbsp;600</span>분을 초과하여 통화한
                                                횟수가 월<span lang="EN-US">&nbsp;3</span>회 이상 시<span lang="EN-US"
                                                    >(</span
                                                >영상통화는 음성의<span lang="EN-US">&nbsp;1.66</span>배로 계산<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">6.&nbsp;</span
                                            ><span style="color: black">
                                                음성통화량이<span lang="EN-US">&nbsp;6,000</span>분을 초과한 경우<span
                                                    lang="EN-US"
                                                    >(</span
                                                >영상통화는 음성의<span lang="EN-US">&nbsp;1.66</span>배로 계산<span
                                                    lang="EN-US"
                                                    >)</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">7.&nbsp;</span
                                            ><span style="color: black">
                                                음성<span lang="EN-US">/</span>영상통화량 수신처가 월<span lang="EN-US"
                                                    >&nbsp;1</span
                                                >천회선<span lang="EN-US">(</span>합산<span lang="EN-US">)</span>을
                                                초과한 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">8.&nbsp;</span
                                            ><span style="color: black">
                                                문자 사용 건수가 일<span lang="EN-US">&nbsp;200</span>건 초과하는 횟수가
                                                월 중<span lang="EN-US">&nbsp;10</span>회 초과 시
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">9.&nbsp;</span
                                            ><span style="color: black">
                                                채팅<span lang="EN-US">(text,</span>스티커<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >파일 등 소용량 컨텐츠<span lang="EN-US">)&nbsp;</span>일 사용량이
                                                발신건수<span lang="EN-US">&nbsp;500</span>건 초과<span lang="EN-US"
                                                    >(</span
                                                >그룹채팅은 과금기준<span lang="EN-US">&nbsp;1</span>건<span
                                                    lang="EN-US"
                                                    >),&nbsp;</span
                                                >또는 채팅 수신처<span lang="EN-US">&nbsp;500</span>명 초과 시
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">10.&nbsp;</span
                                            ><span style="color: black">
                                                문자 수신처가<span lang="EN-US">&nbsp;3,000</span>회선<span lang="EN-US"
                                                    >(CTN)&nbsp;</span
                                                >초과 시
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">11.&nbsp;</span
                                            ><span style="color: black">
                                                상업용 목적이 아닌 직업상 다량 문자 이용자<span lang="EN-US">(</span
                                                >예시<span lang="EN-US">&nbsp;:&nbsp;</span>택배업 종사자<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >콜택시 운전원<span lang="EN-US">,&nbsp;</span>신용카드배달원<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >대리운전업종사자 등의 개인<span lang="EN-US">)</span>의 경우 별도 확인
                                                절차를 통해<span lang="EN-US">&nbsp;8,9,10&nbsp;</span>항 예외 적용 가능
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">38</span>조<span lang="EN-US">(</span>이용번호
                                                변경<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black"
                                                >스팸 메시지 전송으로 인하여 이용이 정지된 번호는 번호변경이
                                                제한됩니다<span lang="EN-US">.</span></span
                                            >
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">39</span>조<span lang="EN-US">(</span>약관 외
                                                준칙<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                스팸 및 불법스팸과 관련하여 본 약관을 다른 약관에 우선하여 적용하며<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >이 약관에서 정하지 아니한 사항에 대하여는 전기통신사업법<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >정보통신망 이용촉진 및 정보보호 등에 관한 법률<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >개인정보보호법<span lang="EN-US">,&nbsp;</span>전자금융거래법<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >전자상거래 등에서의 소비자 보호에 관한 법률 등 관련 법령 및<span
                                                    lang="EN-US"
                                                    >&nbsp;WCDMA&nbsp;</span
                                                >약관 등 이동통신서비스 관련 약관에서 정한 바 또는 상관례에
                                                따릅니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black"
                                                >제<span lang="EN-US">8</span>장 손해배상 및 요금 회수 위탁</span
                                            >
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">40</span>조<span lang="EN-US">&nbsp;(</span
                                                >손해배상<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                회원이 본 약관의 규정을 위반함으로 인하여 회사에 손해가 발생하게 되는
                                                경우<span lang="EN-US">,&nbsp;</span>이 약관을 위반한 회원은 회사에
                                                발생하는 모든 손해를 배상하여야 합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 서비스 요금이 무료인 서비스의 이용과 관련하여 이용자에게 발생한
                                                손해에 대해 어떠한 책임도 지지 않습니다<span lang="EN-US">.&nbsp;</span
                                                >유료 서비스의 경우는 서비스 별 이용약관에 따릅니다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3.&nbsp;</span
                                            ><span style="color: black">
                                                이용자가 서비스를 이용함에 있어 행한 불법행위나 본 약관 위반행위로
                                                인하여 회사가 당해 이용자 이외의 제<span lang="EN-US">3</span>자로부터
                                                손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우 당해 이용자는
                                                자신의 책임과 비용으로 회사를 면책 시켜야 하며<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >회사가 면책되지 못한 경우 당해 이용자는 그로 인하여 회사에 발생한 모든
                                                손해를 배상하여야 합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4.&nbsp;</span
                                            ><span style="color: black">
                                                회사가 고객으로부터 서비스를 이용하지 못한 사실을 통지 받은 경우에는
                                                서비스 재개를 위해 가능한 조치를 취해야 하며<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >서비스를 다시 이용할 수 있게 된 경우 이 사실을 고객에게 통지하여야
                                                합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">5.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 다음 각 호의 하나의 사유를 입증하는 경우에는 요금 감면 또는
                                                손해배상책임이 감면될 수 있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1)&nbsp;</span
                                            ><span style="color: black">
                                                전시<span lang="EN-US">,&nbsp;</span>사변<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >천재지변 또는 이에 준하는 국가비상사태 등 불가항력으로 인한 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2)&nbsp;</span
                                            ><span style="color: black">
                                                전파의 직진 및 회절 특성에 따른 예측할 수 없는 음영지역 추가발생 등과
                                                기술진보에 따라 불가피하게 장비의 성능개선이 필요한 경우 등
                                                전기통신서비스의 특성상 불가피한 사유로 서비스 제공이 불가능한 경우
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">6.&nbsp;</span
                                            ><span style="color: black">
                                                손해배상의 청구는 회사에 서면 및 및 전화<span lang="EN-US">,&nbsp;</span
                                                >홈페이지<span lang="EN-US">, e-mail</span>로 하여야 하며<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >손해배상을 청구하고자 하는 고객은 그 청구사유<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >청구금액 등을 기재하여 서면 및 전화<span lang="EN-US">,&nbsp;</span
                                                >홈페이지<span lang="EN-US">, e-mail</span>로 제출하여야 합니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">41</span>조<span lang="EN-US">&nbsp;(</span
                                                >면책조항<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 국가의 비상사태<span lang="EN-US">,&nbsp;</span>천재지변 또는
                                                이에 준하는 불가항력으로 인해 서비스를 제공할 수 없는 경우에는 서비스
                                                제공에 대한 책임을 지지 않습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 이용자의 귀책사유로 인한 서비스의 이용 장애에 대해 책임을 지지
                                                않습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 이용자가 회사의 서비스 제공으로부터 기대되는 이익을 얻지
                                                못하였거나 서비스로부터 수반되는 잠재가치 및 서비스 자료에 대한 취사선택
                                                또는 이용으로 발생하는 손해 등에 대하여 책임을 지지 않습니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">4.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 이용자가 사이트에 게재한 게시물의 신뢰도 및 정확성 등 내용에 대해
                                                책임을 지지 않으며<span lang="EN-US">,&nbsp;</span>서비스에 게재된
                                                어떠한 의견이나 정보에 대해 확인이나 대표할 의무가 없습니다<span
                                                    lang="EN-US"
                                                    >.&nbsp;</span
                                                >아울러 회사는 회원이나 제<span lang="EN-US">3</span>자에 의해 표출된
                                                의견을 승인<span lang="EN-US">,&nbsp;</span>반대 또는 수정하지
                                                않습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">5.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 이용자 상호간 또는 이용자와 제<span lang="EN-US">3</span>자
                                                상호간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며 이로
                                                인한 손해를 배상할 책임을 지지 않습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">42</span>조<span lang="EN-US">&nbsp;(</span
                                                >신용정보 조회 및 채권의 양도<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                회사는 회원<span lang="EN-US">(</span>또는 회원의 법정대리인<span
                                                    lang="EN-US"
                                                    >)</span
                                                >에 대한 이용금액<span lang="EN-US">,&nbsp;</span>중도해지<span
                                                    lang="EN-US"
                                                    >&nbsp;</span
                                                >·<span lang="EN-US">&nbsp;</span>미반납 변상금 등의 보전을 위하여
                                                신용보험계약을 체결할 수 있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                본조 제<span lang="EN-US">1</span>항의 경우<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >회원과 회원의 법정대리인이 회사에 대하여 이용금액<span lang="EN-US"
                                                    >,&nbsp;</span
                                                >중도해지<span lang="EN-US">&nbsp;</span>·<span lang="EN-US"
                                                    >&nbsp;</span
                                                >미반납변상금 등의 납입의무 또는 상품 반납의무를 이행하지 아니하면
                                                회사는 고객<span lang="EN-US">(</span>고객의 법정대리인이 보험가입자일
                                                경우에는 그 법정대리인<span lang="EN-US">)</span>의 미납 이용금액<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >중도해지<span lang="EN-US">&nbsp;</span>·<span lang="EN-US"
                                                    >&nbsp;</span
                                                >미반납변상금 등을 제<span lang="EN-US">3</span>의 채권추심기관에
                                                이용대금에 관한 채권을 양도할 수 있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3.&nbsp;</span
                                            ><span style="color: black">
                                                제<span lang="EN-US">2</span>항의 경우<span lang="EN-US">,&nbsp;</span
                                                >미납 이용금액<span lang="EN-US">,&nbsp;</span>중도해지<span
                                                    lang="EN-US"
                                                    >&nbsp;</span
                                                >·<span lang="EN-US">&nbsp;</span>미반납변상금 등을 회사에게 대납한
                                                제<span lang="EN-US">3</span>의 채권추심기관은 회원에게 해당 대납금액 및
                                                연체금을 합산하여 청구할 수 있으며<span lang="EN-US">,&nbsp;</span
                                                >이러한 청구에도 불구하고 고객과 고객의 법정대리인이 계속하여 채무이행을
                                                하지 아니하면 회원<span lang="EN-US">(</span>또는 법정대리인<span
                                                    lang="EN-US"
                                                    >)</span
                                                >은 채권사 대지급에 따라 채무불이행 정보가 신용정보 집중기관<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >신용조회 회사 및 은행권 등 각 금융기관에 등록되어 금융상의 불이익을
                                                받게 될 수 있습니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                제<span lang="EN-US">43</span>조<span lang="EN-US">&nbsp;(</span
                                                >관할법원<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">1.&nbsp;</span
                                            ><span style="color: black">
                                                서비스 이용과 관련하여 회사와 고객 사이에 분쟁이 발생한 경우<span
                                                    lang="EN-US"
                                                    >,&nbsp;</span
                                                >회사와 이용자는 분쟁의 해결을 위해 성실히 협의합니다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">2.&nbsp;</span
                                            ><span style="color: black">
                                                본 조 제<span lang="EN-US">1</span>항의 협의에서도 분쟁이 해결되지 않을
                                                경우 양 당사자는 민사소송법상의 관할법원에 소를 제기할 수 있습니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="color: black">3.&nbsp;</span
                                            ><span style="color: black">
                                                회사와 이용자간에 제기된 소송에는 대한민국 법을 적용합니다<span
                                                    lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">부칙</span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                이 약관은<span lang="EN-US">&nbsp;2022</span>년<span lang="EN-US"
                                                    >&nbsp;11</span
                                                >월<span lang="EN-US">&nbsp;30</span>일 부터 시행한다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p class="MsoNormal">
                                            <span lang="EN-US" style="line-height: 107%">&nbsp;</span>
                                        </p>
                                    </span>
                                </div>

                                <li>
                                    <span class="termsCheck">
                                        <input
                                            type="checkbox"
                                            v-model="identityYn"
                                            value="5"
                                            id="clauseCheck5"
                                            name="addchk"
                                        />
                                        <label for="clauseCheck5" class="termsCheckLabel"></label>
                                    </span>
                                    <span class="termsTit fW-L notranslate" @click="agreeIdentity = !agreeIdentity">
                                        {{ $t('trans.t21_26') }}
                                    </span>
                                </li>
                                <div class="termsSub" v-show="agreeIdentity">
                                    <!-- 고유식별정보 수집·이용 동의 -->
                                    <span class="termsTxt">
                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    margin-top: 5.25pt;
    margin-right: 0cm;
    margin-bottom: 7.5pt;
    margin-left: 0cm;
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="letter-spacing: -0.75pt">1)</span
                                            ><span style="letter-spacing: -0.75pt">
                                                수집·이용 목적<span lang="EN-US">: </span>본인확인서비스<span
                                                    lang="EN-US"
                                                    >, </span
                                                >보편적 역무제공<span lang="EN-US">(</span>요금감면 서비스<span
                                                    lang="EN-US"
                                                    >), </span
                                                >요금감면 사무<span lang="EN-US"> , </span>명의도용 및 가입의사 확인을
                                                통한 부정가입 방지<span lang="EN-US">, </span>해지미환급금 안내 등을
                                                포함한 서비스 계약관련 사항의 처리
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    margin-top: 5.25pt;
    margin-right: 0cm;
    margin-bottom: 7.5pt;
    margin-left: 0cm;
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="letter-spacing: -0.75pt">2)</span
                                            ><span style="letter-spacing: -0.75pt">
                                                수집·이용 항목<span lang="EN-US">: </span>주민등록번호<span lang="EN-US"
                                                    >, </span
                                                >여권번호<span lang="EN-US">,</span>운전면허번호<span lang="EN-US"
                                                    >, </span
                                                >외국인등록번호<span lang="EN-US">, </span>영주증번호<span lang="EN-US"
                                                    >,</span
                                                >재외동포거소증번호<span lang="EN-US">, </span>주한미군<span
                                                    lang="EN-US"
                                                >
                                                    ID ( </span
                                                >본인<span lang="EN-US">, </span>대리인 신분확인 이미지 및 내용
                                                포함<span lang="EN-US">)</span>
                                            </span>
                                        </p>

                                        <table
                                            class="MsoTableGrid"
                                            border="1"
                                            cellspacing="0"
                                            cellpadding="0"
                                            width="671"
                                            style="width: 503pt; border-collapse: collapse; border: none"
                                        >
                                            <tr style="height: 9.8pt">
                                                <td
                                                    width="132"
                                                    valign="top"
                                                    style="
        width: 99pt;
        border: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
        height: 9.8pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 15pt;
          margin-right: 0cm;
          margin-bottom: 15pt;
          margin-left: 0cm;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">구분</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="539"
                                                    valign="top"
                                                    style="
        width: 404pt;
        border: solid windowtext 1pt;
        border-left: none;
        padding: 0cm 5.4pt 0cm 5.4pt;
        height: 9.8pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 15pt;
          margin-right: 0cm;
          margin-bottom: 15pt;
          margin-left: 0cm;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">세부항목</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 4.05pt">
                                                <td
                                                    width="132"
                                                    valign="top"
                                                    style="
        width: 99pt;
        border: solid windowtext 1pt;
        border-top: none;
        padding: 0cm 5.4pt 0cm 5.4pt;
        height: 4.05pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 15pt;
          margin-right: 0cm;
          margin-bottom: 15pt;
          margin-left: 0cm;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        식별정보
                                                    </p>
                                                </td>
                                                <td
                                                    width="539"
                                                    valign="top"
                                                    style="
        width: 404pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
        height: 4.05pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 7.5pt;
          margin-left: 0cm;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        성명<span lang="EN-US">(</span>법인명<span lang="EN-US"
                                                            >), </span
                                                        >고유식별정보<span lang="EN-US">(</span>주민등록번호<span
                                                            lang="EN-US"
                                                            >,</span
                                                        >여권번호<span lang="EN-US">, </span>운전면허번호<span
                                                            lang="EN-US"
                                                            >, </span
                                                        >외국인등록번호<span lang="EN-US">),</span>국내거소신고번
                                                        호<span lang="EN-US">, </span>전화번호<span lang="EN-US">
                                                            , </span
                                                        >국적<span lang="EN-US">, </span>체류기간<span lang="EN-US"
                                                            >, </span
                                                        >사업자<span lang="EN-US">(</span>법인<span lang="EN-US"
                                                            >) </span
                                                        >등록번호<span lang="EN-US">, </span>신분증 진위확인정보<span
                                                            lang="EN-US"
                                                            >(</span
                                                        >주민등록증 또는 운전면허 증 발급일자<span lang="EN-US"
                                                            >), </span
                                                        ><span style="letter-spacing: -0.75pt">
                                                            대리인<span lang="EN-US">(</span>성명<span lang="EN-US"
                                                                >,</span
                                                            >고유식별정보<span lang="EN-US">,</span>연락처<span
                                                                lang="EN-US"
                                                                >,</span
                                                            >주소<span lang="EN-US">,</span>본인과의 관계<span
                                                                lang="EN-US"
                                                                >)</span
                                                            >
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 1.85pt">
                                                <td
                                                    width="132"
                                                    valign="top"
                                                    style="
        width: 99pt;
        border: solid windowtext 1pt;
        border-top: none;
        padding: 0cm 5.4pt 0cm 5.4pt;
        height: 1.85pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 15pt;
          margin-right: 0cm;
          margin-bottom: 15pt;
          margin-left: 0cm;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        연락처 정보
                                                    </p>
                                                </td>
                                                <td
                                                    width="539"
                                                    valign="top"
                                                    style="
        width: 404pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
        height: 1.85pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        style="
          margin-top: 15pt;
          margin-right: 0cm;
          margin-bottom: 15pt;
          margin-left: 0cm;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">
                                                            성명<span lang="EN-US">(</span>상호<span lang="EN-US"
                                                                >),</span
                                                            >주소<span lang="EN-US">(</span>설치장소 등<span
                                                                lang="EN-US"
                                                                >), </span
                                                            >휴대폰번호<span lang="EN-US">,</span>유선전화번호<span
                                                                lang="EN-US"
                                                                >, </span
                                                            >이메일
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 0.65pt">
                                                <td
                                                    width="132"
                                                    valign="top"
                                                    style="
        width: 99pt;
        border: solid windowtext 1pt;
        border-top: none;
        padding: 0cm 5.4pt 0cm 5.4pt;
        height: 0.65pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 15pt;
          margin-right: 0cm;
          margin-bottom: 15pt;
          margin-left: 0cm;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">거래정보</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="539"
                                                    valign="top"
                                                    style="
        width: 404pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
        height: 0.65pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        style="
          margin-top: 15pt;
          margin-right: 0cm;
          margin-bottom: 15pt;
          margin-left: 0cm;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">
                                                            납부자<span lang="EN-US">(</span>성명<span lang="EN-US"
                                                                >,</span
                                                            >생년월일<span lang="EN-US">, </span>주소<span lang="EN-US"
                                                                >) </span
                                                            >은행<span lang="EN-US">(</span>카드사<span lang="EN-US"
                                                                >)</span
                                                            >명<span lang="EN-US">, </span>계좌<span lang="EN-US"
                                                                >(</span
                                                            >카드<span lang="EN-US">)</span>번호<span lang="EN-US"
                                                                >, </span
                                                            >카드유효기간<span lang="EN-US">,</span>명의자와의 관계
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 15.8pt">
                                                <td
                                                    width="132"
                                                    valign="top"
                                                    style="
        width: 99pt;
        border: solid windowtext 1pt;
        border-top: none;
        padding: 0cm 5.4pt 0cm 5.4pt;
        height: 15.8pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 15pt;
          margin-right: 0cm;
          margin-bottom: 15pt;
          margin-left: 0cm;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        본인확인
                                                    </p>
                                                </td>
                                                <td
                                                    width="539"
                                                    valign="top"
                                                    style="
        width: 404pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
        height: 15.8pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        style="
          margin-top: 15pt;
          margin-right: 0cm;
          margin-bottom: 15pt;
          margin-left: 0cm;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">
                                                            성명<span lang="EN-US"> , </span>생년월일<span lang="EN-US"
                                                                >, </span
                                                            >이동전화번호<span lang="EN-US">, </span>이동통신사정보<span
                                                                lang="EN-US"
                                                                >,</span
                                                            >연계정보<span lang="EN-US">(CI), </span
                                                            >중복가입확인정보<span lang="EN-US">(DI)</span>
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 15.8pt">
                                                <td
                                                    width="132"
                                                    valign="top"
                                                    style="
        width: 99pt;
        border: solid windowtext 1pt;
        border-top: none;
        padding: 0cm 5.4pt 0cm 5.4pt;
        height: 15.8pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 15pt;
          margin-right: 0cm;
          margin-bottom: 15pt;
          margin-left: 0cm;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        생성정보
                                                    </p>
                                                </td>
                                                <td
                                                    width="539"
                                                    valign="top"
                                                    style="
        width: 404pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
        height: 15.8pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 15pt;
          margin-right: 0cm;
          margin-bottom: 15pt;
          margin-left: 0cm;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        발<span lang="EN-US">·</span>수신번호<span lang="EN-US">(</span
                                                        >통화 상대방 번호 포함<span lang="EN-US"> ), </span
                                                        >통화시각<span lang="EN-US">, </span>사용도수<span lang="EN-US"
                                                            >, </span
                                                        >서비스이용기록<span lang="EN-US">,</span>접속로그<span
                                                            lang="EN-US"
                                                            >, </span
                                                        >쿠키<span lang="EN-US">, </span>접속<span lang="EN-US">IP</span
                                                        >정 보<span lang="EN-US">(</span>도메인 주소정보 및 접속<span
                                                            lang="EN-US"
                                                            >URL</span
                                                        >정보 포함<span lang="EN-US">), </span>결제기록<span
                                                            lang="EN-US"
                                                            >, </span
                                                        >이용정지기록<span lang="EN-US">,</span>멤버십 정보<span
                                                            lang="EN-US"
                                                            >(</span
                                                        >멤버십 가입 고객에 한함<span lang="EN-US">),</span>기타 요금
                                                        과금에 필요한 데이터 및 이를 조합하여 생성되는 정보<span
                                                            lang="EN-US"
                                                            >(</span
                                                        >요약개인정보<span lang="EN-US">,</span>데이터마이닝 분석 정보
                                                        등<span lang="EN-US">), Application </span>사용관련정보<span
                                                            lang="EN-US"
                                                            >(</span
                                                        >사용<span lang="EN-US"> App</span>정보<span lang="EN-US"
                                                            >, </span
                                                        >사용량 등<span lang="EN-US">)</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 15.8pt">
                                                <td
                                                    width="132"
                                                    valign="top"
                                                    style="
        width: 99pt;
        border: solid windowtext 1pt;
        border-top: none;
        padding: 0cm 5.4pt 0cm 5.4pt;
        height: 15.8pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 15pt;
          margin-right: 0cm;
          margin-bottom: 15pt;
          margin-left: 0cm;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        기타 서비스 관련 수집 정보
                                                    </p>
                                                </td>
                                                <td
                                                    width="539"
                                                    valign="top"
                                                    style="
        width: 404pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
        height: 15.8pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 7.5pt;
          margin-left: 11.25pt;
          text-align: left;
          text-indent: -11.25pt;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">
                                                            홈페이지 아이디 · 비밀번호<span lang="EN-US">,</span
                                                            >번호이동전 사업자정보 · 가입인증정보<span lang="EN-US"
                                                                >, </span
                                                            >번호이동 인증정보<span lang="EN-US">,</span>단말기정보<span
                                                                lang="EN-US"
                                                                >(MAC Address, IMEI </span
                                                            >등<span lang="EN-US">),USIM</span>정보<span lang="EN-US"
                                                                >, </span
                                                            >신용정보<span lang="EN-US">, </span>과금정보<span
                                                                lang="EN-US"
                                                                >,</span
                                                            >과금 대행의 경우 미납 정보<span lang="EN-US">, </span
                                                            >품질정보<span lang="EN-US">,</span>이용내역<span
                                                                lang="EN-US"
                                                                >, </span
                                                            >가입정보<span lang="EN-US">, </span>쿠키<span lang="EN-US"
                                                                >,</span
                                                            >접속로그<span lang="EN-US">, </span>접속<span lang="EN-US"
                                                                >IP, </span
                                                            >통계데이터<span lang="EN-US">,</span>홈페이지 설정정보<span
                                                                lang="EN-US"
                                                                >,
                                                            </span> </span
                                                        >부가서비스<span lang="EN-US">·</span>번호이동<span lang="EN-US"
                                                            >·</span
                                                        >할부매매계약 내역<span lang="EN-US">, </span>이동전화 서비스
                                                        가입 및 해지일<span lang="EN-US">·</span>이동전 화 가입 기간 등
                                                    </p>
                                                </td>
                                            </tr>
                                        </table>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    margin-top: 5.25pt;
    margin-right: 0cm;
    margin-bottom: 7.5pt;
    margin-left: 11.25pt;
    text-align: left;
    text-indent: -11.25pt;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US">※ </span>전기통신사업법 제<span lang="EN-US"> 32 </span
                                            >조의<span lang="EN-US">4</span>에 근거하여 전기통신사업자는 전기통신역무
                                            제공에 관한 계약을 체결하는 경우 부정가입방지시스템 등을 이용하여 본인
                                            여부를 확인하기 위해서 본인임을 증명하는 증서 및 서류의 수집<span
                                                lang="EN-US"
                                                >·</span
                                            >이용이 가능합니다<span lang="EN-US">. (</span>주민등록증<span lang="EN-US"
                                                >, </span
                                            >운전면허증 등<span lang="EN-US">)</span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    margin-top: 5.25pt;
    margin-right: 0cm;
    margin-bottom: 7.5pt;
    margin-left: 11.25pt;
    text-align: left;
    text-indent: -11.25pt;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US">※</span
                                            ><span lang="EN-US" style="letter-spacing: -0.75pt"> </span
                                            ><span style="letter-spacing: -0.75pt">
                                                고유식별 정보의 보유 및 이용기간은 서비스 계약해지시까지 이용하고<span
                                                    lang="EN-US"
                                                    >,</span
                                                >요금전산 과 · 오납 분쟁대리를 위하여 해지 후 요금완납 후<span
                                                    lang="EN-US"
                                                >
                                                    6</span
                                                >개월까지 이용 가능합니다<span lang="EN-US">.</span>
                                            </span>
                                        </p>
                                    </span>
                                </div>

                                <li>
                                    <span class="termsCheck">
                                        <input
                                            type="checkbox"
                                            v-model="agreePrivacyCollection"
                                            value="2"
                                            id="clauseCheck2"
                                            name="addchk"
                                        />
                                        <label for="clauseCheck2" class="termsCheckLabel"></label>
                                    </span>
                                    <span
                                        class="termsTit fW-L notranslate"
                                        @click="privacyCollectionShow = !privacyCollectionShow"
                                    >
                                        {{ $t('trans.t04_15') }}
                                    </span>
                                </li>
                                <div class="termsSub" v-show="privacyCollectionShow">
                                    <span class="termsTxt">
                                        <!-- 개인정보 이용 동의 -->
                                        <table
                                            class="MsoNormalTable"
                                            border="0"
                                            cellspacing="0"
                                            cellpadding="0"
                                            width="98%"
                                            style="
    width: 98.16%;
    background: #666666;
    border-collapse: collapse;
  "
                                        >
                                            <tr style="height: 22.5pt">
                                                <td
                                                    width="43%"
                                                    style="
        width: 43.9%;
        border-top: solid #dcdcdb 1.5pt;
        border-left: none;
        border-bottom: solid #dcdcdb 1pt;
        border-right: solid #dcdcdb 1pt;
        background: #f6f6f6;
        padding: 0cm 0cm 0cm 0cm;
        height: 22.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          vertical-align: middle;
          word-break: keep-all;
        "
                                                    >
                                                        <b>
                                                            <span style="color: #212121; letter-spacing: -0.1pt"
                                                                >수집<span lang="EN-US">·</span>이용목적</span
                                                            >
                                                        </b>
                                                    </p>
                                                </td>
                                                <td
                                                    width="36%"
                                                    style="
        width: 36.52%;
        border-top: solid #dcdcdb 1.5pt;
        border-left: none;
        border-bottom: solid #dcdcdb 1pt;
        border-right: solid #dcdcdb 1pt;
        background: #f6f6f6;
        padding: 0cm 0cm 0cm 0cm;
        height: 22.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          vertical-align: middle;
          word-break: keep-all;
        "
                                                    >
                                                        <b>
                                                            <span style="color: #212121; letter-spacing: -0.1pt"
                                                                >수집<span lang="EN-US">·</span>이용항목</span
                                                            >
                                                        </b>
                                                    </p>
                                                </td>
                                                <td
                                                    width="19%"
                                                    style="
        width: 19.58%;
        border-top: solid #dcdcdb 1.5pt;
        border-left: none;
        border-bottom: solid #dcdcdb 1pt;
        border-right: none;
        background: #f6f6f6;
        padding: 0cm 0cm 0cm 0cm;
        height: 22.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          vertical-align: middle;
          word-break: keep-all;
        "
                                                    >
                                                        <b>
                                                            <span style="color: #212121; letter-spacing: -0.1pt"
                                                                >처리 및 보유기간</span
                                                            >
                                                        </b>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 13.5pt">
                                                <td
                                                    style="
        border-top: none;
        border-left: none;
        border-bottom: solid #dcdcdb 1pt;
        border-right: solid #dcdcdb 1pt;
        background: white;
        padding: 0cm 0cm 0cm 0cm;
        height: 13.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <b>
                                                            <span style="color: #212121; letter-spacing: -0.1pt">
                                                                이동전화서비스<span lang="EN-US">&#8729;</span
                                                                >부가서비스<span lang="EN-US">, </span>통신과금서비스 등
                                                                회사가 제공하는 제반서비스<span lang="EN-US">(‘</span
                                                                >서비스<span lang="EN-US">’</span>라 함<span
                                                                    lang="EN-US"
                                                                    >) </span
                                                                >제공
                                                            </span> </b
                                                        ><span
                                                            lang="EN-US"
                                                            style="color: #212121; letter-spacing: -0.1pt"
                                                            >&nbsp;</span
                                                        ><b>
                                                            <span style="color: #212121; letter-spacing: -0.1pt">
                                                                서비스 제공을 위한 본인 식별<span lang="EN-US"
                                                                    >&#8729;</span
                                                                >인증<span lang="EN-US">&lt;</span>
                                                            </span> </b
                                                        ><span
                                                            lang="EN-US"
                                                            style="color: #212121; letter-spacing: -0.1pt"
                                                            >&nbsp;</span
                                                        ><b>
                                                            <span style="color: #212121; letter-spacing: -0.1pt"
                                                                >서비스 품질확인 및 품질개선<span lang="EN-US"
                                                                    >&lt;</span
                                                                ></span
                                                            > </b
                                                        ><span
                                                            lang="EN-US"
                                                            style="color: #212121; letter-spacing: -0.1pt"
                                                            >&nbsp;</span
                                                        ><b>
                                                            <span style="color: #212121; letter-spacing: -0.1pt">
                                                                서비스 관련 요금 정산<span lang="EN-US">&#8729;</span
                                                                >청구<span lang="EN-US">&#8729;</span>수납<span
                                                                    lang="EN-US"
                                                                    >&#8729;</span
                                                                >추심<span lang="EN-US">&#8729;</span>자산유동화<span
                                                                    lang="EN-US"
                                                                    >&lt;</span
                                                                >
                                                            </span> </b
                                                        ><span
                                                            lang="EN-US"
                                                            style="color: #212121; letter-spacing: -0.1pt"
                                                            >&nbsp;</span
                                                        ><b>
                                                            <span style="color: #212121; letter-spacing: -0.1pt"
                                                                >상담<span lang="EN-US">&#8729;</span>불만 처리 및
                                                                서비스 관련 정보제공</span
                                                            >
                                                        </b>
                                                    </p>
                                                </td>
                                                <td
                                                    style="
        border: none;
        border-bottom: solid #dcdcdb 1pt;
        background: white;
        padding: 3.75pt 3.75pt 3.75pt 3.75pt;
        height: 13.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: #212121; letter-spacing: -0.1pt">
                                                            성명<span lang="EN-US">(</span>법인명<span lang="EN-US"
                                                                >), </span
                                                            >생년월일<span lang="EN-US">, </span>성별<span lang="EN-US"
                                                                >,</span
                                                            >주민등록번호<span lang="EN-US">, </span>여권번호<span
                                                                lang="EN-US"
                                                                >, </span
                                                            >외국인등록번호<span lang="EN-US">,</span>영주권번호<span
                                                                lang="EN-US"
                                                                >, </span
                                                            >재외동포거주번호<span lang="EN-US"> , </span>주한미군<span
                                                                lang="EN-US"
                                                                >ID, </span
                                                            >법정대리인 정보<span lang="EN-US">(</span>미성년자에만
                                                            해당<span lang="EN-US">), </span>이동전화번호<span
                                                                lang="EN-US"
                                                                >, </span
                                                            >주소<span lang="EN-US">,</span>전화번호<span lang="EN-US"
                                                                >, e-mail </span
                                                            >주소<span lang="EN-US"> , </span>단말기 정보<span
                                                                lang="EN-US"
                                                                >(</span
                                                            >모델명<span lang="EN-US">, IMEI, USIM</span>번호<span
                                                                lang="EN-US"
                                                                >, MAC Address</span
                                                            >등<span lang="EN-US">), </span>서비스 가입일<span
                                                                lang="EN-US"
                                                                >&#8729;</span
                                                            >해지일<span lang="EN-US">&#8729;</span>가입기간<span
                                                                lang="EN-US"
                                                                >, </span
                                                            >계좌<span lang="EN-US">(</span>카드<span lang="EN-US"
                                                                >)</span
                                                            >정보<span lang="EN-US">, </span>예금주<span lang="EN-US"
                                                                >(</span
                                                            >카드주<span lang="EN-US">) </span>정보<span lang="EN-US"
                                                                >, </span
                                                            >국가유공자증명<span lang="EN-US">&#8729;</span>복지할인
                                                            증명 관련 제반증명<span lang="EN-US">, </span>서비스
                                                            과금정보<span lang="EN-US">,</span>단말기매매계약 내역<span
                                                                lang="EN-US"
                                                                >, </span
                                                            >분할상환계약내역<span lang="EN-US">,</span>연체정보 관련
                                                            신용도 판단정보<span lang="EN-US">, </span>신용거래정보<span
                                                                lang="EN-US"
                                                                >,</span
                                                            >신용등급<span lang="EN-US">, </span>신용보험 가입가능
                                                            여부<span lang="EN-US">,</span>통신과금서비스 거래정보<span
                                                                lang="EN-US"
                                                                >, </span
                                                            >휴대폰결제 비밀번호<span lang="EN-US">,</span
                                                            >번호이동정보<span lang="EN-US">, </span>개인위치정보<span
                                                                lang="EN-US"
                                                                >(</span
                                                            >이동전화 단말기<span lang="EN-US">, </span>기지국 위치<span
                                                                lang="EN-US"
                                                                >, GPS</span
                                                            >정보<span lang="EN-US"
                                                                >, Zigbee AP(Access Point), WiFi AP, RFID</span
                                                            >태그<span lang="EN-US">, USIM</span>관련 정보를<span
                                                                lang="EN-US"
                                                            >
                                                                LBS </span
                                                            >플랫폼을 이용하여 수집<span lang="EN-US">),</span>서비스
                                                            이용과정에서 생성되는 정보<span lang="EN-US">(</span
                                                            >발ㆍ수신번호<span lang="EN-US">(</span>통화상대방번호
                                                            포함<span lang="EN-US">), </span>통화시각<span lang="EN-US">
                                                                , </span
                                                            >사용도수<span lang="EN-US">, </span>서비스이용기록<span
                                                                lang="EN-US"
                                                                >, </span
                                                            >접속로그<span lang="EN-US">,</span>쿠키<span lang="EN-US"
                                                                >, </span
                                                            >접속<span lang="EN-US"> IP </span>정보<span lang="EN-US"
                                                                >,</span
                                                            >결제기록<span lang="EN-US">, </span>이용정지기록<span
                                                                lang="EN-US"
                                                                >, </span
                                                            >기타 요금 과금에 필요한 데이터 및 이를 조합하여 생성되는
                                                            정보
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    rowspan="4"
                                                    style="
        border-top: none;
        border-left: solid #dcdcdb 1pt;
        border-bottom: solid #dcdcdb 1pt;
        border-right: none;
        background: white;
        padding: 3.75pt 3.75pt 3.75pt 3.75pt;
        height: 13.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 12pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          vertical-align: middle;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: #212121; letter-spacing: -0.1pt">
                                                            아래의 경우를 제외하고 서비스 계약 해지 시까지<span
                                                                lang="EN-US"
                                                                >(</span
                                                            >단<span lang="EN-US">,</span>일부 서비스만 해지한 경우는
                                                            제외<span lang="EN-US">)</span>
                                                        </span>
                                                    </p>
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 12pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          vertical-align: middle;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: #212121; letter-spacing: -0.1pt"
                                                            >가<span lang="EN-US">. </span>법령에 따로 정한 경우에는
                                                            해당 기간까지</span
                                                        >
                                                    </p>
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 12pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          vertical-align: middle;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: #212121; letter-spacing: -0.1pt">
                                                            나<span lang="EN-US">. </span>요금관련 분쟁해결을 위해 해지
                                                            후<span lang="EN-US">6</span>개월까지<span lang="EN-US"
                                                                >(</span
                                                            >단<span lang="EN-US">, </span>서비스 계약이 해지된 고객이
                                                            요금정산이 미완료된 경우는 해결 완료일로부터<span
                                                                lang="EN-US"
                                                            >
                                                                6</span
                                                            >개월 까지<span lang="EN-US">,</span>요금 관련 분쟁이 계속될
                                                            경우에는 해결 시까지<span lang="EN-US">)</span>
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 13.5pt">
                                                <td
                                                    style="
        border-top: none;
        border-left: none;
        border-bottom: solid #dcdcdb 1pt;
        border-right: solid #dcdcdb 1pt;
        background: white;
        padding: 0cm 0cm 0cm 0cm;
        height: 13.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <b>
                                                            <span style="color: #212121; letter-spacing: -0.1pt">
                                                                위치정보사업 제공 및 개별적으로 위치기반서비스에
                                                                가입하는 경우 해당 서비스 제공<span lang="EN-US"
                                                                    >&#8729;</span
                                                                >요금정산
                                                            </span>
                                                        </b>
                                                    </p>
                                                </td>
                                                <td
                                                    style="
        border: none;
        border-bottom: solid #dcdcdb 1pt;
        background: white;
        padding: 3.75pt 3.75pt 3.75pt 3.75pt;
        height: 13.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: #212121; letter-spacing: -0.1pt">
                                                            개인위치정보<span lang="EN-US">(</span>이동전화 단말기<span
                                                                lang="EN-US"
                                                                >, </span
                                                            >기지국 위치<span lang="EN-US"> , GPS </span>정보<span
                                                                lang="EN-US"
                                                                >, Zigbee AP(Access Point), WiFi AP, RFID </span
                                                            >태그<span lang="EN-US">, USIM</span>등<span lang="EN-US">
                                                                LBS </span
                                                            >플랫폼 등을 이용하여 수집<span lang="EN-US">)</span>
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="
        border-top: none;
        border-left: none;
        border-bottom: solid #dcdcdb 1pt;
        border-right: solid #dcdcdb 1pt;
        background: white;
        padding: 0cm 0cm 0cm 0cm;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <b>
                                                            <span style="color: #212121; letter-spacing: -0.1pt">
                                                                명의도용 방지<span lang="EN-US">, </span>가입의사
                                                                확인<span lang="EN-US">, </span>스팸발송자 가입제한 등
                                                            </span>
                                                        </b>
                                                    </p>
                                                </td>
                                                <td
                                                    style="
        border: none;
        border-bottom: solid #dcdcdb 1pt;
        background: white;
        padding: 3.75pt 3.75pt 3.75pt 3.75pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: #212121; letter-spacing: -0.1pt">
                                                            성명<span lang="EN-US">(</span>법인명<span lang="EN-US"
                                                                >), </span
                                                            >고유식별정보<span lang="EN-US">, </span>생년월일<span
                                                                lang="EN-US"
                                                                >,</span
                                                            >신분증 기재사항<span lang="EN-US">, </span>주소<span
                                                                lang="EN-US"
                                                                >, </span
                                                            >주민등록번호 대체수단<span lang="EN-US">, </span>이동통신사
                                                            정보
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="
        border-top: none;
        border-left: none;
        border-bottom: solid #dcdcdb 1pt;
        border-right: solid #dcdcdb 1pt;
        background: white;
        padding: 0cm 0cm 0cm 0cm;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <b
                                                            ><span style="color: #212121; letter-spacing: -0.1pt"
                                                                >본인확인서비스</span
                                                            ></b
                                                        >
                                                    </p>
                                                </td>
                                                <td
                                                    style="
        border: none;
        border-bottom: solid #dcdcdb 1pt;
        background: white;
        padding: 3.75pt 3.75pt 3.75pt 3.75pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: #212121; letter-spacing: -0.1pt">
                                                            성명<span lang="EN-US">(</span>법인명<span lang="EN-US"
                                                                >), </span
                                                            >고유식별정보<span lang="EN-US">, </span>생년월일<span
                                                                lang="EN-US"
                                                                >,</span
                                                            >신분증 기재사항<span lang="EN-US">, </span>주소<span
                                                                lang="EN-US"
                                                                >, </span
                                                            >주민등록번호 대체수단<span lang="EN-US">, </span>이동통신사
                                                            정보
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="
        border-top: none;
        border-left: none;
        border-bottom: solid #dcdcdb 1pt;
        border-right: solid #dcdcdb 1pt;
        background: white;
        padding: 0cm 0cm 0cm 0cm;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <b>
                                                            <span style="color: #212121; letter-spacing: -0.1pt">
                                                                스팸발송자 가입제한
                                                            </span>
                                                        </b>
                                                    </p>
                                                </td>
                                                <td
                                                    style="
        border: none;
        border-bottom: solid #dcdcdb 1pt;
        background: white;
        padding: 3.75pt 3.75pt 3.75pt 3.75pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: #212121; letter-spacing: -0.1pt">
                                                            성명<span lang="EN-US">(</span>법인명<span lang="EN-US"
                                                                >), </span
                                                            >고유식별정보<span lang="EN-US">, </span>생년월일<span
                                                                lang="EN-US"
                                                                >,</span
                                                            >신분증 기재사항<span lang="EN-US">, </span>주소<span
                                                                lang="EN-US"
                                                                >, </span
                                                            >주민등록번호 대체수단<span lang="EN-US">, </span>이동통신사
                                                            정보
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    style="
        border-top: none;
        border-left: solid #dcdcdb 1pt;
        border-bottom: solid #dcdcdb 1pt;
        border-right: none;
        background: white;
        padding: 3.75pt 3.75pt 3.75pt 3.75pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: #212121; letter-spacing: -0.1pt">
                                                            스팸발송 사유로 가입제한된 자는 가입제한일로부터<span
                                                                lang="EN-US"
                                                            >
                                                                12</span
                                                            >개월까지
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="
        border-top: none;
        border-left: none;
        border-bottom: solid #dcdcdb 1pt;
        border-right: solid #dcdcdb 1pt;
        background: white;
        padding: 0cm 0cm 0cm 0cm;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <b>
                                                            <span style="color: #212121; letter-spacing: -0.1pt">
                                                                선불서비스 충전
                                                            </span>
                                                        </b>
                                                    </p>
                                                </td>
                                                <td
                                                    style="
        border: none;
        border-bottom: solid #dcdcdb 1pt;
        background: white;
        padding: 3.75pt 3.75pt 3.75pt 3.75pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: #212121; letter-spacing: -0.1pt">
                                                            휴대폰번호<span lang="EN-US">,</span>충전방법<span
                                                                lang="EN-US"
                                                                >(</span
                                                            >신용카드번호<span lang="EN-US">, </span>계좌번호<span
                                                                lang="EN-US"
                                                                >,</span
                                                            >선불<span lang="EN-US">(PPS) </span>카드번호
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    style="
        border-top: none;
        border-left: solid #dcdcdb 1pt;
        border-bottom: solid #dcdcdb 1pt;
        border-right: none;
        background: white;
        padding: 3.75pt 3.75pt 3.75pt 3.75pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span
                                                            lang="EN-US"
                                                            style="color: #212121; letter-spacing: -0.1pt"
                                                            >5</span
                                                        ><span style="color: #212121; letter-spacing: -0.1pt">
                                                            년까지<span lang="EN-US">(</span>전자상거래 등에서의
                                                            소비자보호에 관한 법률<span lang="EN-US">)</span>
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="
        border-top: none;
        border-left: none;
        border-bottom: solid #dcdcdb 1pt;
        border-right: solid #dcdcdb 1pt;
        background: white;
        padding: 0cm 0cm 0cm 0cm;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <b>
                                                            <span style="color: #212121; letter-spacing: -0.1pt">
                                                                주민등록전산정보를 통한 실 사용자 확인
                                                            </span>
                                                        </b>
                                                    </p>
                                                </td>
                                                <td
                                                    style="
        border: none;
        border-bottom: solid #dcdcdb 1pt;
        background: white;
        padding: 3.75pt 3.75pt 3.75pt 3.75pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: #212121; letter-spacing: -0.1pt">
                                                            성명<span lang="EN-US">,</span>주민등록번호
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    style="
        border-top: none;
        border-left: solid #dcdcdb 1pt;
        border-bottom: solid #dcdcdb 1pt;
        border-right: none;
        background: white;
        padding: 3.75pt 3.75pt 3.75pt 3.75pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: #212121; letter-spacing: -0.1pt"
                                                            >서비스 해지 시까지</span
                                                        >
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="
        border-top: none;
        border-left: none;
        border-bottom: solid #dcdcdb 1pt;
        border-right: solid #dcdcdb 1pt;
        background: white;
        padding: 0cm 0cm 0cm 0cm;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <b>
                                                            <span style="color: #212121; letter-spacing: -0.1pt">
                                                                외국인 출국여부 확인
                                                            </span>
                                                        </b>
                                                    </p>
                                                </td>
                                                <td
                                                    style="
        border: none;
        border-bottom: solid #dcdcdb 1pt;
        background: white;
        padding: 3.75pt 3.75pt 3.75pt 3.75pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
            margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: #212121; letter-spacing: -0.1pt">
                                                            성명<span lang="EN-US">,</span>국적<span lang="EN-US"
                                                                >, </span
                                                            >여권번호<span lang="EN-US">, </span>등록번호<span
                                                                lang="EN-US"
                                                                >,</span
                                                            >생년월일<span lang="EN-US">, </span>회선번호
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    style="
        border-top: none;
        border-left: solid #dcdcdb 1pt;
        border-bottom: solid #dcdcdb 1pt;
        border-right: none;
        background: white;
        padding: 3.75pt 3.75pt 3.75pt 3.75pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: #212121; letter-spacing: -0.1pt"
                                                            >체류기간 까지</span
                                                        >
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="border-top: none; border-left: none; border-bottom: 1pt solid rgb(220, 220, 219); border-right: 1pt solid rgb(220, 220, 219); background: white; padding: 0cm;"
                                                >
                                                    <p
                                                        align="center"
                                                        class="MsoNormal"
                                                        style="margin-bottom: 0.0001pt; text-align: center; line-height: normal; word-break: keep-all;"
                                                    >
                                                        <b
                                                            ><span
                                                                style="color: rgb(33, 33, 33); letter-spacing: -0.1pt;"
                                                            >
                                                                전기통신역무 가입
                                                            </span></b
                                                        >
                                                    </p>
                                                </td>
                                                <td
                                                    style="border-top: none; border-right: none; border-bottom: 1pt solid rgb(220, 220, 219); border-left: none; border-image: initial; background: white; padding: 3.75pt;"
                                                >
                                                    <p
                                                        align="left"
                                                        class="MsoNormal"
                                                        style="margin-bottom: 0.0001pt; text-align: left; line-height: normal; word-break: keep-all;"
                                                    >
                                                        <span style="color: rgb(33, 33, 33); letter-spacing: -0.1pt;">
                                                            고유식별정보 <span lang="EN-US">,</span>신용도판단정보
                                                            <span lang="EN-US">(</span>번호변작
                                                            <span lang="EN-US">,</span> 불법스팸
                                                            <span lang="EN-US">,</span> 보이스피싱
                                                            <span lang="EN-US">,</span> 불법대부광고 등으로 인한 서비스
                                                            정지 등
                                                            <span lang="EN-US">)</span>
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    style="border-top: none; border-left: 1pt solid rgb(220, 220, 219); border-bottom: 1pt solid rgb(220, 220, 219); border-right: none; background: white; padding: 3.75pt;"
                                                >
                                                    <p
                                                        align="left"
                                                        class="MsoNormal"
                                                        style="margin-bottom: 0.0001pt; text-align: left; line-height: normal; word-break: keep-all;"
                                                    >
                                                        <span style="color: rgb(33, 33, 33); letter-spacing: -0.1pt;"
                                                            >서비스 제공기간<span lang="EN-US">(</span>단
                                                            <span lang="EN-US">,</span> 가입거절시 상담 종료시점 까지
                                                            <span lang="EN-US">)</span>
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                        </table>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    margin-top: 5.25pt;
    margin-right: 0cm;
    margin-bottom: 7.5pt;
    margin-left: 11.25pt;
    text-align: left;
    text-indent: -11.25pt;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="letter-spacing: -0.75pt"> <br /> </span
                                            ><span style="letter-spacing: -0.75pt">
                                                ※ 방송통신위원회고시 제<span lang="EN-US">2013-16</span>호에 근거하여
                                                기간통신 사업자로부터 이동통신서비스를 도매제공 받아 재판매 하는
                                                전기통신사업자는 주민등록번호 수집 · 이용이 가능합니다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>
                                    </span>
                                </div>

                                <li>
                                    <span class="termsCheck">
                                        <input
                                            type="checkbox"
                                            v-model="offerYn"
                                            value="6"
                                            id="clauseCheck6"
                                            name="addchk"
                                        />
                                        <label for="clauseCheck6" class="termsCheckLabel"></label>
                                    </span>
                                    <span class="termsTit fW-L notranslate" @click="agreeOffer = !agreeOffer">
                                        {{ $t('trans.t21_27') }}
                                    </span>
                                </li>
                                <div class="termsSub" v-show="agreeOffer">
                                    <span class="termsTxt">
                                        <!-- 개인정보 제3자 제공 동의 -->
                                        <table
                                            class="MsoNormalTable"
                                            border="1"
                                            cellspacing="0"
                                            cellpadding="0"
                                            summary="제공받는자,제공목적,제공정보종류,보유 및 이용시간 정보표"
                                            width="605"
                                            style="
    width: 16cm;
    margin-left: -0.25pt;
    border-collapse: collapse;
    border: none;
  "
                                        >
                                            <tr style="height: 35.25pt">
                                                <td
                                                    width="142"
                                                    style="
        width: 106.35pt;
        border: solid windowtext 1pt;
        background: #fcfcfc;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 35.25pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">제공받는자</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="246"
                                                    style="
        width: 184.25pt;
        border: solid windowtext 1pt;
        border-left: none;
        background: #fcfcfc;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 35.25pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">제공목적</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="161"
                                                    style="
        width: 120.5pt;
        border: solid windowtext 1pt;
        border-left: none;
        background: #fcfcfc;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 35.25pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">제공정보종류</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="57"
                                                    valign="top"
                                                    style="
        width: 42.5pt;
        border: solid windowtext 1pt;
        border-left: none;
        background: #fcfcfc;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 35.25pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">보유및이용기간</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 54pt">
                                                <td
                                                    width="142"
                                                    style="
        width: 106.35pt;
        border: solid windowtext 1pt;
        border-top: none;
        background: #fcfcfc;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 54pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">
                                                            한국정보통신진흥협회<span lang="EN-US">, </span
                                                            >행정자치부<span lang="EN-US">,</span>경찰청<span
                                                                lang="EN-US"
                                                                >, </span
                                                            >법무부<span lang="EN-US">, </span>보건복지부<span
                                                                lang="EN-US"
                                                                >,</span
                                                            >국가보훈처
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="246"
                                                    style="
        width: 184.25pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 54pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">신분증 진위확인</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="161"
                                                    style="
        width: 120.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 54pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">
                                                            성명<span lang="EN-US">, </span>주민등록번호<span
                                                                lang="EN-US"
                                                                >(</span
                                                            >외국인의 경우외국인등록번호·거소신고번호<span lang="EN-US"
                                                                >, </span
                                                            >여권번호·생년월일·국적<span lang="EN-US">),</span
                                                            >신분증발급일자<span lang="EN-US">,</span>운전면허번호<span
                                                                lang="EN-US"
                                                            >
                                                                , </span
                                                            >사진 등 신분증 기재사항<span lang="EN-US">(</span>대리인
                                                            포함<span lang="EN-US">)</span>
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="57"
                                                    valign="top"
                                                    style="
        width: 42.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 54pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">서비스제공기간</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 16.5pt">
                                                <td
                                                    width="142"
                                                    style="
        width: 106.35pt;
        border: solid windowtext 1pt;
        border-top: none;
        background: #fcfcfc;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 16.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black"
                                                            >한국정보통신진흥협회<span lang="EN-US">,</span></span
                                                        >
                                                    </p>
                                                </td>
                                                <td
                                                    width="246"
                                                    rowspan="2"
                                                    style="
        width: 184.25pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 16.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">
                                                            사망<span lang="EN-US">, </span>완전출국 확인
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="161"
                                                    rowspan="2"
                                                    style="
        width: 120.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 16.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">
                                                            성명<span lang="EN-US">,</span>주민등록번호<span
                                                                lang="EN-US"
                                                                >(</span
                                                            >외국인등록번호<span lang="EN-US">)</span>
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="57"
                                                    valign="top"
                                                    style="
        width: 42.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 16.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">서비스제공기간</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 16.5pt">
                                                <td
                                                    width="142"
                                                    style="
        width: 106.35pt;
        border: solid windowtext 1pt;
        border-top: none;
        background: #fcfcfc;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 16.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black"
                                                            >행정자치부<span lang="EN-US">, </span>법무부</span
                                                        >
                                                    </p>
                                                </td>
                                                <td
                                                    width="57"
                                                    valign="top"
                                                    style="
        width: 42.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 16.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">서비스제공기간</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 27pt">
                                                <td
                                                    width="142"
                                                    style="
        width: 106.35pt;
        border: solid windowtext 1pt;
        border-top: none;
        background: #fcfcfc;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 27pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black"
                                                            >한국정보통신진흥협회<span lang="EN-US">, </span
                                                            >행정자치부</span
                                                        >
                                                    </p>
                                                </td>
                                                <td
                                                    width="246"
                                                    style="
        width: 184.25pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 27pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">미성년자 부모 여부 확인</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="161"
                                                    style="
        width: 120.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 27pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">
                                                            고객 및 부모의 성명<span lang="EN-US">,</span
                                                            >주민등록번호<span lang="EN-US">(</span>외국인의
                                                            경우외국인등록번호·거소신고번호<span lang="EN-US">)</span>
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="57"
                                                    valign="top"
                                                    style="
        width: 42.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 27pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">서비스제공기간</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 27pt">
                                                <td
                                                    width="142"
                                                    style="
        width: 106.35pt;
        border: solid windowtext 1pt;
        border-top: none;
        background: #fcfcfc;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 27pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">한국정보통신진흥협회</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="246"
                                                    style="
        width: 184.25pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 27pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">
                                                            채무불이행정보 등록 ·해제<span lang="EN-US">(</span
                                                            >연체고객<span lang="EN-US">)</span>
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="161"
                                                    style="
        width: 120.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 27pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">
                                                            체납금액<span lang="EN-US">, </span>고객명<span lang="EN-US"
                                                                >,</span
                                                            >주민등록번호<span lang="EN-US">, </span>청구금액
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="57"
                                                    valign="top"
                                                    style="
        width: 42.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 27pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">서비스제공기간</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 27pt">
                                                <td
                                                    width="142"
                                                    style="
        width: 106.35pt;
        border: solid windowtext 1pt;
        border-top: none;
        background: #fcfcfc;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 27pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">한국인터넷진흥원</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="246"
                                                    style="
        width: 184.25pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 27pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">
                                                            스팸등록 이용제한<span lang="EN-US"> (KISA </span>요청
                                                            시<span lang="EN-US">)</span>
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="161"
                                                    style="
        width: 120.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 27pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black"
                                                            >스팸 신고된 고객명<span lang="EN-US">, </span
                                                            >전화번호</span
                                                        >
                                                    </p>
                                                </td>
                                                <td
                                                    width="57"
                                                    valign="top"
                                                    style="
        width: 42.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 27pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">업무수행기간</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 27pt">
                                                <td
                                                    width="142"
                                                    style="
        width: 106.35pt;
        border: solid windowtext 1pt;
        border-top: none;
        background: #fcfcfc;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 27pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">금융결제원</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="246"
                                                    style="
        width: 184.25pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 27pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">
                                                            자동이체계좌유효성인증<span lang="EN-US">(</span
                                                            >이용고객<span lang="EN-US">)</span>
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="161"
                                                    style="
        width: 120.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 27pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">
                                                            계좌번호<span lang="EN-US">, </span>고객명<span lang="EN-US"
                                                                >,</span
                                                            >생년월일
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="57"
                                                    valign="top"
                                                    style="
        width: 42.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 27pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">업무수행기간</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 16.5pt">
                                                <td
                                                    width="142"
                                                    style="
        width: 106.35pt;
        border: solid windowtext 1pt;
        border-top: none;
        background: #fcfcfc;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 16.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span lang="EN-US" style="color: black">NICE</span
                                                        ><span style="color: black">평가정보㈜</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="246"
                                                    style="
        width: 184.25pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 16.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: #212121; letter-spacing: -0.1pt">
                                                            성명<span lang="EN-US">,</span>성별<span lang="EN-US"
                                                                >, </span
                                                            >생년월일<span lang="EN-US">, </span>이동전화번호<span
                                                                lang="EN-US"
                                                                >,</span
                                                            >본인확인 식별번호<span lang="EN-US">(</span
                                                            >주민등록번호<span lang="EN-US">,</span>외국인등록번호<span
                                                                lang="EN-US"
                                                                >),</span
                                                            >중복가입확인정보<span lang="EN-US">(DI)</span>
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="161"
                                                    style="
        width: 120.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 16.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black"
                                                            >신용정보조회<span lang="EN-US">,</span>실명인증</span
                                                        >
                                                    </p>
                                                </td>
                                                <td
                                                    width="57"
                                                    valign="top"
                                                    style="
        width: 42.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 16.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">서비스제공기간</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 16.5pt">
                                                <td
                                                    width="142"
                                                    rowspan="3"
                                                    style="
        width: 106.35pt;
        border: solid windowtext 1pt;
        border-top: none;
        background: #fcfcfc;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 16.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black"
                                                            >한국정보통신진흥협회<span lang="EN-US">(KAIT)</span></span
                                                        >
                                                    </p>
                                                </td>
                                                <td
                                                    width="246"
                                                    style="
        width: 184.25pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 16.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">
                                                            해지환급<span lang="EN-US">(</span>해당고객<span
                                                                lang="EN-US"
                                                                >)</span
                                                            >
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="161"
                                                    style="
        width: 120.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 16.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">
                                                            고객명<span lang="EN-US">, </span>전화번호<span lang="EN-US"
                                                                >,</span
                                                            >생년월일
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="57"
                                                    valign="top"
                                                    style="
        width: 42.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 16.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">서비스제공기간</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 37.15pt">
                                                <td
                                                    width="246"
                                                    style="
        width: 184.25pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 37.15pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt"
                                                            >주민등록전산정보를 통한 실 사용자 확인</span
                                                        >
                                                    </p>
                                                </td>
                                                <td
                                                    width="161"
                                                    style="
        width: 120.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 37.15pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">
                                                            성명<span lang="EN-US"> , </span>주민등록번호
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="57"
                                                    valign="top"
                                                    style="
        width: 42.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 37.15pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">서비스제공기간</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 37.15pt">
                                                <td
                                                    width="246"
                                                    style="
        width: 184.25pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 37.15pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt"
                                                            >외국인 출국여부 확인</span
                                                        >
                                                    </p>
                                                </td>
                                                <td
                                                    width="161"
                                                    style="
        width: 120.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 37.15pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">
                                                            성명<span lang="EN-US"> , </span>국적<span lang="EN-US"
                                                                >, </span
                                                            >여권번호<span lang="EN-US">, </span>등록번호<span
                                                                lang="EN-US"
                                                                >,</span
                                                            >생년월일<span lang="EN-US">, </span>회선번호
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="57"
                                                    valign="top"
                                                    style="
        width: 42.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 37.15pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">서비스제공기간</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 37.15pt">
                                                <td
                                                    width="142"
                                                    style="
        width: 106.35pt;
        border: solid windowtext 1pt;
        border-top: none;
        background: #fcfcfc;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 37.15pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">
                                                            행정안전부<span lang="EN-US">, </span
                                                            >과학기술정보통신부<span lang="EN-US">,</span
                                                            >한국정보통신진흥협회<span lang="EN-US">(KAIT)</span>
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="246"
                                                    style="
        width: 184.25pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 37.15pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt"
                                                            >주민등록전산 정보 확인</span
                                                        >
                                                    </p>
                                                </td>
                                                <td
                                                    width="161"
                                                    style="
        width: 120.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 37.15pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">
                                                            성명<span lang="EN-US"> , </span>주민등록번호
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="57"
                                                    valign="top"
                                                    style="
        width: 42.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 37.15pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">서비스해지시까지</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 37.15pt">
                                                <td
                                                    width="142"
                                                    style="
        width: 106.35pt;
        border: solid windowtext 1pt;
        border-top: none;
        background: #fcfcfc;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 37.15pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">
                                                            법무부<span lang="EN-US">,</span>한국정보통신진흥협회<span
                                                                lang="EN-US"
                                                                >(KAIT)</span
                                                            >
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="246"
                                                    style="
        width: 184.25pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 37.15pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt"
                                                            >외국인 출국 사실 정보 확인</span
                                                        >
                                                    </p>
                                                </td>
                                                <td
                                                    width="161"
                                                    style="
        width: 120.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 37.15pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">
                                                            성명<span lang="EN-US"> , </span>국적<span lang="EN-US"
                                                                >, </span
                                                            >여권번호<span lang="EN-US">, </span>등록번호<span
                                                                lang="EN-US"
                                                                >,</span
                                                            >생년월일<span lang="EN-US">, </span>회선번호
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="57"
                                                    valign="top"
                                                    style="
        width: 42.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 37.15pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">체류기간까지</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 37.15pt">
                                                <td
                                                    width="142"
                                                    style="
        width: 106.35pt;
        border: solid windowtext 1pt;
        border-top: none;
        background: #fcfcfc;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 37.15pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">카카오</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="246"
                                                    style="
        width: 184.25pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 37.15pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">
                                                            실시간 고객 채팅 상담을 위한 시스템 운영 관리
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="161"
                                                    style="
        width: 120.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 37.15pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">
                                                            고객과 상담원 간의 채팅을 통한 상담 내용 일체
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="57"
                                                    valign="top"
                                                    style="
        width: 42.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 37.15pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">업무수행기간</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 94.5pt">
                                                <td
                                                    width="142"
                                                    style="
        width: 106.35pt;
        border: solid windowtext 1pt;
        border-top: none;
        background: #fcfcfc;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 94.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">한국정보통신진흥협회</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="246"
                                                    style="
        width: 184.25pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 94.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">
                                                            방송통신 신용정보 공동관리<span lang="EN-US">(</span
                                                            >식별<span lang="EN-US">, </span>연체<span lang="EN-US"
                                                                >, </span
                                                            >복지<span lang="EN-US">,</span>이용정지<span lang="EN-US"
                                                                >), </span
                                                            >외국인정보 인증<span lang="EN-US"> , </span>국제전화사기
                                                            공동대응<span lang="EN-US">, </span>명의도용 예방 및
                                                            분쟁조정<span lang="EN-US">,</span>명의도용방지<span
                                                                lang="EN-US"
                                                                >SMS</span
                                                            >안내<span lang="EN-US">, </span>분실단말기 공동관리<span
                                                                lang="EN-US"
                                                                >,</span
                                                            >휴대폰 대출방지
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="161"
                                                    style="
        width: 120.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 94.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">
                                                            고객명<span lang="EN-US">, </span>주민등록번호<span
                                                                lang="EN-US"
                                                                >,</span
                                                            >외국인등록번호 또는 여권번호<span lang="EN-US">, </span
                                                            >생년월일<span lang="EN-US">,</span>국적<span lang="EN-US"
                                                                >, </span
                                                            >신분증 기재사항<span lang="EN-US">, </span>당사 가입자
                                                            여부<span lang="EN-US">, </span>이용내역<span lang="EN-US"
                                                                >, </span
                                                            >연체금액
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="57"
                                                    valign="top"
                                                    style="
        width: 42.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 94.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">서비스제공기간</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 40.5pt">
                                                <td
                                                    width="142"
                                                    style="
        width: 106.35pt;
        border: solid windowtext 1pt;
        border-top: none;
        background: #fcfcfc;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 40.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black"
                                                            >한국통신사업자 연합회<span lang="EN-US">(KTOA)</span></span
                                                        >
                                                    </p>
                                                </td>
                                                <td
                                                    width="246"
                                                    style="
        width: 184.25pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 40.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">
                                                            번호이동업무<span lang="EN-US">, </span>인터넷<span
                                                                lang="EN-US"
                                                                >/</span
                                                            >음성<span lang="EN-US">/</span>책자 등을 통해 번호를
                                                            안내하는 서비스 제공
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="161"
                                                    style="
        width: 120.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 40.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">
                                                            고객고유식별번호<span lang="EN-US">, </span>고객명<span
                                                                lang="EN-US"
                                                                >,</span
                                                            >서비스번호<span lang="EN-US">, </span>이동전화번호<span
                                                                lang="EN-US"
                                                                >, </span
                                                            >우편번호<span lang="EN-US">,</span>주소
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="57"
                                                    valign="top"
                                                    style="
        width: 42.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 40.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">서비스제공기간</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 27pt">
                                                <td
                                                    width="142"
                                                    style="
        width: 106.35pt;
        border: solid windowtext 1pt;
        border-top: none;
        background: #fcfcfc;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 27pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">
                                                            한국정보통신진흥협회<span lang="EN-US">, </span>타
                                                            통신사<span lang="EN-US">,</span>과학기술정보통신부
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="246"
                                                    style="
        width: 184.25pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 27pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">
                                                            통신요금연체통지서비스<span lang="EN-US">(</span
                                                            >대상고객<span lang="EN-US">)</span>
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="161"
                                                    style="
        width: 120.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 27pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">
                                                            성명<span lang="EN-US">, </span>연체정보등록예정일<span
                                                                lang="EN-US"
                                                                >,</span
                                                            >제공기관<span lang="EN-US">,</span
                                                            >통신권중복가입방지정보<span lang="EN-US">(</span>공통<span
                                                                lang="EN-US"
                                                                >DI)</span
                                                            >
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="57"
                                                    valign="top"
                                                    style="
        width: 42.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 27pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">서비스제공기간</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 27pt">
                                                <td
                                                    width="142"
                                                    style="
        width: 106.35pt;
        border: solid windowtext 1pt;
        border-top: none;
        background: #fcfcfc;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 27pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">
                                                            한국정보통신진흥협회<span lang="EN-US">, </span>타 통신사
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="246"
                                                    style="
        width: 184.25pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 27pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">
                                                            명의도용방지서비스<span lang="EN-US">, </span>통신가입사실
                                                            우편안내서비스
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="161"
                                                    style="
        width: 120.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 27pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">
                                                            성명<span lang="EN-US">, </span>고유식별정보<span
                                                                lang="EN-US"
                                                                >,</span
                                                            >신분증기재사항<span lang="EN-US">, </span>주민등록상 주소지
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="57"
                                                    valign="top"
                                                    style="
        width: 42.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 27pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">업무수행기간</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 34.95pt;">
                                                <td
                                                    width="29%"
                                                    style="width: 29.44%; border: 1pt solid windowtext; background: rgb(252, 252, 252); padding: 0cm 4.95pt; height: 34.95pt;"
                                                    rowspan="2"
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        style="margin-bottom: 0.0001pt; vertical-align: baseline; word-break: keep-all;"
                                                    >
                                                        <span style="color: rgb(64, 64, 64); letter-spacing: -0.2pt;"
                                                            >㈜엘지유플러스</span
                                                        >
                                                    </p>
                                                </td>
                                                <td
                                                    width="24%"
                                                    style="width: 24.92%; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 4.95pt; height: 34.95pt;"
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        style="margin-bottom: 0.0001pt; vertical-align: baseline; word-break: keep-all;"
                                                    >
                                                        <span lang="EN-US" style="color: rgb(64, 64, 64);">•</span
                                                        ><span style="color: rgb(64, 64, 64); letter-spacing: -0.2pt;">
                                                            개통<span lang="EN-US">,&nbsp;</span>통신과금서비스<span
                                                                lang="EN-US"
                                                                >,</span
                                                            >㈜엘지 유플러스에서 제공하는 부가서비 스<span lang="EN-US"
                                                                >,&nbsp;</span
                                                            >영업전산시스템<span lang="EN-US">,</span>이용자 및 서
                                                            비스이용에 대한 통계분석 등 고 객의 이동통신 이용관련
                                                            업무처리<span lang="EN-US">,</span>본인인증<span
                                                                lang="EN-US"
                                                                >(</span
                                                            >대상고객<span lang="EN-US">)</span></span
                                                        >
                                                    </p>
                                                </td>
                                                <td
                                                    width="37%"
                                                    style="width: 37.84%; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 4.95pt; height: 34.95pt;"
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        style="margin-bottom: 0.0001pt; vertical-align: baseline; word-break: keep-all;"
                                                    >
                                                        <span style="color: rgb(64, 64, 64); letter-spacing: -0.2pt;">
                                                            성명<span lang="EN-US">,&nbsp;</span>고유식별정보<span
                                                                lang="EN-US"
                                                                >,</span
                                                            >국내거소신 고번호<span lang="EN-US">,&nbsp;</span
                                                            >생년월일<span lang="EN-US">&nbsp;,&nbsp;</span>국적<span
                                                                lang="EN-US"
                                                                >,&nbsp;</span
                                                            >발급일 자 등 신분증 기재사항<span lang="EN-US"
                                                                >,&nbsp;</span
                                                            >주소<span lang="EN-US">,</span>가입여부<span lang="EN-US"
                                                                >,&nbsp;</span
                                                            >이용내역<span lang="EN-US">,&nbsp;</span>신용정보 등
                                                            이동통신 이용관련 업무처리 에 필요한 고객의 개인정보
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="7%"
                                                    style="width: 7.8%; border-top: none; border-left: none; border-right: 1pt solid windowtext; padding: 0cm 4.95pt; height: 34.95pt;"
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        style="margin-bottom: 0.0001pt; vertical-align: baseline; word-break: keep-all;"
                                                    >
                                                        <span style="color: rgb(64, 64, 64); letter-spacing: -0.2pt;"
                                                            >서비스제공기간</span
                                                        >
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 34.95pt;">
                                                <td
                                                    width="24%"
                                                    style="width: 24.92%; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 4.95pt; height: 34.95pt;"
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        style="margin-bottom: 0.0001pt; vertical-align: baseline; word-break: keep-all;"
                                                    >
                                                        <span style="color: rgb(64, 64, 64); letter-spacing: -0.2pt;"
                                                            >타인명의제한 업무 수행</span
                                                        >
                                                    </p>
                                                </td>
                                                <td
                                                    width="37%"
                                                    style="width: 37.84%; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 4.95pt; height: 34.95pt;"
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        style="margin-bottom: 0.0001pt; vertical-align: baseline; word-break: keep-all;"
                                                    >
                                                        성명, 생년월일, 전화번호, 개통당시 사용한 주민등록증 운전면허증
                                                        여권 등록증 등의 신분증번호
                                                    </p>
                                                </td>
                                                <td
                                                    width="7%"
                                                    style="width: 7.8%; border-top: none; border-left: none; border-right: 1pt solid windowtext; padding: 0cm 4.95pt; height: 34.95pt;"
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        style="margin-bottom: 0.0001pt; vertical-align: baseline; word-break: keep-all;"
                                                    >
                                                        <span style="color: rgb(64, 64, 64); letter-spacing: -0.2pt;"
                                                            >서비스제공기간</span
                                                        >
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 39.35pt">
                                                <td
                                                    width="142"
                                                    style="
        width: 106.35pt;
        border: solid windowtext 1pt;
        border-top: none;
        background: #fcfcfc;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 39.35pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">코리아크레딧뷰로</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="246"
                                                    style="
        width: 184.25pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 39.35pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">카드 본인확인 서비스</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="161"
                                                    style="
        width: 120.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 39.35pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">
                                                            카드번호<span lang="EN-US">, </span>고객명<span lang="EN-US"
                                                                >,</span
                                                            >전화번호<span lang="EN-US">, </span>생년월일
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="57"
                                                    valign="top"
                                                    style="
        width: 42.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 39.35pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">서비스제공기간</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 67.5pt">
                                                <td
                                                    width="142"
                                                    style="
        width: 106.35pt;
        border: solid windowtext 1pt;
        border-top: none;
        background: #fcfcfc;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 67.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">
                                                            신용정보집중기관<span lang="EN-US">(</span>한국정보통신
                                                            진흥협회<span lang="EN-US">), </span>신용조회회사<span
                                                                lang="EN-US"
                                                                >(NICE</span
                                                            >평 가정보㈜등<span lang="EN-US">),</span>금융결제원<span
                                                                lang="EN-US"
                                                                >, </span
                                                            >신용카드사<span lang="EN-US">, </span>보증보험회사
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="246"
                                                    style="
        width: 184.25pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 67.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">
                                                            이동전화 서비스가입<span lang="EN-US">, </span>이용<span
                                                                lang="EN-US"
                                                                >,</span
                                                            >유지 여부 및 단말기 할부 구매<span lang="EN-US">, </span
                                                            >신용보 험의 가입 및 유치 목적<span lang="EN-US">, </span
                                                            >당사 제휴 카드 발급가능 여부 등의 결정
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="161"
                                                    style="
        width: 120.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 67.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">
                                                            식별정보<span lang="EN-US">, </span>채무불이행정보<span
                                                                lang="EN-US"
                                                                >,</span
                                                            >신용 거래정보<span lang="EN-US">, </span>연체정보<span
                                                                lang="EN-US"
                                                                >, </span
                                                            >신용등급<span lang="EN-US">,</span>타 기관의 신용정보
                                                            조회기록
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="57"
                                                    valign="top"
                                                    style="
        width: 42.5pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 4.95pt 0cm 4.95pt;
        height: 67.5pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-bottom: 0cm;
          margin-bottom: 0.0001pt;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: black">서비스제공기간</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="width: 106.35pt; border-top: none; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; background: rgb(252, 252, 252); padding: 0cm 4.95pt; height: 67.5pt;"
                                                >
                                                    한국정보통신진흥협회
                                                </td>
                                                <td
                                                    style="width: 184.25pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 4.95pt; height: 67.5pt;"
                                                >
                                                    전기통신역무를 이용한 번호변작, 불법스팸발송, 보이스피싱,
                                                    불법대부광고 등으로 인한 서비스이용정지자 서비스 가입제한
                                                </td>
                                                <td
                                                    style="width: 120.5pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 4.95pt; height: 67.5pt;"
                                                >
                                                    고유식별번호, 이름, 이용정지 사유
                                                </td>
                                                <td
                                                    style="width: 42.5pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 4.95pt; height: 67.5pt;"
                                                >
                                                    서비스 제공기간
                                                </td>
                                            </tr>
                                        </table>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    margin-bottom: 0cm;
    margin-bottom: 0.0001pt;
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US">&nbsp;</span>
                                        </p>
                                    </span>
                                </div>

                                <li>
                                    <span class="termsCheck">
                                        <input
                                            type="checkbox"
                                            v-model="tructYn"
                                            value="7"
                                            id="clauseCheck7"
                                            name="addchk"
                                        />
                                        <label for="clauseCheck7" class="termsCheckLabel"></label>
                                    </span>
                                    <span class="termsTit fW-L notranslate" @click="agreeTruct = !agreeTruct">
                                        {{ $t('trans.t21_28') }}
                                    </span>
                                </li>
                                <div class="termsSub" v-show="agreeTruct">
                                    <span class="termsTxt">
                                        <!-- 개인정보 제3자 위탁 동의 -->
                                        <table
                                            class="MsoTableGrid"
                                            border="1"
                                            cellspacing="0"
                                            cellpadding="0"
                                            width="604"
                                            style="width: 453.35pt; border-collapse: collapse; border: none"
                                        >
                                            <tr>
                                                <td
                                                    width="125"
                                                    valign="top"
                                                    style="
        width: 94.05pt;
        border: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">수탁자</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="152"
                                                    valign="top"
                                                    style="
        width: 113.85pt;
        border: solid windowtext 1pt;
        border-left: none;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">위탁업무</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="124"
                                                    valign="top"
                                                    style="
        width: 93pt;
        border: solid windowtext 1pt;
        border-left: none;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">제공정보</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="203"
                                                    valign="top"
                                                    style="
        width: 152.45pt;
        border: solid windowtext 1pt;
        border-left: none;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">보유 및 이용기간</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    width="125"
                                                    valign="top"
                                                    style="
        width: 94.05pt;
        border: solid windowtext 1pt;
        border-top: none;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        ㈜엘지유플러스
                                                    </p>
                                                </td>
                                                <td
                                                    width="152"
                                                    valign="top"
                                                    style="
        width: 113.85pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">
                                                            본인확인서비스<span lang="EN-US">,</span> </span
                                                        >정산<span lang="EN-US">, </span>청구<span lang="EN-US">, </span
                                                        >수납<span lang="EN-US">,</span>로밍<span lang="EN-US">, </span
                                                        >통화 품질 민원상담서비스 등 고객의 이동통신 이용관련 업무처리
                                                    </p>
                                                </td>
                                                <td
                                                    width="124"
                                                    valign="top"
                                                    style="
        width: 93pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        성명<span lang="EN-US">, </span>고유식별정보<span lang="EN-US"
                                                            >, </span
                                                        >국내거소신고번호<span lang="EN-US">,</span>성별<span
                                                            lang="EN-US"
                                                            >, </span
                                                        >생년월일<span lang="EN-US">, </span>내외국인여부<span
                                                            lang="EN-US"
                                                            >,</span
                                                        >전화번호<span lang="EN-US">, </span>주소<span lang="EN-US"
                                                            >, </span
                                                        >휴대 폰<span lang="EN-US">, USIM, </span>부가서비스<span
                                                            lang="EN-US"
                                                            >, </span
                                                        >요금정산 정보 등 이동통신 이용관련 업무처리에 필요한 정보
                                                    </p>
                                                </td>
                                                <td
                                                    width="203"
                                                    valign="top"
                                                    style="
        width: 152.45pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: #212121; letter-spacing: -0.1pt">
                                                            위탁계약 종료 시까지
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    width="125"
                                                    valign="top"
                                                    style="
        width: 94.05pt;
        border: solid windowtext 1pt;
        border-top: none;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span lang="EN-US" style="letter-spacing: -0.75pt">NICE</span
                                                        ><span style="letter-spacing: -0.75pt">평가정보㈜</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="152"
                                                    valign="top"
                                                    style="
        width: 113.85pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">
                                                            본인인증<span lang="EN-US">,</span>실명인증
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="124"
                                                    valign="top"
                                                    style="
        width: 93pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">
                                                            성명<span lang="EN-US"> , </span>주민등록번호
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="203"
                                                    valign="top"
                                                    style="
        width: 152.45pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: #212121; letter-spacing: -0.1pt">
                                                            위탁계약 종료 시까지
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    width="125"
                                                    valign="top"
                                                    style="
        width: 94.05pt;
        border: solid windowtext 1pt;
        border-top: none;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">㈜텔레센트로</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="152"
                                                    valign="top"
                                                    style="
        width: 113.85pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt"
                                                            >전산시스템 개발 및 운영</span
                                                        >
                                                    </p>
                                                </td>
                                                <td
                                                    width="124"
                                                    valign="top"
                                                    style="
        width: 93pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        성명<span lang="EN-US">, </span>고유식별정보<span lang="EN-US"
                                                            >, </span
                                                        >성별<span lang="EN-US">,</span>생년월일<span lang="EN-US"
                                                            >, </span
                                                        >내외국인여부<span lang="EN-US">, </span>전화번호<span
                                                            lang="EN-US"
                                                            >,</span
                                                        >주소<span lang="EN-US">, USIM, </span>부가서비스<span
                                                            lang="EN-US"
                                                        >
                                                            , </span
                                                        >요금정산 정보 등 이동통신 이용관련 업무처리에 필요한 정보
                                                    </p>
                                                </td>
                                                <td
                                                    width="203"
                                                    valign="top"
                                                    style="
        width: 152.45pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: #212121; letter-spacing: -0.1pt">
                                                            위탁계약 종료 시까지
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    width="125"
                                                    valign="top"
                                                    style="
        width: 94.05pt;
        border: solid windowtext 1pt;
        border-top: none;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">우체국</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="152"
                                                    rowspan="2"
                                                    valign="top"
                                                    style="
        width: 113.85pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">상품배송</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="124"
                                                    rowspan="2"
                                                    valign="top"
                                                    style="
        width: 93pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">
                                                            성명<span lang="EN-US"> , </span>전화번호<span lang="EN-US"
                                                                >, </span
                                                            >주소<span lang="EN-US">, </span>생년월일
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="203"
                                                    valign="top"
                                                    style="
        width: 152.45pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: #212121; letter-spacing: -0.1pt">
                                                            위탁계약 종료 시까지
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    width="125"
                                                    valign="top"
                                                    style="
        width: 94.05pt;
        border: solid windowtext 1pt;
        border-top: none;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">㈜제니엘시스템</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="203"
                                                    valign="top"
                                                    style="
        width: 152.45pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: #212121; letter-spacing: -0.1pt">
                                                            위탁계약 종료 시까지
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    width="125"
                                                    valign="top"
                                                    style="
        width: 94.05pt;
        border: solid windowtext 1pt;
        border-top: none;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">코리아크레딧뷰로㈜</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="152"
                                                    valign="top"
                                                    style="
        width: 113.85pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt"
                                                            >카드 본인확인 서비스</span
                                                        >
                                                    </p>
                                                </td>
                                                <td
                                                    width="124"
                                                    valign="top"
                                                    style="
        width: 93pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        성명<span lang="EN-US">, </span>생년월일<span lang="EN-US"
                                                            >, </span
                                                        >성별<span lang="EN-US">,</span>휴대폰번호<span lang="EN-US"
                                                            >, </span
                                                        >카드번호 뒤<span lang="EN-US"> 8</span>자리<span lang="EN-US"
                                                            >,</span
                                                        >홈페이지<span lang="EN-US"> ID, </span>내외국인 여부<span
                                                            lang="EN-US"
                                                        >
                                                            , CI/DI
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="203"
                                                    valign="top"
                                                    style="
        width: 152.45pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: #212121; letter-spacing: -0.1pt">
                                                            위탁계약 종료 시까지
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    width="125"
                                                    valign="top"
                                                    style="
        width: 94.05pt;
        border: solid windowtext 1pt;
        border-top: none;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt"
                                                            >효성<span lang="EN-US">FMS</span></span
                                                        >
                                                    </p>
                                                </td>
                                                <td
                                                    width="152"
                                                    valign="top"
                                                    style="
        width: 113.85pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">
                                                            선불 요금 청구<span lang="EN-US">/</span>수납<span
                                                                lang="EN-US"
                                                                >(</span
                                                            >자동이체 및 즉시출금<span lang="EN-US">)</span>및 증빙자료
                                                            등록<span lang="EN-US">(ARS </span>및 스캔문서<span
                                                                lang="EN-US"
                                                                >)</span
                                                            >
                                                        </span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="124"
                                                    valign="top"
                                                    style="
        width: 93pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="background: white">이동전화번호</span
                                                        ><span
                                                            lang="EN-US"
                                                            style="font-family: 'Arial', sans-serif; background: white"
                                                            >,</span
                                                        ><span style="background: white">성명</span
                                                        ><span
                                                            lang="EN-US"
                                                            style="font-family: 'Arial', sans-serif; background: white"
                                                            >,</span
                                                        ><span style="background: white">생년월일</span
                                                        ><span
                                                            lang="EN-US"
                                                            style="font-family: 'Arial', sans-serif; background: white"
                                                            >,</span
                                                        ><span style="background: white">금액</span
                                                        ><span
                                                            lang="EN-US"
                                                            style="font-family: 'Arial', sans-serif; background: white"
                                                            >,</span
                                                        ><span style="background: white">수납에</span
                                                        ><span
                                                            style="font-family: 'Arial', sans-serif; background: white"
                                                        ></span
                                                        ><span style="background: white">필요한</span
                                                        ><span
                                                            style="font-family: 'Arial', sans-serif; background: white"
                                                        ></span
                                                        ><span style="background: white">계정정보</span
                                                        ><span
                                                            style="font-family: 'Arial', sans-serif; background: white"
                                                        ></span
                                                        ><span style="background: white">또는</span
                                                        ><span
                                                            style="font-family: 'Arial', sans-serif; background: white"
                                                        ></span
                                                        ><span style="background: white">신용</span
                                                        ><span
                                                            lang="EN-US"
                                                            style="font-family: 'Arial', sans-serif; background: white"
                                                            >(</span
                                                        ><span style="background: white">체크</span
                                                        ><span
                                                            lang="EN-US"
                                                            style="font-family: 'Arial', sans-serif; background: white"
                                                            >)</span
                                                        ><span style="background: white">카드</span
                                                        ><span
                                                            style="font-family: 'Arial', sans-serif; background: white"
                                                        ></span
                                                        ><span style="background: white">정보</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="203"
                                                    valign="top"
                                                    style="
        width: 152.45pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: #212121; letter-spacing: -0.1pt">
                                                            위탁계약 종료 시까지
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    width="125"
                                                    valign="top"
                                                    style="
        width: 94.05pt;
        border: solid windowtext 1pt;
        border-top: none;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span lang="EN-US" style="letter-spacing: -0.75pt">LG CNS</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="152"
                                                    valign="top"
                                                    style="
        width: 113.85pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">알림톡 및 문자발송</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="124"
                                                    valign="top"
                                                    style="
        width: 93pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        휴대폰번호
                                                    </p>
                                                </td>
                                                <td
                                                    width="203"
                                                    valign="top"
                                                    style="
        width: 152.45pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: #212121; letter-spacing: -0.1pt">
                                                            위탁계약 종료 시까지
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    width="125"
                                                    valign="top"
                                                    style="
        width: 94.05pt;
        border: solid windowtext 1pt;
        border-top: none;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">인포뱅크</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="152"
                                                    valign="top"
                                                    style="
        width: 113.85pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span lang="EN-US" style="letter-spacing: -0.75pt">SNS </span
                                                        ><span style="letter-spacing: -0.75pt">문자발송</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="124"
                                                    valign="top"
                                                    style="
        width: 93pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        휴대폰번호
                                                    </p>
                                                </td>
                                                <td
                                                    width="203"
                                                    valign="top"
                                                    style="
        width: 152.45pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: #212121; letter-spacing: -0.1pt">
                                                            위탁계약 종료 시까지
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    width="125"
                                                    valign="top"
                                                    style="
        width: 94.05pt;
        border: solid windowtext 1pt;
        border-top: none;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">대성글로벌</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="152"
                                                    valign="top"
                                                    style="
        width: 113.85pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        고객상담<span lang="EN-US">(</span>고객민원처리 업무 및
                                                        응대<span lang="EN-US">),</span>고객관계관리<span lang="EN-US"
                                                            >, </span
                                                        >기타 안내업무
                                                    </p>
                                                </td>
                                                <td
                                                    width="124"
                                                    valign="top"
                                                    style="
        width: 93pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        성명<span lang="EN-US">, </span>전화번호<span lang="EN-US"
                                                            >, </span
                                                        >주소<span lang="EN-US">,</span>휴대폰<span lang="EN-US"
                                                            >, USIM, </span
                                                        >부가서비스<span lang="EN-US"> , </span>정산<span lang="EN-US"
                                                            >, </span
                                                        >청구<span lang="EN-US">, </span>수납 등 고객상담에 필요한 정보
                                                    </p>
                                                </td>
                                                <td
                                                    width="203"
                                                    valign="top"
                                                    style="
        width: 152.45pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 0cm;
          margin-bottom: 0.0001pt;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="color: #212121; letter-spacing: -0.1pt">
                                                            위탁계약 종료 시까지
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                        </table>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    margin-top: 5.25pt;
    margin-right: 0cm;
    margin-bottom: 0cm;
    margin-left: 0cm;
    margin-bottom: 0.0001pt;
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US" style="letter-spacing: -0.75pt">&nbsp;</span>
                                        </p>
                                    </span>
                                </div>

                                <li>
                                    <span class="termsCheck">
                                        <input
                                            type="checkbox"
                                            v-model="creditYn"
                                            value="8"
                                            id="clauseCheck8"
                                            name="addchk"
                                        />
                                        <label for="clauseCheck8" class="termsCheckLabel"></label>
                                    </span>
                                    <span class="termsTit fW-L notranslate" @click="agreeCredit = !agreeCredit">
                                        {{ $t('trans.t21_29') }}
                                    </span>
                                </li>
                                <div class="termsSub" v-show="agreeCredit">
                                    <span class="termsTxt">
                                        <!-- 개인신용정보 조회 동의 -->
                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    margin-top: 5.25pt;
    margin-right: 0cm;
    margin-bottom: 7.5pt;
    margin-left: 0cm;
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="letter-spacing: -0.75pt">
                                                회사는 서비스의 제공에 관한 계약을 이행하기 위하여<span lang="EN-US">
                                                    [</span
                                                >신용정보의 이용 및 보호에 관한 법률<span lang="EN-US">] </span>제
                                                <span lang="EN-US">32</span>조 제<span lang="EN-US">2</span>항에 따라
                                                신용정보회사 또는 신용정보집중기관으로부터 서비스 가입 신청 시 다음과
                                                같이 본인의 신용정보 조회 동의를 득하며<span lang="EN-US">, </span
                                                >고객은 본인의 신용정보를 조회하는것에 동의합니다<span lang="EN-US"
                                                    >.</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    margin-top: 5.25pt;
    margin-right: 0cm;
    margin-bottom: 7.5pt;
    margin-left: 0cm;
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="letter-spacing: -0.75pt"
                                                >① 조회대상 기관<span lang="EN-US">
                                                    :
                                                </span></span
                                            ><span lang="EN-US" style="color: black">NICE</span
                                            ><span style="color: black">
                                                평가정보㈜<span lang="EN-US">,</span>한국정보통신진흥협회<span
                                                    lang="EN-US"
                                                    >(KAIT)</span
                                                >
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    margin-top: 5.25pt;
    margin-right: 0cm;
    margin-bottom: 7.5pt;
    margin-left: 0cm;
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ② 조회항목
                                                <span lang="EN-US">: </span>신용정보<span lang="EN-US">, </span
                                                >연체정보<span lang="EN-US">,</span>신용거래정보<span lang="EN-US"
                                                    >, </span
                                                >신용등급<span lang="EN-US">, </span>공공정보
                                            </span>
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    margin-top: 5.25pt;
    margin-right: 0cm;
    margin-bottom: 7.5pt;
    margin-left: 0cm;
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black"
                                                >③ 조회목적<span lang="EN-US">: </span>상품 서비스 가입 및 계약
                                                체결</span
                                            >
                                        </p>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    margin-top: 5.25pt;
    margin-right: 0cm;
    margin-bottom: 7.5pt;
    margin-left: 0cm;
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span style="color: black">
                                                ④ 조회 동의 효력 기간
                                                <span lang="EN-US">: </span>조회 동의 제출 시점부터 서비스 계약 효력이
                                                종료<span lang="EN-US">(</span>정산완료 및 계약해지<span lang="EN-US"
                                                    >)</span
                                                >되는 시점까지
                                            </span>
                                        </p>
                                    </span>
                                </div>

                                <li>
                                    <span class="termsCheck">
                                        <input
                                            type="checkbox"
                                            v-model="agreePrivacyAdvert"
                                            value="4"
                                            id="clauseCheck4"
                                            name="addchk"
                                        />
                                        <label for="clauseCheck4" class="termsCheckLabel"></label>
                                    </span>
                                    <span class="termsTit fW-L notranslate" @click="advertShow = !advertShow">
                                        {{ $t('trans.t04_16') }}
                                    </span>
                                </li>
                                <div class="termsSub" v-show="advertShow">
                                    <span class="termsTxt">
                                        <!-- 광고수신동의 -->
                                        <table
                                            class="MsoTableGrid"
                                            border="1"
                                            cellspacing="0"
                                            cellpadding="0"
                                            width="671"
                                            style="width: 503pt; border-collapse: collapse; border: none"
                                        >
                                            <tr style="height: 9.8pt">
                                                <td
                                                    width="340"
                                                    valign="top"
                                                    style="
        width: 254.9pt;
        border: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
        height: 9.8pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 15pt;
          margin-right: 0cm;
          margin-bottom: 15pt;
          margin-left: 0cm;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">수집 · 이용 목적</span>
                                                    </p>
                                                </td>
                                                <td
                                                    width="331"
                                                    valign="top"
                                                    style="
        width: 248.1pt;
        border: solid windowtext 1pt;
        border-left: none;
        padding: 0cm 5.4pt 0cm 5.4pt;
        height: 9.8pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 15pt;
          margin-right: 0cm;
          margin-bottom: 15pt;
          margin-left: 0cm;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        <span style="letter-spacing: -0.75pt">수집항목</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 4.05pt">
                                                <td
                                                    width="340"
                                                    valign="top"
                                                    style="
        width: 254.9pt;
        border: solid windowtext 1pt;
        border-top: none;
        padding: 0cm 5.4pt 0cm 5.4pt;
        height: 4.05pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 15pt;
          margin-right: 0cm;
          margin-bottom: 15pt;
          margin-left: 0cm;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        신규서비스 제공 및 광고를 의뢰하는 업체의 광고성 정보를
                                                        전화<span lang="EN-US">, </span>전자우편<span lang="EN-US">
                                                            ,SMS,MMS </span
                                                        >등을 이용
                                                    </p>
                                                </td>
                                                <td
                                                    width="331"
                                                    valign="top"
                                                    style="
        width: 248.1pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
        height: 4.05pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="left"
                                                        style="
          margin-top: 5.25pt;
          margin-right: 0cm;
          margin-bottom: 7.5pt;
          margin-left: 0cm;
          text-align: left;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        이동전화번호<span lang="EN-US">,</span>주소<span lang="EN-US"
                                                            >, </span
                                                        >전화번호<span lang="EN-US">, </span>연락가능번호<span
                                                            lang="EN-US"
                                                            >, e-mail</span
                                                        >주소<span lang="EN-US">, </span>기타 개인정보<span lang="EN-US"
                                                            >(</span
                                                        >고객이 상담내용에 입력하는 개인정보
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr style="height: 1.85pt">
                                                <td
                                                    width="340"
                                                    valign="top"
                                                    style="
        width: 254.9pt;
        border: solid windowtext 1pt;
        border-top: none;
        padding: 0cm 5.4pt 0cm 5.4pt;
        height: 1.85pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 15pt;
          margin-right: 0cm;
          margin-bottom: 15pt;
          margin-left: 0cm;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        위치정보 및 개인정보를 활용하여 해외로밍 안내<span lang="EN-US"
                                                            >, </span
                                                        >생활정보<span lang="EN-US">, </span>이벤트 및 할인쿠폰 등
                                                        다양한 혜택정보를<span lang="EN-US">
                                                            SMS, LMS, MMS, WAP push, </span
                                                        >이메일<span lang="EN-US">,</span>우편<span lang="EN-US"
                                                            >, </span
                                                        >어플안내<span lang="EN-US">, </span>팝업 등의 방식으로
                                                        전송하는데 이용
                                                    </p>
                                                </td>
                                                <td
                                                    width="331"
                                                    valign="top"
                                                    style="
        width: 248.1pt;
        border-top: none;
        border-left: none;
        border-bottom: solid windowtext 1pt;
        border-right: solid windowtext 1pt;
        padding: 0cm 5.4pt 0cm 5.4pt;
        height: 1.85pt;
      "
                                                >
                                                    <p
                                                        class="MsoNormal"
                                                        align="center"
                                                        style="
          margin-top: 15pt;
          margin-right: 0cm;
          margin-bottom: 15pt;
          margin-left: 0cm;
          text-align: center;
          line-height: normal;
          text-autospace: ideograph-numeric ideograph-other;
          word-break: keep-all;
        "
                                                    >
                                                        이동전화번호<span lang="EN-US">,</span>주소<span lang="EN-US"
                                                            >, </span
                                                        >전화번호<span lang="EN-US">, </span>연락가능번호<span
                                                            lang="EN-US"
                                                            >, e-mail</span
                                                        >주소<span lang="EN-US">, </span>기타 개인정보<span lang="EN-US"
                                                            >(</span
                                                        >고객이 상담내용에 입력하는 개인정보<span lang="EN-US">)</span>
                                                    </p>
                                                </td>
                                            </tr>
                                        </table>

                                        <p
                                            class="MsoNormal"
                                            align="left"
                                            style="
    margin-top: 5.25pt;
    margin-right: 0cm;
    margin-bottom: 0cm;
    margin-left: 0cm;
    margin-bottom: 0.0001pt;
    text-align: left;
    line-height: normal;
    text-autospace: ideograph-numeric ideograph-other;
    word-break: keep-all;
  "
                                        >
                                            <span lang="EN-US">&nbsp;</span>
                                        </p>
                                    </span>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 예상금액 플로팅 -->
        <foot-rate-banner :key="render"></foot-rate-banner>

        <!-- 요금제 선택 팝업 -->
        <div class="popWpper trafficPop" v-if="isRatePop">
            <div class="popContainer center">
                <button
                    class="close ic"
                    @click="
                        isRatePop = false;
                        isDim = false;
                    "
                >
                    닫기
                </button>
                <div class="popWP">
                    <div class="popContTit line">
                        <p class="tit fW-R">요금제 선택</p>
                        <!-- <p class="txt">와이파이도시락 회원 정보로 로그인 하시려면<br>와이드모바일 통합 회원에 동의 후 로그인 가능합니다.</p> -->
                    </div>
                    <div class="popBody">
                        <!-- 팝업 Tab -->
                        <div class="popTabAR">
                            <ul class="popTab">
                                <li
                                    :class="['popTab-link fW-R', { current: selectedTab === 1 }]"
                                    @click="selectedTab = 1"
                                    data-tab="priceTab01"
                                >
                                    BEST<span></span>
                                    <p></p>
                                </li>
                                <li
                                    :class="['popTab-link fW-R', { current: selectedTab === 2 }]"
                                    @click="selectedTab = 2"
                                    data-tab="priceTab02"
                                >
                                    대용량 요금제<span></span>
                                    <p></p>
                                </li>
                                <li
                                    :class="['popTab-link fW-R', { current: selectedTab === 3 }]"
                                    @click="selectedTab = 3"
                                    data-tab="priceTab03"
                                >
                                    실속형 요금제<span></span>
                                    <p></p>
                                </li>
                                <li
                                    :class="['popTab-link fW-R', { current: selectedTab === 0 }]"
                                    @click="selectedTab = 0"
                                    data-tab="priceTab04"
                                >
                                    전체<span></span>
                                    <p></p>
                                </li>
                            </ul>
                        </div>
                        <div class="popSelectAr">
                            <select v-model="rateFilter" @change="onRateFilter">
                                <option value="1" data-filter='{"key":"orderby", "value":"asc"}'>추천순</option>
                                <option value="2" data-filter='{"key":"data_value", "value":"desc"}'
                                    >데이터 많은 순</option
                                >
                                <option value="3" data-filter='{"key":"amount", "value":"desc"}'>높은 가격 순</option>
                                <option value="4" data-filter='{"key":"amount", "value":"asc"}'>낮은 가격 순</option>
                            </select>
                        </div>
                        <div class="popTab-contentWrap">
                            <!-- BEST 요금제 -->
                            <div v-if="selectedTab === 1" :class="['popTab-content', { current: selectedTab === 1 }]">
                                <div class="price_content">
                                    <ul>
                                        <li
                                            v-for="item in planList.filter((x) => x.rt_seqnum === 1)"
                                            :key="item.seqnum"
                                        >
                                            <input
                                                type="radio"
                                                :id="'bestprice' + item.seqnum"
                                                name="popPrice"
                                                :value="item.seqnum"
                                                :checked="selectedRate.seqnum === item.seqnum"
                                                @click="onRate(item)"
                                            />
                                            <label :for="'bestprice' + item.seqnum" class="checkAr">
                                                <div class="lt">
                                                    <em class="mgl-50">{{ item.name }}</em>
                                                    <em class="mgl-50 fW-L"
                                                        >데이터 {{ item.data_text }} / 음성 {{ item.voice_text }} / 문자
                                                        {{ item.sms_text }}</em
                                                    >
                                                </div>
                                                <div class="rt">
                                                    <a class="priceSection">
                                                        <span class="priceTxt">
                                                            <em class="pTit fW-L">월 예상 납부 금액</em>
                                                            <em class="pAmount">{{ item.amount | currency }}원</em>
                                                        </span>
                                                        <span class="arrow"></span>
                                                    </a>
                                                </div>
                                                <div class="priceDt" v-show="selectedRate.seqnum === item.seqnum">
                                                    <span>유의사항</span>
                                                    <span v-html="item.basic_text"> </span>
                                                </div>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <!-- 대용량 요금제 -->
                            <div v-if="selectedTab === 2" :class="['popTab-content', { current: selectedTab === 2 }]">
                                <div class="price_content">
                                    <ul>
                                        <li
                                            v-for="item in planList.filter((x) => x.rt_seqnum === 2)"
                                            :key="item.seqnum"
                                        >
                                            <input
                                                type="radio"
                                                :id="'largeprice' + item.seqnum"
                                                name="popPrice"
                                                :value="item.seqnum"
                                                :checked="selectedRate.seqnum === item.seqnum"
                                                @click="onRate(item)"
                                            />
                                            <label :for="'largeprice' + item.seqnum" class="checkAr">
                                                <div class="lt">
                                                    <em class="mgl-50">{{ item.name }}</em>
                                                    <em class="mgl-50 fW-L"
                                                        >데이터 {{ item.data_text }} / 음성 {{ item.voice_text }} / 문자
                                                        {{ item.sms_text }}</em
                                                    >
                                                </div>
                                                <div class="rt">
                                                    <a class="priceSection">
                                                        <span class="priceTxt">
                                                            <em class="pTit fW-L">월 예상 납부 금액</em>
                                                            <em class="pAmount">{{ item.amount | currency }}원</em>
                                                        </span>
                                                        <span class="arrow"></span>
                                                    </a>
                                                </div>
                                                <div
                                                    class="priceDt"
                                                    id="price01-1"
                                                    v-show="selectedRate.seqnum === item.seqnum"
                                                >
                                                    <span>유의사항</span>
                                                    <span v-html="item.basic_text"></span>
                                                </div>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <!-- 실속형 요금제 -->
                            <div v-if="selectedTab === 3" :class="['popTab-content', { current: selectedTab === 3 }]">
                                <div class="price_content">
                                    <ul>
                                        <li
                                            v-for="item in planList.filter((x) => x.rt_seqnum === 3)"
                                            :key="item.seqnum"
                                        >
                                            <input
                                                type="radio"
                                                :id="'stallprice' + item.seqnum"
                                                name="popPrice"
                                                :value="item.seqnum"
                                                :checked="selectedRate.seqnum === item.seqnum"
                                                @click="onRate(item)"
                                            />
                                            <label :for="'stallprice' + item.seqnum" class="checkAr">
                                                <div class="lt">
                                                    <em class="mgl-50">{{ item.name }}</em>
                                                    <em class="mgl-50 fW-L"
                                                        >데이터 {{ item.data_text }} / 음성 {{ item.voice_text }} / 문자
                                                        {{ item.sms_text }}</em
                                                    >
                                                </div>
                                                <div class="rt">
                                                    <a class="priceSection">
                                                        <span class="priceTxt">
                                                            <em class="pTit fW-L">월 예상 납부 금액</em>
                                                            <em class="pAmount">{{ item.amount | currency }}원</em>
                                                        </span>
                                                        <span class="arrow"></span>
                                                    </a>
                                                </div>
                                                <div class="priceDt" v-show="selectedRate.seqnum === item.seqnum">
                                                    <span>유의사항</span>
                                                    <span v-html="item.basic_text"></span>
                                                </div>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <!-- 전체 요금제 -->
                            <div v-if="selectedTab === 0" :class="['popTab-content', { current: selectedTab === 0 }]">
                                <div class="price_content">
                                    <ul>
                                        <li v-for="item in planDistinct" :key="item.seqnum">
                                            <input
                                                type="radio"
                                                :id="'allprice' + item.seqnum"
                                                name="popPrice"
                                                :value="item.seqnum"
                                                :checked="selectedRate.seqnum === item.seqnum"
                                                @click="onRate(item)"
                                            />
                                            <label :for="'allprice' + item.seqnum" class="checkAr">
                                                <div class="lt">
                                                    <em class="mgl-50">{{ item.name }}</em>
                                                    <em class="mgl-50 fW-L"
                                                        >데이터 {{ item.data_text }} / 음성 {{ item.voice_text }} / 문자
                                                        {{ item.sms_text }}</em
                                                    >
                                                </div>
                                                <div class="rt">
                                                    <a class="priceSection">
                                                        <span class="priceTxt">
                                                            <em class="pTit fW-L">월 예상 납부 금액</em>
                                                            <em class="pAmount">{{ item.amount | currency }}원</em>
                                                        </span>
                                                        <span class="arrow"></span>
                                                    </a>
                                                </div>
                                                <div class="priceDt" v-show="selectedRate.seqnum === item.seqnum">
                                                    <span>유의사항</span>
                                                    <span v-html="item.basic_text"></span>
                                                </div>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <!-- 선택 완료 버튼 -->
                            <a
                                class="selectBtn notranslate"
                                style="cursor:pointer"
                                @click="
                                    isRatePop = false;
                                    isDim = false;
                                "
                                >{{ $t('trans.t05_11') }}</a
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="dim"></div>
        </div>

        <!-- 신청내역 팝업 -->
        <div class="popWpper details" v-if="isAppPop">
            <div class="popContainer center">
                <button
                    class="close ic"
                    @click="
                        isAppPop = false;
                        isDim = false;
                    "
                >
                    닫기
                </button>
                <div class="popWP">
                    <div class="popContTit line">
                        <p class="tit fW-R notranslate">
                            {{ $t('trans.t05_01') }}
                        </p>
                        <!-- <p class="txt">와이파이도시락 회원 정보로 로그인 하시려면<br>와이드모바일 통합 회원에 동의 후 로그인 가능합니다.</p> -->
                    </div>
                    <div class="popBody">
                        <div class="appConfirm">
                            <ul>
                                <li>
                                    <span class="confirmTit fW-R notranslate">{{ $t('trans.t05_02') }}</span>
                                    <span class="confirmTxt notranslate fW-L">{{ $t('trans.t99_99') }}</span>
                                </li>
                                <li>
                                    <span class="confirmTit fW-R notranslate">{{ $t('trans.t05_03') }}</span>
                                    <span class="confirmTxt fW-L">번호이동</span>
                                </li>
                                <li>
                                    <span class="confirmTit fW-R notranslate">{{ $t('trans.t05_04') }}</span>
                                    <span class="confirmTxt fW-L">일반유심 (0,000원)</span>
                                </li>
                                <li>
                                    <span class="confirmTit fW-R notranslate">{{ $t('trans.t04_06') }}</span>
                                    <span class="confirmTxt fW-L">{{ orderState }}</span>
                                </li>
                                <li>
                                    <span class="confirmTit fW-R notranslate">{{ $t('trans.t05_06') }}</span>
                                    <span class="confirmTxt fW-L">0,000원</span>
                                </li>
                                <li>
                                    <span class="confirmTit fW-R notranslate">{{ $t('trans.t05_07') }}</span>
                                    <span class="confirmTxt fW-L">0,000원</span>
                                </li>
                                <li>
                                    <span class="confirmTit fW-R notranslate">{{ $t('trans.t05_08') }}</span>
                                    <span class="confirmTxt fW-L">0,000원</span>
                                </li>
                            </ul>
                        </div>
                        <div class="noticeAr notranslate" v-html="$t('trans.t05_09')"></div>
                        <!-- 선택 완료 버튼 -->
                        <a class="selectBtn fW-R notranslate" href="03-1_dm_subscription_STEP1.html">{{
                            $t('trans.t05_11')
                        }}</a>
                    </div>
                </div>
            </div>
            <div class="dim"></div>
        </div>
    </div>
</template>

<script>
import FootRateBanner from '../../components/FootRateBanner';
import { fetchPlan, fetchMain, fetchSte1 } from '../../api/product';
import { sortBy, orderBy } from '../../js/util';
import { orderMixin } from '../../mixin/storeMixin';
import * as Enum from '../../js/enum';

export default {
    name: 'Step1View',
    mixins: [orderMixin],
    data() {
        return {
            isRatePop: false,
            isAppPop: false,
            planList: [], //플랜리스트
            planDistinct: [], //플랜리스트 중복제거
            selectedTab: 1, //선택요금제 탭
            arrRate: [], // 요금제
            extraTab: 1, // 부가서비스 선택 탭
            extraServices: [], // 부가서비스 목록
            isShowConfirm: false, // 신청내역 확인 팝업
            agreeServiceShow: false, // 서비스 이용약관 동의
            privacyCollectionShow: false, // 개인정보 수집 동의
            privacyTranShow: false, //개인정보 처리위탁 동의
            advertShow: false, //광고수신동의
            agreeIdentity: false, //고유식별 동의
            agreeOffer: false, //3자제공동의
            agreeTruct: false, //3자위탁동의
            agreeCredit: false, //개인신용정보 조회 동의
            arrUsimList: [], // 유심타입
            arrUsim: [],
            render: 0,
            termsPri: '',
            termsAgr: '',
            rateFilter: '1', //요금제 정렬
        };
    },

    components: {
        FootRateBanner,
    },

    methods: {
        async fetchStep() {
            const [extra, plan, sim, terms] = await fetchSte1();

            // 정렬
            this.planList = sortBy(plan.data.ResponseData, 'orderby');

            this.extraServices = extra.data.ResponseData;

            this.arrUsimList = sim.data.ResponseData;

            const mapRate = new Map();

            // 중복제거
            for (const item of plan.data.ResponseData) {
                if (!mapRate.has(item.seqnum)) {
                    mapRate.set(item.seqnum, true);
                    this.planDistinct.push(item);
                }
            }

            this.termsPri = terms.data.ResponseData.find((el) => {
                return el.seqnum === 20;
            });

            this.termsAgr = terms.data.ResponseData.find((el) => {
                return el.seqnum === 21;
            });

            if (this.$route.query.rate) {
                const item = this.planDistinct.find((x) => x.seqnum === parseInt(this.$route.query.rate.toString()));

                if (item) {
                    this.onRate(item);

                    this.forceRender();

                    return;
                }

                this.MTS_SELECTED_RATE();
            }

            if (this.selectedRate) {
                this.arrUsim = this.arrUsimList.filter((x) => x.name_cd === this.selectedRate.name_cd);
            }
        },

        onAllCheck() {
            if (this.clauseAllCheck) {
                this.agreeService = true;
                this.agreePrivacyCollection = true;
                this.agreePrivacyTran = true;
                this.agreePrivacyAdvert = true;
                this.identityYn = true;
                this.offerYn = true;
                this.tructYn = true;
                this.creditYn = true;
                return;
            }

            this.agreeService = false;
            this.agreePrivacyCollection = false;
            this.agreePrivacyTran = false;
            this.agreePrivacyAdvert = false;
            this.identityYn = false;
            this.offerYn = false;
            this.tructYn = false;
            this.creditYn = false;
        },

        onRate(payload) {
            this.arrUsim = this.arrUsimList.filter((x) => x.name_cd === payload.name_cd);

            if (this.isEmpty(this.arrUsim)) {
                this.selectedUsimType = {};
                this.selectedRate = {};
                return;
            }

            this.selectedRate = {
                amount: payload.amount,
                name: payload.name,
                product_cd: payload.product_cd,
                seqnum: payload.seqnum,
            };

            this.monthAmount = payload.amount;
            this.planAmount = payload.amount;
            this.maxDiscount = payload.maxDiscount;
            this.monthlyAmount = payload.monthlyAmount;
            this.selectedUsimType = this.arrUsim[0];

            this.forceRender();
        },

        forceRender() {
            this.render += 1;
        },

        onMonthAmount(payload) {
            this.monthAmount = payload;
        },

        onExtraAmount(payload) {
            this.extraAmount = payload;
        },

        onUsimAmount(payload) {
            this.extraAmount = payload;
        },

        onQuickAmount(payload) {
            this.quickAmount = payload;
        },

        onJoinType(payload) {
            if (payload === Enum.JOIN_TYPE.TRANSFER) {
                this.firstNumber = '';
                this.secondNumber = '';
                this.company = '';
                this.modeName = '';
                return;
            }

            this.beforeAgency = '';
            this.beforeMvnoAgency = '';
            this.beforePattern = '';
            this.beforeCel = '';
            this.accAuthTyp = '';
            this.acc4Num = '';
        },

        onRateFilter(e) {
            const filterKey = JSON.parse(e.target.options[e.target.selectedIndex].getAttribute('data-filter'));

            console.log(filterKey);
            this.planList =
                filterKey.value === 'desc'
                    ? sortBy(this.planList, filterKey.key).reverse()
                    : sortBy(this.planList, filterKey.value);
            this.planDistinct =
                filterKey.value === 'desc'
                    ? sortBy(this.planDistinct, filterKey.key).reverse()
                    : sortBy(this.planList, filterKey.value);
        },
    },

    mounted() {
        // todo 요금제 조회
        this.$nprogress.start();
        this.fetchStep();
        this.$nprogress.done();
    },

    computed: {},

    watch: {
        // selectedExtraServices: function(payload) {
        //     const sum = this.selectedExtraServices.reduce((sum, obj) => {
        //         return sum + obj.amount;
        //     }, 0);
        //     this.onExtraAmount(sum);
        // },
    },
};
</script>

<style></style>
