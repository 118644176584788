<template>
  <div class="wrap subPage">
    <div class="container">
      <!-- sub title -->
      <div class="subTitAr">
        <h1 class="notranslate">{{ $t("trans.t12_16") }}</h1>
        <span class="subTit fW-L"
          >통합회원 가입으로 와이드모바일의 모든 서비스를 편리하게
          이용하세요.</span
        >
      </div>
      <!-- 가입신청서 STEP -->
      <div class="subStepWrap mT30">
        <ul class="stepAr">
          <li>
            <span class="stepNum on">STEP1<b>약관동의</b></span>
          </li>
          <li>
            <span class="stepNum">STEP2<b>본인인증</b></span>
          </li>
          <li>
            <span class="stepNum">STEP3<b>회원정보입력</b></span>
          </li>
        </ul>
      </div>
      <div style="clear: both;"></div>
      <!-- 서브페이지 컨텐츠 -->
      <div class="subPageAr">
        <div class="joinInfo">
          <div class="chkAr">
            <input
              type="checkbox"
              id="joinCheckAll"
              name="joinchk"
              @change="AllCheck"
              v-model="isAll"
            />
            <label for="joinCheckAll" class="joinCheckLabel"></label>
            <span class="joinAgree"
              >와이드모바일 통합회원가입 약관에 모두 동의합니다.</span
            >
          </div>
          <div class="chkAr">
            <input type="checkbox" id="isUse" v-model="isUse" />
            <label for="isUse" class="joinCheckLabel"></label>
            <span class="joinAgree">이용약관 (필수)</span>
          </div>
          <div class="termAr">
            <span
              class="termTxt"
              id="terms"
              style="height: 200px;overflow-y: auto;"
              v-html="used.contents"
            >
            </span>
            <!-- todo -->
          </div>
          <div class="chkAr">
            <input
              type="checkbox"
              id="isPrivacy"
              v-model="isPrivacy"
            />
            <label for="isPrivacy" class="joinCheckLabel"></label>
            <span class="joinAgree"
              >개인정보처리방침 동의 (필수)</span
            >
          </div>
          <div class="termAr">
            <span
              class="termTxt"
              id="terms"
              style="height: 200px;overflow-y: auto;"
              v-html="privacy.contents"
            >
            </span>
          </div>
          <div class="chkAr">
            <input type="checkbox" id="isEmail" v-model="isEmail" />
            <label for="isEmail" class="joinCheckLabel"></label>
            <span class="joinAgree">이메일 수신 동의 (선택)</span>
          </div>
          <div class="chkAr">
            <input type="checkbox" id="isSms" v-model="isSms" />
            <label for="isSms" class="joinCheckLabel"></label>
            <span class="joinAgree">SMS 수신 동의 (선택)</span>
          </div>
          <div class="btnWp">
            <button
              class="btnCom gray notranslate"
              type="button"
              @click="$router.go(-1)"
            >
              {{ $t("trans.t13_06") }}
            </button>
            <button
              class="btnCom notranslate"
              type="button"
              @click="onNext"
            >
              {{ $t("trans.t06_25") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchTerms } from "@/api/common";

export default {
  data() {
    return {
      isAll: false,
      isUse: false,
      isPrivacy: false,
      isEmail: false,
      isSms: false,
      arrTerms: [],
      privacy: "", //개인정보처리방침
      used: "", //통합이용약관
    };
  },
  methods: {
    AllCheck(e) {
      this.isUse = this.isAll;
      this.isPrivacy = this.isAll;
      this.isEmail = this.isAll;
      this.isSms = this.isAll;
    },
    onNext() {
      if (!this.isUse) {
        this.kakaoTrans("이용약관에 동의해 주세요").then((res) =>
          alert(res)
        );
        return;
      }

      if (!this.isPrivacy) {
        this.kakaoTrans(
          "개인정보처리방침에 동의해 주세요"
        ).then((res) => alert(res));
        return;
      }

      this.$router.push({ name: "JoinStep2View" });
    },

    async fetchTerms() {
      const res = await fetchTerms({
        site_type: 3,
      });

      this.arrTerms = res.data.ResponseData;
      this.privacy = res.data.ResponseData.find(
        (x) => x.seqnum === 21
      );
      this.used = res.data.ResponseData.find((x) => x.seqnum === 3);
    },
  },

  mounted() {
    this.fetchTerms();
  },
};
</script>

<style></style>
