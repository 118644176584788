<template>
  <div>
    <div ref="document" id="document" v-show="account">
      <div class="applicationForm">
        <div class="formTopWrap">
          <div class="formLogo">
            <!-- <img src="../../images/formLogo.png" /> -->
          </div>
          <div class="formTit">
            <span class='notranslate'>{{$t('trans.t09_13')}}</span>
          </div>
          <!-- 가입자 정보 -->
          <div class="formConWrap">
            <span class="formConTit notranslate">
              {{$t('trans.t07_32')}}
            </span>
            <div class="formAr">
              <div class="formGroup">
                <div class="infoAr halfAr">
                  <span class="infoTit notranslate">{{$t('trans.t14_05')}}</span>
                  <span class="infoTxt">{{ account.clientname }}</span>
                </div>
                <div class="infoAr halfAr">
                  <span class="infoTit notranslate">{{$t('trans.t08_17')}}</span>
                  <span class="infoTxt">{{
                    account.birth
                  }}</span>
                </div>
                <div style="clear: both;"></div>
              </div>
            </div>
            <div class="formAr" v-if="account.address">
              <div class="infoAr">
                <span class="infoTit">가입자 주소</span>
                <span class="infoTxt"
                  >{{`${account.zipcode}`}} {{ account.address }}</span
                >
              </div>
            </div>
            <div class="formAr">
              <div class="infoAr">
                <span class="infoTit">연락처</span>
                <span class="infoTxt">{{ account.celnumber }}</span>
              </div>
            </div>
            <div class="formAr">
              <div class="infoAr">
                <span class="infoTit notranslate">{{$t('trans.t07_34')}}</span>
                <span class="infoTxt">{{ account.clientmail }}</span>
              </div>
            </div>
            <div class="formAr">
              <div class="infoAr">
                <span class="infoTit">자동결제</span>
                <span class="infoTxt">{{ account.autopay_yn }}</span>
              </div>
            </div>
            <div class="formAr" v-if="account.pgtype_cd === 4">
              <div class="infoAr">
                <span class="infoTit">은행/계좌번호</span>
                <span class="infoTxt">{{
                  `${account.bankname}/${account.accountnumber}`
                }}</span>
              </div>
            </div>
          </div>
          <!-- 가입내역 -->
          <div class="formConWrap">
            <span class="formConTit">
              가입내역
            </span>
            <div class="formAr">
              <div class="formGroup">
                <div class="infoAr">
                  <span class="infoTit">가입번호</span>
                  <span class="infoTxt">{{ account.opencelnumber }}</span>
                </div>
                <div class="infoAr">
                  <span class="infoTit notranslate">{{$t('trans.t17_04')}}</span>
                  <span class="infoTxt">{{ account.name }}</span>
                </div>
                <div style="clear: both;"></div>
              </div>
            </div>
            <div class="formAr">
              <div class="infoAr">
                <span class="infoTit notranslate">{{$t('trans.t11_03')}}</span>
                <span class="infoTxt">{{ account.extra_service }}</span>
              </div>
            </div>
          </div>
          <!-- 신규가입고객 -->

          <div class="formConWrap" v-show="account.subtype_cd === 1">
            <span class="formConTit">
              신규가입고객
            </span>
            <div class="formAr">
              <div class="infoAr">
                <span class="infoTit">희망번호</span>
                <span class="infoTxt"
                  >{{ account.new_number1 }},{{ account.new_number2 }}</span
                >
              </div>
            </div>
          </div>

          <!-- 번호이동고객 -->
          <div class="formConWrap" v-show="account.subtype_cd === 2">
            <span class="formConTit">
              번호이동고객
            </span>
            <div class="formAr">
              <div class="formGroup">
                <div class="infoAr halfAr">
                  <span class="infoTit">이동할 전화번호</span>
                  <span class="infoTxt">{{ account.previous_celnuber }}</span>
                </div>
                <div class="infoAr halfAr">
                  <span class="infoTit">변경 전 사업자</span>
                  <span class="infoTxt">{{ account.agency }}</span>
                </div>
                <div style="clear: both;"></div>
              </div>
            </div>
            <div class="formAr">
              <div class="formGroup">
                <div class="infoAr halfAr">
                  <span class="infoTit">번호이동 인증</span>
                  <span class="infoTxt">{{ account.accAuthNum }}</span>
                </div>
                <div class="infoAr halfAr">
                  <span class="infoTit notranslate">{{$t('trans.t07_14')}}</span>
                  <span class="infoTxt">{{ account.accAuthName }}</span>
                </div>
                <div style="clear: both;"></div>
              </div>

            </div>
          </div>

          <div class="formConWrap">
            <div class="formAr">
              <div class="formGroup">
                <div class="infoAr halfAr" style="width:100%">
                  <span class="infoTxt">본인은(주)와이드모바일의 개인정보처리방침,LTE/CDMA 서비스 이용약관, 위치기반서비스 이용약관, 휴대폰 본인 확인 서비스 이용약관, 발신번호 변작 방지 이용약관에 동의하며, 외국인(여권)가입의 경우 당일 입국기록 조회 불가에 따른 개통 제한시 자사의 법인 명의로 임시 개통 후 익일 입국기록 확인 시 본인 명의 변경 진행에 동의 합니다.<br>본인은 가입신청서 내용을 충분히 이해했으며, 위와 같은 내용으로 계약을 체결합니다.</span>
                </div>
              </div>
            </div>            
          </div>

          <div class="formConWrap">
            <div class="formAr">
              <div class="formGroup" style="background-color:#eeee">
                <div class="infoAr halfAr">
                  <span class="infoTit">가입 신청일</span>
                  <span class="infoTxt">{{ account.regdate }}</span>
                </div>
                <div class="infoAr halfAr">
                  <span class="infoTit">신청인</span>
                  <span class="infoTxt">{{ account.clientname }}</span>
                </div>
                <div style="clear: both;"></div>
              </div>
            </div>          
          </div>              
        </div>        
      </div>

      <div class="appBtnAr">
        <button type="button" class="downBtn" @click="exportToPDF">다운로드</button>
      </div>
    </div>
    <application-doc v-bind:order_id="$route.query.order"></application-doc>
  </div>
</template>

<script>
import { fetchAccountForm } from "../../api/member";
import ApplicationDoc from "../../components/ApplicationDoc";
import { eventBus } from "../../js/eventBus";

export default {
  data() {
    return {
      account: {},
    };
  },

  components:{
    ApplicationDoc
  },

  methods: {
    async fetchAccount() {
      const res = await fetchAccountForm({
        order_id: this.$route.query.order,
      });

      this.account = res.data.ResponseData;
    },

    exportToPDF() {
      eventBus.$emit("downApplication");
    },
  },

  mounted() {
    this.fetchAccount();
  },
};
</script>

<style scoped>
.applicationForm {
  padding: 90px;
}
</style>
