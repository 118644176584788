import { loginType } from '../js/enum'


const memberStore = {
    namespaced:true,
    state:{
        member_login_type:loginType.EMAIL,
		member_seq: "",
		member_cellnumber:"",
		member_id:"",
		member_name:"",
		isLogin:false,
		sns:"",
		reservation_cd:"",
		member_mvno:[]
    },
    getters:{
        GTS_STATE: (state) => state
	},
	mutations:{
		MTS_SET_LOGIN: (state, payload) => {
			state.member_login_type = payload.member_login_type
			state.member_seq = payload.member_seq
			state.member_cellnumber = payload.member_cellnumber
			state.member_name = payload.member_name
			state.member_id = payload.member_id
			state.isLogin = true,
			state.sns=payload.sns,
			state.reservation_cd=payload.reservation_cd
			state.member_mvno = payload.member_mvno
		},
		MTS_SET_LOGOUT:(state) => {
			state.member_login_type = loginType.EMAIL,
			state.member_seq = "",
			state.member_cellnumber = "",
			state.member_id = "",
			state.member_name = "",
			state.isLogin = false,
			state.sns=""
			reservation_cd:"",
			state.member_mvno = []
		}
	}
}

export default memberStore;