<template>
  <div class="footerAr">
    <div class="footerCon">
      <div class="policyAr">
        <router-link
            :to="{ name:'TermsView', query:{q:0}}">
          <span class="notranslate">{{$t('trans.t10_01')}}</span>
        </router-link>|
        <router-link
            :to="{ name:'TermsView', query:{q:2}}">
          <span class='notranslate'>{{$t('trans.t10_02')}}</span>
        </router-link>
      </div>
      <div class="companyInfo">
        <div class="infoAr notranslate" v-html="$t('trans.t10_03')">

        </div>
        <div>
          [{{ `${browserName} ${browserVersion} ${deviceType === undefined ? 'PC' : deviceType.toUpperCase()}` }}]
        </div>
        <div class="familySiteAr">
          <span><a href=""><img
              src="../images/whMobile.png"
              alt="도시락모바일"></a></span>
          <span><a
              href="https://www.wifidosirak.com/"><img
              src="../images/whDosirak.png"
              alt="와이파이도시락"></a></span>
          <span><a href="https://dosiraksim.com/"><img
              src="../images/whUsim.png"
              alt="도시락유심"></a></span>
          <span><a
              href="https://www.insudosirak.com/"><img
              src="../images/whTravel.png"
              alt="여행보험도시락"></a></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {}
</script>

<style>

</style>