<template>
  <div class="wrap subPage">
    <div class="container">
      <!-- sub title -->
      <div class="subTitAr fW-R">
        <h1 class='notranslate'>{{ $t("trans.t18_01") }}</h1>
        <span class="subTit fW-L notranslate"
          >{{$t('trans.t99_99')}}{{ $t("trans.t18_02") }}</span
        >
      </div>
      <!-- 서브페이지 컨텐츠 -->
      <form @submit.prevent="onSubmit">
        <div class="subPageAr">
          <div class="mypageAr">
            <div class="infoMod">
              <!-- 비밀번호 변경 안내 -->
              <span class="modTit notranslate">{{ $t("trans.t20_01") }}</span>
              <div class="infoModcon notranslate">
                <div class="noteAr notranslate" v-html="$t('trans.t20_02')">

                </div>
              </div>
              <!-- 현재 비밀번호 입력 -->
              <span class="modTit notranslate">{{$t('trans.t20_03')}}</span>
              <div class="infoModcon notranslate">
                <input
                  type="password"
                  class="change"
                  name="passNow"
                  v-model.trim="passNow"
                  v-validate="'required'"
                  :placeholder="$t('trans.t20_03')"
                />
              </div>
              <!-- 신규 비밀번호 입력 -->
              <span class="modTit notranslate">{{$t('trans.t20_04')}}</span>
              <div class="infoModcon notranslate">
                <div class="inpGroup">
                  <input
                    ref="passFirst"
                    type="password"
                    class="change"
                    name="passFirst"
                    v-model.trim="passFirst"
                    v-validate="'required'"
                    :placeholder="$t('trans.t20_04')"
                  />
                  <div class="grouping nextLine notranslate">
                    <input
                      type="password"
                      class="change"
                      name="passSecond"
                      v-model.trim="passSecond"
                      v-validate="'required|confirmed:passFirst'"
                      :placeholder="$t('trans.t20_05')"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="btnWp">
              <button
                class="btnCom gray notranslate"
                type="button"
                @click="$router.go(-1)"
              >
                {{ $t("trans.t13_06") }}
              </button>
              <button class="btnCom notranslate" type="submmit">
                {{$t('trans.t20_06')}}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { putPass } from "../../api/member";
import * as auth from "../../api/auth";

export default {
  data() {
    return {
      passNow: "",
      passFirst: "",
      passSecond: "",
    };
  },
  methods: {
    async onSubmit() {
      const result = await this.$validator.validateAll();

      console.log(result);
      console.log(this.memberInfo.member_seq);

      if (!result) {
        this.kakaoTrans(this.errors.items[0].msg).then((res) =>
          alert(res)
        );
        return;
      }

      if (result) {
        const res = await putPass({
          member_seq: this.memberInfo.member_seq,
          pass_new: this.passFirst,
          pass_old: this.passNow,
        });

        if (!res.data.ResultCode) {
          this.kakaoTrans(res.data.ResultMessage).then((res) =>
            alert(res)
          );
          return;
        }

        this.kakaoTrans(res.data.ResultMessage).then((res) =>
          alert(res)
        );

        this.$router.replace({ name: "MemberEditView" });

        return;
      }

      this.kakaoTrans(this.errors.items[0].msg).then((res) =>
        alert(res)
      );
    },
  },
};
</script>
<style lang=""></style>
