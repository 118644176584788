/*
    ----------------------------------------
    ** 주문 API
    ----------------------------------------
*/

import {dosirak} from "./config";

export function fetchShop(payload){
  return dosirak.post('/mvno/reservation/shop', payload)

}