import ApplicationList from "../views/mypage/ApplicationListView";
import MyPage from "../views/mypage/MyPageView";
import OnlineApplicationFormView from "../views/order/OnlineApplicationFormView"
import UsageView from "../views/mypage/UsageView";
import CancelView from "../views/order/CancelView"
import UserAuthView from "../views/mypage/UserAuthView.vue"

export default[
    {
        path: "/mypageview",
        name: "MyPageView",
        component: MyPage,
        meta: {
            isLogin: true,
            transitionName: 'slide'
        }
    },
    {
        path: "/mypage/applicationListView",
        name: "ApplicationListView",
        component: ApplicationList,
        meta: {
            isLogin: true,
            isUserAuth: true,
            transitionName: 'slide'
        }
    },
    {
        path: "/mypage/onlineApplicationFormView",
        name: "OnlineApplicationFormView",
        component: OnlineApplicationFormView,
        meta: {
            transitionName: 'slide'
        }
    },
    {
        path: "/mypage/usageview",
        name: "UsageView",
        component: UsageView,
        meta: {
            transitionName: 'slide'
        }
    }, {
        path: "/mypage/cancelView",
        name: "CancelView",
        component: CancelView,
        meta: {
            transitionName: 'slide'
        }

    }, {
        path: "/mypage/authView",
        name: "UserAuthView",
        component: UserAuthView,
        meta: {
            isUserAuth: true,
            transitionName: 'slide'
        }
    },
    {
        path: '*',
        redirect: "/"
    },
]
