<template>
    <div id="app">
        <div :class="['wrap mainPage']" :style="isDim ? 'overflow: hidden; position: fixed;' : ''">
            <div class="content container">
                <main-header></main-header>
                <transition :name="transitionName" mode="out-in">
                    <router-view></router-view>
                </transition>
                <Footer></Footer>
                <!-- 영문 팝업 -->
                <div class="DMobile_Popup" id="DMobile_Popup" style="display:none;">
                    <div id="popup-overlay">
                        <div id="popup-container">
                            <div class="popupWp">
                                <a href="#" target="_blank"><img src="https://wmstgbb001.blob.core.windows.net/wm-images/dosirakmobile/popup/Membership_EN_240328.jpg" alt="Popup Image"></a>
                                <div class="closeWp">
                                    <button id="ViewPlan" @click="RatePlan">Rate Plan</button>
                                    <div id="close-popup" class="close-popup" @click="closePopup">
                                        Close
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./js/common.js"></script>
<script>
    const angent = window.navigator.userAgent.toLowerCase();
    if (angent.indexOf('trident') > -1) {
        location.href = 'microsoft-edge:' + location.href;
        setTimeout(function () {
            location.href = 'https://go.microsoft.com/fwlink/?linkid=2135547';
        }, 1);
    }
</script>
<script>
    const DEFAULT_TRANSITION = 'fade';

    import './js/lib/nprogress';

    import MainHeader from './components/MainHeader.vue';
    import Footer from './components/Footer.vue';
    import { request } from './api/common';
    import { orderMixin } from './mixin/storeMixin';
    import { test } from './api/api';

    // 페이스북 픽셀
    !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };

        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

    fbq('init', '383914919595107');
    fbq('track', 'PageView');

    export default {
        metaInfo: {
            title: '도시락모바일, Korea Prepaid sim card',
            titleTemplate: '%s | prepaid.dosirakmobile.com', // title 뒤에 붙일 공통된 이름 (주로 사이트이름)
            meta: [
                { charset: 'utf-8' },
                {
                    name: 'description',
                    content:
                        '인증없는 온라인 가입으로 누구나 조건없이 본인명의 가입. 인천공항에서 현장개통 가능, 약정없는 선불유심 요금제 판매, 장기할인 자동결제 할인',
                    vmid: 'description',
                },
                {
                    name: 'keywords',
                    content: `선불유심,외국인유심,인천공항유심,인천공항선불유심,선불유심충전,선불폰,외국인한국유심,신불자핸드폰개통,통신연체자핸드폰개통,인천공항유심칩,선불폰개통,
선불유심데이터무제한,신용불량자핸드폰개통,한국선불유심,선불유심요금제,외국인선불유심,외국인심카드,한국유심,선불유심칩,선불유심개통,선불요금제,선불유심구매,선불폰데이터무제한,
korea prepaid sim card,korea usim card,incheon airport sim card,korea sim card,korea usim,self quarantine,self isolation`,
                },
            ],
        },
        components: {
            MainHeader,
            Footer,
        },
        mixins: [orderMixin],
        data() {
            return {
                preHeight: 0,
                transitionName: DEFAULT_TRANSITION,
            };
        },
        created() {
            this.$router.beforeEach((to, from, next) => {
                let transitionName = to.meta.transitionName || from.meta.transitionName;

                if (transitionName === 'slide') {
                    const toDepth = to.path.split('/').length;
                    const fromDepth = from.path.split('/').length;
                    transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
                }

                this.transitionName = transitionName || DEFAULT_TRANSITION;

                next();
            });

            window.googleTranslateElementInit = function () {
                const tran = new google.translate.TranslateElement(
                    {
                        pageLanguage: 'ko',
                        layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
                        includedLanguages: 'ko,en,vi,zh-CN',
                        autoDisplay: false,
                    },
                    'google_translate_element'
                );
            };

            const playAlert = setInterval(async function () {
                const [d, p] = await request();
            }, 5000);
        },

        methods: {
            beforeLeave(element) {
                this.preHeight = getComputedStyle(element).height;
            },

            test() {
                this.$nprogress.start();
            },
            closePopup() {
                document.getElementById("DMobile_Popup").style.display = "none";
            },
            RatePlan() {
                this.$router.push({ name: 'PaymentSystemView' });
                document.getElementById("DMobile_Popup").style.display = "none";
            }
        },
    };
</script>

<style>
    @import url('https://fonts.googleapis.com/css?family=Quicksand:300,600&display=swap');
    @import url('https://cdn.jsdelivr.net/npm/xeicon@2.3.3/xeicon.min.css');

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }

    .slide-left-enter-active,
    .slide-left-leave-active,
    .slide-right-enter-active,
    .slide-right-leave-active {
        transition-duration: 0.5s;
        transition-property: height, opacity, transform;
        transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
        overflow: hidden;
    }

    .slide-left-enter,
    .slide-right-leave-active {
        opacity: 0;
        transform: translate(2em, 0);
    }

    .slide-left-leave-active,
    .slide-right-enter {
        opacity: 0;
        transform: translate(-2em, 0);
    }

    .google-translate {
        display: none !important;
        display: inline-block;
        vertical-align: top;
        padding-top: 0px;
    }

    .goog-logo-link {
        display: none !important;
    }

    .goog-te-gadget {
        color: transparent !important;
    }

    #google_translate_element {
        display: none;
    }

    .goog-te-banner-frame.skiptranslate {
        display: none !important;
    }

    .slide-fade-enter-active {
        transition: all 0.3s ease;
    }

    .slide-fade-leave-active {
        /* transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0); */
        transition: all 0.3s ease;
    }

    .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translateX(10px);
        opacity: 0;
    }

    .DMobile_Popup #popup-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 999999;
    }

    .DMobile_Popup #popup-container {
        position: relative;
    }

    .DMobile_Popup .popupWp {
        width: 40%;
        display: block;
        margin: 0 auto;
        background-color: #3963ad;
        border-radius: 30px;
    }

        .DMobile_Popup .popupWp img {
            width: 100%;
            height: auto;
            display: block;
            border-radius: 30px 30px 0 0;
        }

        .DMobile_Popup .popupWp .closeWp {
            display: flex;
            justify-content: space-around;
            width: 100%;
        }

        .DMobile_Popup .popupWp #ViewPlan {
            color: #fff;
            font-weight: bold;
            border: none;
            background: none;
            padding: 20px 0;
            cursor: pointer;
            font-size: 18px;
        }

        .DMobile_Popup .popupWp #close-popup {
            color: #fff;
            padding: 20px 0;
            cursor: pointer;
            font-size: 18px;
        }

    @media all and (max-width: 1020px) {
        .DMobile_Popup .popupWp {
            width: 90%;
        }

            .DMobile_Popup .popupWp #ViewPlan {
                font-size: 40px;
                padding: 50px 0;
            }

            .DMobile_Popup .popupWp #close-popup {
                font-size: 40px;
                padding: 50px 0;
            }
    }
</style>
