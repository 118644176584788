import Vue from "vue";
import VueRouter from "vue-router";
import HomeRoute from "./homeRouter";
import MemberRoute from "./memberRouter";
import MyPageRoute from "./myPageRouter";
import StepRouter from "./stepRouter";
import SnsRouter from "./snsRouter";
import Store from "../store";
import promotionRouter from "./promotionRouter";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        ...HomeRoute,
        ...MemberRoute,
        ...MyPageRoute,
        ...StepRouter,
        ...SnsRouter,
        ...promotionRouter
    ],
    // router 이동시 화면 최상단 위치
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0};
    },
});

/*
router.beforeResolve((to, from, next) => {
    next()
})

router.afterEach((to, from) => {
    next()
})
*/

router.afterEach((to, from) => {
    if (!wcs_add) var wcs_add = {};

    window.wcs_add = {wa: "s_22b5388c1089"};

    if (!nasa) var nasa = {};

    if (window.wcs) {
        wcs.inflow();

        wcs_do(nasa);
    }

});

router.beforeEach((to, from, next) => {
    Store.commit("orderStore/MTS_DIM", false);
    if (!to.matched.some((record) => record.meta.isStep)) {
        Store.commit("orderStore/MTS_ORDER_INIT");
        sessionStorage.removeItem("imageCard");
        sessionStorage.removeItem("imageFace");
    }

    if (to.matched.some((record) => record.meta.isLogin)) {
        const memberState = Store.getters["memberStore/GTS_STATE"];

        if (!memberState.isLogin) {
            if (to.matched.some((record) => record.meta.isUserAuth)) {
                if (to.query.user) {
                    next();
                    return;
                }
                next({
                    name: "UserAuthView",
                    query: {
                        view: to.name,
                    },
                });
                return;
            }

            next({
                name: "LoginView",
                query: {
                    view: to.name,
                },
            });
        }
    }

    next();
});

export default router;
