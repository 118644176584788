/*
    ----------------------------------------
    ** 주문 API
    ----------------------------------------
*/

import {dosirak, axiosAll, global, pay} from "./config";

// 주문 정보 조회
export function fetchOrder(payload) {
    const order = dosirak.post(`/mvno/reservation/getReservationByOrderId`, payload)
    const extra = dosirak.post(`/mvno/reservation/getextraByOrderId`, payload)

    return axiosAll([order, extra])
}

// 제니일 배송 가능여부
export function fetchZipcode(payload) {
    return dosirak.post(`/zeniel/getDeliveryArea?zipcode=${payload}`)
}

// 예약 트랜 처리
export function orderTranCommit(payload) {
    return dosirak.post('/mvno/reservation/order/tran', payload)
}

// 예약 트랜 처리
export function orderCommit(payload) {
    return dosirak.post('/mvno/reservation/order/commit', payload)
}

// 편의점 결제 바코드
export function storeBarcode(payload) {
    pay.head()
    return pay.post('/galaxia/NumIssue', payload)
}

// 이미지 임시 저장
export function putTempImage(payload){
    return dosirak.post('/mvno/reservation/temp/image', payload)
}

// 데이터 임시 저장
export function putTempData(payload){
    return dosirak.post('/mvno/reservation/temp/data', payload)
}

// 임시 데이터 조회
export function fetchTempData(payload){
    const data = dosirak.post(`/mvno/reservation/temp/data/get`, payload)
    const image = dosirak.post(`/mvno/reservation/temp/image/get`, payload)

    return axiosAll([data, image])
}

// 임시 데이터 삭제
export function deleteTempData(payload){
    return dosirak.post('/mvno/reservation/temp/data/remove', payload)
}

// 취소요청
export function patchOrderCancel(payload){
    return dosirak.post('/mvno/reservation/cancel', payload)
}

// 반품요청
export function patchRefundCancel(payload){
    return dosirak.post('/mvno/reservation/deliveryCancel', payload)
}

// 개통요청
export function patchOpenRequest(payload){
    return dosirak.post('/mvno/reservation/open/request', payload)
}

// 개통요청 취소
export function patchOpenCancelRequest(payload){
    return dosirak.post('/mvno/reservation/open/request/cancel', payload)
}

// 자동결제 취소 신청
export function putAutoPay(payload){
    return dosirak.post('/mvno/reservation/autopayCancelUpdate', payload)
}

// 자동결제 동의서 조회
export function fetchDebit(payload){
    return dosirak.post('/mvno/reservation/debit', payload)
}

// 무선서비스 계약 표준 안내서
export function fetchWireless(payload){
    return dosirak.post('/mvno/reservation/wirelessForm', payload)
}

// 충전잔액조회
export function fetchRemaining(payload){
    return dosirak.post('/mvno/telecentro/RemainingAmountSelect', payload)
}