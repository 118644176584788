const memberJoinStore = {
    namespaced:true,
    state:{
        member_name:"",
        member_mail:"",
        member_pass:"",
        member_cell:"",
        gender:10,
        birthday:""
    },
    getters:{

    },
    mutations:{
        MTS_SET_MEMBER_JOIN:(state, payload) => {
            state.member_name = payload.member_name
            state.member_mail = payload.member_mail
            state.member_pass = payload.member_pass
            state.member_cell = payload.member_cell
            state.gender = payload.gender
            state.brithday = payload.birthday
        }
    }
}

export default memberJoinStore