import Home from "../views/Home"
import About from "../views/About"
import PaymentSystem from "../views/order/PaymentSystemView";
import AddService from "../views/order/AddServiceView"
import TermsView from "../views/common/TermsView"

export default[
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: { transitionName: 'slide' },

        beforeEnter:(to, from, next ) => {
            if(to.query.q){
                document.cookie = `googtrans=${to.query.q}; domain=.dosirakmobile.com;`;
                document.cookie = `googtrans=${to.query.q}; domain=192.168.0.173`;
            }

            next()
            
        },

        children:[]
    }, {
        path : "/about",
        name : "About",
        meta: { transitionName: 'slide' },
        component : About
    }, {
        path:"/paymentsystemview",
        name:"PaymentSystemView",
        meta: { transitionName: 'slide' },
        component:PaymentSystem
    }, {
        path:"/addserviceview",
        name:"AddServiceView",
        meta: { transitionName: 'slide' },
        component:AddService
    },{
        path:"/common/termsview",
        name:"TermsView",
        component:TermsView
    },
    {
        path: '*',
        redirect: "/"
    },    
]
