const order = () => {
  return {
    // 공통
    isStep1: false,
    isStep2: false,
    isStep3: false,
    isStep4: false,
    currentPage: "", // 현재페이지
    isAlien: false, // 외국인 여부
    member_id: "", // 로그인 아이디
    joinType: 1, // 가입유형 (1 신규가입,2 번호이동)
    selectedRate: {}, // 선택요금제
    arrRate: [], // 요금제
    selectedUsimType: {},
    extraTab: 1, // 부가서비스 선택 탭
    selectedExtraServices: [], // 선택한 부가서비스
    clauseAllCheck: false, // 전체체크
    agreeService: false, // 서비스이용약관 동의
    agreePrivacyCollection: false, // 개인정보 수집동의
    agreePrivacyTran: false, // 개인정보 처리위탁 동의
    agreePrivacyAdvert: false, // 광고수신 동의
    isShowConfirm: false,
    // 신청내역 확인 팝업
    // arrUsim: usim, // 유심타입 데이터
    monthAmount: 0, // 월기본요금
    extraAmount: 0, // 부가서비스
    usimAmount: 0, // 유심비
    quickAmount: 0, // 퀵서비스비용
    totalAmount: 0, // 총결제금액
    monthEstmAmount: 0, // 월예상금액
    totalDiscountAmount: 0, //총할인금액
    autoDiscountAmount: 0, //자동결제할인
    longTermDiscountAmount: 0, //장기할인
    planAmount: 0, //플랜 금액

    // step2
    authType: 0, // 인증종류(신용카드, 인증서)
    authYn: false, // 인증여부
    activeTab: 1, // 활성화탭
    accName: "", // 가입자명
    accCard: "1", // 본인인증유형 (주민등록:1, 운전면허:2)
    accNum: "", // 주민번호
    accPassport: "", // 여권번호
    accNumIssuedDate: "", // 주민번호 발급일자
    accDriverNumFront: "", // 면허번호 앞2자리
    accDriverNumEnd: "",
    // 면허번호 나머지
    // accDriverFullNum:"", //면허번호 풀
    accDriverIssuedDate: "", // 면허발급일
    agentPriCard: "1", // 법정대리인 주민등록중|운전면허증
    agentName: "", // 법정대리인명
    agentCell: "", // 법정대리인 휴대폰
    agentNum: "", // 법정대리인 주민번호
    accCountry: "", // 외국인 선택국가
    agreeYn: false, // 법정대리인 동의 여부
    agentNumIssuedDate: "", // 법정대리인 주민등록 발급일
    agentDriverIssuedDate: "", // 법정대리인 면허발급일자
    agentDriverNumFront: "", // 법정대리인 면허번호 앞2자리
    agentDriverNumEnd: "", // 법정대리인 면허번호 나머지
    accInBoundNum: "",
    imageCard: "", // 주민등록증 OR 운전면증 OR 여권 이미지 base64
    imageFace: "",
    // 본인얼굴 base64
    // 외국인 주민번호
    // step3
    reciAddressMaster: "", // 수령인주소
    reciAddressDetail: "", // 수령인주소 상세
    firstNumber: "", // 1지망
    secondNumber: "", // 2지망
    company: "", // 제조자
    modeName: "", // 모델명
    accCell: "", // 가입자휴대폰번호
    accMail: "", // 가입자 메일
    reciName: "", // 수령인
    reciCell: "", // 수령인휴대폰번호
    reciMail: "", // 수령인메일
    reciZipcode: "", // 수령인 우편번호
    reciFullAddress: "", // 수령인 풀주소
    deliveryOption: [
      {
        delivery: "찾아가는 서비스",
        amount: 0,
        product_cd: 303,
        delivery_cd: 4,
      },
      {
        delivery: "직접 수령",
        amount: 0,
        product_cd: 306,
        delivery_cd: 6,
      },
    ], // 배송유형 X
    selectedDelivery: {
      delivery: "찾아가는 서비스",
      amount: 0,
      product_cd: 303,
      delivery_cd: 4,
    },
    // 배송유형 (1택배 2퀵 3두발히어로 4찾아가는방문)
    // 수령인주소 상세
    // arrQuick: arrQuick, // 퀵요금정보
    beforeAgency: "", // 현재통신사
    beforeMvnoAgency: "", // 현재 mvno 사업자
    beforePattern: "", // 유형
    beforeCell: "", // 현재휴대폰번호
    accAuthType: "", // 번호이동인증 방법
    acc4Num: "", // 인증 뒤4자리
    isAccEqualYn: false,
    // 가입자정보와 동일 체크 여부
    // 번호이동인증 뒷번호4자리(카드,휴대폰)

    // step4
    payOption: 4, // 신용카드 2: 계좌이체 3:카카오페이 4:가상계좌 5:편의점결제
    autoPayYn: true, // 자동결제 여부
    cardCom: "", // 카드사
    cardNum: "", // 카드번호
    cardValidityYear: "", // 카드유효기간 년
    cardValidityMonth: "", // 카드유효기간 월
    cardValidateYn: true, // 카드유효성체크 성공여부
    arrCardValidity: [], // 카드유효기간
    autoPayAgreeYn: false, // 자동결제 동의여부
    bank: "", // 은행
    accountNum: "", // 계좌
    orderId: "", // 주문번호
    encryptOrderId: "", // 주문번호 암호화
    isValidCheck: "", // 카드, 실시간 계좌 확인
    pgType: "", // pg사
    cno: "", // 거래번호
    bankName: "", // 가상계좌 은행명
    birth: "", // 생년월일
    maxDiscount: 0, //최대할인금액
    monthlyAmount: 0, // 월예약 납부금액
    virtualAccountNum: "", //영구 가상계좌번호
    virtualpaymentCompany: "", //영구 가상계좌 은행코드
    payKind: "1", //결제타입 (가입후 결제, 즉시결제)
    shop_cd: 2, //판매처
    browser: "", //브라우저 정보
    isDim: false, // dim 활성화
    googtrans: "/ko/ko",
    hsMember: "", //효성 멤버 가입 아이디
    accCell4Num: "", // 휴대폰 일련번호 4자리
    device: "", //디바이스
    turn: 1, //회차
    siteType: "미인증", //사이트유형
    req_turn: 1, //선택 회차
    cardUrl: "", //신분증이미지 URL
    faceUrl: "", // 본인사진 URL
    promotionCode:"",// 프로모션 코드(베트남)
    privacyYn:"N", //개인정보 동의
    serviceYn:"N",  //서비스약관 동의
    adYn:"N", //광고수신 동의
    subscYn:"N", //정기결제 동의
    identityYn:"N", //고유식별자 수집동의
    offerYn:"N", // 3자 제공의동의
    tructYn:"N", // 3자 위탁동의
    creditYn:"N", // 신용정보 조회 동의
  };
};

const orderStore = {
  namespaced: true,
  state: order(),
  getters: {
    totalPayPrice: (state) => {
      return (
        state.planAmount +
        state.usimAmount +
        state.extraAmount +
        state.quickAmount
      );
    },
  },
  actions: {
    ASYNC_MTS_MONTH_AMOUNT({ commit }, payload) {
      this.kakaoTrans(payload).then((res) => alert(res));
      commit("MTS_MONTH_AMOUNT", payload);
    },
  },

  mutations: {
    // 초기화
    MTS_ORDER_INIT(state) {
      state.isStep1 = false;
      state.isStep2 = false;
      state.isStep3 = false;
      state.isStep4 = false;
      state.member_id = "";
      state.currentPage = ""; // 현재페이지
      state.isAlien = false; // 외국인 여부
      state.activeTab = 1; // 내국인 외국인 탭
      state.joinType = 1; // 가입유형 (1 신규가입2 번호이동)
      state.selectedRate = {}; // 선택요금제
      state.arrRate = []; // 요금제
      state.selectedUsimType = {}; // 선택한 유심타입
      state.extraTab = 1; // 부가서비스 선택 탭
      state.selectedExtraServices = []; // 선택한 부가서비스
      state.clauseAllCheck = false; // 전체체크
      state.agreeService = false; // 서비스이용약관 동의
      state.agreePrivacyCollection = false; // 개인정보 수집동의
      state.agreePrivacyTran = false; // 개인정보 처리위탁 동의
      state.agreePrivacyAdvert = false; // 광고수신 동의
      state.isShowConfirm = false;
      // 신청내역 확인 팝업
      // arrUsim= usim // 유심타입 데이터
      state.monthAmount = 0; // 월기본요금
      state.extraAmount = 0; // 부가서비스
      state.usimAmount = 0; // 유심비
      state.quickAmount = 0; // 퀵서비스비용
      state.totalAmount = 0; // 총결제금액
      state.monthEstmAmount = 0; //월예상납부금액
      state.planAmount = 0; //플랜 금액

      // step2
      state.authType = 1; // 인증종류(신용카드 인증서)
      state.authYn = false; // 인증여부
      state.deliveryOption = [
        {
          delivery: "찾아가는 서비스",
          amount: 0,
          product_cd: 303,
          delivery_cd: 4,
        },
        {
          delivery: "직접 수령",
          amount: 0,
          product_cd: 306,
          delivery_cd: 6,
        },
      ]; // 배송유형 X
      state.selectedDelivery = {
        delivery: "찾아가는 서비스",
        amount: 0,
        product_cd: 303,
        delivery_cd: 4,
      }; // 배송유형
      state.activeTab = 1; // 활성화탭
      state.accName = ""; // 가입자명
      state.accCard = "1"; // 본인인증유형 (주민등록=1 운전면허=2)
      state.accNum = ""; // 주민번호
      state.accPassport = ""; // 여권번호
      state.accNumIssuedDate = ""; // 주민번호 발급일자
      state.accDriverNumFront = ""; // 면허번호 앞2자리
      state.accDriverNumEnd = ""; // 면허번호 나머지
      state.accDriverIssuedDate = ""; // 면허발급일
      state.agentPriCard = "1"; // 법정대리인 주민등록중|운전면허증
      state.agentName = ""; // 법정대리인명
      state.agentCell = ""; // 법정대리인 휴대폰
      state.agentNum = ""; // 법정대리인 주민번호
      state.accCountry = ""; // 외국인 선택국가
      state.agreeYn = false; // 법정대리인 동의 여부
      state.agentNumIssuedDate = ""; // 법정대리인 주민등록 발급일
      state.agentDriverIssuedDate = ""; // 법정대리인 면허발급일자
      state.agentDriverNumFront = ""; // 법정대리인 면허번호 앞2자리
      state.agentDriverNumEnd = ""; // 법정대리인 면허번호 나머지
      state.accInBoundNum = "";
      state.imageCard = ""; // 주민등록증 OR 운전면증 OR 여권 이미지 base64
      state.imageFace = "";
      // 본인얼굴 base64
      // 외국인 주민번호
      // step3
      state.reciAddressMaster = ""; // 수령인주소
      state.reciAddressDetail = ""; // 수령인주소 상세
      state.reciFullAddress = ""; // 풀주소
      state.firstNumber = ""; // 1지망
      state.secondNumber = ""; // 2지망
      state.company = ""; // 제조자
      state.modeName = ""; // 모델명
      state.accCell = ""; // 가입자휴대폰번호
      state.accMail = ""; // 가입자 메일
      state.reciName = ""; // 수령인
      state.reciCell = ""; // 수령인휴대폰번호
      state.reciMail = ""; // 수령인메일
      state.reciZipcode = "";
      // 수령인주소 상세
      // arrQuick= arrQuick // 퀵요금정보
      state.beforeAgency = ""; // 현재통신사
      state.beforeMvnoAgency = ""; // 현재 mvno 사업자
      state.beforePattern = ""; // 유형
      state.beforeCell = ""; // 현재휴대폰번호
      state.accAuthType = ""; // 번호이동인증 방법
      state.acc4Num = "";
      state.isAccEqualYn = false;
      // 가입자 정보와 동일
      // 번호이동인증 뒷번호4자리(카드휴대폰)
      // step4
      state.payOption = 1; // 신용카드 2= 계좌이체 3=카카오페이 4=가상계좌 5=편의점결제
      state.autoPayYn = true;
      // 자동결제 여부
      // arrPay= arrPay // 결제종류
      state.cardCom = ""; // 카드사
      state.cardNum = ""; // 카드번호
      state.cardValidityYear = ""; // 카드유효기간 년
      state.cardValidityMonth = ""; // 카드유효기간 월
      state.cardValidateYn = true; // 카드유효성체크 성공여부
      state.arrCardValidity = []; // 카드유효기간
      state.autoPayAgreeYn = false; // 자동결제 동의여부
      state.bank = ""; // 은행
      state.accountNum = ""; // 계좌
      state.orderId = ""; // 주문번호
      state.encryptOrderId = ""; // 주문번호 암호화
      state.isValidCheck = ""; // 카드 실시간 계좌 확인
      state.pgType = ""; // pg사코드
      state.cno = ""; // 거래번호
      state.bankName = ""; // 은행명
      state.birth = ""; // 생년월일
      state.maxDiscount = 0; //최대할인금액
      state.monthlyAmount = 0; // 월예약 납부금액
      state.virtualAccountNum = ""; // 영구 가상계좌번호
      state.virtualpaymentCompany = ""; // 영구 가상계좌 은행 코드
      state.payKind = 1; // 즉시결제 || 가입후 결제
      state.shop_cd = 2; //대리점
      state.browser = ""; //브라우저 정보
      state.isDim = "";
      state.hsMember = ""; //효성멤버
      state.accCell4Num = ""; //휴대폰인증번호 4자리
      state.device = ""; //디바이스 정보
      state.turn = 1;
      state.siteType = "미인증";
      state.req_turn = 1;
      state.cardUrl = ""; //신분증
      state.faceUrl = ""; //본인사진
      state.totalDiscountAmount = 0; //총할인금액
      state.autoDiscountAmount = 0; //자동결제할인
      state.longTermDiscountAmount = 0; //장기할인
      state.promotionCode = "";// 프로모션 코드(베트남)
      state.privacyYn = 'N'; //개인정보 동의
      state.serviceYn = 'N';  //서비스약관 동의
      state.adYn = 'N'; //광고수신 동의
      state.subscYn = 'N'; //정기결제 동의      
      state.identityYn = 'N'; //고유식별자 동의
      state.offerYn = 'N'; // 3자 제공동의
      state.tructYn = 'N';  // 3자 위탁동의
      state.creditYn = 'N'; // 개인신용정보 조회 동의
    },

    MTS_SET_ORDER(state, payload) {
      state.isStep1 = payload.isStep1;
      state.isStep2 = payload.isStep2;
      state.isStep3 = payload.isStep3;
      state.isStep4 = payload.isStep4;
      state.member_id = payload.member_id;
      state.currentPage = payload.currentPage;
      state.isAlien = payload.isAlien;
      state.activeTab = payload.activeTab;
      state.joinType = payload.joinType;
      state.selectedRate = payload.selectedRate;
      state.arrRate = payload.arrRate;
      state.selectedUsimType = payload.selectedUsimType;
      state.extraTab = payload.extraTab;
      state.selectedExtraServices = payload.selectedExtraServices;
      state.clauseAllCheck = payload.clauseAllCheck;
      state.agreeService = payload.agreeService;
      state.agreePrivacyCollection = payload.agreePrivacyCollection;
      state.agreePrivacyTran = payload.agreePrivacyTran;
      state.agreePrivacyAdvert = payload.agreePrivacyAdvert;
      state.isShowConfirm = payload.isShowConfirm;
      // 신청내역 확인 팝업
      // arrUsim= usim // 유심타입 데이터
      state.monthAmount = payload.monthAmount;
      state.extraAmount = payload.extraAmount;
      state.usimAmount = payload.usimAmount;
      state.quickAmount = payload.quickAmount;
      state.totalAmount = payload.totalAmount;
      state.monthEstmAmount = payload.monthEstmAmount;
      state.planAmount = payload.planAmount;
      // 월예상금액

      // step2
      state.authType = payload.authType;
      state.authYn = payload.authYn;
      state.deliveryOption = payload.deliveryOption; // X
      state.selectedDelivery = payload.selectedDelivery;
      state.activeTab = payload.activeTab;
      state.accName = payload.accName;
      state.accCard = payload.accCard;
      state.accNum = payload.accNum;
      state.accPassport = payload.accPassport;
      state.accNumIssuedDate = payload.accNumIssuedDate;
      state.accDriverNumFront = payload.accDriverNumFront;
      state.accDriverNumEnd = payload.accDriverNumEnd;
      state.accDriverIssuedDate = payload.accDriverIssuedDate;
      state.agentPriCard = payload.agentPriCard;
      state.agentName = payload.agentName;
      state.agentCell = payload.agentCell;
      state.agentNum = payload.agentNum;
      state.accCountry = payload.accCountry;
      state.agreeYn = payload.agreeYn;
      state.agentNumIssuedDate = payload.agentNumIssuedDate;
      state.agentDriverIssuedDate = payload.agentDriverIssuedDate;
      state.agentDriverNumFront = payload.agentDriverNumFront;
      state.agentDriverNumEnd = payload.agentDriverNumEnd;
      state.accInBoundNum = payload.accInBoundNum;
      state.imageCard = payload.imageCard;
      state.imageFace = payload.imageFace;
      // 본인얼굴 base64
      // 외국인 주민번호
      // step3
      state.reciAddressMaster = payload.reciAddressMaster;
      state.reciAddressDetail = payload.reciAddressDetail;
      state.reciFullAddress = payload.reciFullAddress;
      state.firstNumber = payload.firstNumber;
      state.secondNumber = payload.secondNumber;
      state.company = payload.company;
      state.modeName = payload.modeName;
      state.accCell = payload.accCell;
      state.accMail = payload.accMail;
      state.reciName = payload.reciName;
      state.reciCell = payload.reciCell;
      state.reciMail = payload.reciMail;
      state.reciZipcode = payload.reciZipcode;
      // 수령인주소 상세
      // arrQuick= arrQuick // 퀵요금정보
      state.beforeAgency = payload.beforeAgency;
      state.beforeMvnoAgency = payload.beforeMvnoAgency;
      state.beforePattern = payload.beforePattern;
      state.beforeCell = payload.beforeCell;
      state.accAuthType = payload.accAuthType;
      state.acc4Num = payload.acc4Num;
      state.isAccEqualYn = payload.isAccEqualYn;
      // 가입자 정보와 동일
      // 번호이동인증 뒷번호4자리(카드휴대폰)
      // step4
      state.payOption = payload.payOption;
      state.autoPayYn = payload.autoPayYn;
      // 자동결제 여부
      // arrPay= arrPay // 결제종류
      state.cardCom = payload.cardCom;
      state.cardNum = payload.cardNum;
      state.cardValidityYear = payload.cardValidityYear;
      state.cardValidityMonth = payload.cardValidityMonth;
      state.cardValidateYn = payload.cardValidateYn;
      state.arrCardValidity = payload.arrCardValidity;
      state.autoPayAgreeYn = payload.autoPayAgreeYn;
      state.bank = payload.bank;
      state.accountNum = payload.accountNum;
      state.orderId = payload.orderId;
      state.encryptOrderId = payload.encryptOrderId;
      state.isValidCheck = payload.isValidCheck;
      state.pgType = payload.pgType;
      state.cno = payload.cno;
      state.bankName = payload.bankName;
      state.birth = payload.birth;
      state.maxDiscount = payload.maxDiscount;
      state.monthlyAmount = payload.monthlyAmount;
      state.virtualAccountNum = payload.virtualAccountNum;
      state.virtualpaymentCompany = "";
      state.payKind = payload.payKind;
      state.shop_cd = 2;
      state.isDim = false;
      state.googtrans = "/ko/ko";
      state.hsMember = "";
      state.accCell4Num = payload.accCell4Num;
      state.device = "";
      state.turn = payload.turn;
      state.siteType = payload.siteType;
      state.req_turn = payload.req_turn;
      state.cardUrl = payload.cardUrl;
      state.faceUrl = payload.faceUrl;
      state.totalDiscountAmount = payload.totalDiscountAmount;
      state.autoDiscountAmount = payload.autoDiscountAmount;
      state.longTermDiscountAmount = payload.longTermDiscountAmount;
      state.promotionCode = payload.promotionCode;
      state.privacyYn = payload.privacyYn; //개인정보 동의
      state.serviceYn = payload.serviceYn;  //서비스약관 동의
      state.adYn = payload.adYn; //광고수신 동의
      state.subscYn = payload.subscYn; //정기결제 동의        
      state.identityYn = payload.identityYn; //고유식별자 동의
      state.offerYn = payload.offerYn; // 3자 제공동의
      state.tructYn = payload.tructYn;  // 3자 위탁동의
      state.creditYn = payload.creditYn; // 개인신용정보 조회 동의
    },

    MTS_STEP1(state, payload) {
      state.isStep1 = payload;
    },

    MTS_STEP2(state, payload) {
      state.isStep2 = payload;
    },

    MTS_STEP3(state, payload) {
      state.isStep3 = payload;
    },

    MTS_STEP4(state, payload) {
      state.isStep4 = payload;
    },

    // 로그인 아이디
    MTS_MEMBER_ID(state, payload) {
      state.member_id = payload;
    },

    // 현재페이지
    MTS_CURRENT_PAGE(state, payload) {
      state.currentPage = payload;
    },

    // 외국인 여부 (true:외국인, false:내국인)
    MTS_ALIEN(state, payload) {
      state.isAlien = payload;
    },

    // 월기본요금
    MTS_MONTH_AMOUNT(state, payload) {
      state.monthAmount = payload;
    },

    // 부가서비스
    MTS_EXTRA_AMOUNT(state, payload) {
      state.extraAmount = payload;
    },

    // 유심비
    MTS_USIM_AMOUNT(state, payload) {
      state.usimAmount = payload;
    },

    // 배송비
    MTS_QUICK_AMOUNT(state, payload) {
      state.quickAmount = payload;
    },

    // 총 결제금액
    MTS_TOTAL_AMOUNT(state, payload) {
      state.totalAmount = payload;
    },

    // 가입유형
    MTS_JOIN_TYPE(state, payload) {
      state.joinType = payload;
    },

    // 선택 플랜
    MTS_SELECTED_RATE(state, payload = {}) {
      state.selectedRate = payload;
    },

    // 유심타입
    MTS_USIM_TYPE(state, payload) {
      state.selectedUsimType = payload;
    },

    // 선택한 부가서비스
    MTS_SELECTED_EXTRA_SERVICE(state, payload) {
      state.selectedExtraServices = payload;
    },

    // 이용약관 동의
    MTS_SELECTED_AGREE(state, payload) {
      state.clauseAgree = payload;
    },

    // 전체체크
    MTS_AGREE_ALL(state, payload) {
      state.clauseAllCheck = payload;
    },

    // 서비스 이용동의
    MTS_AGREE_SERVICE(state, payload) {
      state.agreeService = payload;
    },

    // 개인정보 수집 동의
    MTS_AGREE_PRIVACY_COLLECTION(state, payload) {
      state.agreePrivacyCollection = payload;
    },

    // 개인정보 위탁처리 동의
    MTS_AGREE_PRIVACY_TRAN(state, payload) {
      state.agreePrivacyTran = payload;
    },

    // 광고 수신 동의
    MTS_AGREE_PRIVACY_ADVERT(state, payload) {
      state.agreePrivacyAdvert = payload;
    },

    // 가입자명
    MTS_ACC_NAME(state, payload) {
      state.accName = payload;
    },

    // 실명확인 (주민번호 OR 운전면허증) 선택 1 주민등록증 2 운전면허증 3 여권번호
    MTS_ACC_CARD(state, payload) {
      state.accCard = payload;
    },

    // 주민번호
    MTS_ACC_NUM(state, payload = "") {
      state.accNum = payload;
    },

    // 여권번호
    MTS_ACC_PASSPORT(state, payload = "") {
      state.accPassport = payload;
    },

    // 운전면허 번호
    MTS_ACC_DRIVER_NUM(state, payload = "") {
      if (payload.length >= 2) {
        state.accDriverNumFront = payload.substr(0, 2);
        state.accDriverNumEnd = payload.substr(2);
        return;
      }

      state.accDriverNumFront = "";
      state.accDriverNumEnd = "";
    },

    // 신분증이미지 BASE64
    MTS_ACC_CARD_IMAGE(state, payload) {
      state.imageCard = payload;
    },

    // 가입자 얼굴 BASE64
    MTS_ACC_FACE_IMAGE(state, payload) {
      state.imageFace = payload;
    },

    // 1지망
    MTS_FIRST_NUMBER(state, payload) {
      state.firstNumber = payload;
    },

    // 2지망
    MTS_SECOND_NUMBER(state, payload) {
      state.secondNumber = payload;
    },

    // 제조사
    MTS_COMPANY(state, payload) {
      state.company = payload;
    },

    // 모델명
    MTS_MODEL_NAME(state, payload) {
      state.modeName = payload;
    },

    // 가입자메일
    MTS_ACC_MAIL(state, payload) {
      state.accMail = payload;
    },

    // 가입자 휴대폰
    MTS_ACC_CELL(state, payload) {
      state.accCell = payload;
    },

    // 배송타입 X
    MTS_DELIVERY_OPTION(state, payload) {
      state.deliveryOption = payload;
    },

    // 배송타입
    MTS_SET_DELIVERY(state, payload) {
      state.selectedDelivery = payload;
    },

    // 수령인
    MTS_RECI_NAME(state, payload) {
      state.reciName = payload;
    },

    // 수령인 휴대폰번호
    MTS_RECI_CELL(state, payload) {
      state.reciCell = payload;
    },

    // 메일
    MTS_RECI_MAIL(state, payload) {
      state.reciMail = payload;
    },

    // 우편번호
    MTS_RECI_ZIPCODE(state, payload) {
      state.reciZipcode = payload;
    },

    // 기본주소
    MTS_RECI_ADDRESS1(state, payload) {
      state.reciAddressMaster = payload;
    },

    // 상세주소
    MTS_RECI_ADDRESS2(state, payload) {
      state.reciAddressDetail = payload;
    },

    // 풀주소
    MTS_RECI_ADDRESS_FULL(state, payload) {
      state.reciFullAddress = payload;
    },

    // 가입자 정보와 동일 체크
    MTS_ACC_EQUALS(state, payload) {
      state.isAccEqualYn = payload;
    },

    // 결제 종류
    MTS_PAY_OPTION(state, payload) {
      state.payOption = payload;
    },

    // 자동결제 서비스 신청유무
    MTS_AUTO_PAY(state, payload) {
      state.autoPayYn = payload;
    },

    // pg사
    MTS_PG(state, payload) {
      state.pgType = payload;
    },

    // 거래번호
    MTS_PG_CNO(state, payload) {
      state.cno = payload;
    },

    // 자동결제 동의 여부
    MTS_AUTO_PAY_AGREE(state, payload) {
      state.autoPayAgreeYn = payload;
    },

    // 신용카드번호
    MTS_CARD_NUM(state, payload) {
      state.cardNum = payload;
    },

    // 카드 유효기간 년
    MTS_CARD_YEAR(state, payload) {
      state.cardValidityYear = payload;
    },

    // 카드 유효기간 월
    MTS_CARD_MONTH(state, payload) {
      state.cardValidityMonth = payload;
    },

    // 은행코드
    MTS_BANK(state, payload) {
      state.bank = payload;
    },

    // 계좌번호
    MTS_BANK_ACCNO(state, payload) {
      state.accountNum = payload;
    },

    // 주문번호
    MTS_ORDER_NO(state, payload) {
      state.orderId = payload;
    },

    // 주문번호 암호화
    MTS_ENCRYPT_ORDER_NO(state, payload) {
      state.encryptOrderId = payload;
    },

    // 현재통신사
    MTS_BEFORE_AGENCY(state, payload) {
      state.beforeAgency = payload;
    },

    // 현재 mvno 사업자
    MTS_BEFORE_MVNO_AGENCY(state, payload) {
      state.beforeMvnoAgency = payload;
    },

    // 현재 mvno 사업자
    MTS_BEFORE_PATTERN(state, payload) {
      state.beforePattern = payload;
    },

    // 현재휴대폰번호
    MTS_BEFORE_CELL(state, payload) {
      state.beforeCell = payload;
    },

    // 번호이동인증방법
    MTS_ACC_AUTH_TYPE(state, payload) {
      state.accAuthType = payload;
    },

    // 인증번호 4자리
    MTS_ACC_AUTH_NUM(state, payload) {
      state.acc4Num = payload;
    },

    // 가상계좌 발급 받을 은행명
    MTS_BANK_NAME(state, payload) {
      state.bankName = payload;
    },

    // 생년월일
    MTS_BIRTH(state, payload = "") {
      state.birth = payload;
    },

    // 최대할인금액
    MTS_MAX_DISCOUNT(state, payload) {
      state.maxDiscount = payload;
    },

    //월예약상금
    MTS_MONTHLY_AMOUNT(state, payload) {
      state.monthlyAmount = payload;
    },

    //영구 가상계좌번호
    MTS_VISUAL_ACCOUNT(state, payload) {
      state.virtualAccountNum = payload;
    },

    //국적
    MTS_COUNTRY(state, payload) {
      state.accCountry = payload;
    },

    //영구 가상계좌 은행코드
    MTS_VIRTUAL_COMPANY(state, payload) {
      state.virtualpaymentCompany = payload;
    },

    // 즉시결제 || 가입후 결제
    MTS_PAY_KIND(state, payload) {
      state.payKind = payload;
    },

    // 브라우저 정보
    MTS_BROWSER(state, payload) {
      state.browser = payload;
    },

    //dim 처리여부
    MTS_DIM(state, payload) {
      state.isDim = payload;
    },

    //구글 번역
    MTS_GOOGLE_TRANS(state, payload) {
      state.googtrans = payload;
    },

    // 대리점코드 (미인증 : 2)
    MTS_SHOP_CD(state, payload) {
      state.shop_cd = payload;
    },

    // 효성 멤버 가입 아이디
    MTS_HS_MEMBER(state, payload) {
      state.hsMember = payload;
    },

    //휴대폰 일련번호
    MTS_CELL_AUTH_NUM(state, payload) {
      state.accCell4Num = payload;
    },

    //디바이스
    MTS_DEVICE(state, payload) {
      state.device = payload;
    },

    //내외국인 탭
    MTS_ACTIVE_TAB(state, payload) {
      state.activeTab = payload;
    },

    // 회차
    MTS_TURN(state, payload) {
      state.turn = payload;
    },

    //사이트 유형
    MTS_SITE_TYPE(state, payload) {
      state.siteType = payload;
    },

    // 선택 회차 (3회차까지 가능)
    MTS_REQ_TURN(state, payload) {
      state.req_turn = payload;
    },

    // 신분증이미지 URL
    MTS_CARD_URL(state, payload) {
      state.cardUrl = payload;
    },

    // 본인사진 URL
    MTS_FACE_URL(state, payload) {
      state.faceUrl = payload;
    },

    // 총 할인금액
    MTS_TOTAL_DISCOUNT(state, payload) {
      state.totalDiscountAmount = payload;
    },

    // 자동결제 할인
    MTS_AUTO_DISCOUNT(state, payload) {
      state.autoDiscountAmount = payload;
    },

    // 장기할인
    MTS_LONG_DISCOUNT(state, payload) {
      state.longTermDiscountAmount = payload;
    },

    // 플랜 금액
    MTS_PLAN_AMOUNT(state, payload) {
      state.planAmount = payload;
    },

    //프로모션 코드
    MTS_PROMOTION_CODE(state, payload){
      state.promotionCode = payload;
    },
    // 개인정보 약관 동의
    MTS_PRIVACY_YN(state, payload){
      state.privacyYn = payload;
    },
    
    // 서비스 약관 동의
    MTS_SERVICE_YN(state, payload){
      state.serviceYn = payload;
    },
    
    // 광고 수신 약관 동의
    MTS_AD_YN(state, payload){
      state.adYn = payload;
    },
    
    // 정기결제 약관 동의
    MTS_SUBSC_YN(state, payload){
      state.subscYn = payload;
    },  

    // 고유식별정보 수집동의
    MTS_IDENT_YN(state, payload){
      state.identityYn = payload;
    },  
    
    // 3자 제공 동의
    MTS_OFFER_YN(state, payload){
      state.offerYn = payload;
    },  
    
    // 3자 위탁동의
    MTS_TRUCT_YN(state, payload){
      state.tructYn = payload;
    },  
    
    // 개인정보 조회 동의
    MTS_CREDIT_YN(state, payload){
      state.creditYn = payload;
    },        
    
  },
};

export default orderStore;
