import {createNamespacedHelpers} from "vuex";
import * as Enum from "../js/enum";

const {
    mapState: orderMapState,
    mapMutations: orderMapMutation,
    mapGetters: orderMapGetters,
    mapActions: orderMapActions
} = createNamespacedHelpers("orderStore");

const {
    mapState: memberMapState,
    mapMutations: memberMapMutation,
    mapGetters: memberMapGetters
} = createNamespacedHelpers("memberStore");

const {
    mapState: memberJoinMapState,
    mapMutations: memberJoinMapMutation,
    mapGetters: memberJoinMapGetters
} = createNamespacedHelpers("memberJoinStore");

const {
    mapState: configMapState,
    mapMutations: configMapMutations,
    mapGetters: configGetters
} = createNamespacedHelpers("configStore");

export const orderMixin = {
    methods: {
        ...orderMapMutation([
            "MTS_SET_ORDER",
            "MTS_MONTH_AMOUNT",
            "MTS_EXTRA_AMOUNT",
            "MTS_USIM_AMOUNT",
            "MTS_QUICK_AMOUNT",
            "MTS_JOIN_TYPE",
            "MTS_SELECTED_RATE",
            "MTS_USIM_TYPE",
            "MTS_SELECTED_EXTRA_SERVICE",
            "MTS_SELECTED_AGREE",
            "MTS_AGREE_SERVICE",
            "MTS_AGREE_PRIVACY_COLLECTION",
            "MTS_AGREE_PRIVACY_TRAN",
            "MTS_AGREE_PRIVACY_ADVERT",
            "MTS_AGREE_ALL",
            "MTS_ACC_NAME",
            "MTS_ACC_CARD",
            "MTS_ACC_NUM",
            "MTS_ACC_DRIVER_NUM",
            "MTS_ACC_CARD_IMAGE",
            "MTS_ACC_FACE_IMAGE",
            "MTS_ACC_PASSPORT",
            "MTS_CURRENT_PAGE",
            "MTS_ALIEN",
            "MTS_FIRST_NUMBER",
            "MTS_SECOND_NUMBER",
            "MTS_COMPANY",
            "MTS_MODEL_NAME",
            "MTS_RECI_NAME",
            "MTS_RECI_CELL",
            "MTS_RECI_MAIL",
            "MTS_RECI_ZIPCODE",
            "MTS_RECI_ADDRESS1",
            "MTS_RECI_ADDRESS2",
            "MTS_ACC_MAIL",
            "MTS_ACC_CELL",
            "MTS_DELIVERY_OPTION",
            "MTS_SET_DELIVERY",
            "MTS_ACC_EQUALS",
            "MTS_ORDER_INIT",
            "MTS_PAY_OPTION",
            "MTS_AUTO_PAY",
            "MTS_PG",
            "MTS_PG_CNO",
            "MTS_AUTO_PAY_AGREE",
            "MTS_CARD_NUM",
            "MTS_CARD_YEAR",
            "MTS_CARD_MONTH",
            "MTS_BANK",
            "MTS_BANK_ACCNO",
            "MTS_ORDER_NO",
            "MTS_MEMBER_ID",
            "MTS_TOTAL_AMOUNT",
            "MTS_STEP4",
            "MTS_STEP3",
            "MTS_STEP2",
            "MTS_STEP1",
            "MTS_RECI_ADDRESS_FULL",
            "MTS_BEFORE_AGENCY",
            "MTS_BEFORE_MVNO_AGENCY",
            "MTS_BEFORE_PATTERN",
            "MTS_BEFORE_CELL",
            "MTS_ACC_AUTH_TYPE",
            "MTS_ACC_AUTH_NUM",
            "MTS_ENCRYPT_ORDER_NO",
            "MTS_BANK_NAME",
            "MTS_BIRTH",
            "MTS_MAX_DISCOUNT",
            "MTS_MONTHLY_AMOUNT",
            "MTS_VISUAL_ACCOUNT",
            "MTS_COUNTRY",
            "MTS_VIRTUAL_COMPANY",
            "MTS_PAY_KIND",
            "MTS_BROWSER",
            "MTS_DIM",
            "MTS_GOOGLE_TRANS",
            "MTS_SHOP_CD",
            "MTS_HS_MEMBER",
            "MTS_CELL_AUTH_NUM",
            "MTS_DEVICE",
            "MTS_ACTIVE_TAB",
            "MTS_TURN",
            "MTS_SITE_TYPE",
            "MTS_REQ_TURN",
            "MTS_CARD_URL",
            "MTS_FACE_URL",
            "MTS_TOTAL_DISCOUNT",
            "MTS_AUTO_DISCOUNT",
            "MTS_LONG_DISCOUNT",
            "MTS_PLAN_AMOUNT",
            "MTS_PROMOTION_CODE",
            "MTS_PRIVACY_YN",
            "MTS_SERVICE_YN",
            "MTS_AD_YN",
            "MTS_SUBSC_YN",            
            "MTS_IDENT_YN", 
            "MTS_OFFER_YN", 
            "MTS_TRUCT_YN", 
            "MTS_CREDIT_YN", 
        ]),
        ...orderMapActions({
            ASYNC_MTS_MONTH_AMOUNT: "ASYNC_MTS_MONTH_AMOUNT"
        })
    },

    computed: {
        ...orderMapGetters({
            totalPayPrice: "totalPayPrice"
        }),

        ...orderMapState({
            OrderDataSet: state => state,
            orderState: state => state,
            repo: state => state,
        }),

        /*
         * VUEX 프로퍼티 선언 ..
         */

        joinType: {
            get() {
                return this.OrderDataSet.joinType;
            },
            set(value) {
                this.MTS_JOIN_TYPE(value);
            }
        },
        selectedRate: {
            get() {
                return this.OrderDataSet.selectedRate;
            },
            set(value) {
                this.MTS_SELECTED_RATE(value);
            }
        },

        selectedUsimType: {
            get() {
                return this.OrderDataSet.selectedUsimType;
            },
            set(value) {
                this.MTS_USIM_AMOUNT(value.sim_amount);
                this.MTS_USIM_TYPE(value);
            }
        },

        selectedExtraServices: {
            get() {
                return this.OrderDataSet.selectedExtraServices;
            },
            set(value) {
                this.MTS_SELECTED_EXTRA_SERVICE(value);
                const sum = this.OrderDataSet.selectedExtraServices.reduce(
                    (sum, obj) => {
                        return sum + obj.amount;
                    },
                    0
                );
                this.MTS_EXTRA_AMOUNT(sum);
            }
        },
        agreeService: {
            get() {
                return this.OrderDataSet.agreeService;
            },
            set(value) {
                this.MTS_AGREE_SERVICE(value);
                this.MTS_SERVICE_YN(value ? 'Y': 'N');
            }
        },

        agreePrivacyCollection: {
            get() {
                return this.OrderDataSet.agreePrivacyCollection;
            },
            set(value) {
                this.MTS_AGREE_PRIVACY_COLLECTION(value);
                this.MTS_PRIVACY_YN(value ? 'Y': 'N');
            }
        },

        agreePrivacyTran: {
            get() {
                return this.OrderDataSet.agreePrivacyTran;
            },
            set(value) {
                this.MTS_AGREE_PRIVACY_TRAN(value);
            }
        },

        agreePrivacyAdvert: {
            get() {
                return this.OrderDataSet.agreePrivacyAdvert;
            },
            set(value) {
                this.MTS_AGREE_PRIVACY_ADVERT(value);
                this.MTS_AD_YN(value ? 'Y': 'N');
            }
        },

        clauseAllCheck: {
            get() {
                return this.OrderDataSet.clauseAllCheck;
            },
            set(value) {
                this.MTS_AGREE_ALL(value);
            }
        },

        // 가입자명
        accName: {
            get() {
                return this.OrderDataSet.accName;
            },
            set(value) {
                this.MTS_ACC_NAME(value);
            }
        },

        // 실명확인 (주민등록증 OR 운전면허증)
        accCard: {
            get() {
                return this.OrderDataSet.accCard;
            },
            set(value) {
                this.MTS_ACC_CARD(value);
            }
        },

        // 주민등록번호
        accNum: {
            get() {
                return this.OrderDataSet.accNum;
            },
            set(value) {
                this.MTS_ACC_NUM(value);
                this.MTS_BIRTH(value.substring(0, 6));
            }
        },

        //여권번호
        accPassport: {
            get() {
                if (this.accCard === Enum.identity.ALIEN_REGIST_CARD) {
                    return this.OrderDataSet.accNum;
                }
                return this.OrderDataSet.accPassport;
            },
            set(value) {
                //외국인 등록증이면 주민번로 필드로 입력을 받는다.
                if (this.accCard === Enum.identity.ALIEN_REGIST_CARD) {
                    this.MTS_ACC_NUM(value);
                } else {
                    this.MTS_ACC_PASSPORT(value);
                }
            }
        },

        // 신분증 사진
        imageCard: {
            get() {
                return this.OrderDataSet.imageCard;
            },
            set(value) {
                this.MTS_ACC_CARD_IMAGE(value);
            }
        },

        // 얼굴 사진
        imageFace: {
            get() {
                return this.OrderDataSet.imageFace;
            },
            set(value) {
                this.MTS_ACC_FACE_IMAGE(value);
            }
        },

        //가입자메일
        accMail: {
            get() {
                return this.OrderDataSet.accMail;
            },
            set(value) {
                this.MTS_ACC_MAIL(value);
            }
        },

        //가입자 휴대폰
        accCell: {
            get() {
                return this.OrderDataSet.accCell;
            },
            set(value) {
                this.MTS_ACC_CELL(value);
            }
        },

        //1지망
        firstNumber: {
            get() {
                return this.OrderDataSet.firstNumber;
            },
            set(value) {
                this.MTS_FIRST_NUMBER(value);
            }
        },

        //2지망
        secondNumber: {
            get() {
                return this.OrderDataSet.secondNumber;
            },
            set(value) {
                this.MTS_SECOND_NUMBER(value);
            }
        },

        //제조사
        company: {
            get() {
                return this.OrderDataSet.company;
            },
            set(value) {
                this.MTS_COMPANY(value);
            }
        },

        //휴대폰모델명
        modeName: {
            get() {
                return this.OrderDataSet.modeName;
            },
            set(value) {
                this.MTS_MODEL_NAME(value);
            }
        },

        //수령인명
        reciName: {
            get() {
                return this.OrderDataSet.reciName;
            },
            set(value) {
                this.MTS_RECI_NAME(value);
            }
        },

        //수령일휴대폰
        reciCell: {
            get() {
                return this.OrderDataSet.reciCell;
            },
            set(value) {
                this.MTS_RECI_CELL(value);
            }
        },

        // 수령인메일
        reciMail: {
            get() {
                return this.OrderDataSet.reciMail;
            },
            set(value) {
                this.MTS_RECI_MAIL(value);
            }
        },

        //수령인 우편번호
        reciZipcode: {
            get() {
                return this.OrderDataSet.reciZipcode;
            },
            set(value) {
                this.MTS_RECI_ZIPCODE(value);
            }
        },

        //수령인 주소기본
        reciAddressMaster: {
            get() {
                return this.OrderDataSet.reciAddressMaster;
            },
            set(value) {
                this.MTS_RECI_ADDRESS1(value);
            }
        },

        //수령인 주소상세
        reciAddressDetail: {
            get() {
                return this.OrderDataSet.reciAddressDetail;
            },
            set(value) {
                this.MTS_RECI_ADDRESS2(value);
            }
        },

        //배송방법
        deliveryOption: {
            get() {
                return this.OrderDataSet.deliveryOption;
            },
            set(value) {
                this.MTS_DELIVERY_OPTION(value);
            }
        },

        //배송방법
        selectedDelivery: {
            get() {
                return this.OrderDataSet.selectedDelivery;
            },
            set(value) {
                this.MTS_SET_DELIVERY(value);
            }
        },

        //가입자정보와 동일 체크
        isAccEqualYn: {
            get() {
                return this.OrderDataSet.isAccEqualYn;
            },
            set(value) {
                this.MTS_ACC_EQUALS(value);
            }
        },

        // 결제 종류
        payOption: {
            get() {
                return this.OrderDataSet.payOption;
            },
            set(value) {
                this.pgType = value;

                if (value === 1) {
                    this.pgType = 1;
                }

                this.cardType = "";

                this.MTS_PAY_OPTION(value);
            }
        },

        //자동결제 신청 유무
        autoPayYn: {
            get() {
                return this.OrderDataSet.autoPayYn;
            },
            set(value) {
                this.MTS_AUTO_PAY(value);
            }
        },

        //pg사
        pgType: {
            get() {
                return this.OrderDataSet.pgType;
            },
            set(value) {
                this.MTS_PG(value);
            }
        },

        //거래번호
        cno: {
            get() {
                return this.OrderDataSet.cno;
            },
            set(value) {
                this.MTS_PG_CNO(value);
            }
        },

        // 자동결제 인출 동의 여부
        autoPayAgreeYn: {
            get() {
                return this.OrderDataSet.autoPayAgreeYn;
            },
            set(value) {
                this.MTS_AUTO_PAY_AGREE(value);
                this.MTS_SUBSC_YN(value ? 'Y':'N')
            }
        },

        //은행코드
        bank: {
            get() {
                return this.OrderDataSet.bank;
            },
            set(value) {
                this.MTS_BANK(value);
            }
        },

        //계좌번호
        accountNum: {
            get() {
                return this.OrderDataSet.accountNum;
            },
            set(value) {
                this.MTS_BANK_ACCNO(value);
            }
        },

        //카드 유효기간 년
        cardValidityYear: {
            get() {
                return this.OrderDataSet.cardValidityYear;
            },
            set(value) {
                this.MTS_CARD_YEAR(value);
            }
        },

        //카드 유효기간 월
        cardValidityMonth: {
            get() {
                return this.OrderDataSet.cardValidityMonth;
            },
            set(value) {
                this.MTS_CARD_MONTH(value);
            }
        },

        //카드 번호
        cardNum: {
            get() {
                return this.OrderDataSet.cardNum;
            },
            set(value) {
                this.MTS_CARD_NUM(value);
            }
        },

        isStep3: {
            get() {
                return this.OrderDataSet.isStep3;
            },
            set(value) {
                this.MTS_STEP3(value);
            }
        },

        isStep2: {
            get() {
                return this.OrderDataSet.isStep2;
            },
            set(value) {
                this.MTS_STEP2(value);
            }
        },

        isStep1: {
            get() {
                return this.OrderDataSet.isStep1;
            },
            set(value) {
                this.MTS_STEP1(value);
            }
        },

        // 이전 통신사
        beforeAgency: {
            get() {
                return this.OrderDataSet.beforeAgency;
            },
            set(value) {
                this.MTS_BEFORE_AGENCY(value);
            }
        },

        // 이전 MVNO 사업자
        beforeMvnoAgency: {
            get() {
                return this.OrderDataSet.beforeMvnoAgency;
            },
            set(value) {
                this.MTS_BEFORE_MVNO_AGENCY(value);
            }
        },

        // 이전 폰 (스멜)
        beforePattern: {
            get() {
                return this.OrderDataSet.beforePattern;
            },
            set(value) {
                this.MTS_BEFORE_PATTERN(value);
            }
        },

        // 이전번호
        beforeCell: {
            get() {
                return this.OrderDataSet.beforeCell;
            },
            set(value) {
                this.MTS_BEFORE_CELL(value);
            }
        },

        //번호이동 인증종류
        accAuthType: {
            get() {
                return this.OrderDataSet.accAuthType;
            },
            set(value) {
                this.MTS_ACC_AUTH_TYPE(value);
            }
        },

        // 번호이동 인증번호 4자리
        acc4Num: {
            get() {
                return this.OrderDataSet.acc4Num;
            },
            set(value) {
                this.MTS_ACC_AUTH_NUM(value);
            }
        },

        //주문번호
        orderId: {
            get() {
                return this.OrderDataSet.orderId;
            },
            set(value) {
                this.MTS_ORDER_NO(value);
            }
        },

        //주문번호
        encryptOrderId: {
            get() {
                return this.OrderDataSet.encryptOrderId;
            },
            set(value) {
                this.MTS_ENCRYPT_ORDER_NO(value);
            }
        },

        //월예상금액
        monthAmount: {
            get() {
                return this.OrderDataSet.monthAmount;
            },
            set(value) {
                this.MTS_MONTH_AMOUNT(value);
            }
        },

        //은행명
        bankName: {
            get() {
                return this.OrderDataSet.bankName;
            },
            set(value) {
                this.MTS_BANK_NAME(value);
            }
        },

        //생년월일 6자리
        birth: {
            get() {
                return this.OrderDataSet.birth;
            },
            set(value) {
                this.MTS_BIRTH(value);
            }
        },

        // 최대할인 금액
        maxDiscount: {
            get() {
                return this.OrderDataSet.maxDiscount;
            },
            set(value) {
                this.MTS_MAX_DISCOUNT(value);
            }
        },

        // 예상납부금액
        monthlyAmount: {
            get() {
                return this.OrderDataSet.monthlyAmount;
            },
            set(value) {
                this.MTS_MONTHLY_AMOUNT(value);
            }
        },

        // 영구 가상계좌
        virtualAccountNum: {
            get() {
                return this.OrderDataSet.virtualAccountNum;
            },
            set(value) {
                this.MTS_VISUAL_ACCOUNT(value);
            }
        },

        // 국적
        accCountry: {
            get() {
                return this.OrderDataSet.accCountry;
            },
            set(value) {
                this.MTS_COUNTRY(value);
            }
        },

        // 영구 가상계좌 은행 코드
        virtualpaymentCompany: {
            get() {
                return this.OrderDataSet.virtualpaymentCompany;
            },
            set(value) {
                this.MTS_VIRTUAL_COMPANY(value);
            }
        },

        payKind: {
            get() {
                return this.OrderDataSet.payKind;
            },
            set(value) {
                this.MTS_PAY_KIND(value);
            }
        },

        // 브라우저 정보
        browser: {
            get() {
                return this.OrderDataSet.browser;
            },
            set(value) {
                this.MTS_BROWSER(value);
            }
        },

        // 페이드 여부
        isDim: {
            get() {
                return this.OrderDataSet.isDim;
            },
            set(value) {
                this.MTS_DIM(value);
            }
        },

        // 구글번역 언어설정
        googtrans: {
            get() {
                return this.OrderDataSet.googtrans;
            },
            set(value) {
                this.MTS_GOOGLE_TRANS(value);
            }
        },

        // 대리점 코드
        shop_cd: {
            get() {
                return this.OrderDataSet.shop_cd;
            },
            set(value) {
                this.MTS_SHOP_CD(value);
            }
        },

        // 효성 멤버 가입 아이디
        hsMember: {
            get() {
                return this.OrderDataSet.hsMember;
            },
            set(value) {
                this.MTS_HS_MEMBER(value);
            }
        },

        // 휴대폰 일련번호 4자리
        accCell4Num: {
            get() {
                return this.OrderDataSet.accCell4Num;
            },
            set(value) {
                this.MTS_CELL_AUTH_NUM(value);
            }
        },

        //디바이스
        device: {
            get() {
                return this.OrderDataSet.device;
            },
            set(value) {
                this.MTS_DEVICE(value);
            }
        },

        //내외국인 선택 탭
        activeTab: {
            get() {
                return this.OrderDataSet.activeTab;
            },
            set(value) {
                this.MTS_ACTIVE_TAB(value);
            }
        },

        //회차
        turn: {
            get() {
                return this.OrderDataSet.turn;
            },
            set(value) {
                this.MTS_TURN(value);
            }
        },

        //사이트유형
        siteType: {
            get() {
                return state.siteType;
            },
            set(value) {
                this.MTS_SITE_TYPE(value);
            }
        },

        // 신청 회차
        req_turn: {
            get() {
                return this.OrderDataSet.req_turn
            },
            set(value) {
                this.MTS_REQ_TURN(value)
            }
        },

        totalDiscountAmount:{
            get(){
                return this.OrderDataSet.totalDiscountAmount
            },
            set(value){
                this.MTS_TOTAL_DISCOUNT(value)
            }

        },

        autoDiscountAmount:{
            get(){
                return this.OrderDataSet.autoDiscountAmount
            },
            set(value){
                this.MTS_AUTO_DISCOUNT(value)
            }

        },
        
        longTermDiscountAmount:{
            get(){
                return this.OrderDataSet.longTermDiscountAmount
            },
            set(value){
                this.MTS_LONG_DISCOUNT(value)
            }

        }, 

        planAmount:{
            get(){
                return this.OrderDataSet.planAmount
            },
            set(value){
                this.MTS_PLAN_AMOUNT(value)
            }
        },

        promotionCode:{
            get(){
                return this.OrderDataSet.promotionCode
            },
            set(value){
                this.MTS_PROMOTION_CODE(value);
            }
        },

        privacyYn:{
            get(){
                return this.OrderDataSet.privacyYn == 'Y' ? true : false
            },
            set(value){
                this.MTS_PRIVACY_YN(value ? 'Y':'N');
            }
        },

        serviceYn:{
            get(){
                return this.OrderDataSet.serviceYn == 'Y' ? true : false
            },
            set(value){
                this.MTS_SERVICE_YN(value ? 'Y':'N');
            }
        },        

        adYn:{
            get(){
                return this.OrderDataSet.adYn == 'Y' ? true : false
            },
            set(value){
                this.MTS_AD_YN(value ? 'Y':'N');
            }
        },
        
        subscYn:{
            get(){
                return this.OrderDataSet.subscYn == 'Y' ? true : false
            },
            set(value){
                this.MTS_SUBSC_YN(value ? 'Y':'N');
            }
        },
        
        identityYn:{
            get(){
                return this.OrderDataSet.identityYn == 'Y' ? true : false
            },
            set(value){
                this.MTS_IDENT_YN(value ? 'Y':'N');
            }
        },
        
        offerYn:{
            get(){
                return this.OrderDataSet.offerYn == 'Y' ? true : false
            },
            set(value){
                this.MTS_OFFER_YN(value ? 'Y':'N');
            }
        },
        
        tructYn:{
            get(){
                return this.OrderDataSet.tructYn == 'Y' ? true : false
            },
            set(value){
                this.MTS_TRUCT_YN(value ? 'Y':'N');
            }
        },
        
        creditYn:{
            get(){
                return this.OrderDataSet.creditYn == 'Y' ? true : false
            },
            set(value){
                this.MTS_CREDIT_YN(value ? 'Y':'N');
            }
        },        
    }
};

export const memberMixin = {
    methods: {
        ...memberMapMutation({
            MTS_SET_LOGOUT: "MTS_SET_LOGOUT",
            MTS_SET_LOGIN: "MTS_SET_LOGIN",
            MTS_SET_MEMBER_JOIN: "MTS_SET_MEMBER_JOIN"
        })
    },

    computed: {
        ...memberMapGetters({}),

        ...memberMapState({
            MemberDataSet: state => state
        })
    }
};

export const memberJoinMixin = {
    methods: {
        ...memberJoinMapMutation({
            MTS_SET_MEMBER_JOIN: "MTS_SET_MEMBER_JOIN"
        })
    },

    computed: {
        ...memberJoinMapGetters({}),

        ...memberJoinMapState({
            MemberJoinDataSet: state => state
        })
    }
};

export const configMixin = {
    computed: {
        ...configMapState({
            configStore: state => state
        }),

        isMainSite: {
            get() {
                return this.configStore.isMainSite;
            },
            set(value) {
                this.MTS_IS_MAIN(value);
            }
        }
    },

    methods: {
        ...configMapMutations({
            MTS_IS_MAIN: "MTS_IS_MAIN"
        })
    }
};
