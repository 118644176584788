import { dosirak } from "./config";

dosirak.interceptors.request.use(function (config) {
    // 요청 전에 로딩 오버레이 띄우기
    return config;
}, function (error) {
    // 에라 나면 로딩 끄기
    return Promise.reject(error);
    });

// 응답 인터셉터
dosirak.interceptors.response.use(function (response) {
    // 응답 받으면 로딩 끄기
    return response;
}, function (error) {
    // 응답 에러 시에도 로딩 끄기
    return Promise.reject(error);
});


// 요금제 조회
export function fetchPlan() {
    return dosirak.post('/mvno/common/planInfoList')
}

// 주문
export function fetchOrder(payload) {
    return dosirak.post('/mvno/reservation/order', payload)
}

// 부가서비스 조회
export function fetchExtraService() {
    return dosirak.post('/mvno/common/planExtraserviceList')
}

//메인화면 데이터 조회
export function main() {
    const extra = dosirak.post(`${dosirak.defaults.baseURL}/mvno/common/planExtraserviceList`);
    const plan = dosirak.post(`${dosirak.defaults.baseURL}/mvno/common/planInfoList`);
    return axios.all([extra, plan]);
}

export function test(){
    return dosirak.get('https://reqres.in/api/users?delay=20')
}