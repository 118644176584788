/*
    ----------------------------------------
    ** 상품 API
    ----------------------------------------
*/

import { dosirak, axiosAll, pay, global } from "./config";

export function dosirakBaseUrl(){
    return dosirak.defaults.baseURL
}

export function payBaseUrl(){
    return pay.defaults.baseURL
}

export function globalBaseUrl(){
    return global.defaults.baseURL
}

//pg 사
export function van(){
    return dosirak.post('/mvno/common/pay')
}

// 결제 모듈정보 조회
export function fetchPay() {
    const van       = dosirak.post(`${dosirak.defaults.baseURL}/mvno/common/pay`)
    const bank      = dosirak.post(`${dosirak.defaults.baseURL}/mvno/common/bank`)

    return axiosAll([van, bank])    
}

// 주문번호 생성
export function fetchOrderNo(){
    return dosirak.get('/mvno/reservation/getOrderId')
}

// 효성 멤버 생성
export function fetchHyosungMember(){
    return dosirak.get('/mvno/reservation/hs/member')
}

//예약
export function order(payload){
    return dosirak.post('/mvno/reservation/order', payload)
}

//신용카드 인증
export function creditAuth(payload){
    return pay.post('/api/Hyosung/v1/custs/check/card/verify-payer-number', payload)
}

// 계좌인증
export function accountAuth(payload){
    return pay.post('/api/Hyosung/v1/custs/check/account/verify-payer-number', payload)
}

// 은행코드
export function fetchBank(){
    return dosirak.post('/mvno/common/bank')
}

//효성 신용카드 가입
export function putHyosungMember(payload){
    return pay.post('/api/Hyosung/members', payload)
}

//효성 카드 결제
export function payCreditHyosung(payload){
    return pay.post('/api/Hyosung/v1/payments/card', payload)
}

//거래번호 (효성, 쿠콘)
export function payTransactNo(){
    return dosirak.get('/mvno/reservation/getCno')
}

//텔레센트로 가상계좌 요청
export function VisualAccountCreate(payload){
    return dosirak.post('/mvno/telecentro/VirtualAccountRequest', payload)
}

// 이미지 업로드
export function putImageUpload(payload){
    return dosirak.post('/mvno/reservation/blob/privacy', payload)
}

//카카오페이
export function payKakao(payload){
    return pay.post('/kakaopay/mvnoready', payload)
}

// 즉시 계좌이체
export function payHyosungAccount(payload){
    return pay.post('/api/Hyosung/v1/payments/realcms', payload)
}


//주문번호 / 거래번호 / 효성멤버키 생성
export function fetchPayOrderData(payload){
    const order = dosirak.get(`${dosirak.defaults.baseURL}/mvno/reservation/getOrderId`)
    const cno = dosirak.get(`${dosirak.defaults.baseURL}/mvno/reservation/getCno`)
    return axiosAll([order, cno])
}

// 계좌이체 동의서 업로드
export function putHyosungDocUpload(payload){
    return pay.post('/api/Hyosung/v1/custs/agreements/upload', payload)
}