import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import Plugin from './plugins/global.js'
import VueCryptojs from "vue-cryptojs";
import VeeValidate, {Validator} from "vee-validate";
import VCalendar from "v-calendar";
import moment from "vue-moment"
import "./css/common.css";
import "./css/sub-style.css";
import "./css/mo-style.css";
import "@babel/polyfill";
import VueGtag from 'vue-gtag'
import Meta from 'vue-meta'
import LoadScript from 'vue-plugin-load-script';
import VueI18n from 'vue-i18n'
import translate from './locales/dic'
import NProgress from "vue-nprogress"

/* ----------------------------
 // vee validate 설정
-------------------------------*/
// 한글
const KoConfig = { // locale: ko,
  dictionary: {
    en: {
      attributes: {
        member_name: '이름',
        member_pass: '비밀번호',
        member_pass_confirm: '비밀번호',
        mail_account: "메일",
        mail_domain: "메일 형식",
        password: "비밀번호",
        member_cell: "휴대폰번호",
        member_birthday: "생년월일",
        member_login_id: "아이디",
        member_login_pass: "비밀번호",
        member_login_auth_no: "인증번호",
        member_login_cell: "개통번호",
        member_login_name: "사용자명",
        member_order: "주문번호",
        member_new_cell_auth_no: "인증번호",
        member_new_cell: "신규 휴대폰번호",
        passNow: "현재 비밀번호",
        passFirst: "신규 비밀번호",
        passSecond: "신규 비밀번호",
        accPassport: "여권번호",
        accNum: "주민등록번호",
        accNumAlien: "신분증번호",
        firstNumber: "1지망 번호",
        secondNumber: "2지망 번호",
        company: "제조사",
        modeName: "휴대폰 모델명",
        accName: "가입자명",
        accMail: "메일",
        reciZipcode: "우편번호",
        reciAddressMaster: "주소",
        reciAddressDetail: "상세주소",
        reciName: "수령인명",
        reciCell: "수령인 휴대폰번호",
        cardType: "카드타입",
        creditBirthday: "생년월일",
        accountBirthday: "생년월일",
        accountNum: "계좌번호",
        card_no: "카드번호",
        card_year: "유효기간(년)",
        card_month: "유효기간(월)",
        beforeAgency: "현재 통신사",
        beforeCell: "현재 휴대폰번호",
        accAuthType: "인증방법",
        acc4Num: "인증번호",
        accCell: "가입자 휴대폰번호",
        bank: "은행",
        accountName: "예금주명",
        bank_number: "계좌번호",
        reason: "취소사유",
        beforeMvnoAgency: "현재 알뜰폰 사업자",
        beforePattern: "유형",
        accCountry: "국적",
        reciMail: "이메일",
        accCell4Num:"휴대폰 일련번호"


      },
      messages: {
        required: (field) => `${field} 항목은 필수 정보입니다.`,
        alpha_num: (field) => `${field} 항목은 영문자와 숫자만 사용 가능합니다`,
        confirmed: (field) => `${field}가 일치하지 않습니다.`,
        digits: (field) => `${field} 항목은 숫자만 입력가능합니다.`,
        min: (field, length) => `${field} 항목은 ${length}자리 이상이여야 합니다.`,
        email: (field) => `${field}이 잘못되었습니다.`,
        numeric: (field) => `${field} 항목은 숫자만 입력가능합니다.`,
        length: (field, length) => `${field} 항목은 ${length}자리로 입력하세요.`
      }
    }
  },
  events: 'blur'
}

// 기타언어
const UsConfig = {
  dictionary: {
    en: {
      attributes: {
        member_name: 'Name',
        member_pass: 'Password',
        member_pass_confirm: 'Password',
        mail_account: "Email",
        mail_domain: "Email",
        password: "Password",
        member_cell: "Cellphone",
        member_id: "ID"

      }
    }
  },
  events: 'blur'
}

// KoConfig.dictionary.en.attributes.member_name = '테스트'
// Validator.localize('ko', dict)

Vue.use(VeeValidate, KoConfig)
Vue.config.productionTip = false
Vue.use(VueCookies)
Vue.use(Plugin)
Vue.use(VueCryptojs)
Vue.use(VCalendar)
Vue.use(moment)
Vue.use(VueGtag, {
  config: {
    id: 'G-3YL2VZ825H'
  },
  includes: [
    {
      id: 'AW-396164207'
    },
  ]
})
Vue.use(Meta)
Vue.use(LoadScript)
Vue.use(VueI18n)
Vue.use(NProgress)

const nprogress = new NProgress();

const i18n = new VueI18n({
  locale:'ko',
  messages:translate,
  // silentTranslationWarn: process.env.NODE_ENV === 'production'
})

new Vue({
  router,
  store,
  i18n,
  nprogress,
  render: h => h(App)
}).$mount('#app')
