<template>
  <div clas="dim">

  </div>
</template>

<script>
import {
  orderCommit,
  deleteTempData,
} from "../../api/order";
import { putImageUpload } from "../../api/pay";
import { orderMixin } from "../../mixin/storeMixin";
import * as Enum from "../../js/enum";

export default {
  mixins: [orderMixin],
  methods: {
    async imageUpload() {
      const res = await putImageUpload({
        imageCard: sessionStorage.getItem("imageCard"),
        imageFace: sessionStorage.getItem("imageFace"),
        order_id: this.orderId,
      });
    },

    //주문
    async orderCommit() {
      try {
        const res = await orderCommit({
          order_id: this.$route.query.order,
        });

        if (res.status === 200) {
          if (res.data.ResultCode) {
            await this.imageUpload();
            await this.$router.push({
              name: "CompletedView",
              query: {
                order: encodeURIComponent(
                    this.OrderDataSet.encryptOrderId
                ),
                amt: this.aesEncrypt(
                    this.OrderDataSet.totalAmount.toString(),
                    "user_amt"
                ),
                oid: this.OrderDataSet.orderId
              },
            });

            this.MTS_ORDER_INIT();
            sessionStorage.removeItem("imageCard");
            sessionStorage.removeItem("imageFace");

            let key = "";

            if (
              this.memberInfo.member_login_type === Enum.loginType.EMAIL ||
              this.memberInfo.member_login_type === Enum.loginType.SNS
            ) {
              key = this.memberInfo.member_seq;
            } else {
              key = this.memberInfo.member_mvno.celnumber;
            }

            if (key) {
              await deleteTempData({
                key: key,
              });

              localStorage.removeItem(key);
            }

            return;
          }
        } else {
          throw "예약 실패";
        }
      } catch (error) {
        alert(error);
      }
    }
  },
  mounted(){

      const msg = this.$route.query.msg

      if (msg === null || msg == "" || msg == undefined) {
        this.orderCommit();
        return;
      }

      this.kakaoTrans(msg).then((res) => alert(res));
      this.$router.replace({name:'Step4View'})
      return;
  },

}
</script>

<style scoped>
.dim {
  width:100%;
  height:100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top:0;
  left:0;
}
</style>