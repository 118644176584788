import Vue from "vue";
import Vuex from "vuex";
import memberStore from "./memberStore";
import orderStore from "./orderStore";
import memberJoinStore from "./memberJoinStore";
import configStore from "./configStore";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      key: "member",
      paths: ["memberStore"],
    }),
    createPersistedState({
      storage: window.sessionStorage,
      key: "order",
      paths: ["orderStore"],
    }),
    createPersistedState({
      storage: window.sessionStorage,
      key: "config",
      paths: ["configStore"],
    }),
  ],
  modules: {
    memberStore,
    orderStore,
    memberJoinStore,
    configStore,
  },
  strict: true,
});
