<template>
  <div>
    <div class="subTitAr">
      <h1 class='notranslate'>{{$t('trans.t21_01')}}</h1>
      <span class="subTit fW-L notranslate">{{$t('trans.t21_02')}}</span>
    </div>
    <div class="subPageAr">
      <!-- 약관 Tab -->
      <div class="termsTabAr">
        <ul class="termsTab">

          <li :class="['termsTab-link fW-R notranslate', {current: tabNo === 0}]"
              @click="tabNo = 0"
              data-tab="termsOpt20"
              data-index="20">{{$t('trans.t21_03')}}<span
              data-tab="termsOpt20"></span></li>

          <li :class="['termsTab-link fW-R notranslate', {current: tabNo === 1}]"
              @click="tabNo = 1"
              data-tab="termsOpt3" data-index="3">
            {{$t('trans.t21_04')}}<span
              data-tab="termsOpt3"></span></li>

          <li :class="['termsTab-link fW-R notranslate', {current: tabNo === 2}]"
              @click="tabNo = 2"
              data-tab="termsOpt21"
              data-index="21">{{$t('trans.t21_05')}}<span
              data-tab="termsOpt21"></span></li>
        </ul>
      </div>

      <div
          :class="['termsTab-content', {current: tabNo === index}]"
          v-for="(item, index) in arrTerms"
          :key="index">
        <div class="termsBox"
             v-html="item.contents">
        </div>
      </div>


    </div>
  </div>
</template>
<script>
import {fetchTerms} from "../../api/common";

export default {
  name: "TermsView",
  data() {
    return {
      tabNo: 0,
      arrTerms: []
    }
  },

  methods: {
    async fetchTerms() {
      const res = await fetchTerms({
        site_type: 3
      })
      this.arrTerms = res.data.ResponseData
    }
  },

  created() {
    this.fetchTerms()
  },

  mounted(){
    if(this.$route.query.q !== undefined) {
      this.tabNo = Number.parseInt(this.$route.query.q.toString())
    }
  }
}

</script>
<style>

</style>