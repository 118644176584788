<template>
  <div>
    도시락톡 배너영역
  </div>
</template>

<script>
export default {
  name:"DosirakTalkBanner",
  data: () => {
    return {
      dosirakTalkBanner: {
        grabCursor: false,
        centeredSlides: false,
        slidesPerView: 'auto',
        loop: false,
        spaceBetween: 10,
        slidesPerColumn: 1,
        // navigation: {
        //   nextEl: "#bestnext",
        //   prevEl: "#bestprev",
        // },
        // pagination: {
        //   el: "#bestpage",
        //   clickable: true,
        // },
        autoplay:{
            delay:1000
        }
      },
    }
  }
}
</script>

<style scoped>

</style>