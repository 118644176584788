import { dosirak, axiosAll, global } from "./config";

// 로그인 조회
export function login(payload) {
    return global.post('/m/member/login', payload)
}

// 번호 로그인
export function mvnoLogin(payload){
    return dosirak.post('/mvno/member/nc/cell/login', payload)
}

// 아이디 중복 체크
export function idCheck(payload){
    return global.post('/m/member/exists', payload)
}

// 회원가입
export function MemberCreate(payload){
    return global.post('/m/member/create', payload)
}

// 인증번호 요청
export function RequestAuthNo(payload){
    return dosirak.post('/mvno/member/nc/certify', payload)
}

// 인증번호 확인
export function AuthConfirm(payload){
    return dosirak.post('/mvno/member/loginSmsCertifyConfirm', payload)
}
