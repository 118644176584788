<template>
    <div class="wrap subPage">
        <div class="container">
            <!-- sub title -->
            <div class="subTitAr">
                <h1 class='notranslate'>{{$t('trans.t09_06')}}</h1>
                <span class="subTit"
                    >도시락 Prepaid 이용 시 신청 가능한 부가 서비스입니다.</span
                >
            </div>
            <!-- 서브페이지 컨텐츠 -->
            <div class="subPageAr">
                <!-- 부가서비스 Tab -->
                <div class="addTabAr">
                    <ul class="addTab">
                        <li
                            @click="selectedCategory = item.category_cd"
                            v-for="item in addServiceHeader"
                            :key="item.category_cd"
                            :class="[
                                'addTab-link',
                                {
                                    current:
                                        selectedCategory === item.category_cd,
                                },
                            ]"
                        >
                            {{ item.category }}<span></span>
                        </li>
                    </ul>
                </div>

                <div
                    v-for="(head, index) in addServiceHeader"
                    :key="index"
                    :class="[
                        'addTab-content',
                        { current: selectedCategory === head.category_cd },
                    ]"
                >
                    <div class="addAr">
                        <ul class="addListDetail">
                            <div
                                v-for="(body, index) in addServiceBody.filter(
                                    (x) => x.category_cd === head.category_cd
                                )"
                                :key="index"
                            >
                                <li>
                                    {{ body.category }}
                                    <div class="detailCon">
                                        <span class="option">{{
                                            body.amount === 0 ? "무료" : "유료"
                                        }}</span>
                                        <span class="mainTit">{{
                                            body.extraservice
                                        }}</span>
                                        <span class="mainTxt">{{
                                            body.reference
                                        }}</span>
                                    </div>
                                    <div class="detailPrice">
                                        <span class="addPrice"
                                            >월
                                            {{ body.amount | currency }}원</span
                                        >
                                        <span
                                            class="addArrow"
                                            @click="showDetail(body)"
                                        >
                                            <span
                                                class="arrowDown"
                                                onclick="this.classList.toggle('active')"
                                            ></span>
                                        </span>
                                    </div>
                                    <div style="clear: both"></div>
                                </li>
                                <div
                                    class="detailNote"
                                    :id="'deatailNote' + body.seqnum"
                                    v-if="body.isShowText"
                                >
                                    <span class="noteTit">[상세안내]</span>
                                    <span class="noteTxt" v-html="body.detail_info">
                                    </span>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { product } from "../../api";

export default {
    data() {
        return {
            selectedCategory: 1,
            selectedAddService: 0,
            addServiceBody: [],
            addServiceHeader: [],
            isTest: [],
        };
    },
    methods: {
        async fetchAddService() {
            const res = await product.fetchExtraService();

            this.addServiceBody = res.data.ResponseData;
            this.isTest = this.addServiceBody.filter(
                (x) => x.category_cd === 1
            );

            if (this.addServiceBody.length > 0) {
                this.addServiceBody.map((p) => {
                    p.isShowText = false;
                });

                const map = new Map();
                for (const item of this.addServiceBody) {
                    if (!map.has(item.category)) {
                        map.set(item.category, true);
                        this.addServiceHeader.push({
                            category: item.category,
                            category_cd: item.category_cd,
                        });
                    }
                }

                this.header = this.addServiceHeader.sort(
                    this.sortBy("category_cd")
                );

                const header = this.addServiceHeader.map(function(v) {
                    return v.category_cd;
                });

                this.selectedCategory = header.reduce((a, b) => {
                    return Math.min(a, b);
                });
            }
        },

        showDetail(pmtr) {
            let item = this.addServiceBody.map((n) => {
                if (n.seqnum === pmtr.seqnum) {
                    n.isShowText = !n.isShowText;
                }
                return n;
            });

            this.addServiceBody = item;
        },
    },
    mounted() {
        this.fetchAddService();
    },
};
</script>

<style></style>
