import crypto from "crypto-js";

export function topFloatBaner() {
  // 플로팅배너 상단
  let _topMenuEle = document.querySelector("#priceFloat_top");
  if (_topMenuEle) {
    let _domRect = _topMenuEle.getBoundingClientRect();
    if (_domRect) {
      let offset = window.pageYOffset;

      if (offset > _domRect.top) {
        _topMenuEle.classList.add("on");
      } else {
        _topMenuEle.classList.remove("on");
      }
    }
  }
}

export function footFloatBaner() {
  // 플로팅배너 하단
  let _underMenuEle = document.querySelector("#priceFloat");

  if (_underMenuEle) {
    let _top = document.documentElement.scrollTop;
    let footerHei = document
      .querySelector(".footerAr")
      .getBoundingClientRect().height;

    var val =
      document.body.clientHeight - window.innerHeight - footerHei;
    if (_top >= val) {
      _underMenuEle.classList.add("on");
    } else {
      _underMenuEle.classList.remove("on");
    }
  }
}

export function footFloatBanerHome() {
  // 플로팅배너 하단
  var _underMenuEle = document.querySelector("#gnbFloat");
  // var _top = window.scrollY;
  var _top = document.documentElement.scrollTop;
  var footerHei = document
    .querySelector(".footerAr")
    .getBoundingClientRect().height;

  var val =
    document.body.clientHeight - window.innerHeight - footerHei;
  if (_top >= val) _underMenuEle.classList.add("on");
  else _underMenuEle.classList.remove("on");
}

export function filterByEquals(list, criteria) {
  console.log(list, criteria);
  return list.filter((candidate) =>
    Object.keys(criteria).every(
      (key) => candidate[key] == criteria[key]
    )
  );
}

export function filterByMultiple(list, criteria) {
  return list.filter((candidate) =>
    Object.keys(criteria).every((key) =>
      console.log(candidate[key], criteria[key])
    )
  );
}

export function filterByRange(list, minValue = 0, maxValue = 0) {
  return list.filter(
    (x) =>
      x.amount >= Number(minValue) && x.amount <= Number(maxValue)
  );
}

export function sortBy(arr, key) {
  return arr.sort(function(a, b) {
    if (a[key] < b[key]) return -1;

    if (a[key] > b[key]) return 1;

    return 0;
  });
}

export function orderBy(arr, key, orderby) {
  const arrResult = arr.sort(function(a, b) {
    if (a[key] < b[key]) return -1;

    if (a[key] > b[key]) return 1;

    return 0;
  });

  return orderby === "desc" ? arrResult.reverse() : arrResult;
}

export function removeDuplicates(originalArray, prop) {
  var newArray = [];
  var lookupObject = {};
  for (var i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }
  for (i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
}

// 용량 반환
export function bytesToSize(payload) {
  var sizes = ["MB", "GB", "TB"];
  if (payload == 0) return "0 Byte";

  var i = parseInt(Math.floor(Math.log(payload) / Math.log(1024)));
  return `${Math.round(payload / Math.pow(1024, i), 2)}${sizes[i]}`;
}

export function scrollMove(payload) {
  const tagHeight = document.getElementById(payload).offsetTop;
  // window.scrollTo({top: tagHeight, behavior: "smooth"})
  window.scrollTo(0, tagHeight);
}

export function windowOpen(url, width, height) {
  var sst = window.open(
    url,
    "popwin",
    "top=" +
      ((screen.availHeight - height) / 2 - 40) +
      ", left=" +
      (screen.availWidth - width) / 2 +
      ", width=" +
      width +
      ", height=" +
      height +
      ", toolbar=0, directories=0, status=0, menubar=0, scrollbars=0, resizable=0"
  );
  if (sst) {
    sst.focus();
  }

  return sst;
}

export function aesEncrypt(data, key) {
  return crypto.AES.encrypt(data, key).toString();
}

export function aesDecrypt(data, key) {
  var bytes = crypto.AES.decrypt(data, key);
  return bytes.toString(crypto.enc.Utf8);
}

export function resizeImage(settings) {
  var file = settings.file;
  var maxSize = settings.maxSize;
  var reader = new FileReader();
  var image = new Image();
  var canvas = document.createElement("canvas");
  var dataURItoBlob = function(dataURI) {
    var bytes =
      dataURI.split(", ")[0].indexOf("base64") >= 0
        ? atob(dataURI.split(",")[1])
        : unescape(dataURI.split(",")[1]);
    var mime = dataURI
      .split(",")[0]
      .split(":")[1]
      .split(";")[0];
    var max = bytes.length;
    var ia = new Uint8Array(max);
    for (var i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);

    return new Blob([ia], { type: "image/jpeg" });
  };
  
  var resize = function() {
    var width = image.width;
    var height = image.height;
    if (width > height) {
      if (width > maxSize) {
        height *= maxSize / width;
        width = maxSize;
      }
    } else {
      if (height > maxSize) {
        width *= maxSize / height;
        height = maxSize;
      }
    }
    canvas.width = width;
    canvas.height = height;
    canvas.getContext("2d").drawImage(image, 0, 0, width, height);
    var dataUrl = canvas.toDataURL("image/jpeg");
    return dataURItoBlob(dataUrl);
  };

  return new Promise(function(ok, no) {
    if (!file.type.match(/image.*/)) {
      no(new Error("Not an image"));
      return;
    }
    reader.onload = function(readerEvent) {
      image.onload = function() {
        return ok(resize());
      };
      image.src = readerEvent.target.result;
    };
    reader.readAsDataURL(file);
  });
}

export function addScript(url) {
  let script = document.createElement("script");
  script.type = "application/javascript";
  script.src = url;
  document.head.appendChild(script);
}

export function getUrlParameter(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  let regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  let results = regex.exec(location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}
export function removeCookies() {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    let eqPos = cookie.indexOf("=");
    let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie =
      name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

export function removeCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1999 00:00:10 GMT;`;
}
