<template>
<div class="wrap subPage">
    <div class="container">
        <!-- sub title -->
        <div class="subTitAr fW-R">
            <h1>{{$t('trans.t18_19')}}</h1>
            <span class="subTit fW-L">와이드모바일에서 제공하는 모든 서비스에서 탈퇴처리가 진행됩니다.</span>
        </div>
        <!-- 서브페이지 컨텐츠 -->
        <div class="subPageAr">
            <div class="mypageAr">
                <div class="infoMod">
                    <span class="byeTxt">그 동안 도시락모바일 서비스를<b>이용해 주셔서 감사합니다.</b></span>
                    <span class="byeTxt">보다 나은 서비스로 다시 찾아 뵙겠습니다.</span>
                </div>
                <div class="btnWp">
                    <button class="btnCom fW-R extend" @click="$router.replace('/')">도시락모바일 홈으로</button>
                </div>
            </div>
        </div>
    </div>
</div>  
</template>

<script>
export default {

}
</script>

<style>

</style>