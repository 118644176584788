<template>
    <!-- 예상금액 플로팅 -->
    <div id="priceFloat">
        <div class="priceFloatAr">
            <div class="lt fW-L">
                <span class="floatTit notranslate">{{$t('trans.t05_08')}}</span>
                <span class="floatPrice"
                    ><strong class="fW-B" :key="totalCharge"
                        >{{ totalCharge | currency }}원</strong
                    >
                    ({{$t('trans.t21_07')}})</span
                >
            </div>
            <div class="rt">
                <span
                    :class="['arrowPrice', { active: isExtention }]"
                    @click="isExtention = !isExtention"
                ></span>
                <button class="floatPriceBtn fW-B notranslate" @click="onAccount">
                  {{$t('trans.t01_08')}}
                </button>
             
            </div>
        </div>
        <div class="pricefDetail" id="pricefDetail" v-show="isExtention">
            <ul class="priceDetailAr">
                <li>
                    <div class="priceMain">
                        <span class="priceName fW-R etc notranslate"
                            ><b>{{$t('trans.t11_01')}}</b></span
                        >
                        <span class="priceAmount fW-R etc" :key="monthEstiCharge"
                            ><b>{{ monthEstiCharge | currency }}원</b></span
                        >
                    </div>
                    <div class="priceMain">
                        <span class="priceName fW-L notranslate notranslate">{{$t('trans.t11_02')}}</span>
                        <span class="priceAmount fW-L"
                            >{{ OrderDataSet.monthAmount | currency }}원</span
                        >
                    </div>
                    <div class="priceMain">
                        <span class="priceName fW-L notranslate">{{$t('trans.t11_03')}}</span>
                        <span class="priceAmount fW-L"
                            >{{ OrderDataSet.extraAmount | currency }}원</span
                        >
                    </div>
                    <div class="priceMain">
                        <span class="priceName fW-L notranslate">{{$t('trans.t11_04')}}</span>
                        <span class="priceAmount fW-L"
                            >-0원</span
                        >
                    </div>
                </li>
                <li>
                    <div class="priceMain">
                        <span class="priceName fW-R etc notranslate"
                            ><b>{{$t('trans.t05_07')}}</b></span
                        >
                        <span class="priceAmount fW-R etc"
                            ><b>{{ oneTimeCharge | currency }}원</b></span
                        >
                    </div>
                    <div class="priceMain">
                        <span class="priceName fW-L notranslate">{{$t('trans.t11_06')}}
                            <div class="question">
                                <img src="../images/priceFloat_question.png">
                                <span class="tooltiptext">
                                    선택 요금제에 따라 유심비가 선 결제 또는 후 청구됩니다. (1회)
                                </span>
                            </div>
                        </span>
                        <span class="priceAmount fW-L"
                            >{{ OrderDataSet.usimAmount | currency }}원</span
                        >
                    </div>
                    <div class="priceMain">
                        <span class="priceName fW-L notranslate">{{$t('trans.t11_07')}}
                            <div class="question">
                                <img src="../images/priceFloat_question.png">
                                <span class="tooltiptext">
                                    배송 방법 선택에 따라 배송비가 다르게 책정됩니다.<br>
                                    <em>택배 : 0,000원</em>
                                    <em>퀵서비스 : 0,000~0,000원 (수도권에 한함, 지역에 따라 차등적용)</em>
                                </span>
                            </div>
                        </span>
                        <span class="priceAmount fW-L notranslate">{{$t('trans.t11_08')}}</span>
                    </div>
                </li>
                <li>
                    <div class="priceAds">
                        <div class="priceMain">
                        <span class="priceName fW-L notranslate">{{$t('trans.t11_09')}}</span>
                        <span class="priceAmount fW-L"
                            >{{totalDiscountAmount | currency}}원</span
                        >
                    </div>
                    <div class="priceMain">
                        <span class="priceName fW-L notranslate">{{$t('trans.t11_10')}}</span>
                        <span class="priceAmount fW-L"
                            >{{autoDiscountAmount | currency}}원</span
                        >
                    </div>
                    <div class="priceMain">
                        <span class="priceName fW-L notranslate">{{$t('trans.t11_11')}}</span>
                        <span class="priceAmount fW-L"
                            >{{longTermDiscountAmount | currency}}원</span
                        >
                    </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="popWpper details" v-if="isAppPop">
            <div class="popContainer center">
                <button class="close ic" @click="isAppPop = false; isDim = false;">닫기</button>
                <div class="popWP">
                    <div class="popContTit line">
                        <p class="tit notranslate">{{$t('trans.t05_01')}}</p>
                        <!-- <p class="txt">와이파이도시락 회원 정보로 로그인 하시려면<br>와이드모바일 통합 회원에 동의 후 로그인 가능합니다.</p> -->
                    </div>
                    <div class="popBody">
                        <div class="appConfirm">
                            <ul>
                                <li>
                                    <span class="confirmTit notranslate">{{$t('trans.t05_02')}}</span>
                                    <span class="confirmTxt notranslate">
                                        {{$t('trans.t99_99')}}
                                    </span>
                                </li>
                                <li>
                                    <span class="confirmTit notranslate">{{$t('trans.t05_03')}}</span>
                                    <span class="confirmTxt">{{OrderDataSet.joinType === 1 ? '신규가입' : '번호이동'}}</span>
                                </li>
                                <li>
                                    <span class="confirmTit notranslate">{{$t('trans.t05_04')}}</span>
                                    <span class="confirmTxt"
                                        >{{OrderDataSet.selectedUsimType.simtype}} ({{OrderDataSet.selectedUsimType.sim_amount | currency}}원)</span
                                    >
                                </li>
                                <li>
                                    <span class="confirmTit notranslate">{{$t('trans.t01_09')}}</span>
                                    <span class="confirmTxt" 
                                        >{{ OrderDataSet.selectedRate.name }}</span
                                    >
                                </li>
                                <li>
                                    <span class="confirmTit notranslate"
                                        >{{$t('trans.t05_06')}}</span
                                    >
                                    <span class="confirmTxt">{{monthEstiCharge | currency}}원</span>
                                </li>
                                <li>
                                    <span class="confirmTit notranslate"
                                        >{{$t('trans.t05_07')}}</span
                                    >
                                    <span class="confirmTxt">{{oneTimeCharge | currency}}원</span>
                                </li>
                                <li>
                                    <span class="confirmTit notranslate">{{$t('trans.t05_08')}}</span>
                                    <span class="confirmTxt">{{totalCharge | currency}}원</span>
                                </li>
                            </ul>
                        </div>
                        <div class="noticeAr notranslate" v-html="$t('trans.t05_09')">

                        </div>
                        <div class="notranslate" v-html="$t('trans.t05_10')">

                        </div>
                        <!-- 선택 완료 버튼 -->
                        <button type="button" class="selectBtn notranslate" @click="onSelectedRate">{{$t('trans.t05_11')}}</button>
                    </div>
                </div>
            </div>
            <div class="dim"></div>
        </div>        
    </div>
</template>

<script>
import * as banner from "../js/util";
import { orderMixin  } from "../mixin/storeMixin";

export default {
    name: "FootRateBanner",
    mixins:[orderMixin],
    data() {
        return {
            isExtention: false,
            isAppPop:false,
        };
    },

    methods:{

        onAccount(){

            if (Object.keys(this.OrderDataSet.selectedRate).length === 0) {
                this.kakaoTrans('요금제를 선택하세요.').then((res) => alert(res))
                return false;
            }

            if(this.OrderDataSet.serviceYn != 'Y') {
                this.kakaoTrans('서비스 이용약관 동의는 필수입니다.').then((res) => alert(res))
                return
            }

            if(this.OrderDataSet.identityYn != 'Y') {
                this.kakaoTrans('고유식별정보 수집 동의는 필수입니다.').then((res) => alert(res))
                return                
            }                

            if(this.OrderDataSet.privacyYn != 'Y') {
                this.kakaoTrans('개인정보 수집 및 이용안내 동의는 필수입니다.').then((res) => alert(res))
                return                
            }        

            if(this.OrderDataSet.offerYn != 'Y') {
                this.kakaoTrans('개인정보 제3자 제공 동의는 필수입니다.').then((res) => alert(res))
                return                
            }        
            
            if(this.OrderDataSet.tructYn != 'Y') {
                this.kakaoTrans('개인정보 제3자 위탁 동의는 필수입니다.').then((res) => alert(res))
                return                
            }         
            
            if(this.OrderDataSet.creditYn != 'Y') {
                this.kakaoTrans('개인정보조회 동의는 필수입니다.').then((res) => alert(res))
                return                
            }               
            
            this.isAppPop = true
            this.isDim = true
        },

        onSelectedRate(){
            if(this.isEmpty(this.OrderDataSet.selectedUsimType)) {
                this.kakaoTrans('선택하신 플랜의 유심이 없습니다.').then((res) => alert(res))
                return               
            }
            this.isDim = false
            this.isStep1 = true
            this.$router.push({name:"Step2View"})
        },
    },

    mounted() {
        window.addEventListener("scroll", banner.footFloatBaner);
    },

    computed: {

        // 월예상 납부금액
        monthEstiCharge() {
            return this.OrderDataSet.monthAmount + this.OrderDataSet.extraAmount;
        },

        // 최초1회 결제
        oneTimeCharge() {
            return this.OrderDataSet.quickAmount + this.OrderDataSet.usimAmount;
        },

        //결제금액
        totalCharge() {
            return (
                this.OrderDataSet.planAmount +
                this.OrderDataSet.extraAmount +
                this.OrderDataSet.quickAmount +
                this.OrderDataSet.usimAmount
            );
        },
        
    },
};
</script>

<style></style>
