<template>
  <div class="wrap subPage">
    <div class="container">
      <!-- sub title -->
      <div class="subTitAr fW-R">
        <h1 class='notranslate'>{{$t('trans.t18_01')}}</h1>
        <span class="subTit fW-L notranslate">{{$t('trans.t18_02')}}</span>
      </div>
      <!-- 서브페이지 컨텐츠 -->
      <div class="subPageAr">
        <div class="mypageAr">
          <div class="infoMod">
            <ul>
              <li>
                <span class="infoModTit fW-R notranslate">{{$t('trans.t16_04')}}</span>
                <span class="infoModTxt fW-L">{{ member.member_id }}</span>
              </li>
              <li>
                <span class="infoModTit fW-R notranslate">{{$t('trans.t06_07')}}</span>
                <span class="infoModTxt fW-L">{{ member.member_name }}</span>
              </li>
              <li>
                <span class="infoModTit fW-R notranslate">{{$t('trans.t18_05')}}</span>
                <span class="infoModTxt fW-L">{{ member.member_cellnumber }}</span>
                <button
                    class="btnChange fW-R fr notranslate"
                    type="button"
                    @click="
                    $router.push({
                      name: 'MemberCellEditView',
                    })
                  "
                >
                  {{$t('trans.t18_06')}}
                </button>
              </li>
              <li>
                <span class="infoModTit fW-R notranslate">{{$t('trans.t08_17')}}</span>
                <span class="infoModTxt fW-L">{{ member.member_number }}</span>
              </li>
              <li>
                <span class="infoModTit fW-R notranslate">{{$t('trans.t18_08')}}</span>
                <button
                    class="btnChange fW-R"
                    type="button"
                    @click="
                    $router.push({
                      name: 'MemberPassEditView',
                    })
                  "
                >
                  {{$t('trans.t18_06')}}
                </button>
              </li>
              <li>
                <span class="infoModTit fW-R notranslate">{{$t('trans.t18_09')}}</span>
                <button
                    :class="[
                    'btnGender fW-R notranslate',
                    {
                      active: member.member_gender === '10',
                    },
                  ]"
                    @click="member.member_gender = '10'"
                >
                  {{$t('trans.t18_10')}}
                </button>
                <button
                    :class="[
                    'btnGender fW-R notranslate',
                    {
                      active: member.member_gender === '20',
                    },
                  ]"
                    @click="member.member_gender = '20'"
                >
                  {{$t('trans.t18_11')}}
                </button>
              </li>
              <li>
                <span class="infoModTit fW-R notranslate">{{$t('trans.t18_12')}}</span>
                <input
                    type="checkbox"
                    id="agreeMail"
                    true-value="Y"
                    false-value="N"
                    v-model="member.member_email_send_yn"
                    @click="
                    member.member_email_send_yn === 'Y'
                      ? (member.member_email_send_yn = 'N')
                      : (member.member_email_send_yn = 'Y')
                  "
                />
                <label for="agreeMail" class="agree fW-L notranslate">{{$t('trans.t07_34')}}</label>
                <input
                    type="checkbox"
                    id="agreeSms"
                    true-value="Y"
                    false-value="N"
                    v-model="member.member_sms_send_yn"
                    @click="
                    member.member_sms_send_yn === 'Y'
                      ? (member.member_sms_send_yn = 'N')
                      : (member.member_sms_send_yn = 'Y')
                  "
                />
                <label for="agreeSms" class="agree fW-L">SMS</label>
              </li>
              <li>
                <span class="infoModTit fW-R notranslate">{{$t('trans.t18_15')}}</span>

                <button
                    type="button"
                    @click="onSnsTran('F')"
                    :class="['btnSns fW-R notranslate', { active: facebook }]"
                >
                  {{$t('trans.t18_16')}}
                </button>
                <button
                    type="button"
                    @click="onSnsTran('K')"
                    :class="['btnSns fW-R notranslate', { active: kakao }]"
                >
                  {{$t('trans.t18_17')}}
                </button>
                <button
                    type="button"
                    @click="onSnsTran('N')"
                    :class="['btnSns fW-R notranslate', { active: naver }]"
                >
                  {{$t('trans.t18_18')}}
                </button>
              </li>
            </ul>
          </div>
          <div class="btnWp">
            <button
                class="btnCom fW-R gray"
                type="button"
                @click="$router.push({ name: 'MemberDropReasonView' })"
            >
              {{$t('trans.t18_19')}}
            </button>
            <button class="btnCom fW-R notranslate" type="button" @click="onMemberUpdate">
              {{$t('trans.t18_20')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {snsKeys, snsType} from "../../js/enum";
import {snsCreate, fetchMemberEdit, putSns, putMember} from "../../api/member";
import {memberMixin} from "../../mixin/storeMixin";
import {windowOpen} from "../../js/util";

export default {
  mixins: [memberMixin],
  data() {
    return {
      member: {},
      kakao: false,
      naver: false,
      facebook: false,
      snsList: [],
    };
  },
  methods: {
    async fetchMember() {
      const [members, sns] = await fetchMemberEdit({
        member_id: this.memberInfo.member_seq,
      });

      this.member = members.data.ResponseData[0];
      this.snsList = sns.data.ResponseData;

      if (sns.data.ResponseData) {
        this.kakao =
            sns.data.ResponseData.filter((x) => x.snsType === "K").length > 0;
        this.naver =
            sns.data.ResponseData.filter((x) => x.snsType === "N").length > 0;
        this.facebook =
            sns.data.ResponseData.filter((x) => x.snsType === "F").length > 0;
      }
    },

    async onMemberUpdate() {
      var confMsg = await this.kakaoTrans("회원정보를 수정하시겠습니까?");

      if (confirm(confMsg)) {
        const res = await putMember({
          member_seq: this.memberInfo.member_seq,
          member_name: this.member.member_name,
          member_number: this.member.member_number,
          member_gender: this.member.member_gender,
          member_htel1: this.member.member_htel1,
          member_htel2: this.member.member_htel2,
          member_htel3: this.member.member_htel3,
          member_sms_send_yn: this.member.member_sms_send_yn,
          member_email_send_yn: this.member.member_email_send_yn,
        });

        if (!res.data.ResultCode) {
          this.kakaoTrans(res.data.ResultMessage).then((res) => alert(res));
          return;
        }

        this.kakaoTrans(res.data.ResultMessage).then((res) => alert(res));

        this.fetchMember();
      }
    },

    async onSnsTran(payload) {
      var confMsg = await this.kakaoTrans("연동 해제 하시겠습니까?");

      const sns = this.snsList.filter((x) => x.snsType === payload);

      if (sns.length > 0) {
        if (confirm(confMsg)) {
          const res = await putSns({
            sns_member_seq: this.memberInfo.member_seq,
            sns_id: sns[0].snsID,
            sns_type: sns[0].snsType,
            sns_use_yn: 1,
          });

          if (res.data.ResultCode) {
            this.kakaoTrans("해제되었습니다.").then((res) => alert(res));
            this.fetchMember();
            return;
          }
        }

        return;
      }

      if (payload === snsType.kakao) {
        var self = this;
        window.Kakao.Auth.login({
          scope: "profile",
          success: function (authObj) {
            window.Kakao.API.request({
              url: "/v2/user/me",
              success: (res) => {
                const resLogin = new Promise((resolve, reject) => {
                  const resCreate = snsCreate({
                    sns_member_seq: self.memberInfo.member_seq,
                    sns_id: res.id,
                    sns_type: payload,
                  });

                  resolve(resCreate);
                });

                resLogin.then((res) => {
                  if (res.data.ResultCode) {
                    self.kakaoTrans("연동되었습니다.").then((res) => alert(res));
                    self.fetchMember();
                    return;
                  }
                });
              },
            });
          },
        });
      } else if (payload === snsType.naver) {
        windowOpen("http://api.wifidosirak.com/sns/naverlogin", 452, 400);
      } else {
        const self = this;
        FB.login(
            async function (response) {
              if (response.status === "connected") {
                const resCreate = await snsCreate({
                  sns_member_seq: self.memberInfo.member_seq,
                  sns_id: response.authResponse.userID,
                  sns_type: snsType.facebook,
                });

                if (resCreate.data.ResultCode) {
                  self.kakaoTrans("연동되었습니다.").then((res) => alert(res));
                  self.fetchMember();
                } else {
                  alert(e.data.resultData.resultMessage);
                }

                // console.log(response)
              }
            },
            {scope: "public_profile,email"}
        );
      }
    },
  },
  mounted() {
    try {
      var self = this;

      window.fbAsyncInit = function () {
        FB.init({
          appId: "1620337284941713",
          xfbml: true,
          version: "v4.0",
        });
        FB.AppEvents.logPageView();
      };

      (function (d, s, id) {
        var js,
            fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");

      window.Kakao.init(snsKeys.kakao.key);

      window.addEventListener("message", function (e) {
        if (
            e.origin === "http://api.wifidosirak.com" ||
            e.origin === "https://api.wifidosirak.com"
        ) {
          if (e.data.resultData.resultCode) {
            const resCreate = snsCreate({
              sns_member_seq: self.memberInfo.member_seq,
              sns_id: e.data.resultData.resultData,
              sns_type: snsType.naver,
            });

            resCreate.then((res) => {
              if (res.data.ResultCode) {
                self.kakaoTrans("연동되었습니다.").then((res) => alert(res));
                self.fetchMember();
              } else {
                this.kakaoTrans(e.data.resultData.resultMessage).then((res) =>
                    alert(res)
                );
              }
            });
          }
        }
      });
    } catch (error) {
    }

    this.fetchMember();
  },
  computed: {
    isSendSms() {
      return this.member.member_sms_send_yn === "Y";
    },

    isSendMail() {
      return this.member.member_email_send_yn === "Y";
    },
  },
};
</script>

<style></style>
