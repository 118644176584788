<template>
<div class="wrap subPage">
    <div class="container">
        <!-- sub title -->
        <div class="subTitAr fW-R">
            <h1 class='notranslate'>{{$t('trans.t18_01')}}</h1>
            <span class="subTit fW-L notranslate">{{$t('trans.t18_02')}}</span>
        </div>
        <!-- 서브페이지 컨텐츠 -->
        <div class="subPageAr">
            <div class="mypageAr">
                <div class="infoMod">
                    <!-- 휴대폰 번호 변경 안내 -->
                    <span class="modTit notranslate">{{$t('trans.t19_01')}}</span>
                    <div class="infoModcon notranslate">                        
                        <div class="noteAr" v-html="$t('trans.t19_02')">

                        </div>
                    </div>
                    <!-- 신규 휴대폰 번호 입력 -->
                    <form @submit.prevent="onSend" data-vv-scope="frmCell">
                        <span class="modTit notranslate">{{$t('trans.t19_03')}}</span>
                        <div class="infoModcon">
                            <div class="inpGroup">
                                <input type="text" class="half notranslate" v-model="inputCell"  v-validate="'required'" data-vv-name="member_new_cell" :placeholder="$t('trans.t19_03')">
                                <div class="grouping">
                                    <button class="btnCode notranslate" button="submit" :disabled="isSend">{{$t('trans.t19_05')}}</button>
                                </div>
                            </div>
                        </div>
                    </form>

                    <!-- 신규 휴대폰 번호 입력 -->
                    <form data-vv-scope="frmConfirm">
                        <span class="modTit notranslate">{{$t('trans.t19_04')}}</span>
                        <div class="infoModcon">
                            <div class="inpGroup">
                                <input type="text" class="num notranslate" v-model="inputAuthNo" v-validate="'required'" data-vv-name="member_new_cell_auth_no" :placeholder="$t('trans.t12_06')">
                                <div class="grouping num">
                                    <button class="btnCode chk notranslate" type="button" @click="onConfirm">{{$t('trans.t06_25')}}</button>
                                </div>
                                <div class="grouping">
                                    <!-- <button class="btnCode" type="button" :disabled="isAuth">인증번호 재전송</button> -->
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
                <div class="btnWp">
                    <button class="btnCom fW-R gray notranslate" type="button" @click="$router.go(-1)">{{$t('trans.t13_06')}}</button>
                    <button class="btnCom fW-R notranslate" type="button" @click="onChange">{{$t('trans.t19_07')}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import * as member from "../../api/member";
export default {
    name:"MemberCellEditView",
    data(){
        return {
            inputCell:"",
            inputAuthNo:"",
            resAuthNo:"",
            isAuth:false,
            isSend:false,
        }
    },
    methods:{
        async onSend(){

            const authSend = await this.$validator.validateAll('frmCell');

            if(authSend){

                const res = await member.sendAuthNumber({
                    member_cellnumber: this.inputCell        
                })

                if(res.data.ResultCode) {
                    this.isSend = true
                    this.resAuthNo = res.data.ResponseData
                }

                this.kakaoTrans(res.data.ResultMessage).then((res) => alert(res))

            }

            this.kakaoTrans(this.errors.items[0].msg).then((res) => alert(res))
        },
        async onChange(){    

            // 성공시 회원정보 페이지로 이동
            if(!this.isAuth){
                this.kakaoTrans('인증 후 변경이 가능합니다.').then((res) => alert(res))
                return;
            }

            // 휴대폰번호 변경api

            const res = await member.putCell({
                member_id: this.memberInfo.member_seq,
                member_cell:this.inputCell
            })

            this.kakaoTrans(res.data.ResultMessage).then((res) => alert(res))

            this.$router.replace({name:"MemberEditView"})
            

        },
        async onConfirm(){

            const authConfirm = await this.$validator.validateAll('frmConfirm');

            if(authConfirm){

                if(this.inputAuthNo != this.resAuthNo){
                    this.kakaoTrans('인증번호가 잘못되었습니다.').then((res) => alert(res))
                    return;
                }
                
                this.isAuth = true
                this.kakaoTrans('인증되었습니다.').then((res) => alert(res))

            }



        }
        //member_cellnumber: 01047359854
    }
    
}
</script>
<style lang="">
    
</style>