<template>
  <div class="about" style="height:500px;">
    <div id="map" style="width:500px;height:400px;"></div>
  </div>
</template>
<script>
import { test } from "../api/api";
export default {
  components: {
    // HelloWorld
  },
  async mounted() {
    const res = await test()

  },
  mothods: {

  },

  beforeDestroy() {
    // window.removeEventListener('testmsg', this.show)
  },

  methods: {},
};
</script>
<style scoped>
iframe {
  height: 100%;
}
</style>
