import VietNamView from "../views/promotion/vietnam/VietnamView";

export default[
    {
        path: "/promotion/vietnamview",
        name: "VietNamView",
        component: VietNamView,
        meta: {
            transitionName: 'slide'
        }
    },
    {
        path: '*',
        redirect: "/"
    }
]
