import {
    dosirak
} from "./config";

export function putMember(payload) {
    return dosirak.post('/mvno/promotion/vietnam/create', payload)
}

export function fetchCode(payload) {
    return dosirak.post('/mvno/promotion/vietnam/mycode', payload)
}