/*
    ----------------------------------------
    ** 상품 API
    ----------------------------------------
*/

import { dosirak, axiosAll } from "./config";


// 요금제 조회
export function fetchPlan() {
    return dosirak.post('/mvno/common/planInfoList')
}

// 주문
export function fetchOrder(payload) {
    return dosirak.post('/mvno/reservation/order', payload)
}

// 부가서비스 조회
export function fetchExtraService() {
    return dosirak.post('/mvno/common/planExtraserviceList')
}

//메인화면 데이터 조회
export function fetchMain(data = { page_no:1, list_count:10 }) {
    const extra     = dosirak.post(`${dosirak.defaults.baseURL}/mvno/common/planExtraserviceList`);
    const plan      = dosirak.post(`${dosirak.defaults.baseURL}/mvno/common/planInfoList`);
    const terminal  = dosirak.post(`${dosirak.defaults.baseURL}/mvno/common/terminal`, data);

    return axiosAll([extra, plan, terminal]);
}

// 요금제, 부가상품 조회
export function fetchSte1(){
    const extra     = dosirak.post(`${dosirak.defaults.baseURL}/mvno/common/planExtraserviceList`);
    const plan      = dosirak.post(`${dosirak.defaults.baseURL}/mvno/common/planInfoList`);
    const sim       = dosirak.post(`${dosirak.defaults.baseURL}/mvno/reservation/fetch/sim`)
    const terms     = dosirak.post(`${dosirak.defaults.baseURL}/mvno/reservation/nc/terms`)
    return axiosAll([extra, plan, sim, terms])
}

// 도시락 요금제 조회
export function fetchRate(){
    const plan          = dosirak.post(`${dosirak.defaults.baseURL}/mvno/common/planInfoList`);
    const stepDiscount  = dosirak.post(`${dosirak.defaults.baseURL}/mvno/common/planlongdiscountList`);
    return axiosAll([plan, stepDiscount])
}

// 플랜조회
export function fetchPlans(){
    return dosirak.post('/mvno/common/planInfoList')
}

//회차별 할인 조회
export function fetchDiscount(payload){    
    return dosirak.post('/mvno/reservation/discount/long', payload);
}