const config = () => {
  return {
    isMainSite: true,
  };
};

const configStore = {
  namespaced: true,
  state: config(),
  getters: {},
  mutations: {
    MTS_IS_MAIN: (state, payload) => {
      state.isMainSite = payload;
    },
  },
};

export default configStore;
