<template>
  <div class="wrap subPage">
    <div class="container">
      <!-- sub title -->
      <div class="subTitAr fW-R">
        <h1><span class="notranslate">{{$t('trans.t01_05')}}</span></h1>
        <span class="subTit fW-L"
          ><span class="notranslate">{{$t('trans.t14_02')}}</span></span
        >
      </div>
      <!-- 서브페이지 컨텐츠 -->
      <div class="subPageAr">
        <div class="loginAr">
          <!-- login Tab -->
          <ul class="loginTab">
            <li
              @click="isMemberLogin = !isMemberLogin"
              :class="['loginTab-link fW-R notranslate', { current: !isMemberLogin }]"
              data-tab="login_number"
            >
              {{$t('trans.t14_03')}}<span></span>
            </li>
            <li
              @click="isMemberLogin = !isMemberLogin"
              :class="['loginTab-link fW-R', { current: isMemberLogin }]"
              data-tab="login_member"
            >
              <span class="notranslate">{{$t('trans.t14_06')}}</span>
            </li>
          </ul>
          <div class="loginTab-contentWrap">
            <!-- 번호 인증 로그인-->
            <form data-vv-scope="frmNumber" @submit.prevent="onUserAuth">
              <div
                :class="['loginTab-content', { current: !isMemberLogin }]"
                id="login_number"
              >
                <div class="loginInput">
                  <div class="inputAr">
                    <input
                      type="text"
                      class="mid"
                      id="order"
                      @input="inputOrderNo"
                      :value="order_id"
                      :placeholder="$t('trans.t14_04')"
                      name="member_order"
                      v-validate="'required'"
                    />
                  </div>
                  <div class="inputAr">
                    <input
                      type="text"
                      class="mid"
                      id="name"
                      @input="inputName"
                      :value="name"
                      :placeholder="$t('trans.t14_05')"
                      name="member_login_name"
                      v-validate="'required'"
                    />
                  </div>
                  <div class="inputAr">
                    <div class="inpGroup">
                      <!-- <input
                        type="text"
                        class="phone"
                        id="celnumber"
                        v-model="mobile"
                        placeholder="가입신청 휴대폰번호"
                        name="member_login_cell"
                        v-validate="'required|numeric'"
                      /> -->
                      <!-- <div class="grouping btn">
                        <button class="sendCertify re" v-if="isSend" @click="onSend">
                          재발송
                        </button>
                        <button
                          v-else
                          class="sendCertify"
                          type="button"
                          @click="onSend"
                        >
                          {{$t('trans.t12_06')}}
                        </button>
                      </div>
                      <div class="grouping nextLine">
                        <input
                          type="text"
                          class="short"
                          v-model="authNumber"
                          placeholder="인증번호 확인"
                          name="member_login_auth_no"
                        />

                        <button class="chkCertify gray" type="button" v-if="isAuth">
                          인증완료
                        </button>

                        <button
                          class="chkCertify"
                          type="button"
                          @click="onAuthConfirm"
                          v-else
                        >
                          인증번호 확인
                        </button>

                      </div>-->
                    </div>
                  </div>
                  <div class="inputAr" style="display:none;">
                    <div class="inpGroup">
                      <div class="captcha" id="imageCaptcha"></div>
                      <input
                        type="text"
                        class="midcaptcha"
                        id="captcha_number"
                        placeholder="보안문자 입력"
                      />
                      <div class="grouping nextLine rt">
                        <span class="captchaOpt" id="refreshCaptcha"
                          ><img
                            src="//d01.wifidosirak.com/dosirakmobile/images/refreshIcon.png"
                          />새로고침</span
                        >
                        <span class="captchaOpt" id="voiceCaptcha"
                          ><img
                            src="//d01.wifidosirak.com/dosirakmobile/images/speakerIcon.png"
                          />음성듣기</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="privacyPolicy">
                  <span class="policyTit notranslate">
                    {{$t('trans.t12_08')}}
                  </span>
                  <div class="policyBox">
                    <b>총 칙</b><br /><br />
                    주식회사 와이드모바일(이하 “회사”)은 고객님의 개인정보를 소중하게
                    생각하고 고객님의 개인정보를 효과적으로 관리하고 안전하게
                    보호하기 위하여 최선의 노력을 다 하고 있습니다. 회사 는
                    『정보통신망 이용촉진 및 정보보호 등에 관한 법률』과 개인정보보호
                    관련 각종 법규를 준수하고 있습니다. 또한 개인정보처리방침을
                    제정하여 이를 준수하고 있으며, 본 처리방침을 홈페이지에 공개하여
                    고객님께서 언제나 쉽게 열람하실 수 있도록 하고 있습니다.
                    <br /><br />
                    1.개인정보란 생존하는 개인에 관한 정보로서 성명, 주민등록번호
                    등에 의하여 당해 개인을 알아볼 수 있는 부호, 문자, 음성, 음향,
                    영상 및 생체 특성 등에 관한 정보(당해 정보만으로는 특정 개인을
                    알아볼 수 없는 경우에도 다른 정보와 용이하게 결합하여 알아볼 수
                    있는 것을 포함)를 말합니다. <br />
                    2.회사는 고객님의 개인정보를 중요시하며, 『정보통신망 이용촉진 및
                    정보보호 등에 관한 법률』과 관련 법규를 준수하고 있습니다. <br />
                    3.회사는 개인정보처리방침을 통하여 고객님의 개인정보가 어떠한
                    용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한
                    조치가 취해지고 있는지 알려드립니다. <br />
                    4.회사의 개인정보처리방침은 관련 법령 및 내부 운영 방침의 변경에
                    따라 개정될 수 있습니다. 개인정보처리방침이 변경되는 경우에는
                    시행 일자 등을 부여하여 개정된 내용을
                    홈페이지(https://www.wifidosirak.com) 에 지체 없이 공지합니다.
                    <br /><br />
                    5. 회사의 개인정보처리방침은 다음과 같은 내용을 담고 있습니다.
                    <br />
                    제 1장 수집하는 개인정보의 항목<br />
                    제 2장 개인정보의 수집 및 이용목적<br />
                    제 3장 개인정보의 보유 및 이용기간<br />
                    제 4장 개인정보의 제 3자 제공<br />
                    제 5장 개인정보의 처리 위탁<br />
                    제 6장 개인정보의 파기 절차 및 방법<br />
                    제 7장 이용자 및 법정대리인의 권리와 그 행사방법<br />
                    제 8장 개인정보 자동수집 장치의 설치ㆍ운영 및 그 거부에 관한
                    사항<br />
                    제 9장 개인정보의 기술적/관리적 보호 대책<br />
                    제 10장 개인정보 보호책임자 및 개인정보의 열람청구를
                    접수ㆍ처리하는 부서<br />
                    제 11장 기타 개인정보 처리에 관한 방침<br />
                    제 12장 고지의무<br /><br /><b
                      >제 1 장 수집하는 개인정보의 항목 및 수집방법</b
                    ><br /><br />
                    회사는 회원가입, 상담, 서비스 제공을 위하여 아래와 같이 필요한
                    최소한의 개인정보만을 수집하며, 사상, 신념, 가족 및 친인척관계,
                    학력(學歷)·병력(病歷), 기타 사회활동 경력 등 고객님의
                    권리·이익이나 사생활을 뚜렷하게 침해할 우려가 있는 개인정보는
                    수집하지 않습니다. <br />
                    다만, 고객님께서 동의하시거나 다른 법률에 따라 특별히 수집 대상
                    개인정보로 허용된 경우에는 필요한 범위에서 최소한으로 위
                    개인정보를 수집할 수 있습니다. <br /><br />
                    1.수집하는 개인정보의 항목<br />
                    <table>
                      <tbody>
                        <tr>
                          <th
                            style="background-color: rgb(207, 220, 224); border: 1px solid rgb(128, 128, 128); width: 13%; height: 20px;"
                          >
                            구분
                          </th>
                          <th
                            style="background-color: rgb(207, 220, 224); border: 1px solid rgb(128, 128, 128); width: 53%;"
                          >
                            수집항목
                          </th>
                          <th
                            style="background-color: rgb(207, 220, 224); border: 1px solid rgb(128, 128, 128); width: 33%;"
                          >
                            이용목적
                          </th>
                        </tr>
                        <tr>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 13%; height: 20px; padding-left: 10px;"
                          >
                            필수정보
                          </td>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 53%; padding-left: 10px;"
                          >
                            ① 정회원(회원가입 시)<br />
                            - 이메일, 이름, 생년월일, 성별, 비밀번호, 비밀번호확인,
                            휴대폰, 인증번호<br />
                            SNS 회원가입 시 아래의 정보를 서비스 주체로부터
                            제공받습니다.<br />
                            -페이스북: 이메일주소, 프로필정보(프로필사진,이름),
                            사용자 친구리스트<br />
                            -카카오톡: 프로필정보(프로필사진,닉네임)<br />
                            -네이버 : 이메일주소<br /><br />
                            ② 비회원 서비스 예약 시(내국인)<br />
                            - 이용자명, 생년월일, 성별, 휴대폰, 인증번호, 이메일<br /><br />
                            ③ 비회원 서비스 예약 시(외국인)<br />
                            - 이름, 국적, 여권번호, 이메일, 휴대폰<br /><br />
                            ④고객문의 시<br />
                            - 이름, 이메일, 연락처<br /><br />
                            ⑤ 이벤트 참여 시<br />
                            - 이름, 이메일, 휴대폰<br />
                          </td>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 33%; padding-left: 10px;"
                          >
                            - 회원서비스 제공 및 본인확인 또는 인증<br />
                            - 인터넷 세션 별 인증 유지<br />
                            - 구매 및 계약 이행에따른VOC 처리<br />
                            - 통계처리 및 분석을 위한기초자료 활용<br />
                            - 성별: 제품 수령 시 본인 확인<br />
                            - 생년월일: 만 14세 미만여부의 판단기준<br />
                            - 이벤트 참여 및 추첨<br />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 13%; height: 20px; padding-left: 10px;"
                          >
                            선택정보
                          </td>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 53%; padding-left: 10px;"
                          >
                            ① 회원가입 시<br />
                            - 추천인(추천인이메일), SMS/이메일 수신동의<br /><br />
                            ② 상품 예약 시<br />
                            - 주소, 아시아나항공회원번호, 엘포인트카드번호<br /><br />
                            ③ 분실신고 시<br />
                            - 체류국가, 긴급연락처<br />
                          </td>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 33%; padding-left: 10px;"
                          >
                            - 마케팅/홍보 안내<br />
                            - 포인트 발급 및 사용<br />
                            - 상품 분실 대응<br />
                            - 상품 택배 발송<br />
                            - 추천인 혜택 제공<br />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 13%; height: 20px; padding-left: 10px;"
                          >
                            서비스 이용과정 및 사업 처리과정에서 수집될 수 있는
                            개인정보 범위
                          </td>
                          <td
                            colspan="2"
                            style="border: 1px solid rgb(128, 128, 128); width: 53%; padding-left: 10px;"
                          >
                            - 서비스 이용 및 중지기록, 접속로그, 쿠키, 접속IP정보<br />
                            - 출국일(시간), 입국일(시간), 출국장소, 입국장소, 서비스
                            이용 국가,<br />
                            - 수취인주소, 수취인명, 수취인 연락처 등 배송정보<br />
                            - (모바일기기 이용시) 단말기식별번호, 단말기OS정보, 광고
                            식별자, 이동통신회사, PUSH수신여부<br />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br />
                    2. 회사는 다음과 같은 방법으로 개인정보를 수집합니다. <br />
                    가. 가입신청서, 명의변경 신청서 등 각종 서면 작성, 인터넷
                    홈페이지, 전화(고객센터:1566-9070), Fax 등을 통한 회원/서비스
                    가입, 전화나 인터넷을 통한 상담, 경품 행사 응모, 배송 요청 등의
                    과정에서 동의 및 수집<br />
                    나. 서비스 이용 또는 업무 처리 과정에서 생성되거나 제휴사 등
                    제3자로부터의 제공, 생성정보 수집 툴을 통한 수집 등<br /><br />
                    회사는 이용자의 개인정보를 수집할 때, 위 수집항목의 내용을 변경할
                    때 사전에 해당 사실을 이용자에게 알리고 동의를 구합니다.
                    <br /><br />
                    다만, 이용자가 요구하는 서비스를 제공하는 과정에서 이를 이행하기
                    위하여 필요한 개인정보로서 경제적, 기술적 사유로 통상적인 동의를
                    받는 것이 뚜렷하게 곤란한 경우, 서비스 제공에 따른 요금 정산을
                    위하여 필요한 경우, 기타 정보통신망법 또는 다른 법률에 특별한
                    규정이 있는 경우에는 동의를 받지 않고 이용자의 개인정보를 수집할
                    수 있습니다. <br /><br />
                    ※ 해당 서비스의 본질적 기능을 수행하기 위한 정보는 필수 정보로서
                    수집하며 이용자가 그 정보를 회사에 제공하지 않는 경우 서비스
                    이용에 제한이 가해질 수 있지만, 선택 정보로 추가 수집되는 정보의
                    경우에는 이를 입력하지 않은 경우에도 서비스 이용제한은 없습니다.
                    <br />
                    ※ 회원은 자신의 정보에 대해 정확성 및 적법성을 보장해야 합니다.
                    만약 이를 위반하여 타인의 정보를 도용하는 등 각종 방법으로 허위
                    정보를 입력할 경우 당사는 해당 회원을 관계법령에 따라 신고 할 수
                    있으며 강제 탈퇴를 시킬 수도 있습니다. <br /><br /><b
                      >제 2 장 개인정보의 수집 및 이용 목적</b
                    ><br /><br />
                    회사는 이용자들에게 회원가입, 원활한 고객상담, 각종 서비스의 제공
                    등 보다 더 향상된 양질의 서비스를 제공하기 위하여<br />
                    아래와 같이 고객 개인의 정보를 수집하고 있습니다.<br /><br />
                    <table>
                      <tbody>
                        <tr>
                          <th
                            style="background-color: rgb(207, 220, 224); border: 1px solid rgb(128, 128, 128); width: 20%; height: 20px;"
                          >
                            구분
                          </th>
                          <th
                            style="background-color: rgb(207, 220, 224); border: 1px solid rgb(128, 128, 128); width: auto;"
                          >
                            이용목적
                          </th>
                        </tr>
                        <tr>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 13%; height: 20px; padding-left: 10px;"
                          >
                            회원관리
                          </td>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 53%; padding-left: 10px;"
                          >
                            -회원제 서비스 이용에 따른 본인 확인 ,개인식별<br />
                            -불량회원의 부정이용 방지와 비인가 사용 방지<br />
                            -미성년자의 확인<br />
                            -고객상담, 고객불만 접수 및 처리, 분쟁조정을 위한
                            기록보존<br />
                            -고지사항 전달<br />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 13%; height: 20px; padding-left: 10px;"
                          >
                            서비스 제공에 관한 계약의 이행 및 서비스 제공에 따른
                            요금정산
                          </td>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 53%; padding-left: 10px;"
                          >
                            -서비스 및 부가 서비스 이용에 대한 요금 결제<br />
                            -콘텐츠 제공, 이벤트/경품당첨 결과안내 및 상품배송<br />
                            -금융거래 관련 본인인증 및 금융서비스<br />
                            -청구서 발송, 구매 및 요금결제, 환불 등<br />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 13%; height: 20px; padding-left: 10px;"
                          >
                            신규 서비스 개발 및 마케팅 활용
                          </td>
                          <td
                            colspan="2"
                            style="border: 1px solid rgb(128, 128, 128); width: 53%; padding-left: 10px;"
                          >
                            -새로운 서비스 및 신상품이나 이벤트 정보 등의 안내<br />
                            -고객에게 최적화된 서비스 제공<br />
                            -개인맞춤 서비스를 제공하기 위한 자료, 마케팅 활용<br />
                            -웹페이지 접속 빈도 파악<br />
                            -서비스 이용에 대한 통계<br />
                            -새로운 상품 또는 서비스 안내<br />
                            -고객 관심사에 부합하는 웹서비스 및 이벤트 기획<br />
                            -경품행사, 이벤트 등 광고성 정보 전달 또는 회원 참여공간
                            운영<br />
                            -고객설문조사<br />
                            -서비스 및 상품 안내<br />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br /><b>제 3장 개인정보의 보유 및 이용기간</b><br /><br />
                    이용자의 개인정보는 원칙적으로, 개인정보 수집 및 이용목적이
                    달성되면 지체 없이 파기합니다. 다만, 관계법령의 규정에 의하여
                    보존할 필요성이 있는 경우에는 관계법령에 따라 보존합니다.
                    <br /><br />
                    <table>
                      <tbody>
                        <tr>
                          <th
                            style="background-color: rgb(207, 220, 224); border: 1px solid rgb(128, 128, 128); width: 33%; height: 20px;"
                          >
                            구분
                          </th>
                          <th
                            style="background-color: rgb(207, 220, 224); border: 1px solid rgb(128, 128, 128); width: 33%;"
                          >
                            보존이유
                          </th>
                          <th
                            style="background-color: rgb(207, 220, 224); border: 1px solid rgb(128, 128, 128); width: 33%;"
                          >
                            보존기간
                          </th>
                        </tr>
                        <tr>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 13%; height: 20px; padding-left: 10px;"
                          >
                            부정이용기록
                          </td>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 53%; padding-left: 10px;"
                          >
                            부정 이용 예방 및 방지<br />
                          </td>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 33%; padding-left: 10px;"
                          >
                            1년<br />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 13%; height: 20px; padding-left: 10px;"
                          >
                            표시·광고에 관한 기록
                          </td>
                          <td
                            rowspan="4"
                            style="border: 1px solid rgb(128, 128, 128); width: 53%; padding-left: 10px;"
                          >
                            전자상거래법<br />
                          </td>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 33%; padding-left: 10px;"
                          >
                            <span class='notranslate'>{{$t('trans.t15_04')}}</span><br />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 13%; height: 20px; padding-left: 10px;"
                          >
                            계약 또는 청약철회 등에 관한 기록
                          </td>
                          <td
                            rowspan="2"
                            style="border: 1px solid rgb(128, 128, 128); width: 53%; padding-left: 10px;"
                          >
                            5년<br />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 13%; height: 20px; padding-left: 10px;"
                          >
                            대금결제 및 재화 등의 공급에 관한 기록
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 13%; height: 20px; padding-left: 10px;"
                          >
                            소비자의 불만 또는 분쟁 처리에 관한 기록
                          </td>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 53%; padding-left: 10px;"
                          >
                            3년<br />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 13%; height: 20px; padding-left: 10px;"
                          >
                            본인 확인에 관한 기록
                          </td>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 13%; padding-left: 10px;"
                          >
                            정보통신망법<br />
                          </td>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 53%; padding-left: 10px;"
                          >
                            <span class='notranslate'>{{$t('trans.t15_04')}}</span><br />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 13%; height: 20px; padding-left: 10px;"
                          >
                            방문에 관한 기록(통신사실 확인 자료)
                          </td>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 13%; padding-left: 10px;"
                          >
                            통신비밀 보호법<br />
                          </td>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 53%; padding-left: 10px;"
                          >
                            <span class='notranslate'>{{$t('trans.t15_03')}}</span><br />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br />
                    2. 개인정보 유효기간 제도 도입<br />
                    가. 당사는 장기간(1년 이상) 서비스 미이용자의 개인정보보호를
                    위하여 2018년 9월 이후에는 다른 이용자의 개인정보와 분리하여
                    저장, 관리합니다. <br />
                    나. 미이용의 기간은 로그인, 상담원 접촉 일자 등으로 산정하며
                    법령에서 정의한 기간 동안 서비스 미이용자에 대하여 분리, 저장
                    관리합니다. <br />
                    다. 당사는 미이용자 개인정보 분리/저장 시점 도래 1개월 이전에
                    이메일 등을 통해 해당 이용자에게 관련내용을 공지합니다. <br />
                    라. 분리, 저장된 미이용자 개인정보는 관련 법령에 따라 일정기간
                    동안 보유하며, 해당 기간 종료 후 파기됩니다. 파기되지 않은
                    개인정보는 해당 이용자의 요청에 따라 서비스 이용을 재개하는
                    시점에 다시 제공됩니다. <br /><br />
                    다만, 서비스 문의를 위하여 개인정보를 제공 후 1년이내에 서비스
                    이용(계약)이 발생하지 않은 경우에는 제공한 개인정보를 즉시
                    파기합니다. <br /><br /><b>제 4장 개인정보의 제 3자 제공</b
                    ><br /><br />
                    1. 회사는 고객님의 개인정보를 "서비스 이용약관" 또는
                    "개인정보처리방침"에서 명시한 범위 내에서 이용하며, 명시한 범위를
                    넘어서 제3자와 공유하거나 제공하지 않습니다. <br />
                    다만, 고객님의 사전 동의가 있거나, 관련 법령(통신비밀보호법,
                    전기통신사업법, 국세기본법 등)의 특별한 규정이 있는 경우, 법령에
                    정해진 규정과 절차에 따라 제공하는 경우는 예외로 합니다. <br />
                    2. 다음의 경우에는 관련 법령의 정해진 규정과 절차에 따라 제공하는
                    경우는 예외로 합니다. <br />
                    가. 서비스제공에 따른 요금 정산을 위하여 필요한 경우(요금 체납의
                    경우) <br />
                    나. 통계작성/학술연구 또는 시장조사를 위하여 필요한 경우로서 특정
                    개인을 알아볼 수 없는 형태로 가공하여 제공하는 경우<br />
                    다. 금융실명거래및비밀보장에 관한법률, 신용정보의
                    이용및보호에관한법률, 전기통신기본법, 전기통신사업법, 지방세법,
                    소비자보호법, 한국은행법, 형사소송법 등 법률에 특별한 규정이 있는
                    경우<br />
                    3. 개인정보의 제3자 제공에 대한 동의를 거부하시는 경우에도 기본
                    서비스를 이용하실 수 있습니다. <br /><b
                      >제 5 장 개인정보의 처리 위탁</b
                    >
                    <br /><br />
                    회사가 이용자의 편의 증진을 위하여 개인정보 처리를 위탁하는 경우
                    수탁자와 위탁업무 내용에 대해 제공자에게 고지 또는 공개하거나
                    사전 동의를 받고 있습니다. <br />
                    회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고
                    있으며, 관계 법률에 따라 위탁계약 시 개인정보가 안전하게 관리될
                    수 있도록 필요한 사항을 규정하고, 이를 준수하도록 관리감독하고
                    있습니다. <br /><br />
                    <table>
                      <tbody>
                        <tr>
                          <th
                            style="background-color: rgb(207, 220, 224); border: 1px solid rgb(128, 128, 128); width: 33%; height: 20px;"
                          >
                            수탁업체
                          </th>
                          <th
                            style="background-color: rgb(207, 220, 224); border: 1px solid rgb(128, 128, 128); width: 33%;"
                          >
                            위탁업무 내용
                          </th>
                          <th
                            style="background-color: rgb(207, 220, 224); border: 1px solid rgb(128, 128, 128); width: 33%;"
                          >
                            개인정보의 보유 및 이용기간
                          </th>
                        </tr>
                        <tr>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 33%; height: 20px; padding-left: 10px;"
                          >
                            LG CNS
                          </td>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 33%; padding-left: 10px;"
                          >
                            알림톡 및 문자발송
                          </td>
                          <td
                            rowspan="7"
                            style="border: 1px solid rgb(128, 128, 128); width: 33%; padding-left: 10px;"
                          >
                            위탁계약 종료시까지
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 33%; height: 20px; padding-left: 10px;"
                          >
                            인포뱅크
                          </td>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 33%; padding-left: 10px;"
                          >
                            SMS 문자발송
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 33%; height: 20px; padding-left: 10px;"
                          >
                            LG U+
                          </td>
                          <td
                            rowspan="3"
                            style="border: 1px solid rgb(128, 128, 128); width: 33%; padding-left: 10px;"
                          >
                            결제처리({{$t('trans.t07_15')}})
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 33%; height: 20px; padding-left: 10px;"
                          >
                            KICC
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 33%; height: 20px; padding-left: 10px;"
                          >
                            (주)PayGate
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 33%; height: 20px; padding-left: 10px;"
                          >
                            대성 글로벌
                          </td>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 33%; padding-left: 10px;"
                          >
                            고객상담
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 33%; height: 20px; padding-left: 10px;"
                          >
                            트랜스코스모스코리아
                          </td>
                          <td
                            style="border: 1px solid rgb(128, 128, 128); width: 33%; padding-left: 10px;"
                          >
                            카카오 상담톡 서비스를 통한<br />메시지 발송 대행
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br /><br /><b>제 6 장 개인정보의 파기절차 및 방법</b
                    ><br /><br />
                    1. 고객의 개인정보는 회사가 고객에게 서비스를 제공하는 기간
                    동안에 한하여 보유 및 이용됩니다. 다만, 관계법령의 규정에 의하여
                    보존할 필요성이 있는 경우에는 관계법령에 따라 보존합니다. <br />
                    2. 회원의 개인정보는 개인정보의 수집목적 또는 제공받은 목적이
                    달성되면 지체없이 파기됩니다. 따라서 일시적인 목적에 의해 수집한
                    개인정보는 그 이용목적을 달성하면 지체없이 삭제합니다. <br />
                    3. 개인정보제공에 대한 동의를 철회할 경우 즉시 수집된 개인정보를
                    파기하며, 예외 규정을 제외하고 어떠한 목적으로도 사용할 수
                    없습니다. <br />
                    4. 회원이 본인이 회사의 회원임을 증빙하기 위해 회사에 발송한
                    신분증 사본 등 서류 일체는 본인확인 후 즉시 파기합니다. <br />
                    5. 파기절차<br />
                    가. 고객님의 개인정보는 수집 및 이용목적이 달성된 후 별도의 DB로
                    옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에
                    의한 정보보호 사유(보유 및 이용기간 참조)에 따라 일정 기간 저장된
                    후 파기됩니다. <br />
                    나. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는
                    보유되는 이외의 다른 목적으로 이용되지 않습니다. <br />
                    6. 파기방법<br />
                    가. 종이(서면)에 작성·출력된 개인정보 : 분쇄하거나 소각 등의
                    방법으로 파기<br />
                    나. DB 등 전자적 파일 형태로 저장된 개인정보 : 재생할 수 없는
                    기술적 방법으로 삭제<br />
                    7. 파기 기한<br />
                    이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는
                    보유기간의 종료일로부터 5일 이내에 파기하며, 서비스문의를 위하여
                    개인정보를 제공 후 1년이내에 계약이 발생하지 않거나 또는 계약이
                    해지된 경우, 뉴스레터 신청 후 수신거부를 한 경우 등 그 개인정보가
                    불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로
                    인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다. <br />
                    8. 장기미이용자의 개인정보 파기<br />
                    정보통신망법에 따라 온라인 또는 유선으로 개인정보 제공 후 12개월
                    이상 서비스 이용(또는 계약)이 없는 고객 및 뉴스레터를 수신거부 한
                    경우 분리, 저장된 미이용자 개인정보는 관련 법령에 따라 일정기간
                    동안 보유하며, 해당 기간 종료 후 파기됩니다. <br />
                    이러한 경우 위 12개월의 기간 도래 30일 전까지 회사는 이용자의
                    개인정보가 파기된다는 사실과 기간 만료일 및 해당 개인정보의
                    항목을 전자우편, 서면, 모사전송, 전화 또는 이와 유사한 방법 중
                    어느 하나의 방법으로 이용자에게 알려드립니다. <br /><br /><b
                      >제 7 장 이용자 및 법정대리인의 권리와 그 행사 방법 </b
                    ><br /><br />
                    고객님(만 14세 미만 아동의 경우 법정대리인 포함)께서는 언제든지
                    개인정보에 대한 열람, 정정을 요구하시거나 가입해지 및 개인정보의
                    수집과 이용, 위탁 또는 제공에 대한 동의를 철회를 하실 수
                    있습니다. <br />
                    고객님의 개인정보 열람 및 정정을 위해서는 ‘개인정보변경’(또는
                    ‘고객정보수정’ 등)을, 가입해지(동의철회)를 위해서는
                    ‘해지신청서’를 작성하여 회사에 제출하거나, 웹사이트 고객님의 경우
                    ‘가입탈퇴’를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정
                    또는 탈퇴가 가능합니다. <br /><br />
                    1. 개인정보의 열람증명 또는 정정<br />
                    가. 고객님(법정대리인)께서는 회사를 직접 방문하시거나 전화,
                    이메일 등을 통하여 개인정보에 대한 열람증명을 요구할 수 있습니다.
                    <br />
                    나. 고객님께서 본인의 개인정보에 대한 열람증명을 요구하시는 경우
                    고객님의 신분을 증명할 수 있는 주민등록증, 여권, 운전면허증(신형)
                    등의 신분증명(사본)을 제시 받아 본인 여부를 확인합니다. <br />
                    다. 고객님의 대리인이 열람증명을 요구하는 경우에는 대리 관계를
                    나타내는 위임장, 명의고객님의 인감증명서와 대리인의 신분증명서
                    등의 증표를 제시 받아 대리인인지 여부를 확인합니다. <br />
                    라. 고객님의 개인정보는 오류에 대한 정정을 요청하신 경우에는
                    정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지
                    않습니다. <br />
                    또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정
                    처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록
                    하겠습니다. <br />
                    2. 개인정보의 수집과 이용 또는 제공에 대한 동의 철회<br />
                    가. 고객님께서는 위 제1항 ‘나’에 고지된 신분증을 지참하시고
                    회사를 방문하여 개인정보의 수집과 이용, 위탁 또는 제공에 대해
                    동의하시거나, 이에 대한 동의를 선택적으로 철회하실 수 있습니다.
                    <br />
                    나. 회사의 고객센터에 전화 또는 "https://www.wifidosirak.com &gt;
                    고객센터 &gt; 고객게시판" 또는 이메일 문의 등으로 연락하시면 지체
                    없이 조치하겠습니다. <br />
                    다. 법정대리인(부모 중 어느 일방이 요구할 경우에도 가능)은 만
                    14세 미만 아동의 개인정보 수집과 이용 또는 제공에 대한 동의를
                    철회할 수 있으며, 만 14세 미만의 아동이 제공한 개인정보에 대한
                    열람 또는 오류의 정정을 요구할 수 있습니다. <br />
                    라. 회사는 고객님의 요청에 의해 해지 또는 삭제된 개인정보는
                    “회사가 수집하는 개인정보의 보유 및 이용기간”에 명시된 바에 따라
                    처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고
                    있습니다. <br /><br /><b
                      >제 8 장 개인정보 자동 수집장치의 설치 운영 및 그 거부에 관한
                      사항</b
                    ><br /><br />
                    회사는 이용자의 정보를 수시로 저장하고 찾아내는 "쿠키(cookie)"와
                    "세션"을 운용합니다. <br />
                    "쿠키"란 회사의 웹사이트를 운영하는데 이용되는 서버가 이용자의
                    브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터
                    하드디스크에 저장됩니다. <br />
                    회사는 쿠키 운용과 관련하여 이용자의 컴퓨터는 식별하지만 이용자를
                    개인적으로 식별하지는 않습니다. <br />
                    "세션"이란 웹사이트를 운영하는데 이용되는 서버가 이용자 로그인
                    시간 동안에 이용자의 정보를 서버에 저장하는 것을 의미합니다.
                    <br />
                    회사(는) 다음과 같은 목적을 위해 쿠키와 세션을 사용합니다.
                    <br /><br />
                    가. 쿠키와 세션의 사용 목적<br />
                    회사는 이용자들에게 특화된 맞춤서비스를 제공하기 위해서
                    이용자들의 정보를 저장하고 수시로 불러오는 "세션(session)"을
                    사용합니다. <br />
                    이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서,
                    이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를
                    허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든
                    쿠키의 저장을 거부할 수도 있습니다. <br /><br />
                    나. 쿠키 설정 거부 방법 <br />
                    예: 쿠키 설정을 거부하는 방법으로는 <br />
                    설정방법 예(인터넷 익스플로어의 경우) : 웹 브라우저 상단의 도구
                    &gt; 인터넷 옵션 &gt; 개인정보 &gt; 설정에서 조정 가능.<br />
                    단, 이용자께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이
                    있을 수 있습니다.<br /><br /><br /><b
                      >제 9장 개인정보보호를 위한 기술적, 관리적 보호대책</b
                    ><br /><br />
                    회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난,
                    유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은
                    기술적/관리적 대책을 강구하고 있습니다. <br />
                    가. 개인정보 암호화<br />
                    이용자의 개인정보(신용카드정보 등)는 암호화하여 저장/관리되고
                    있으며, 암호화통신(SSL) 등을 통하여 네트워크상에서 개인정보를
                    안전하게 전송할 수 있도록 하고 있습니다. <br />
                    비밀번호는 복호화가 불가능한 일방향 암호화되어 저장/관리되고
                    있습니다. <br />
                    나. 해킹 등에 대비한 대책<br />
                    회사는 해킹이나 악성코드 등에 의해 이용자의 개인정보가 유출되거나
                    훼손되는 것을 막기 위해 외부로부터 접근이 통제된 구역에 시스템을
                    설치하고 있습니다. <br />
                    또한 최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가
                    유출되거나 손상되지 않도록 방지하고 있으며, 암호화통신(SSL) 등을
                    통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고
                    있습니다. <br />
                    그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을
                    통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한
                    모든 기술적 장치를 갖추려고 노력하고 있습니다. <br />
                    다. 개인정보 취급자의 최소화 및 교육실시<br />
                    개인정보를 처리하는 직원을 최소화 하며, 업무용 PC에서는 일부 외부
                    인터넷 서비스를 사용할 수 없도록 차단 하여 개인정보 유출에 대한
                    위험을 줄이고 있습니다. <br />
                    또한 개인정보를 보관하는 데이터베이스 시스템과 개인정보를
                    처리하는 시스템에 대한 비밀번호의 생성과 변경, 그리고 접근할 수
                    있는 권한에 대한 체계적인 기준을 마련하고 지속적인 감사를
                    실시하고 있습니다. <br />
                    또한 개인정보를 처리하는 모든 임직원들을 대상으로 개인정보보호
                    의무와 보안에 대한 정기적인 교육과 캠페인을 실시하고 있습니다.
                    <br /><br /><b>제 10 장 개인정보 보호책임자 및 담당자 안내</b
                    ><br /><br />
                    회사는 고객님의 개인정보를 보호하고 개인정보와 관련한 불만 및
                    문의사항을 처리하기 위하여 아래와 같이 개인정보 보호책임자와
                    개인정보 보호담당자를 지정하여 운영하고 있습니다. <br />
                    또한 고객님의 의견을 매우 소중하게 생각합니다. 고객님께서
                    문의사항이 있을 경우 회사의 지사를 방문하시거나 콜센터 또는
                    개인정보담당부서로 문의하시면 신속.정확한 답변을 드리겠습니다.
                    <br />
                    1.개인정보 보호책임자<br />
                    가. 이름 : 이충덕<br />
                    나. 소속 : 전략기획팀<br />
                    다. 직위 : 부장<br />
                    라. 연락처 : 1566-9070<br />
                    마. 이메일 : info@widemobile.com<br /><br />
                    2.개인정보 보호담당자<br />
                    가. 이름 : 윤성수<br />
                    나. 소속 : 전략기획팀<br />
                    다. 직위 : 과장<br />
                    라. 연락처 : 1566-9070<br />
                    마. 이메일 : info@widemobile.com<br /><br />
                    3. 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래
                    기관에 문의하시기 바랍니다.<br />
                    가. 개인정보침해신고센터 : http://privacy.kisa.or.kr /
                    (국번없이)118<br />
                    나. 개인정보 분쟁조정위원회(http://kopico.go.kr) : 1833-6972<br />
                    다. 대검찰청 사이버수사과 : http://www.spo.go.kr / (국번 없이)
                    1301<br />
                    라. 경찰청 사이버안전국 : http://cyberbureau.police.go.kr / (국번
                    없이) 182<br /><br /><b>제 11장 기타 개인정보 취급에 관한 방침</b
                    ><br /><br />
                    가. 개인 아이디와 비밀번호 관리<br />
                    이용자가 사용하고 계시는 아이디와 비밀번호는 원칙적으로
                    이용자만이 사용하도록 되어 있습니다. <br />
                    회사의 고의 또는 과실이 없는 경우에, 이용자의 아이디와 비밀번호
                    도용 또는 기타 타인의 사용으로 인해 발생된 문제에 대하여 회사가
                    책임지지 않습니다. <br />
                    어떠한 경우에도 비밀번호는 타인에게 알려 주지 마시고 로그온
                    상태에서는 주위의 다른 사람에게 개인 정보가 유출되지 않도록
                    특별한 주의를 기울여 주시기 바랍니다. <br />
                    타인의 개인정보를 도용하였음이 확인되었을 경우에는 해당 사이트
                    이용이 일방적으로 제한될 수 있으며, 유관법에 의해 징역 또는
                    벌금이 부과될 수 있습니다. <br /><br /><b>제 12장 고지의무</b
                    ><br /><br />
                    본 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 경우 개정
                    최소 7일 전에 ‘공지사항’을 통해 사전 공지를 할 것입니다.<br />
                    다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자
                    권리의 중대한 변경이 발생할 때에는 최소 30일 전에 공지하며, 필요
                    시 이용자 동의를 다시 받을 수도 있습니다. <br /><br />
                    - 개인정보 처리방침 공고일자 : 2018년 10월 15일<br />
                    - 개인정보 처리방침 시행일자 : 2018년 10월 15일<br />
                  </div>
                  <div class="chkAr">
                    <input
                      type="checkbox"
                      id="policyCheck"
                      name="privacyCheck"
                      v-model="isPrivacy"
                    />
                    <label for="policyCheck" class="policyCheckLabel"></label>
                    <span class="policyAgree">{{$t('trans.t12_09')}}</span>
                  </div>

                  <div class="btnWp">
                    <button
                      class="btnCom gray"
                      id="btnPrev"
                      type="button"
                      @click="$router.go(-1)"
                    >
                      이전
                    </button>
                    <button class="btnCom notranslate" id="btnCertify" type="submit">
                      {{$t('trans.t15_05')}}
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <!-- 회원 로그인 -->
            <form data-vv-scope="frmEmail" @submit.prevent="Login">
              <div
                :class="['loginTab-content', { current: isMemberLogin }]"
                id="login_number"
              >
                <div class="memberLogin">
                  <div class="inputAr">
                    <input
                      type="text"
                      class="loginInfo"
                      :value="email"
                      @input="inputEmail"
                      :placeholder="$t('trans.t16_04')"
                      v-validate="'required|email'"
                      name="member_login_id"
                    />
                  </div>
                  <div class="inputAr">
                    <input
                      type="password"
                      class="loginInfo"
                      :value="pass"
                      @input="inputPass"
                      placeholder="비밀번호"
                      v-validate="'required'"
                      name="member_login_pass"
                    />
                  </div>
                  <div class="inputAr">
                    <span
                      :class="['check-btn notranslate', { active: isLoginKeep }]"
                      @click="isLoginKeep = !isLoginKeep"
                      >{{$t('trans.t12_11')}}</span
                    >
                  </div>
                  <div class="btnWp">
                    <button class="loginBtn notranslate" type="submit">
                      {{$t('trans.t01_03')}}
                    </button>
                    <button class="loginBtn gray notranslate" @click="$router.push('/')">
                      {{$t('trans.t12_12')}}
                    </button>
                  </div>
                  <div class="snsLogin">
                    <span class="snsTit notranslate">SNS {{$t('trans.t01_03')}}</span>
                  </div>
                  <ul class="snsIcon">
                    <li>
                      <!-- <kakao-login  api-key="2bcd2636d2020bdfc45e2063511b94b9" image="kakao_login_btn_small" :on-success="onKakaoSuccess" :on-failure="onKakaoFailure"></kakao-login> -->
                    </li>
                    <li>
                      <a @click="kakao"><img src="../../images/iconKakao.png"/></a>
                    </li>
                    <li>
                      <a @click="naver"><img src="../../images/iconNaver.png"/></a>
                    </li>
                    <li>
                      <a @click="facebook"><img src="../../images/iconFb.png"/></a>
                    </li>
                  </ul>
                  <div id="naverIdLogin" style="display:none;"></div>

                  <div class="loginOpt">
                    <span
                      ><a class='notranslate'
                        @click="
                          $router.push({
                            name: 'MemberFindView',
                            query: { q: 1 },
                          })
                        "
                        >{{$t('trans.t12_14')}}</a
                      ></span
                    >
                    <span
                      ><a class='notranslate'
                        @click="
                          $router.push({
                            name: 'MemberFindView',
                            query: { q: 2 },
                          })
                        "
                        >{{$t('trans.t12_15')}}</a
                      ></span
                    >
                    <span
                      ><router-link :to="{ name: 'MemberJoinView' }"
                        ><span class='notranslate'>{{$t('trans.t12_16')}}</span></router-link
                      ></span
                    >
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as auth from "../../api/auth";
import { loginType, snsKeys, snsType } from "../../js/enum";
import { snslogin, fetchUserAuth, fetchMemberMvnoUser } from "../../api/member";
import { memberMixin } from "../../mixin/storeMixin";
import { windowOpen, aesEncrypt, aesDecrypt } from "../../js/util";
import { fetchUserCert } from "../../api/member"

export default {
  mixins: [memberMixin],

  mounted() {
    try {
      var self = this;

      window.fbAsyncInit = function() {
        FB.init({
          appId: "1620337284941713",
          xfbml: true,
          version: "v4.0",
        });
        FB.AppEvents.logPageView();
      };

      (function(d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");

      if (!Kakao.isInitialized()) {
        window.Kakao.init(snsKeys.kakao.key);
      }

      window.addEventListener("message", function(e) {
        if (
          e.origin === "http://api.wifidosirak.com" ||
          e.origin === "https://api.wifidosirak.com"
        ) {
          if (e.data.resultData.resultCode) {
            const res_login = snslogin({
              sns_id: e.data.resultData.resultData,
              sns_type: snsType.naver,
            });

            res_login.then((res) => {
              if (res.data.ResultCode) {
                localStorage.removeItem("user_sessoin");

                self.MTS_SET_LOGIN({
                  member_login_type: loginType.SNS,
                  sns: "naver",
                  member_seq: res.data.ResponseData.member_seq,
                  member_cellnumber: res.data.ResponseData.member_cellnumber,
                  member_name: res.data.ResponseData.member_name,
                  member_id: res.data.ResponseData.member_id,
                  isLogin: true,
                });

                //로그인정보 유지시 로컬스토리지도 저장
                if (self.isLoginKeep) {
                  localStorage.setItem(
                    "user_session",
                    self.aesEncrypt(JSON.stringify(self.memberInfo), "user_session")
                  );
                }

                if (self.$route.query.view) {
                  self.$router.replace({ name: self.$route.query.view });
                  return;
                }

                self.$router.replace("/");

                return;
              }
            });
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  },

  data() {
    return {
      naverObj: {},
      isMemberLogin: false,
      name: "",
      mobile: "",
      authNumber: "",
      isAuth: false,
      email: "",
      pass: "",
      isLoginKeep: false,
      isPrivacy: false,
      isSend: false,
      order_id: "",
    };
  },
  created() {},
  methods: {
    callbackFunction(status) {
      console.log(status);
    },

    kakao() {
      var self = this;
      window.Kakao.Auth.login({
        scope: "profile",
        success: function(authObj) {
          window.Kakao.API.request({
            url: "/v2/user/me",
            success: (res) => {
              const reslogin = new Promise(function(resolve, reject) {
                const res_login = snslogin({
                  sns_id: res.id,
                  sns_type: snsType.kakao,
                });

                resolve(res_login);
              });

              reslogin.then(function(res) {
                if (res.data.ResultCode) {
                  localStorage.removeItem("user_sessoin");

                  self.MTS_SET_LOGIN({
                    member_login_type: loginType.SNS,
                    sns: "kakao",
                    member_seq: res.data.ResponseData.member_seq,
                    member_cellnumber: res.data.ResponseData.member_cellnumber,
                    member_name: res.data.ResponseData.member_name,
                    member_id: res.data.ResponseData.member_id,
                    isLogin: true,
                  });

                  //로그인정보 유지시 로컬스토리지도 저장
                  if (self.isLoginKeep) {
                    localStorage.setItem(
                      "user_session",
                      self.aesEncrypt(
                        JSON.stringify(self.memberInfo),
                        "user_session"
                      )
                    );
                  }

                  if (self.$route.query.view) {
                    self.$router.replace({ name: self.$route.query.view });
                    return;
                  }

                  self.$router.replace("/");

                  return;
                }

                self.kakaoTrans(res.data.ResultMessage).then((res) => alert(res));
              });
            },
          });
        },
      });
    },

    naver() {
      windowOpen("http://api.wifidosirak.com/sns/naverlogin", 452, 550);
    },

    facebook() {
      const self = this;
      FB.login(
        async function(response) {
          if (response.status === "connected") {
            const res_login = await snslogin({
              sns_id: response.authResponse.userID,
              sns_type: snsType.facebook,
            });

            if (res_login.data.ResultCode) {
              localStorage.removeItem("user_sessoin");

              self.MTS_SET_LOGIN({
                member_login_type: loginType.SNS,
                sns: "facebook",
                member_seq: res.data.ResponseData.member_seq,
                member_cellnumber: res.data.ResponseData.member_cellnumber,
                member_name: res.data.ResponseData.member_name,
                member_id: res.data.ResponseData.member_id,
                isLogin: true,
              });

              //로그인정보 유지시 로컬스토리지도 저장
              if (self.isLoginKeep) {
                localStorage.setItem(
                  "user_session",
                  self.aesEncrypt(JSON.stringify(this.memberInfo), "user_session")
                );
              }

              if (self.$route.query.view) {
                await self.$router.replace({ name: self.$route.query.view });
                return;
              }

              await self.$router.replace("/");

              return;
            }

            self.kakaoTrans(res_login.data.ResultMessage).then((res) => alert(res));

            return;
          }
        },
        { scope: "public_profile,email" }
      );
    },

    inputName(e) {
      this.name = e.target.value;
    },

    inputOrderNo(e) {
      this.order_id = e.target.value;
    },

    inputEmail(e) {
      this.email = e.target.value;
    },

    inputPass(e) {
      this.pass = e.target.value;
    },

    async Login() {
      const mailLogin = await this.$validator.validateAll("frmEmail");

      if (mailLogin) {
        const res = await auth.login({
          member_id: this.email,
          member_password: this.pass,
        });

        if (!res.data.ResultCode) {
          this.kakaoTrans(res.data.ResultMessage).then((res) => alert(res));
          return;
        }

        localStorage.removeItem("user_sessoin");

        //vuex 로그인 정보에 저장
        this.MTS_SET_LOGIN({
          member_login_type: loginType.EMAIL,
          member_seq: res.data.ResponseData.member_seq,
          member_cellnumber: res.data.ResponseData.member_cellnumber,
          member_name: res.data.ResponseData.member_name,
          member_id: res.data.ResponseData.member_id,
          isLogin: true,
        });

        //로그인정보 유지시 로컬스토리지도 저장
        if (this.isLoginKeep) {
          localStorage.setItem(
            "user_session",
            this.aesEncrypt(JSON.stringify(this.memberInfo), "user_session")
          );
        }

        if (this.$route.query.view) {
          this.$router.replace({ name: this.$route.query.view });
          return;
        }

        this.$router.push("/");

        return;
      }

      this.kakaoTrans(this.errors.items[0].msg).then((res) => alert(res));
    },
    async onSend() {
      const res = await auth.RequestAuthNo({
        user_celnumber: this.mobile,
        user_name: this.name,
      });

      if (res.data.ResultCode) {
        this.isSend = true;
      }

      this.kakaoTrans(res.data.ResultMessage).then((res) => alert(res));
    },
    async onUserAuth() {
      const mvnoLogin = await this.$validator.validateAll("frmNumber");

      if (mvnoLogin) {
        // if (!this.isAuth) {
        //   this.kakaoTrans("인증번호 확인은 필수입니다.").then((res) => alert(res));
        //   return;
        // }

        if (!this.isPrivacy) {
          this.kakaoTrans("개인정보수집 체크는 필수입니다.").then((res) =>
            alert(res)
          );
          return;
        }

        const res = await fetchUserCert({
          user_name: this.name,
          orderid: this.order_id
        })

        if (!res.data.ResultCode) {
          this.kakaoTrans(res.data.ResultMessage).then((res) => alert(res));
          return;
        }

        this.$router.replace({
          name: "ApplicationListView",
          query: {
            user: encodeURIComponent(
              aesEncrypt(
                JSON.stringify(res.data.ResponseData),
                "dosirak.mobile.user"
              )
            ),
          },
        });
        return;
      }

      this.kakaoTrans(this.errors.items[0].msg).then((res) => alert(res));
    },
    async onAuthConfirm() {
      if (!this.authNumber) {
        this.kakaoTrans("인증번호를 입력하세요.").then((res) => alert(res));
        return;
      }

      const res = await auth.AuthConfirm({
        certify_no: this.authNumber,
        user_celnumber: this.mobile,
      });

      if (res.data.ResultCode) {
        this.isAuth = true;
      }

      this.kakaoTrans(res.data.ResultMessage).then((res) => alert(res));
    },
  },
};
</script>

<style></style>
