<template>
  <div class="wrap subPage">
    <div class="container">
      <!-- sub title -->
      <div class="subTitAr fW-R">
        <h1>
            <span class="notranslate">{{$t('trans.t14_01')}}</span>
        </h1>
        <span class="subTit fW-L notranslate">{{$t('trans.t15_01')}}</span>
      </div>
      <!-- 서브페이지 컨텐츠 -->
      <div class="subPageAr">
        <div class="applyDetail">
          <div class="applyInquiry">
            <div class="left">
              <button
                :class="['btnTerm fW-R notranslate', { active: selectedMonth === 1 }]"
                @click="onSelectedMonth(1)"
              >
                {{$t('trans.t15_02')}}
              </button>
              <button
                :class="['btnTerm fW-R notranslate', { active: selectedMonth === 3 }]"
                @click="onSelectedMonth(3)"
              >
                {{$t('trans.t15_03')}}
              </button>
              <button
                :class="['btnTerm fW-R notranslate', { active: selectedMonth === 6 }]"
                @click="onSelectedMonth(6)"
              >
                {{$t('trans.t15_04')}}
              </button>
            </div>
            <div class="right">
              <v-date-picker
                v-model="startDate"
                mode="date"
                class="date"
                :masks="masks"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input class="date" :value="inputValue" v-on="inputEvents" />
                </template>
              </v-date-picker>
              <span>~</span>
              <v-date-picker
                v-model="endDate"
                mode="date"
                class="date"
                :masks="masks"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input class="date" :value="inputValue" v-on="inputEvents" />
                </template>
              </v-date-picker>

              <button class="btnInquiry fW-R mL10 notranslate" @click="fetchMyAccount">
                {{$t('trans.t15_05')}}
              </button>
            </div>
          </div>
          <div style="clear: both"></div>
          <div class="applyAr">
            <table>
              <thead>
                <tr class="notranslate">
                  <th>{{$t('trans.t15_06')}}</th>
                  <th>{{$t('trans.t14_04')}}</th>
                  <th class="pc-con">{{$t('trans.t04_03')}}</th>
                  <th class="pc-con">{{$t('trans.t15_09')}}</th>
                  <th>{{$t('trans.t15_10')}}</th>
                  <th>{{$t('trans.t15_11')}}</th>
                  <th>{{$t('trans.t15_12')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in myList" :key="index">
                  <td>
                    <span class="applyTxt">{{ item.regDate }}</span>
                  </td>
                  <td>
                    <span class="applyTxt">{{ item.orderid }}</span>
                    <span
                      ><a
                        @click="
                          $router.push({
                            name: 'OnlineApplicationFormView',
                            query: {
                              order: item.order_id_encrypt,
                            },
                          })
                        "
                        v-if="item.status_cd !== 2"
                        >가입신청서 보기</a
                      ></span
                    >
                  </td>
                  <td class="pc-con">
                    <span class="applyTxt">{{ item.subtype }}</span>
                  </td>
                  <td class="pc-con">
                    <span class="applyTxt">{{ item.plan_name }}</span>
                  </td>
                  <td>
                    <span class="applyTxt">{{ item.openstatus }}</span>
                    <button
                      class="blueBtn"
                      v-if="item.openstatus_yn === 'Y'"
                      @click="requestOpen(item)"
                    >
                      개통요청
                    </button>
                    <button
                      v-if="item.open_cancel_yn === 'Y'"
                      class="blueBtn notranslate"
                      @click="requestOpenCancel(item)"
                    >
                      {{$t('trans.t13_06')}}
                    </button>
                  </td>
                  <td>
                    <span class="applyTxt">{{ item.delivery_status }}</span>
                    <button
                      v-show="item.delivery_no"
                      class="blueBtn notranslate"
                      @click="openPost(item.delivery_no)"
                      type="button"
                    >
                      {{$t('trans.t21_08')}}
                    </button>
                  </td>
                  <td>
                    <button
                      class="blueBtn"
                      v-if="
                        (item.reserv_cancel_yn === 'Y' ||
                          item.reserv_wait_cancel_yn === 'Y') &&
                          item.status_cd === 1
                      "
                      :data-order="item"
                      @click="onCancel(item.order_id_encrypt, 1)"
                    >
                      취소요청
                    </button>
                    <button
                      class="blueBtn"
                      v-if="item.status_cd === 4 || item.status_cd === 7"
                      :data-order="item"
                      disabled
                    >
                      요청중
                    </button>
                    <button
                      class="blueBtn"
                      v-if="item.status_cd === 2"
                      :data-order="item"
                      disabled
                    >
                      취소완료
                    </button>
                    <button
                      v-if="item.delivery_cancel_yn === 'Y' && item.status_cd === 1"
                      class="blueBtn"
                      :data-order="item"
                      @click="onCancel(item.order_id_encrypt, 2)"
                    >
                      반품요청
                    </button>
                    <button
                      v-if="item.status_cd === 8"
                      class="blueBtn"
                      :data-order="item"
                      @click="onCancel(item.order_id_encrypt, 2)"
                    >
                      반품접수
                    </button>
                    <button
                      v-if="item.status_cd === 6"
                      class="blueBtn"
                      :data-order="item"
                      @click="onCancel(item.order_id_encrypt, 2)"
                    >
                      반품접수
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <button
            v-show="btnMoreHidden"
            class="moreBtn fW-R notranslate"
            type="button"
            @click="onMore"
          >
            + {{$t('trans.t15_13')}}
          </button>
        </div>
      </div>
    </div>

    <!-- 취소 안내 팝업 -->
    <div class="popWpper paymentSystemPop" v-if="isCancel">
      <form data-vv-scope="frmCancel">
        <div class="popContainer center">
          <button class="close ic" type="button" @click="isCancel = false">
            닫기
          </button>
          <div class="popWP">
            <div class="popContTit line">
              <p class="tit">취소 안내</p>
            </div>
            <div class="popBody">
              <div class="noticeAr" v-show="isCancelType === 1">
                <span class="nt_Txt">가입 신청이 즉시 취소됩니다.</span>
                <span class="nt_Txt"
                  >취소된 신청서는 재 사용할 수 없으며, 필요하신 경우 가입신청서를
                  다시 작성하셔야 합니다.</span
                >
                <span class="nt_Txt"
                  >환불 방법은 결제 방법에 따라 상이하며, 아래 안내를 참고하시기
                  바랍니다.</span
                >
                <span class="nt_Txt"
                  >환불은 취소 접수일로부터 약 3~4일 이내 처리됩니다. (주말, 공휴일
                  제외)</span
                >
              </div>
              <div class="noticeAr" v-show="isCancelType === 2">
                <span class="nt_Txt">반품 신청.</span>
              </div>
              <div class="appConfirm mT20 notranslate">
                <span class="appTit notranslate">{{$t('trans.t01_08')}}</span>
                <ul>
                  <li>
                    <span class="confirmTit notranslate">{{$t('trans.t15_06')}}</span>
                    <span class="confirmTxt">{{ cancelData.regdate }}</span>
                  </li>
                  <li>
                    <span class="confirmTit notranslate">{{$t('trans.t14_04')}}</span>
                    <span class="confirmTxt">{{ cancelData.orderid }}</span>
                  </li>
                  <li>
                    <span class="confirmTit notranslate">{{$t('trans.t04_03')}}</span>
                    <span class="confirmTxt ">{{ cancelData.subtype }}</span>
                  </li>
                  <li>
                    <span class="confirmTit notranslate">{{$t('trans.t01_09')}}</span>
                    <span class="confirmTxt">{{ cancelData.name }}</span>
                  </li>
                </ul>
              </div>
              <div class="appConfirm mT20">
                <span class="appTit">결제/환불 정보</span>
                <ul>
                  <li>
                    <span class="confirmTit">결제금액</span>
                    <span class="confirmTxt"
                      >{{ cancelData.amount | currency }}원</span
                    >
                  </li>
                  <li>
                    <span class="confirmTit notranslate">{{$t('trans.t09_08')}}</span>
                    <span class="confirmTxt">{{ cancelData.paytypename }}</span>
                  </li>
                  <li>
                    <span class="confirmTit">환불예정금액</span>
                    <span class="confirmTxt"
                      >{{ cancelData.amount | currency }}원</span
                    >
                  </li>
                  <li>
                    <span class="confirmTit">환불방법</span>
                    <span class="confirmTxt">{{ cancelData.refundname }}</span>
                  </li>
                </ul>
              </div>
              <div class="refundInfo" v-if="cancelData.refund_yn === 'Y'">
                <span class="refundTit">환불 받으실 계좌 정보를 입력해주세요.</span>
                <div class="inputAr">
                  <div class="inpGroup">
                    <select
                      v-model="bank_cd"
                      v-validate="'required'"
                      data-vv-name="bank"
                    >
                      <option value=""><span class='notranslate'>{{$t('trans.t08_06')}}</span></option>
                      <option value="002">산업은행</option>
                      <option value="003">기업은행</option>
                      <option value="004">국민은행</option>
                      <option value="007">수협중앙회</option>
                      <option value="011">농협은행</option>
                      <option value="020">우리은행</option>
                      <option value="023">SC은행</option>
                      <option value="027">한국씨티은행</option>
                      <option value="031">대구은행</option>
                      <option value="032">부산은행</option>
                      <option value="034">광주은행</option>
                      <option value="035">제주은행</option>
                      <option value="037">전북은행</option>
                      <option value="039">경남은행</option>
                      <option value="045">새마을금고</option>
                      <option value="048">신협중앙회</option>
                      <option value="071">우체국</option>
                      <option value="081">KEB하나은행</option>
                      <option value="088">신한은행</option>
                      <option value="089">케이뱅크</option>
                      <option value="209">유안타증권</option>
                      <option value="240">삼성증권</option>
                    </select>
                    <div class="grouping selectInput">
                      <input
                        type="text"
                        class="idNum"
                        @input="inputBankOwner"
                        :value="bank_owner"
                        v-validate="'required'"
                        data-vv-name="accountName"
                        placeholder="예금주명"
                      />
                    </div>
                  </div>
                </div>
                <div class="inputAr">
                  <input
                    type="text"
                    class="full"
                    v-model="bank_number"
                    v-validate="'required|numeric'"
                    placeholder="계좌번호 입력(숫자만)"
                    data-vv-name="bank_number"
                  />
                </div>
                <div class="inputAr">
                  <select
                    class="full"
                    @change="onReasonChange"
                    v-validate="'required'"
                    data-vv-name="reason"
                  >
                    <option value="">취소 사유 선택</option>
                    <option value="단순 변심">단순 변심</option>
                    <option value="요금 불만족">요금 불만족</option>
                    <option value="서비스 불만족">서비스 불만족</option>
                    <option value="기타(직접입력)">기타(직접입력)</option>
                  </select>
                </div>
                <div class="inputAr" v-show="isReason">
                  <textarea @input="inputReason" :value="cancelReason"></textarea>
                </div>
              </div>
              <!-- 취소신청 버튼 -->
              <button
                class="selectBtn notranslate"
                type="button"
                @click="cancelCommit"
                v-if="isCancelType === 1"
              >
                {{$t('trans.t13_06')}}
              </button>
              <button
                class="selectBtn"
                type="button"
                @click="refundCommit"
                v-if="isCancelType === 2"
              >
                반품신청
              </button>
            </div>
          </div>
        </div>
        <div class="dim"></div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  fetchMemberMvno,
  fetchMemberMvnoMobile,
  fetchMemberMvnoUser,
} from "../../api/member";
import * as Enum from "../../js/enum";
import VCalendar from "v-calendar";
import { aesDecrypt, windowOpen } from "../../js/util";
import {
  fetchOrder,
  patchOrderCancel,
  patchRefundCancel,
  patchOpenRequest,
  patchOpenCancelRequest,
} from "../../api/order";

export default {
  components: {
    VCalendar,
  },

  data() {
    return {
      myList: [],
      myListAll: [],
      startDate: this.$moment()
        .add(-1, "months")
        .format("YYYY-MM-DD"),
      endDate: this.$moment().format("YYYY-MM-DD"),
      date: null,
      modalIsActive: false,
      inputProps: {
        class: "input",
      },
      showClearMargin: false,
      selectedMonth: 1,
      currentNo: 3,
      btnMoreHidden: true,
      isCancel: false,
      cancelData: {},
      isCancelType: 1,
      masks: {
        input: "YYYY-MM-DD",
      },
      cancelReason: "",
      bank_cd: "",
      bank_number: "",
      bank_owner: "",
      isReason: false,
    };
  },

  methods: {
    //신청내역조회
    async fetchMyAccount() {
      let res = [];

      if (this.$route.query.user) {
        const user = JSON.parse(
          aesDecrypt(
            decodeURIComponent(this.$route.query.user.toString()),
            "dosirak.mobile.user"
          )
        );

        res = await fetchMemberMvnoUser({
          user_name: user.clientname,
          user_celnumber: user.celnumber,
          orderid: user.orderid,
          start_date: this.$moment(this.startDate).format("YYYY-MM-DD"),
          end_date: this.$moment(this.endDate).format("YYYY-MM-DD"),
        });
      } else {
        if (
          this.memberInfo.member_login_type === Enum.loginType.EMAIL ||
          this.memberInfo.member_login_type === Enum.loginType.SNS
        ) {
          res = await fetchMemberMvno({
            member_seqnum: this.memberInfo.member_seq,
            start_date: this.$moment(this.startDate).format("YYYY-MM-DD"),
            end_date: this.$moment(this.endDate).format("YYYY-MM-DD"),
          });
        } else if (this.memberInfo.member_login_type === Enum.loginType.MOBILE) {
          res = await fetchMemberMvnoMobile({
            user_cell: this.memberInfo.member_mvno.opencelnumber,
            user_name: this.memberInfo.member_mvno.clientname,
            start_date: this.$moment(this.startDate).format("YYYY-MM-DD"),
            end_date: this.$moment(this.endDate).format("YYYY-MM-DD"),
          });
        }
      }

      if (res.data.ResultCode) {
        this.myList = res.data.ResponseData.filter((x) => x.no <= 3);
        this.myListAll = res.data.ResponseData;
        this.currentNo = 3;

        if (this.myListAll.length >= 3) {
          this.btnMoreHidden = true;
        }

        return;
      }

      this.kakaoTrans(res.data.ResultMessage).then((res) => alert(res));
    },

    async onCancel(payload, flag) {
      console.log(payload, flag)


      const [order, extra] = await fetchOrder({
        orderId: payload,
      });

      this.cancelData = order.data.ResponseData;
      this.isCancelType = flag;
      this.bank_cd = "";
      this.bank_owner = "";
      this.bank_number = "";
      this.cancelReason = "";
      this.isCancel = true;
      this.isReason = false;
    },

    onSelectedMonth(payload) {
      this.selectedMonth = payload;
      this.startDate = this.$moment()
        .add(parseInt(payload) * -1, "months")
        .format("YYYY-MM-DD");
      this.endDate = this.$moment().format("YYYY-MM-DD");
    },

    onMore() {
      if (this.currentNo + 3 >= this.myListAll.length) {
        this.btnMoreHidden = false;
      }
      this.currentNo = this.currentNo + 3;
      this.myList = this.myListAll.filter((x) => x.no <= this.currentNo);
    },

    openPost(payload) {
      windowOpen(
        `https://zedes.zenexpress.kr/api/zedes_site_delivery.do?apikey=2c2e159ccd870810fd7a1dd3ea26b30049aad15c3c66cd72f1ed11b1ea3e2040&cb=${payload}`,
        780,
        700
      );
    },

    onReasonChange(e) {
      if (e.target.value === "기타(직접입력)") {
        this.isReason = true;
        this.cancelReason = "";
        return;
      }
      this.cancelReason = e.target.value;
      this.isReason = false;
    },

    async cancelCommit() {
      try {
        if (this.cancelData) {
          const cancelvdt = await this.$validator.validateAll("frmCancel");

          if (!cancelvdt) {
            this.kakaoTrans(this.errors.items[0].msg).then((res) => alert(res));
            return;
          }



          const resCancel = await patchOrderCancel({
            orderid: this.cancelData.orderid,
            amount: this.cancelData.amount,
            bank_cd: this.bank_cd,
            bank_owner: this.bank_owner,
            bank_number: this.bank_number,
            reason: this.cancelReason,
            delivery_status: this.cancelData.delivery_status_cd,
          });

          if (resCancel.status === 200) {
            if (resCancel.data.ResultCode) {
              this.kakaoTrans("접수되었습니다.").then((res) => alert(res));
              this.isCancel = false;
              await this.fetchMyAccount();
              return;
            }
          }
          
          this.kakaoTrans(resCancel.data.ResultMessage).then((res) => alert(res));
        }
      } catch (error) {
        this.kakaoTrans(error).then((res) => alert(res));
      }
    },

    async refundCommit() {
      try {
        if (this.cancelData) {
          const cancelvdt = await this.$validator.validateAll("frmCancel");

          if (!cancelvdt) {
            this.kakaoTrans(this.errors.items[0].msg).then((res) => alert(res));
            return;
          }

          const resCancel = await patchRefundCancel({
            orderid: this.cancelData.orderid,
            amount: this.cancelData.amount,
            bank_cd: this.bank_cd,
            bank_owner: this.bank_owner,
            bank_number: this.bank_number,
            reason: this.cancelReason,
            delivery_status: this.cancelData.delivery_status_cd,
          });

          if (resCancel.data.ResultCode) {
            this.kakaoTrans("접수되었습니다.").then((res) => alert(res));
            this.isCancel = false;
            await this.fetchMyAccount();
            return;
          }

          this.kakaoTrans(resCancel.data.ResultMessage).then((res) => alert(res));
        }
      } catch (error) {
        this.kakaoTrans(error).then((res) => alert(res));
      }
    },

    inputBankOwner(e) {
      this.bank_owner = e.target.value;
    },

    inputReason(e) {
      this.cancelReason = e.target.value;
    },

    async requestOpen(payload) {
      const res = await patchOpenRequest({
        reservation_cd: payload.reservation_cd,
      });

      this.kakaoTrans(res.data.ResultMessage).then((res) => alert(res));

      await this.fetchMyAccount();
    },

    async requestOpenCancel(payload) {
      const res = await patchOpenCancelRequest({
        reservation_cd: payload.reservation_cd,
      });

      this.kakaoTrans(res.data.ResultMessage).then((res) => alert(res));

      await this.fetchMyAccount();
    },
  },

  mounted() {
    // 리스트조회
    this.fetchMyAccount();
  },

  computed: {
    inputState() {
      if (!this.date) {
        return {
          type: "is-danger",
          message: "Date required.",
        };
      }
      return {
        type: "is-primary",
        message: "",
      };
    },

    fromDate() {
      return this.$moment().format("YYYY-MM-DD");
    },

    toDate() {
      return this.$moment()
        .add(-1, "months")
        .format("YYYY-MM-DD");
    },
  },
};
</script>

<style scoped>
@import url("../../css/form-style.css");
</style>
