<template>
  <div class="wrap subPage">
    <div class="container">
        <!-- sub title -->
        <div class="subTitAr fW-R">
            <h1 class='notranslate'>{{$t('trans.t13_01')}}</h1>
            <span class="subTit fW-L notranslate">{{$t('trans.t13_02')}}</span>
        </div>
        <!-- 서브페이지 컨텐츠 -->
        <div class="subPageAr">
            <div class="findAr">
                <div class="findResult">
                    <span class="resultTxt">{{ decodeURIComponent($route.query.name.toString()) }}님의 아이디는</span>
                    <span class="resultTxt"><strong>{{ decodeURIComponent($route.query.mail.toString()) }}</strong> 입니다.</span>
                </div>
                <div class="btnWp">
                    <button class="findBtn fW-R notranslate" @click="$router.replace({name:'LoginView'})">{{$t('trans.t12_17')}}</button>
                    <button class="findBtn fW-R wh notranslate" @click="$router.replace({name:'MemberFindView', query:{q:2}})">{{$t('trans.t13_07')}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
}
</script>

<style>

</style>