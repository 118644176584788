<template>
  <div id="pdf" ref="document" v-show="false">
    <div class="applicationForm">
      <div class="formTopWrap">
        <div class="formLogo">
          <img src="../images/formLogo.png" />
        </div>
        <div class="formTit notranslate">
          {{$t('trans.t09_12')}}
        </div>
        <!-- 가입자 정보 -->
        <div class="formConWrap">

          <span class="formConTit tb"> 이동통신 요금 <b>(VAT 포함)</b> </span>
          <table class="formTb">
            <tr>
              <td class="tit tc">선택 요금제</td>
              <td class="tit tc">월 정액요금</td>
              <td class="tit tc">월 요금할인(무약정)</td>
              <td class="tit tc">납부액</td>
            </tr>
            <tr>
              <td class="tc">{{ account.name }}</td>
              <td class="tc">
                {{ account.amount | currency }}원<span class="calcul">-</span>
              </td>
              <td class="tc">0원<span class="calcul">=</span></td>
              <td class="tc">{{ account.amount | currency }}원</td>
            </tr>
          </table>

          <!-- 리얼   -->
          <span class="formConTit tb"> 요금할인 <b>(VAT 포함)</b> </span>
          <table class="formTb">
            <tr>
              <td class="tit tc" colspan="3">할인 적용 시 예상 납부액</td>
            </tr>
            <tr>
              <td class="gr tc">자동결제할인</td>
              <td class="gr tc">
                장기할인(4회차부터){{
                  account.long_discountamount_f | currency
                }}원<span class="calcul mid">=</span>
              </td>
              <td class="tc" rowspan="2">
                {{
                  (account.auto_discountamount + account.long_discountamount_f)
                    | currency
                }}원
              </td>
            </tr>
            <tr>
              <td class="tc" rowspan="3">
                {{ account.auto_discountamount | currency }}원
                <span class="calcul half">+</span>
              </td>
              <td class="tc">{{ account.long_discountamount_f | currency }}원</td>
            </tr>
            <tr>
              <td class="gr tc">
                장기할인(10회차부터){{
                  account.long_discountamount_t | currency
                }}원<span class="calcul mid">=</span>
              </td>
              <td class="tc" rowspan="2">
                {{
                  (account.auto_discountamount + account.long_discountamount_t)
                    | currency
                }}원
              </td>
            </tr>
            <tr>
              <td class="tc">{{ account.long_discountamount_t | currency }}원</td>
            </tr>
          </table>

          <span class="redTxt"
            >※ 자동결제할인은 신청 후 익월(다음 달) 자동 결제분 부터 할인이
            적용됩니다.</span
          >
          <div style="page-break-after:always"></div>
          <span class="formConTit tb" style="background-color:#afafaf;">
            기타 안내
          </span>
          <ul class="guideAr etc">
            <li class="guideTxt">요금제 변경은 불가합니다.</li>
            <li class="guideTxt">
              이용 중 자동결제 신청을 해지하는 경우 해당 할인이 적용되지 않습니다.
            </li>
            <li class="guideTxt">
              이용기간 경과 후 40일 내 기본료 미납 시 가입 회선이 해지 되며, 해지 후
              재 가입 시 이용기간이 초기화됩니다.
            </li>
            <li class="guideTxt">해외에서는 데이터 사용이 불가합니다.</li>
            <li class="guideTxt">
              본 표준안내서는 이용요금, 약정조건, 요금할인 등의 중요한 사항을 정리한
              것이며, 그 밖의 사항은 가입신청서 등을 반드시 확인하시기 바랍니다.
            </li>
          </ul>

          <table class="formTb">
            <tr>
              <td class="tit tc notranslate">{{$t('trans.t14_04')}}</td>
              <td class="tc">{{ account.orderid }}</td>
              <td class="tit tc">이동전화번호</td>
              <td class="tc">{{ account.celnumber }}</td>
            </tr>
            <tr>
              <td class="tit tc">가입일자</td>
              <td class="tc">{{ account.r_date }}</td>
              <td class="tit tc">가입자(대리인)<br />서명</td>
              <td class="tc">
                {{ account.clientname }}<br />{{
                  account.accAuthNum === ""
                    ? ""
                    : `본인인증번호 ${account.accAuthNum}`
                }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "../js/eventBus";
import { fetchWireless } from "../api/order";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";

function addScript(url) {
  var script = document.createElement("script");
  script.type = "application/javascript";
  script.src = url;
  document.head.appendChild(script);
}
addScript(
  "https://raw.githack.com/eKoopmans/html2pdf/master/dist/html2pdf.bundle.js"
);

export default {
  data() {
    return {
      account: {},
    };
  },

  created() {
    eventBus.$on("downContrace", () => {
      this.$nprogress.start()
      this.fetchWireless();

      setTimeout(async () => {
        await this.exportToPDF();
      }, 1000);
      this.$nprogress.done()
    });
  },

  props: {
    order_id: String,
  },

  methods: {
    async fetchWireless() {
      const res = await fetchWireless({
        orderid: decodeURIComponent(this.order_id),
      });
      this.account = res.data.ResponseData;
    },

    exportToPDF() {
      const pdfHtml = this.$refs.document;
      const node = pdfHtml.firstChild.childNodes[0];
      node.style.width = "780px";
      node.style.padding = "0";
      node.style.border = "0";

      const opt = {
        margin: [0, -10, 0, 0],
        filename: "무선서비스 계약 표준 안내서.pdf",
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          scale: 4,
          dpi: 192,
          letterRendering: true,
        },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        },
      };

      // html2pdf()
      //   .from(pdfHtml.innerHTML)
      //   .outputPdf()
      //   .then(function(pdf) {
      //     // This logs the right base64
      //     console.log(btoa(pdf));
      //   });

      html2pdf()
        .set(opt)
        .from(pdfHtml.innerHTML)
        .save();

      //pdfHtml.innerHTML = "";
    },
  },
};
</script>

<style scoped>
.applicationForm.pdf {
  width: 780px !important;
  /* padding:50px  !important; */
}
</style>
