import { createNamespacedHelpers } from "vuex";
import {
  isMobile,
  isBrowser,
  browserVersion,
  browserName,
  isIE,
  isTablet,
  isMobileOnly,
  deviceType,
} from "mobile-device-detect";
import { fetchKakaoTrans } from "../api/common";
import * as util from "../js/util";

const {
  mapState: memberMapState,
  mapMutations: memberMutation,
} = createNamespacedHelpers("memberStore");

const {
  mapState: configMapState,
  mapMutations: configMapMutations,
  mapGetters: configGetters,
} = createNamespacedHelpers("configStore");

export default {
  install(Vue, options) {
    Vue.mixin({
      mounted() {
        if (localStorage.getItem("user_session") !== null) {
          const userStorage = JSON.parse(
            this.aesDecrypt(
              localStorage.getItem("user_session"),
              "user_session"
            )
          );
          this.MTS_SET_LOGIN({
            member_login_type: userStorage.member_login_type,
            member_seq: userStorage.member_seq,
            member_cellnumber: userStorage.member_cellnumber,
            member_name: userStorage.member_name,
            member_id: userStorage.member_id,
            isLogin: true,
          });
        }

        this.isMainSite = true;

        if (!location.hostname.includes("prepaid")) {
          if (!location.hostname.includes("192")) {
            if(!location.hostname.includes("plus"))
            this.isMainSite = false;
          }
        }
        // if (!location.hostname.includes("prepaid")) {
        //     this.isMainSite = false;
        // }        
      },

      methods: {
        ...memberMutation({ MTS_SET_LOGIN: "MTS_SET_LOGIN" }),
        ...configMapMutations({ MTS_IS_MAIN: "MTS_IS_MAIN" }),

        sortBy(property) {
          return function(a, b) {
            if (a[property] > b[property]) return 1;
            else if (a[property] < b[property]) return -1;

            return 0;
          };
        },

        isEmpty(obj) {
          for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
              return false;
            }
          }

          return true;
        },

        currency(payload) {
          return String(payload).replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          );
        },

        async kakaoTrans(payload) {
          let source_lang = "kr";
          let target_lang = "";
          let resTrans = payload;

          try {
            const lng = sessionStorage.getItem('googtrans')

            if (lng !== "/ko/ko") {
              switch (lng) {
                case "/ko/en":
                  target_lang = "en";
                  break;
                case "/ko/vi":
                  target_lang = "vi";
                  break;
                case "/ko/zh-CN":
                  target_lang = "cn";
                  break;

                default:
                  target_lang = "ko";
                  break;
              }

              const res = await fetchKakaoTrans({
                q: payload,
                source_lang: source_lang,
                target_lang: target_lang,
              });
              resTrans = res.data.translated_text[0];
            }
          } catch (error) {}

          return resTrans;
        },

        aesEncrypt(data, key) {
          return util.aesEncrypt(data, key);
        },

        aesDecrypt(data, key) {
          return util.aesDecrypt(data, key);
        },
      },
      computed: {
        ...memberMapState({
          memberInfo: (state) => state,
        }),

        isMobile() {
          return isMobile;
        },

        deviceType(){
          return deviceType
        },

        isTablet(){
          return isTablet;
        },

        isMobileOnly(){
          return isMobileOnly
        },

        isBrowser() {
          return isBrowser;
        },

        browserVersion() {
          return browserVersion;
        },

        browserName() {
          return browserName;
        },

        isIE() {
          return isIE;
        },

        isUs() {
          return this.$cookies.get("googtrans") === "/auto/en"
            ? true
            : false;
        },

        isCn() {
          return this.$cookies.get("googtrans") === "/auto/zh-CN"
            ? true
            : false;
        },

        isJp() {
          return this.$cookies.get("googtrans") === "/auto/ja"
            ? true
            : false;
        },

        ...configMapState({
          configStore: (state) => state,
        }),

        isMainSite: {
          get() {
            return this.configStore.isMainSite;
          },
          set(value) {
            this.MTS_IS_MAIN(value);
          },
        },
      },
      filters: {
        currency(payload) {
          if (payload !== undefined) {
            return String(payload).replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            );
          }
        },
      },
    });
  },
};
