/*
    ----------------------------------------
    ** 회원 API
    ----------------------------------------
*/

import Axios from "axios";
import {dosirak, axiosAll, global, kakao} from "./config";

// 회원정보 조회
export function fetchMember(payload) {
    return global.post('/m/member/info', payload)
}

// 휴대폰 변경 인증번호 발송
export function sendAuthNumber(payload) {
    return global.post('/m/member/sms/auth', payload)
}

// 회원정보 수정
export function putMember(payload) {
    return global.post('/m/member/edit', payload)
}

// sns 연동 해제
export function putSns(payload) {
    return global.post('/m/sns/update', payload)
}

// sns 로그인
export function snslogin(payload) {
    return global.post('/m/sns/login', payload)
}

// sns 연동
export function snsCreate(payload) {
    return global.post('/m/sns/create', payload)
}

// 휴대폰번호 변경
export function putCell(payload) {
    return dosirak.post('/mvno/member/put/cell', payload)
}

// 신청내역 조회(메일 로그인)
export function fetchMemberMvno(payload) {
    return dosirak.post('/mvno/member/memberReservationList', payload)
}

// 신청내역 조회(번호로그인)
export function fetchMemberMvnoMobile(payload) {
    return dosirak.post('/mvno/member/nc/certifyReservationList', payload)
}

// 신청내역 조회(사용자정보로 조회)
export function fetchMemberMvnoUser(payload) {
    return dosirak.post('/mvno/member/infoReservationList', payload)
}

// 신청내역 조회(주문번호, 이름, 휴대폰번호)
export function fetchUserAuth(payload) {
    return dosirak.post('/mvno/member/auth', payload)
}

// 신청내역조회(주문번호, 이름)
export function fetchUserCert(payload){
    return dosirak.post('/mvno/member/cert', payload)
}

// 가입신청정보
export function fetchAccountForm(payload) {
    return dosirak.post('/mvno/reservation/account/form', payload)
}

// 마이페이지 메인 (회원아이디 로그인)
export function fetchMypage(payload) { // 회원정보
    return dosirak.post('/mvno/member/myPageInfo', payload)
}

// 마이페이지 메인 (번호 로그인)
export function fetchMypageMobile(payload) { // 회원정보
    return dosirak.post('/mvno/member/nc/certifyMyPageInfo', payload)
}

// 마이페이지 회원정보 수정 데이터 조회
export function fetchMemberEdit(payload) {
    const members = dosirak.post(`${
        dosirak.defaults.baseURL
    }/mvno/member/info`, payload)
    const sns = dosirak.post(`${
        dosirak.defaults.baseURL
    }/mvno/member/sns`, payload)
    return axiosAll([members, sns])
}

// 마이페이지 가입정보 및 사용량 조회
export function fetchMemberUsage(payload) {
    const members = dosirak.post(`${
        dosirak.defaults.baseURL
    }/mvno/reservation/member/usage`, payload)
    // const usage = dosirak.post(`${dosirak.defaults.baseURL}`)
    return axiosAll([members])
}

// 마이페이지 회원탈퇴
export function putMemberDrop(payload) {
    return global.post('/m/member/drop', payload)
}

// 마이페이시 회원탈퇴 사유 조회
export function fetchDropReason() {
    return global.post('/m/member/drop/reason')
}

// 번호정보로 아이디찾기
export function fetchId(payload) {
    return global.post('/m/member/account/id', payload)
}

// 계정 인증
export function fetchAccountCheck(payload) {
    return global.post('/m/member/account/check', payload)
}

// 비밀번호 변경
export function putPass(payload) {
    return global.post('/m/member/account/pass', payload)
}

// 예약 부가서비스 조회
export function fetchOrderExtra(payload) {
    return dosirak.post('/mvno/reservation/extra', payload)
}

// 미인증 마이페이지(번호 로그인)
export function fetchOrder(payload) {
    const order = dosirak.post(`${
        dosirak.defaults.baseURL
    }/mvno/member/nc/order`, payload)
    const extra = dosirak.post(`${
        dosirak.defaults.baseURL
    }/mvno/member/nc/order/extra`, payload)
    return axiosAll([order, extra])
}

// 미인증 마이페이지(아이디 로그인)
export function fetchIdOrder(payload) {
    const order = dosirak.post(`${
        dosirak.defaults.baseURL
    }/mvno/member/nc/order/id`, payload)
    const extra = dosirak.post(`${
        dosirak.defaults.baseURL
    }/mvno/member/nc/order/extra/id`, payload)
    return axiosAll([order, extra])
}
