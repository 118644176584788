<template>
    <div class="wrap subPage">
        <div class="container">
            <!-- sub title -->
            <div class="subTitAr">
                <h1 class="notranslate">{{$t('trans.t12_16')}}</h1>
                <span class="subTit fW-L"
                    >통합회원 가입으로 와이드모바일의 모든 서비스를 편리하게
                    이용하세요.</span
                >
            </div>
            <!-- 가입신청서 STEP -->
            <div class="subStepWrap mT30">
                <ul class="stepAr">
                    <li>
                        <span class="stepNum">STEP1<b>약관동의</b></span>
                    </li>
                    <li>
                        <span class="stepNum">STEP2<b>본인인증</b></span>
                    </li>
                    <li>
                        <span class="stepNum on">STEP3<b>회원정보입력</b></span>
                    </li>
                </ul>
            </div>
            <div style="clear: both;"></div>
            <!-- 서브페이지 컨텐츠 -->
            <form @submit.prevent="onSubmit">
                <div class="subPageAr">
                    <div class="joinInput">
                        <!-- 이름 -->
                        <div class="inputAr">
                            <input
                                type="text"
                                class="mid"
                                name="member_name"
                                v-validate="'required'"
                                @input="inputName"
                                :value="member_name"
                                :placeholder="$t('trans.t12_04')"
                            />
                            <!-- <div class="invalid-feedback">{{ errors.first("member_name") }}</div> -->
                        </div>
                        <!-- 이메일 -->
                        <div class="inputAr">
                            <div class="inpGroup">
                                <input
                                    type="text"
                                    class="short"
                                    name="mail_account"
                                    v-validate="'required|alpha_num'"
                                    v-model="mail_account"
                                    :placeholder="$t('trans.t16_04')"
                                />
                                <span>@</span>
                                <input
                                    type="text"
                                    class="short"
                                    v-validate="'required'"
                                    name="mail_domain"
                                    v-model.trim="mail_domain"
                                    placeholder="이메일 형식"
                                />
                                <div class="grouping mailForm">
                                    <select @change="onSelectedDomain">
                                        <option
                                            label="직접입력"
                                            value=""
                                            selected="selected"
                                            >직접입력</option
                                        ><option label="naver.com" value="naver.com"
                                            >naver.com</option
                                        ><option
                                            label="hanmail.net"
                                            value="hanmail.net"
                                            >hanmail.net</option
                                        ><option label="nate.com" value="nate.com"
                                            >nate.com</option
                                        ><option label="gmail.com" value="gmail.com"
                                            >gmail.com</option
                                        ><option
                                            label="hotmail.com"
                                            value="hotmail.com"
                                            >hotmail.com</option
                                        ><option label="korea.com" value="korea.com"
                                            >korea.com</option
                                        ><option label="empal.com" value="empal.com"
                                            >empal.com</option
                                        >
                                        <option>직접입력</option>
                                        <option value="naver.com">naver.com</option>
                                    </select>
                                </div>
                                <div class="grouping nextLine">
                                    <button class="inputBtn blue" type="button" @click="onEmailCheck">중복확인</button>
                                </div>
                                <!-- <div class="invalid-feedback">{{ errors.first("mail_account") }}</div>
                                <div class="invalid-feedback">{{ errors.first("mail_domain") }}</div> -->
                            </div>
                        </div>
                        <!-- 비밀번호 -->
                        <div class="inputAr">
                            <div class="inpGroup">
                                <input
                                    type="password"
                                    class="mid"
                                    v-validate="'required|min:10'"
                                    name="member_pass"
                                    ref="password"
                                    v-model.trim="member_pass"
                                    :placeholder="$t('trans.t18_08')"
                                />
                                <div class="grouping nextLine">
                                    <input
                                        type="password"
                                        v-validate="'required|min:10|confirmed:password'"
                                        class="mid"
                                        name="member_pass_confirm"
                                        v-model.trim="member_pass_confirm"
                                        placeholder="비밀번호 확인"
                                    />
                                </div>
                            </div>
                            <!-- <div class="invalid-feedback">{{ errors.first("member_pass") }}</div>
                            <div class="invalid-feedback">{{ errors.first("member_pass_confirm") }}</div> -->
                        </div>
                        <!-- 휴대폰 번호 -->
                        <div class="inputAr">
                            <input
                                type="text"
                                class="mid"
                                name="member_cell"
                                v-validate="'numeric|required'"
                                v-model.trim="member_cell"
                                :placeholder="$t('trans.t07_13')"
                            />
                        </div>
                        <!-- 성별 -->
                        <div class="inputAr">
                            <div class="inpGroup">
                                <button
                                    :class="[
                                        'genderBtn notranslate',
                                        { 'active': gender === 10 },
                                    ]"
                                    @click="gender = 10"
                                    type="button"
                                >
                                    {{$t('trans.t18_10')}}
                                </button>
                                <button
                                    :class="[
                                        'genderBtn notranslate',
                                        { 'active': gender === 20 },
                                    ]"
                                    type="button"
                                    @click="gender = 20"
                                >
                                    {{$t('trans.t18_11')}}
                                </button>
                                <div class="grouping nextLine">
                                    <input
                                        type="text"
                                        class="mid"
                                        name="member_birthday"
                                        v-validate="'numeric|required|min:8'"
                                        v-model.number="member_birthday"
                                        placeholder="생년월일을 입력해주세요."
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="btnWp">
                            <button class="btnCom gray notranslate" type="button"  @click="$router.go(-1)">{{$t('trans.t13_06')}}</button>
                            <button class="btnCom" type="submit">회원가입완료</button>
                        </div>
                    </div>
                </div>
            </form>            
        </div>
    </div>
</template>

<script>

import Vue from 'vue'
import * as auth from "../../../api/auth";
import { memberJoinMixin } from "../../../mixin/storeMixin"
// import ko from '../../../js/ko.min.js'

export default {
    mixins:[memberJoinMixin],
    data() {
        return {
            member_name: "",
            mail_account: "",
            mail_domain: "",
            member_pass: "",
            member_pass_confirm:"",
            member_birthday: "",
            member_cell: "",
            gender: 10,
            isCellCheck:false,
        };
    },
    methods: {

        inputName(e) {
            this.member_name = e.target.value.trim();
        },
        onSelectedDomain(e){
            this.mail_domain = e.target.options[e.target.selectedIndex].value
        },
        async onEmailCheck(){
            const res = await auth.idCheck({
                member_id: `${this.mail_account}@${this.mail_domain}`
            })

            this.isCellCheck =  res.data.ResultCode
            this.kakaoTrans(res.data.ResultMessage).then((res) => alert(res))
        },
        async onSubmit(){

            const result = await this.$validator.validateAll()

            if(result) {
                const res = await auth.MemberCreate({
                        member_id: `${this.mail_account}@${this.mail_domain}`,
                        member_name: this.member_name,
                        member_number: this.member_birthday,
                        member_gender: this.gender,
                        member_htel1: this.firstCell,
                        member_htel2: this.secondCell,
                        member_htel3: this.thirdCell,                        
                        member_sms_send_yn: "n",
                        member_email_send_yn: "n",
                        member_recommend_id: "",
                        member_is_recommend: 0,
                        member_is_linked: 0,
                        member_password: this.member_pass,
                        member_cellnumber: this.member_cell                        
                })
                
                if(!res.data.ResultCode) {
                    this.kakaoTrans(res.data.ResultMessage).then((res) => alert(res))
                    return
                }
                
                this.$router.push({name:"JoinStep4View", query:{success: `${this.mail_account}@${this.mail_domain}`}})
                return;
            }

            this.kakaoTrans(this.errors.items[0].msg).then((res) => alert(res))

        }
    },
    computed: {
        passConfirm(){
            if(this.member_pass !== this.member_pass_confirm){
                return false
            }
            return true
        },
        firstCell(){
            return this.member_cell.substring(0,3);
        },
        secondCell(){
            return this.member_cell.substring(3,7);
        },
        thirdCell(){
            return this.member_cell.substring(7,11);
        }        
    },
};
</script>

<style scoped>
.invalid-feedback {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #e41616;
}
</style>
