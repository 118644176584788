<template>
  <div class="wrap subPage">
    <div class="container">
      <!-- sub title -->
      <div class="subTitAr fW-R">
        <h1>{{$t('trans.t17_01')}}</h1>
        <span class="subTit fW-L">{{$t('trans.t17_02')}}</span>
      </div>
      <!-- 서브페이지 컨텐츠 -->
      <div class="subPageAr">
        <div class="mypageAr">
          <div class="infoCheck">
            <ul>
              <li>
                <span class="infoChkTit fW-R notranslate">{{$t('trans.t17_03')}}</span>
                <span class="infoChkTxt fW-L">{{ memberPlan.clientname }}</span>
              </li>
              <li>
                <span class="infoChkTit fW-R notranslate">{{$t('trans.t17_04')}}</span>
                <span class="infoChkTxt fW-L">{{ memberPlan.name }}</span>
                <!-- <button class="btnChange fr">{{$t('trans.t18_06')}}</button> -->
              </li>
              <li>
                <span class="infoChkTit fW-R notranslate">{{$t('trans.t17_05')}}</span>
                <span class="infoChkTxt fW-L">{{ memberPlan.tern_start }}</span>
              </li>
              <li>
                <span class="infoChkTit fW-R notranslate">{{$t('trans.t17_06')}}</span>
                <span class="infoChkTxt fW-L" :key="openDate"
                  >{{ openDate }} ~ {{ expired }}</span
                >
              </li>
              <div class="mypageBan">
                <img src="../../images/mypagebannerZone.jpg" />
              </div>
              <li>
                <span class="infoChkTit fW-R notranslate">{{$t('trans.t17_07')}}</span>
                <!-- <span class="infoChkTxt" :key="memberPlan.tern_end"
                  >{{ memberPlan.tern_end }}｜{{
                    memberPlan.dday > 0 ? `D-${memberPlan.dday}일` : "만료"
                  }}</span
                > -->
                <span class="infoChkTxt fW-L" :key="expired">{{ expired }}</span>
              </li>
              <li>
                <span class="infoChkTit fW-R notranslate">{{$t('trans.t17_08')}}</span>
                <span class="infoChkTxt fW-L">{{ remainAmount | currency }}원</span>
              </li>
              <li>
                <span class="infoChkTit fW-R notranslate">{{$t('trans.t17_09')}}</span>
                <span class="infoChkTxt fW-L">{{ memberPlan.autopay_name }}</span>
                <!-- 자동결제 미신청 -->
                <a
                  v-if="memberPlan.autopay_yn === 'N'"
                  href="https://www.dosirakmobile.com/ChargeStation/ChargeStationAdditional"
                  target="_blank"
                  class="btnChange fW-R fr notranslate"
                  >{{$t('trans.t08_27')}}</a
                >
                <button
                  v-if="memberPlan.autopay_yn === 'Y'"
                  @click="cancel"
                  class="btnChange fW-R fr"
                >
                  해지
                </button>
              </li>
              <li v-if="false">
                <span class="infoChkTit short">사용량</span>
                <div class="progressAr">
                  <span class="barTit">충전금액 : 8,000원</span>
                  <div class="progress-bar">
                    <span class="progress-bar-fill" style="width: 100%;"></span>
                  </div>
                  <span class="barUse">사용 : 0원</span>
                  <span class="barRest">잔여 : 8,000원</span>
                  <div class="useDetailTb">
                    <table>
                      <thead>
                        <tr>
                          <th>항목</th>
                          <th>기본료</th>
                          <th>데이터</th>
                          <th>음성</th>
                          <th>문자</th>
                          <th>영상통화</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>사용량</td>
                          <td>3일</td>
                          <td>5GB</td>
                          <td>20분</td>
                          <td>3건</td>
                          <td>10분</td>
                        </tr>
                        <tr>
                          <td>사용금액</td>
                          <td>660원</td>
                          <td>2,100원</td>
                          <td>3,000원</td>
                          <td>300원</td>
                          <td>200원</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="btnWp">
            <button class="btnCom fW-R extend notranslate">{{$t('trans.t17_11')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchUserIp } from "../../api/common";
import { fetchMemberUsage } from "../../api/member";
import { putAutoPay } from "../../api/order";
import { fetchRemaining, fetchTelecentroMember } from "../../api/telecentro";

export default {
  data() {
    return {
      memberPlan: [],
      remainAmount: 0, // 잔액
      expired: "", // 만료일
      openDate: "", // 오픈일
    };
  },

  methods: {
    async fetchUsage() {
      // 가입정보 조회
      const [members] = await fetchMemberUsage({
        r_cd: this.$route.query.rcd,
      });

      this.memberPlan = members.data.ResponseData;

      if (Object.keys(this.memberPlan).length === 0) {
        this.$router.go(-1);
      }

      if (this.memberPlan.opencelnumber) {
        if (this.memberPlan.accountnumber !== "") {
          // 유저 아이피
          const userIp = await fetchUserIp();
          const resRemain = await fetchTelecentroMember({
            user_ip: userIp.data.ResponseData,
            cell: this.memberPlan.opencelnumber,
            birth: this.memberPlan.birth,
          });
          console.log(resRemain.data.ResponseData);
          if (resRemain.status === 200) {
            this.remainAmount = resRemain.data.ResponseData.BALANCE;
            this.expired = resRemain.data.ResponseData.TOPUP_EXPIRE;
            this.openDate = resRemain.data.ResponseData.OPEN_DATE;
            return;
          }
        }
      }
    },

    async cancel() {
      this.kakaoTrans(
        "자동결제 신청 해지 시 할인혜택이 사라집니다.\n그래도 해지하시겠습니까?"
      ).then(async (res) => {
        if (confirm(res)) {
          const res = await putAutoPay({
            reservation_cd: this.memberPlan.reserv_enc_cd,
            autopay_yn: "N",
          });

          this.kakaoTrans(res.data.ResultMessage).then((res) => alert(res));

          this.fetchUsage();
        }
      });
    },
  },

  mounted() {
    this.fetchUsage();
  },
};
</script>

<style></style>
