<template>
  <div>
    <div class="VinaSupporter">
      <!--메인-->
      <div class="VinaSupporter_main">
        <div class="inner">
          <h2>VIETNAM</h2>
          <h1>DOSIRAK<br /><span>SUPPORTESRS</span></h1>
          <p>
            Dosirak Supporters, là chiến dịch quảng cáo được chạy dưới
            hình thức on/offline nhằm <br />
            nâng cao nhu cầu viễn thông với giá cước rẻ, <br />
            dịch vụ chất lượng cho người Việt Nam đang sinh sống tại
            Hàn Quốc.
          </p>
          <div class="Vina_icon_Wrap">
            <img
              src="../../../images/promotion/vina_icon_01.png"
              alt=""
            />
            <img
              src="../../../images/promotion/vina_icon_02.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <!--도시락 서포터즈가 받는 혜택-->
      <div class="VinaSupporter_con1">
        <div class="inner">
          <div class="VinaSupporter_box">
            <h3>Ưu đãi dành cho Dosirak Supporters!</h3>
            <span>Hỗ trợ cước viễn thông (Vô thời hạn)</span>
            <p>
              - Hoàn toàn miễn phí cước tháng đầu tiên<br />
              - Sau tháng đầu, số tháng được miễn cước viễn thông sẽ
              tương ứng với số lượng người giới thiệu sử dụng dịch vụ
              thành công.<br /><br />
            </p>
            <span>Nhận thưởng ngay khi mời bạn bè tham gia</span>
            <p>
              - Nhận ngay voucher trị giá 10,000 won khi giới thiệu
              thành công dịch vụ của Dosirak Mobile cho bạn bè. (Đối
              tượng nhận giới thiệu sẽ nhận voucher trị giá 5,000 won)
            </p>
          </div>
        </div>
      </div>
      <!--도시락 서포터즈 활동-->
      <div class="VinaSupporter_con2">
        <div class="inner">
          <div class="VinaSupporter_box">
            <h3>Hình thức hoạt động của Dosirak Mobile</h3>
            <p>
              - Giới thiệu, quảng cáo cho các đối tượng du học sinh,
              xuất khẩu lao động là người Việt Nam đến Hàn Quốc <br />
              - Quảng bá Dosirak Mobile cho người quen, trên mạng xã
              hội và cộng đồng người Việt Nam
            </p>
          </div>
        </div>
      </div>
      <!--도시락 서포터즈 신청-->
      <div class="VinaSupporter_con3">
        <div class="inner">
          <div class="VinaSupporter_box">
            <h3>Đăng kí trở thành Dosirak Supporters!</h3>
            <p>
              <span>- Số lượng: 20 người</span><br />
              - Đăng kí bằng cách chọn vào mục bên dưới<br />
              - Thông tin đăng kí sẽ được kiểm duyệt và gửi thông báo
              sau khi hoàn tất
            </p>
            <div class="btWrap01">
              <button
                class="apply_btn"
                type="button"
                @click="
                  isAccount = true;
                  isMyCode = false;
                  isMyCodeList = false;
                "
              >
                Đăng kí làm Supporters
                <img src="../../../images/promotion/arrow.png" />
              </button>
              <button
                class="code_check_btn"
                type="button"
                @click="
                  isMyCode = true;
                  isMyCodeList = false;
                  isAccount = false;
                "
              >
                Xác nhận mã code của tôi
                <img src="../../../images/promotion/arrow.png" />
              </button>
            </div>

            <transition name="slide-fade">
              <!--도시락 서포터즈 신청 선택 시 입력창-->
              <div class="VinaSupporter_con4" v-if="isAccount">
                <div class="input_area">
                  <span>Tên</span>
                  <input
                    type="text"
                    name="name"
                    placeholder="Vui lòng nhập đầy đủ họ tên."
                    @input="inputName"
                    :value="member_name"
                  />
                </div>
                <div class="input_area">
                  <span>Ngày tháng năm sinh</span>
                  <input
                    type="text"
                    name="YYDDMM"
                    placeholder="YYMMDD"
                    v-model="member_birth"
                  />
                </div>
                <div class="input_area">
                  <span>Số điện thoại</span>
                  <input
                    type="text"
                    name="phone"
                    placeholder="010-1234-5678"
                    v-model="member_cell"
                  />
                </div>
                <div class="input_area">
                  <span>E-mail</span>
                  <input
                    type="text"
                    name="email"
                    placeholder="dosirak@mobile.com"
                    v-model="member_mail"
                  />
                </div>
                <div class="input_area">
                  <span>Nghề nghiệp</span>
                  <ul>
                    <li>
                      <label class="box-radio-input"
                        ><input
                          name="r_job"
                          type="radio"
                          value="유학생"
                          v-model="member_job"
                          checked="checked"
                        /><span>Du học sinh</span></label
                      >
                      <label class="box-radio-input"
                        ><input
                          name="r_job"
                          type="radio"
                          value="근로자"
                          v-model="member_job"
                        /><span>Lao động</span></label
                      >
                      <label class="box-radio-input"
                        ><input
                          type="radio"
                          @change="onEtcJob"
                        /><span>Khác:</span>
                        <input
                          type="text"
                          name="r_job"
                          placeholder=""
                          class="etc_input"
                          v-model="member_job"
                        />
                      </label>
                    </li>
                  </ul>
                </div>
                <div class="btWrap02">
                  <button
                    type="button"
                    class="apply_big_btn"
                    @click="putSupport"
                  >
                    Đăng kí làm Supporters<img
                      src="../../../images/promotion/arrow.png"
                    />
                  </button>
                </div>
              </div>
            </transition>

            <transition name="slide-fade">
              <!--도시락 서포터즈 신청 내 코드 확인하기 선택-->
              <div class="VinaSupporter_con5" v-if="isMyCode">
                <div class="input_area">
                  <span>Số điện thoại</span>
                  <input
                    type="text"
                    name="name"
                    placeholder="Vui lòng nhập số điện thoại"
                    v-model="cell"
                  />
                </div>

                <div class="btWrap02">
                  <button
                    class="code_check_big_btn"
                    type="button"
                    @click="fetchCode"
                  >
                    Xác nhận mã code của tôi<img
                      src="../../../images/promotion/arrow.png"
                    />
                  </button>
                </div>
              </div>
            </transition>

            <transition name="slide-fade">
              <!--도시락 서포터즈 신청 내 코드 확인하기(휴대폰번호입력후)선택 시-->
              <div class="VinaSupporter_con6" v-if="isMyCodeList">
                <div class="input_area">
                  <span>Mã Code của {{ info.name }}.</span>
                  <ul>
                    <li>
                      {{ info.promotion_code }}
                    </li>
                  </ul>
                </div>

                <div class="btWrap01">
                  <button
                    class="code_copy_btn"
                    type="button"
                    @click="clipboard"
                  >
                    Sao chép mã code
                  </button>
                  <button
                    class="kakao_btn"
                    type="button"
                    @click="sendLink"
                  >
                    Chia sẻ tới Kakao Talk
                  </button>
                </div>
                <div class="friend_list">
                  <p>
                    Phần thưởng sẽ được gửi khi bạn bè của bạn nhập mã
                    code trên trong bước<br />gia nhập và sử dụng dịch
                    vụ thành công.
                  </p>
                  <h1>Danh sạch bạn bè đã mời</h1>
                  <table>
                    <tbody>
                      <tr>
                        <td id="friend_list_title">
                          Tên
                        </td>
                        <td id="friend_list_title">
                          Số điện thoại nhập khi đăng kí
                        </td>
                      </tr>
                      <tr
                        v-for="(item, index) in infoChild"
                        :key="index"
                      >
                        <td>
                          {{ item.cName }}
                        </td>
                        <td>
                          {{ item.cCell }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>

      <!--도시락 서포터즈 신청-->
      <div class="VinaSupporter_con7">
        <div class="inner">
          <div class="VinaSupporter_box">
            <h3>Lưu ý khi trở thành Supporters</h3>
            <p>
              - Phần thưởng khi mời bạn bè sẽ được gửi vào số điện
              thoại đăng kí ban đầu vào giữa tháng tiếp theo.<br />
              - Thông tin chi tiết về các hoạt động của người ủng hộ sẽ được gửi qua email sau khi đăng ký xong.
            </p>
          </div>
        </div>
      </div>
    </div>
    <input type="hidden" id="code" v-model="info.promotion_code" />
  </div>
</template>
<script>
import { putMember, fetchCode } from "../../../api/promotion";
import { snsKeys } from "../../../js/enum";

export default {
  name: "CompletedView",
  data() {
    return {
      member_name: "",
      member_birth: "",
      member_cell: "",
      member_job: "",
      member_mail: "",
      cell: "",
      info: {},
      infoChild: [],
      isAccount: false,
      isMyCode: false,
      isMyCodeList: false,
    };
  },

  mounted() {
    window.Kakao.init("265ae6dcac45b25e17d3965cd2218e78");
  },

  methods: {
    async fetchCode() {
      if (this.cell === "") {
        alert("전화번호를 입력하세요.");
        return false;
      }
      this.$nprogress.start();
      const res = await fetchCode({
        cell: this.cell,
      });
      this.$nprogress.done();

      if (!res.data.ResultCode) {
        alert(res.data.ResultMessage);
        return false;
      }

      this.isAccount = false;
      this.isMyCode = false;
      this.isMyCodeList = true;
      this.info = res.data.ResponseData;
      this.infoChild = JSON.parse(res.data.ResponseData.openMember);
    },

    regInit() {
      this.member_name = "";
      this.member_birth = "";
      this.member_cell = "";
      this.member_job = "";
      this.member_mail = "";
    },

    cellInit() {
      this.cell = "";
    },

    async putSupport() {
      if (this.member_name === "") {
        alert("이름을 입력하세요.");
        return false;
      }

      if (this.member_birth === "") {
        alert("생년월일을 입력하세요.");
        return false;
      }

      if (this.member_cell === "") {
        alert("휴대폰번호를 입력하세요.");
        return false;
      }

      if (this.member_mail === "") {
        alert("메일을 입력하세요.");
        return false;
      }

      if (this.member_job === "") {
        alert("직업을 입력하세요.");
        return false;
      }

      this.$nprogress.start();
      const res = await putMember({
        cell: this.member_cell,
        birth: this.member_birth,
        name: this.member_name,
        email: this.member_mail,
        job: this.member_job,
      });
      this.$nprogress.done();

      alert(res.data.ResultMessage);

      if (res.data.ResultCode) {
        this.info = res.data.ResponseData || null;
        this.isMyCodeList = true;
      }

      if (res.data.ResultCode) {
        this.regInit();
        this.isAccount = false;
      }
    },
    async fetchCodeMember() {},
    inputName(e) {
      this.member_name = e.target.value;
    },

    clipboard() {
      const copyCode = document.querySelector("#code");
      copyCode.setAttribute("type", "text");
      copyCode.select();
      document.execCommand("copy");
    },

    sendLink() {
      const self = this;
      Kakao.Link.sendDefault({
        objectType: "feed",
        content: {
          title: "Dosirak Supporters",
          description:
            `▶ ${self.info.name} Đã gửi cho bạn một lời mời.\n▶ Mã code ${self.info.promotion_code}`,
          imageUrl:
            "http://k.kakaocdn.net/dn/Q2iNx/btqgeRgV54P/VLdBs9cvyn8BJXB3o7N8UK/kakaolink40_original.png",
          link: {
            mobileWebUrl: "https://developers.kakao.com",
            webUrl: "https://developers.kakao.com",
          },
        },
        buttons: [
          {
            title: "Xem chi tiết",
            link: {
              webUrl: "https://developers.kakao.com",
            },
          },
        ],
      });
    },

    onEtcJob(e) {
      this.member_job = "";
    },
  },
};
</script>
<style scoped="scoped">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
/*@import url('https://www.wifidosirak.com/v3/inc/base.css');*/
/*@import url('https://www.wifidosirak.com/v3/inc/layout.css');*/

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}
.VinaSupporter {
  max-width: 100%;
  background: #fff;
}
.VinaSupporter .inner {
  width: 1080px;
  padding: 40px 20px 0 20px;
  text-align: center;
}

/*메인 css*/
.VinaSupporter .VinaSupporter_main {
  position: relative;
  width: 100%;
  background: url("../../../images/promotion/VinaSupporter_back_01.jpg")
    no-repeat top center;
  background-size: cover;
  height: 937px;
}

.VinaSupporter .VinaSupporter_main h2 {
  color: #fff;
  padding-top: 15vh;
  font-size: 5rem;
  font-weight: 100;
  letter-spacing: -0.05rem;
}
.VinaSupporter .VinaSupporter_main h1 {
  color: #fff;
  padding-top: 5vh;
  font-size: 5rem;
  line-height: 5rem;
  font-weight: 900;
}
.VinaSupporter .VinaSupporter_main span {
  color: #fff;
  font-size: 3.2rem;
  line-height: 3rem;
  font-weight: 900;
  letter-spacing: -0.1rem;
}
.VinaSupporter .VinaSupporter_main p {
  color: #fff;
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 100;
  padding-top: 5vh;
}
.VinaSupporter_main .Vina_icon_Wrap {
  padding-top: 8vh;
}
.VinaSupporter_main .Vina_icon_Wrap img {
  margin: 1vh;
}

/*css*/
.VinaSupporter .VinaSupporter_con1 {
  width: 100%;
}
.VinaSupporter .VinaSupporter_con2 {
  width: 100%;
}
.VinaSupporter .VinaSupporter_con3 {
  width: 100%;
}
.VinaSupporter .VinaSupporter_con3 span {
  font-size: 2vh;
}

.VinaSupporter .VinaSupporter_con5 {
  width: 100%;
}
.VinaSupporter .VinaSupporter_con5 span {
  font-size: 2vh;
}
.VinaSupporter .VinaSupporter_con5 .input_area {
  text-align: center;
}
.VinaSupporter .VinaSupporter_con5 .VinaSupporter_box input {
  text-align: center;
  width: 100%;
}
.VinaSupporter .VinaSupporter_con6 {
  width: 100%;
}
.VinaSupporter .VinaSupporter_con6 .input_area {
  text-align: center;
}
.VinaSupporter .VinaSupporter_con6 span {
  font-size: 1.3rem;
}
.VinaSupporter .VinaSupporter_con6 .input_area li {
  display: inline-block;
}
.VinaSupporter .VinaSupporter_con6 li {
  width: 50%;
  padding-bottom: 16px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #c6c6c6;
  outline: none;
  font-size: 2rem;
  font-weight: 400;
  line-height: 130%;
  color: #333;
  font-family: "Open Sans", sans-serif;
}
.VinaSupporter .VinaSupporter_con7 {
  width: 100%;
  padding-bottom: 40px;
}

.VinaSupporter .etc_input {
  width: 56vh;
}

.VinaSupporter .VinaSupporter_con6 .friend_list {
  text-align: center;
}
.VinaSupporter .VinaSupporter_con6 .friend_list h1 {
  color: #212121;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

/*친구리스트 표 css*/
.VinaSupporter .VinaSupporter_con6 .friend_list table {
  width: 80%;
  margin: auto;
}

.VinaSupporter .VinaSupporter_con6 .friend_list td {
  font-size: 1.2rem;
  font-family: "Open Sans", sans-serif;
  letter-spacing: -0.5px;
  background: #fff;
  border: 1px solid #3d62ac;
  color: #222;
  line-height: 48px;
  width: 50%;
}
td#friend_list_title {
  background: #3d62ac;
  border: 1px solid #fff;
  color: #fff;
  font-weight: 900;
}

/*공통 css*/
.VinaSupporter_box {
  flex-basis: 100%;
  border-radius: 5vh;
  border: 1px solid #3d62ac;
  text-align: left;
  padding: 70px 50px;
}

.VinaSupporter_box h3 {
  color: #3d62ac;
  font-size: 3.8vh;
  font-weight: bold;
  padding-bottom: 4vh;
}
.VinaSupporter_box span {
  color: #212121;
  font-size: 1.3rem;
  font-weight: bold;
  padding-bottom: 2vh;
}
.VinaSupporter_box p {
  color: #212121;
  font-size: 1.3rem;
  line-height: 3vh;
  padding-top: 2vh;
}

/*공통 인풋 css*/
.VinaSupporter_box input {
  width: 100%;
  padding-bottom: 16px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #c6c6c6;
  outline: none;
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 130%;
  color: #333;
  font-family: "Open Sans", sans-serif;
}

.VinaSupporter_box input::placeholder {
  color: #c6c6c6;
}
.VinaSupporter_box input::-webkit-input-placeholder {
  color: #c6c6c6;
}
.VinaSupporter_box input:-ms-input-placeholder {
  color: #c6c6c6;
}

.input_area {
  padding-top: 3vh;
}

/*라디오 버튼*/

input[type="radio"] {
  margin: 0 0 2px 0;
}

.input_area li {
  display: flex;
}

.box-radio-input input[type="radio"] {
  width: 20px;
  height: 20px;
}

.box-radio-input input[type="radio"] + span {
  display: inline-block;
  background: none;
  padding: 20px 20px 0 10px;
  text-align: left;
  font-weight: 300;
  cursor: pointer;
}

.box-radio-input input[type="radio"]:checked + span {
  font-weight: 900;
}

/* 서포터즈 신청 & 코드확인 btn css */
.VinaSupporter .btWrap01 {
  width: 100%;
  margin: 25px 0 0 0;
  position: relative;
  bottom: 15%;
  display: flex;
}
.VinaSupporter .btWrap01 img {
  margin-left: 2vh;
}

.VinaSupporter .btWrap02 {
  width: 100%;
  margin: auto;
  position: relative;
  padding-top: 2rem;
}

.VinaSupporter .btWrap02 img {
  margin-left: 2vh;
}
/* :root {
  --button-color: #ffffff;
  --button-bg-color: #7f7f7f;
  --button-hover-bg-color: #025ce2;
} */

button {
  position: inherit;
  top: 50%;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #7f7f7f;
  color: #fff;
  margin: 1rem;
  padding: 1.2rem;
  font-family: "Open Sans", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 13px;
  display: inline-block;
  width: 520px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);

  cursor: pointer;
  transition: 0.5s;
}

button.apply_btn {
  background-color: #7f7f7f;
}
button.apply_btn:hover {
  background-color: #005ce6;
}
a {
  color: #fff;
}

button.code_check_btn {
  background-color: #7f7f7f;
}
button.code_check_btn:hover {
  background-color: #fc4302;
}
a {
  color: #fff;
}

button.apply_big_btn {
  background-color: #7f7f7f;
  width: 97%;
}
button.apply_big_btn:hover {
  background-color: #005ce6;
}
a {
  color: #fff;
}

button.code_check_big_btn {
  background-color: #7f7f7f;
  width: 97%;
}
button.code_check_big_btn:hover {
  background-color: #fc4302;
}
a {
  color: #fff;
}

button.code_copy_btn:hover {
  background-color: #3d62ac;
}
button.kakao_btn:hover {
  background-color: #ffbb0f;
}

button:active,
button:hover,
button:focus {
  background: #7f7f7f;
  outline: 0;
}
button:disabled {
  opacity: 0.5;
}

form {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
}

@media screen and (max-width: 1024px) {
  .VinaSupporter .inner {
    width: 100%;
    padding: 60px 50px 0 50px;
  }
  .VinaSupporter .VinaSupporter_main {
    height: 1220px;
  }
  .VinaSupporter .VinaSupporter_main h2 {
    padding-top: 8vh;
  }
  .VinaSupporter .VinaSupporter_main .Vina_icon_Wrap img {
    width: 40%;
  }
  .VinaSupporter .VinaSupporter_box h3 {
    line-height: 3.8rem;
  }
  .VinaSupporter .VinaSupporter_box span {
    font-size: 2rem;
  }
  .VinaSupporter .VinaSupporter_box p {
    font-size: 1.8rem;
  }
  .VinaSupporter .etc_input {
    width: 26vh;
  }
  .VinaSupporter .VinaSupporter_con6 span {
    font-size: 1.8rem;
  }
  .VinaSupporter .btWrap01 img {
    display: none;
  }
  .VinaSupporter .VinaSupporter_con6 .friend_list h1 {
    font-size: 1.8rem;
  }
  .VinaSupporter .VinaSupporter_con6 .friend_list table {
    width: 100%;
  }
  .VinaSupporter .VinaSupporter_con6 .friend_list td {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 768px) {
  .VinaSupporter .VinaSupporter_main {
    height: 920px;
  }
  .VinaSupporter .VinaSupporter_box h3 {
    line-height: 2.8rem;
  }
  .VinaSupporter .VinaSupporter_box p {
    font-size: 1.3rem;
  }
  .VinaSupporter .VinaSupporter_box span {
    font-size: 1.3rem;
  }
  .VinaSupporter .VinaSupporter_box input {
    font-size: 1.5rem;
  }
  .VinaSupporter .etc_input {
    width: 17vh;
  }
  td#friend_list_title {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 414px) {
  .VinaSupporter .inner {
    padding: 60px 20px 0 20px;
  }
  .VinaSupporter .VinaSupporter_main {
    height: 86vh;
  }
  .VinaSupporter .VinaSupporter_main .Vina_icon_Wrap img {
    width: 45%;
  }
  .VinaSupporter .VinaSupporter_main h2 {
    padding-top: 3vh;
    font-size: 3.5rem;
  }
  .VinaSupporter .VinaSupporter_main h1 {
    font-size: 3.5rem;
    line-height: 2rem;
  }
  .VinaSupporter .VinaSupporter_main span {
    font-size: 2.3rem;
  }
  .VinaSupporter .VinaSupporter_main p {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
  .VinaSupporter .VinaSupporter_box {
    padding: 30px 15px;
    border-radius: 3vh;
  }
  .VinaSupporter .VinaSupporter_box h3 {
    line-height: 2rem;
  }
  .VinaSupporter .VinaSupporter_box p {
    font-size: 1.2rem;
  }
  .VinaSupporter .btWrap01 {
    display: inline-block;
  }
  .VinaSupporter button {
    width: -webkit-fill-available;
    font-size: 1.3rem;
  }
  .VinaSupporter .btWrap02 img {
    display: none;
  }
  .VinaSupporter .input_area li {
    padding-top: 1rem;
    display: inline-grid;
  }
  .VinaSupporter .etc_input {
    width: 32vh;
  }
  .VinaSupporter .VinaSupporter_con6 .friend_list h1 {
    font-size: 1.3rem;
  }
  .VinaSupporter td#friend_list_title {
    font-size: 0.7rem;
  }
  .VinaSupporter .VinaSupporter_con6 .friend_list td {
    font-size: 1rem;
  }
}
@media screen and (max-width: 411px) {
  .VinaSupporter .etc_input {
    width: 28vh;
  }
}
@media screen and (max-width: 375px) {
  .VinaSupporter .inner {
    padding: 60px 20px 0 20px;
  }
  .VinaSupporter .VinaSupporter_main {
    height: 90vh;
  }
  .VinaSupporter .VinaSupporter_main p {
    font-size: 1rem;
  }
  .VinaSupporter button {
    font-size: 1rem;
  }
  .VinaSupporter .etc_input {
    width: 27vh;
  }
}
@media screen and (max-width: 320px) {
  .VinaSupporter .VinaSupporter_main {
    height: 110vh;
  }
  .VinaSupporter button {
    font-size: 0.9rem;
  }
  .VinaSupporter .VinaSupporter_box span {
    font-size: 1rem;
  }
  .VinaSupporter .VinaSupporter_box input {
    font-size: 1rem;
  }
  .VinaSupporter td#friend_list_title {
    font-size: 0.7rem;
    line-height: 1rem;
    padding: 0.5rem;
  }
}

body {
}
.main {
  position: relative;
  font-size: 14px;
  font-family: "맑은 고딕", malgun gothic, "돋움", dotum, AppleGothic,
    sans-serif;
  letter-spacing: -0.5px;
  color: #4a4a49;
}
.Topquick {
  width: 480px;
  background-color: #fff;
  position: absolute;
  top: 35px;
  left: 50%;
  z-index: 10;
  margin-left: -500px;
}
.Topquick button.namebox {
  box-sizing: border-box;
  float: left;
}
.Topquick .btn_reg {
  text-align: center;
  margin: 20px 0 0;
}

.Topquick .btnReser {
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  padding: 20px;
  cursor: pointer;
  width: 200px;
  margin: 2.5%;
  display: inline-block;
  background-color: #6186bc;
}

.Topquick .btnMainReser {
  background-color: #487ec6;
  color: #fff;
  width: 200px;
  height: 35px;
  display: inline-block;
  text-align: center;
  line-height: 35px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #597ec5;
  font-weight: bold;
}

.Topquick .con {
  padding: 25px 25px 10px;
  margin: 0;
}
.Topquick .con > div {
  margin-bottom: 13px;
}
.Topquick input,
.Topquick select {
  height: 35px;
  border: 1px solid #d3d3d3;
  background: #fff;
  font-family: "맑은 고딕", "malgun gothic", 돋움, dotum, AppleGothic,
    sans-serif;
  color: #575756;
  font-size: 13px;
  text-align: left;
  padding-left: 6px;
}
.Topquick .country {
  overflow: hidden;
  text-align: center;
}
.country input.etc {
  width: 48%;
  background: url(//img.widemobile.com/v3/images/btn_search.jpg)
    no-repeat;
  background-position: 180px 5px;
  float: right;
  margin: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.Topquick .leaveEntry {
  overflow: hidden;
}
.Topquick .selected {
  border: 1px solid #cccccc;
  overflow-y: scroll;
  background-color: #fcfcfc;
  height: 50px;
  padding: 5px 5px 5px 5px;
}
.Topquick .leaveEntry input {
  width: 90px;
  padding-left: 10px;
  box-sizing: border-box;
}
.Topquick .leaveEntry select {
  width: 55px;
  margin-left: 1px;
}
.Topquick .leaveEntry button {
}
.Topquick .spanText {
  line-height: 17px;
  font-size: 12px;
  margin: 3px 0 0;
  text-indent: -10px;
  padding-left: 10px;
  letter-spacing: -0.1px;
  color: #767676;
}
.Topquick .spanText span {
  color: #6186bc;
  font-weight: bold;
}
.Topquick .leaveEntry .airport > select {
}
.Topquick div.selectBox {
  overflow: hidden;
  margin-bottom: 20px;
}
.Topquick .selectBox > div {
  float: left;
}
.Topquick .selectBox i {
  float: left;
  width: 17px;
  line-height: 35px;
  color: #3a68b2;
  text-align: center;
}
.Topquick .selectBox input,
.Topquick .selectBox select {
  float: right;
  box-sizing: border-box;
}
.selectBox ::placeholder {
  font-size: 13px;
  padding-left: 3px;
  opacity: 1;
}
.selectBox :-ms-input-placeholder {
  font-size: 13px;
  padding-left: 3px;
}
.selectBox ::-ms-input-placeholder {
  font-size: 13px;
  padding-left: 3px;
}
.Topquick .selectBox .centerTop {
  width: 28%;
  margin-right: 2%;
}
.Topquick .selectBox .dateTop {
  width: 43%;
  margin-right: 2%;
}
.Topquick .selectBox .timeTop {
  width: 25%;
  margin-right: 0%;
}

.Topquick .centerTop input {
  width: 100px;
}
.Topquick .dateTop input {
  width: 164px;
  text-align: left;
  padding-left: 6px;
}
.Topquick .timeTop select {
  width: 87px;
}

.pic_tab > h3 {
  text-align: center;
}
.Topquick button.btnSelect {
  text-align: center;
  width: 100%;
  border: 1px solid #3a68b2;
  line-height: 35px;
  color: #3a68b2;
  font-family: "맑은 고딕", malgun gothic, "돋움", sans-serif;
  border-radius: 3px;
}
.Topquick button.btnSelect span {
  float: right;
  width: 30px;
}
.Topquick h3 {
  text-align: center;
  background-color: #3a68b2;
  color: #fff;
  height: 40px;
  line-height: 40px;
}

.inner {
  width: 1000px;
  margin: auto;
  /*border: 1px solid  red;*/
  /*height: 100%;*/
  padding: 40px 0;
  overflow: hidden;
  box-sizing: border-box;
}
.halfBan .inner {
  padding: 0;
  overflow: hidden;
  position: relative;
}
.halfBan .inner > div {
  width: 50%;
  float: left;
  margin: 0;
}
.quickWrap {
  position: absolute;
  top: 0;
  /*border: 1px solid red;*/
  left: 50%;
  height: 500px;
  z-index: 10;
  margin-left: -500px;
}
.mainCon h3,
.mainCon h3 > a {
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: normal;
  font-family: "Noto Sans KR", "nanumgothic", sans-serif;
  letter-spacing: -0.3px;
}
.mainCon h3 .en {
  color: #3a68b2;
  font-size: 13px;
  letter-spacing: 0;
}
.blue {
  color: #3a68b2;
}

div.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 15px;
}
div.swiper-container {
  background-color: #d1d5e0;
}
#banners_main .swiper-pagination-bullet {
  background-color: #999999 !important;
  opacity: 0.3;
}
#banners_main .swiper-pagination-bullet-active {
  background: #262626 !important;
}
#banners_main .swiper-slide {
  text-align: center;
}
.event_n .swiper-pagination-bullet {
  background-color: #868686 !important;
  opacity: 0.3;
}
.event_n .swiper-pagination-bullet-active {
  background: #041b3b !important;
}
.event_n {
  position: relative;
}

#banners_event_pagination {
  position: absolute;
  top: 14px;
  right: 10px;
  margin: 0;
  padding: 0;
  width: 70%;
  text-align: right;
  line-height: 0;
}
#banners_event_pagination .swiper-pagination-bullet {
  margin: 0 4px;
  background-color: #b9cde8;
}
#banners_event_pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #fff;
}
/*#banners_main .swiper-container-fade .swiper-slide { text-align: center; left: 50%; margin-left: -960px; }*/
.mainBan {
  left: 50%;
  margin-left: -960px;
  height: 520px;
}
.owl-pagination {
  top: -34px !important;
  height: 0px !important;
}
.halfBan {
  background-color: #fa8a89;
  position: relative;
}
.halfBan .bgRight {
  background-color: #8fbbe0;
  width: 50%;
  position: absolute;
  left: 50%;
  height: 100%;
}
.section1 {
}
.quickMenu {
  background: url(//d01.wifidosirak.com/v3/images/newMain/quickM_bar.png);
  background-position: 435px 0;
  background-repeat: no-repeat;
  height: 260px;
  font-family: "Noto Sans KR", "nanumgothic", sans-serif;
}
.quickMenu .box {
  width: 85%;
  height: 100%;
  border: 2px solid #3a68b2;
  border-radius: 20px;
  margin-left: 5%;
  background-color: #fff;
  box-sizing: border-box;
}
.quickMenu .box ul {
  overflow: hidden;
}
.quickMenu .box li {
  width: 50%;
  float: left;
  text-align: center;
  height: 50%;
  box-sizing: border-box;
}
.quickMenu .box li a {
  display: block;
  height: 100%;
  padding: 5px 0;
  box-sizing: border-box;
}
.section2 {
  background-color: #f2f2f2;
}
.event_n {
}
.slide_ban {
  height: 125px;
}
.section3 {
  position: relative;
}
.section4 {
  background-color: #f2f2f2;
}
.mainCon div.notice {
  width: 45%;
  margin-right: 6%;
}
.mainCon .faq {
}
.footer .bottomMenu {
  background-color: #424242;
}
.footer .bottomMenu .inner {
  padding: 20px 0;
}
.footer .bottomLogo {
  background-color: #2c2a2b;
  letter-spacing: 0;
  text-align: center;
  color: #737373;
}
.notice .date {
  float: right;
  color: #9d9d9c;
}
.half {
  width: 49%;
  float: left;
}
.half:first-child {
  margin-right: 2%;
}
.boardcontent_n a {
  font-size: 14px;
}
.mainCon .box li > span {
  font-size: 17px;
  color: #878787;
  font-family: "Noto Sans KR", "nanumgothic", sans-serif;
  margin-top: 6px;
  display: inline-block;
}
.quickMenu li span {
  display: block;
  font-size: 18px;
  color: #646363;
  margin-top: 4px;
}
.quickMenu li:nth-child(2n + 1) {
  border-right: 1px solid #3d6eba;
}
.quickMenu li:nth-child(1),
.quickMenu li:nth-child(2) {
  border-bottom: 1px solid #3d6eba;
}

.mainCon .borderR {
  border-right: 1px solid #3d6eba;
}
.mainCon .faq {
  overflow: hidden;
}
.mainCon .faq li {
  float: left;
  width: 20%;
  text-align: center;
}
.mainCon .faq ul {
  overflow: hidden;
  margin: 0;
}
.mainCon .faq li span {
  display: inline-block;
  font-size: 15px;
  color: #646363;
  margin-top: 10px;
  color: #878787;
  font-weight: bold;
}
.mainCon .faq li a {
  height: 100%;
  padding: 5px 0;
  box-sizing: border-box;
}
.mainCon .faq img {
  background-color: #3d6eba;
  border-radius: 50%;
  border: 8px solid #3d6eba;
}
.mainCon .notice li {
  margin: 3px 0;
}
.mainCon .more {
  float: right;
  font-size: 15px;
  color: #8f8f8f;
}
.mainCon .faq .more {
  margin-right: 20px;
}
.mainCon .customer {
  overflow: hidden;
}
.mainCon .customer > div {
  float: left;
  width: 50%;
}
.mainCon .kakao .fPlus {
  font-size: 13px;
  background-color: #767676;
  color: #fff;
  border-radius: 5px;
  padding: 3px 10px;
  letter-spacing: 0;
  position: absolute;
  right: 15px;
  top: -5px;
}

.mainCon .customer > div > div:first-child {
  margin-right: 20px;
}

.mainCon .customer > div > div,
.mainCon .kakao > div > div {
  float: left;
  margin-top: 10px;
}

.mainCon .customer h5 {
  font-size: 16px;
  margin-bottom: 5px;
}

.mainCon .kakao h5 {
  font-size: 15px;
  margin: 5px 0 0 0;
}

.mainCon .customer .num1 {
  font-size: 27px;
  margin-bottom: 3px;
  display: inline-block;
}

.mainCon .customer .num2 {
  font-size: 18px;
}

.mainCon .customer .time1 {
  font-size: 25px;
  margin-bottom: 3px;
  display: inline-block;
}

.mainCon .customer .time2 {
  font-size: 16px;
}

.mainCon .kakao {
  font-size: 15px;
  position: relative;
}

.mainCon .kakao .first {
  width: 22%;
  text-align: center;
}

.mainCon .kakao .second {
  width: 45%;
  text-align: center;
  margin-top: 6px;
}

.mainCon .kakao .second span,
.kakao .center span {
  line-height: 15px;
  color: #6d6d6d;
}

.mainCon .center {
  text-align: center;
  width: 20%;
  margin: 0;
  margin-left: 5%;
}

.center span {
  letter-spacing: 0;
}

.mainCon .kakao .kakao_note {
  display: inline-block;
  font-size: 14px;
  color: #8f8f8f;
  padding-left: 5px;
}

.mainCon .kakao .kakaobizM {
  display: inline-block;
}

.mainCon .certify {
  overflow: hidden;
  padding-bottom: 6px;
}
.mainCon .certify li {
  float: left;
  width: 32.5%;
}
.mainCon .certify p {
  font-size: 13px;
  margin-left: 10px;
  float: left;
  margin-top: 10px;
}
.mainCon .certify img {
  float: left;
}
.mainCon .certify span {
  font-size: 12px;
  color: #949494;
  line-height: 13px;
  letter-spacing: -0.2px;
}
.mainCon .certify a {
  background-color: #ededed;
  color: #868686;
  padding: 0px 10px;
  position: absolute;
  top: 50px;
  right: 23px;
  font-size: 11px;
  border-radius: 6px;
  border: 1px solid #b9b9b9;
}
.mainCon .gray {
  color: #878787;
}
.mainCon .section6 {
  border-top: 1px solid #ededed;
}
.mainCon .section6 .inner {
  padding: 20px 0;
}
.footer {
  text-align: center;
}
.footer .bottomMenu {
  overflow: hidden;
}
.footer .bottomMenu .inner > div {
  float: left;
  box-sizing: border-box;
  border-right: 1px solid #323232;
  height: 250px;
  padding: 20px;
}
.footer .bottomMenu .inner > div:last-child {
  border: none;
}
.footer,
.footer a {
  color: #b9b9b9;
}
.footer .menu {
  width: 25%;
  text-align: center;
  line-height: 23px;
}
.footer .bottomMenu div.info {
  width: 33%;
  padding-left: 40px;
  text-align: left;
}
.footer .right {
  width: 42%;
}
.footer .app {
  float: left;
  width: 60%;
  margin-right: 5%;
}
.footer .sns {
  float: right;
  width: 35%;
}
.footer .sns > a {
  width: 50px;
  height: 40px;
  background: url(//d01.wifidosirak.com/v3/images/newMain/snsIcon.png)
    no-repeat;
  display: inline-block;
  margin: 0;
  padding: 0;
}
.footer a.face_f {
  background-position: 7px 0px;
}
.footer a.naverTv_f {
  background-position: 7px -43px;
}
.footer a.youtube_f {
  background-position: 7px -86px;
}
.footer a.instagram_f {
  background-position: -35px 0px;
}
.footer a.blog_f {
  background-position: -35px -43px;
}
.footer a.twitter_f {
  background-position: -35px -86px;
}

.footer .app a {
  color: #bdbdbd;
  padding: 8px 0;
  border: 1px solid #272727;
  border-radius: 28px;
  width: 180px;
  text-align: left;
  display: inline-block;
  margin-bottom: 10px;
  padding-left: 20px;
  box-sizing: border-box;
  background-color: #373737;
}
.footer h5 {
  font-size: 17px;
  letter-spacing: 0;
  margin-bottom: 15px;
}
.footer .menu a,
.footer .info p {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: #b9b9b9;
}

.footer .bottomLogo .inner {
  padding: 20px 0;
}

.footer .bottomLogo p {
  letter-spacing: -0.5px;
  line-height: 11px;
  margin-top: 10px;
}

.footer .bottomLogo span {
  display: block;
  font-size: 14px;
  font-weight: bold;
}
/*차별화탭*/
.specialTab {
  color: #000 !important;
  background-color: #f1f1f1 !important;
  overflow: hidden;
  margin-top: 50px;
}

.specialTab .tabTit .listT {
  width: 100%;
  display: inline-block;
  padding: 2px 16px;
  box-sizing: border-box;
  padding: 2px 16px;
  height: auto;
}
.specialTab .tabTit {
  float: left;
  width: 200px;
}
.specialTab .tabView,
.tabTit {
  float: left;
  box-sizing: border-box;
}
.specialTab .tabView {
  width: 770px;
  height: 300px;
  margin-left: 30px;
}
.specialTab .activColor,
.specialTab .activColor:hover {
  color: #3a68b2 !important;
  font-weight: bold;
}
.specialTab .tabTit h5 {
  font-size: 15px;
  height: 90px;
}
.specialTab .tabTit li {
  height: 42px;
  border-bottom: 1px solid #dadada;
}
.specialTab .tabTit li a::after {
  content: ">";
  float: right;
  vertical-align: middle;
}
.specialTab .tabTit a {
  line-height: 40px;
}
.kkoPopup {
  display: none;
  position: fixed;
  top: 100px;
  left: 50%;
  z-index: 10001;
  margin-left: -243px;
}
/*차별화탭 end*/

.btnReser {
  box-sizing: border-box;
  font-size: 18px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  cursor: pointer;
  width: 200px;
  display: inline-block;
  background-color: rgb(97, 134, 188);
  border-radius: 10px;
  padding: 20px;
  margin: 2.5%;
}

.topTit {
  background-color: #f6f6f6;
}
.topTit .topInner2 {
  height: 210px;
  width: 1000px;
  margin: auto; /*padding-left: 50px;*/
  box-sizing: border-box;
  background-position: 600px 0;
  background-repeat: no-repeat;
}
.topTit .topInner {
  height: 210px;
  width: 1000px;
  margin: auto;
  padding-left: 150px;
  box-sizing: border-box;
  background-position: 600px 0;
  background-repeat: no-repeat;
}
.topTit .topImg01 {
  background-image: url(//d01.wifidosirak.com/v3/images/newMain/subTop01.jpg);
}
.topTit .topImg_price {
  background-image: url(//d01.wifidosirak.com/v3/images/newMain/subTop_price.jpg);
}
.topTit .topImg02 {
  background-image: url(//d01.wifidosirak.com/v3/images/newMain/subTop02.jpg);
}
.topTit .topImg03 {
  background-image: url(//d01.wifidosirak.com/v3/images/newMain/subTop03.jpg);
}
.topTit .topImg04 {
  background-image: url(//d01.wifidosirak.com/v3/images/newMain/subTop04.jpg);
}
.topTit .topImg05 {
  background-image: url(//d01.wifidosirak.com/v3/images/newMain/subTop05.jpg);
}
.topTit .topImg06 {
  background-image: url(//d01.wifidosirak.com/v3/images/newMain/subTop_report.jpg);
}
.topTit .topImg07 {
  background-image: url(//d01.wifidosirak.com/v3/images/newMain/subTop01.jpg);
}
.topTit .topImg08 {
  background-image: url(//d01.wifidosirak.com/v3/images/newMain/subTop01.jpg);
}
.topTit .topImg08 {
  background-image: url(//d01.wifidosirak.com/v3/images/newMain/subTop01.jpg);
}
.topTit .top_dtalk {
  background-image: url(//d01.wifidosirak.com/v3/images/newMain/subTop_dtalk.jpg);
}
.topTit .top_obstacle {
  background-image: url(//d01.wifidosirak.com/v3/images/newMain/subTop_obstacle.jpg);
}
.topTit .topImg_roaming {
  background-image: url(//d01.wifidosirak.com/v3/images/newMain/subTop_roaming.jpg);
}
.topTit .topImg_my {
  background-image: url(//d01.wifidosirak.com/v3/images/newMain/subTop_my.jpg);
}
.topTit .topImg_reser {
  background-image: url(//d01.wifidosirak.com/v3/images/newMain/subTop01.jpg);
}

.bottomBan1 {
  background-color: #d9e5ff;
}
.bottomBan2 {
  background-color: #d10356;
}
.innerBan {
  width: 1000px;
  margin: auto;
}

div.wrapAccordion {
  position: relative;
}
#applyPopup .inner {
  max-width: 750px;
}
.sirakTop {
  position: absolute;
  /* top: 10px; */
  bottom: 0;
  left: 144px;
}
.mainSirak {
  position: absolute;
  left: 50%;
  bottom: 25px;
  margin-left: 505px;
}
a.searchCan,
a.searchCan:visited,
.topDsr a:visited {
  color: #848484;
}
.idfind p {
  text-align: center;
}
.main button.namebox {
  padding: 0 5px;
  min-width: 67px;
  width: auto;
  margin-right: 5px;
  font-size: 14px;
}
.main button.namebox:last-child {
  margin-right: 0;
}

/*간소화 수령반납외 팝업*/
div.pop_pick h4 {
  font-size: 22px;
  color: #3a68b2;
  margin-top: 10px;
}
.pick > ul {
  margin-top: 20px;
}
.pick .pickContent {
  line-height: 18px;
  display: table-cell;
  float: none;
}
.pick .w3-navbar2 li {
  display: inline-block;
  width: 139px;
  text-align: center;
  border: 1px solid #d1d3d4;
  border-radius: 8px;
  margin: 3px;
  font-size: 14px;
  border-collapse: collapse;
  line-height: 19px;
}

.pick .w3-navbar2 li a {
  display: block;
  padding: 8px;
  color: #6d6e71;
  border-radius: 8px;
}
.pick .w3-red,
.pick .w3-hover-red:hover {
  color: #fff !important;
  background-color: #3a68b2 !important;
}
.pick input[type="text"] {
  width: 85%;
  max-width: 700px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
  background-image: url("//d01.widemobile.com/v3/images/searchicon.png");
  background-position: 10px 8px;
  background-repeat: no-repeat;
  padding: 10px 10px 10px 40px;
  color: #58595b;
  margin-left: 3px;
  font-size: 14px;
  height: 40px;
  line-height: 14px;
  vertical-align: middle;
}

.pick input[type="text"]:focus {
  background-color: #f1f2f2;
}

.pick .btnSearch {
  width: 10%;
  background-color: #a7a9ac;
  color: #fff;
  border-radius: 6px;
  background-color: #a7a9ac;
  border: none;
  color: white;
  padding: 11px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  cursor: pointer;
  vertical-align: middle;
  line-height: 15px;
  font-family: "맑은 고딕", Malgun Gothic, "굴림", Gulim, sans-serif;
}

.pick .search {
  margin-top: 10px;
  width: 80%;
  margin: 25px 10% 0;
}

.pick div.pickAcco {
  background-color: #fff;
  color: #636466;
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 14px;
  transition: 0.4s;
  border-bottom: 1px solid #d1d3d4;
  position: relative;
  font-family: "맑은 고딕", Malgun Gothic, "굴림", Gulim, sans-serif;
  line-height: 14px;
  display: table;
  vertical-align: middle;
}

.pick div.pickAcco.active label {
  color: #3a68b2;
}

div.pop_pick {
  width: 700px;
  margin: auto;
  border: 1px solid #5982c4;
  position: relative;
  border-radius: 20px;
  margin-bottom: 30px;
  padding: 30px;
}
div.pop_pick .pickPanel p {
  border-bottom: 1px solid #d2d2d4;
}
div.pop_pick .titBold {
  display: block;
  font-weight: bold;
  font-size: 13px;
  line-height: 25px;
}
div.pop_pick .closed {
  right: 12px;
  top: 6px;
  background: url(//d01.wifidosirak.com/v3/images/reserSimple/pop_close_blu.png)
    no-repeat;
  background-size: 100%;
  width: 30px;
  height: 30px;
}

div.pop_pick .poppick_info {
  text-align: center;
  color: #f55255;
  font-size: 13px;
  margin: 15px 0;
  font-weight: bold;
}
div.pop_pick label {
  line-height: 45px;
  cursor: pointer;
}
.pick input[type="radio"] {
  margin: 0 13px;
  width: 15px;
  height: 15px;
}
.pick div.pickPanel {
  padding: 0 15px 0 25px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #f6f6f6;
}

.pick .pickPanel p {
  font-size: 12px;
  padding: 20px 10px 20px 30px;
  letter-spacing: -0.5px;
  line-height: 18px;
}

.pick .list {
  margin-top: 15px;
  vertical-align: middle;
}

.pick .list {
  border-top: 2px solid #92a9cf;
  border-bottom: 2px solid #92a9cf;
}

.pick .q {
  font-size: 20px;
  padding-right: 5px;
  display: table-cell;
  width: 25px;
  vertical-align: middle;
  width: 32%;
  font-size: 14.2px;
  letter-spacing: -0.5px;
  color: #545454;
  font-weight: bold;
  padding: 0;
  border-right: 1px solid #d2d2d4;
  background-color: #fff;
}

.pick .list div:last-child {
  border-bottom: none;
}

.pick .a {
  color: #f15b40;
  font-size: 20px;
  font-family: "Noto Sans KR", "nanumgothic", sans-serif;
  padding-right: 5px;
  float: left;
  width: 10px;
  padding-top: 20px;
}

.pick .aCon {
  width: 700px;
}

.pick .pagination {
  /*display: inline-block;*/
  width: 750px;
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
}

.pick .pagination a {
  color: #666;
  padding: 8px;
  text-decoration: none;
  transition: background-color 0.3s;
  display: inline-block;
}

.pick .pagination a.active {
  font-weight: bold;
  color: #3a68b2;
}
.pick .pagination a:hover:not(.active) {
  font-weight: bold;
}
.pick div.pickAcco.active:after,
.pick div.pickAcco2.active:after {
  content: "\2227";
}
.pick div.pickAcco:after,
.pick div.pickAcco2:after {
  content: "\2228";
  margin-left: 5px;
  font-size: 15px;
  display: table-cell;
  vertical-align: middle;
  width: 25px;
  text-align: center;
  color: #ababab;
  background-color: #fafafc;
  padding-right: 5px;
}

.pick .category {
  color: #939598;
  font-size: 13px;
  text-align: center;
  line-height: 13px;
  display: table-cell;
  vertical-align: middle;
}

.pick .category {
  width: 130px;
}

.pick {
  margin: auto;
  border-top: 1px solid #d2d2d2;
  border-left: 1px solid #d2d2d2;
  border-right: 1px solid #d2d2d2;
}
.pick .pickContent {
  font-size: 13px; /*font-weight:bold;*/
  letter-spacing: -0.5px;
  padding: 0;
  background-color: #fafafc;
  line-height: 45px;
  padding-left: 10px;
}
.pick button.pickAcco {
  padding: 0;
}
.pick button.pickAcco,
.pick button.pickAcco2 {
  background-color: #fafafc;
}
.pick button.pickAcco.active .pickContent,
.pick button.pickAcco:hover .pickContent,
.pick button.pickAcco2.active .pickContent,
.pick button.pickAcco2:hover .pickContent {
  background-color: transparent;
}
.pick .pickPanel input[type="radio"] {
  display: none;
}
.pick .pickPanel2 input[type="radio"] {
  display: none;
}
.pick .pickPanel input[type="radio"] + label::before,
.pick .pickPanel2 input[type="radio"] + label::before {
  content: " ";
  display: inline-block;
  width: 15px;
  height: 15px;
  vertical-align: middle;
  margin: -3px 10px 0 0;
  background: url("//d01.wifidosirak.com/v3/images/reserSimple/radio_t_check.png");
}
.pick .pickPanel input[type="radio"]:checked + label::before,
.pick .pickPanel2 input[type="radio"]:checked + label::before {
  background: url("//d01.wifidosirak.com/v3/images/reserSimple/radio_t_check.png");
  background-position: 15px 0;
}
.pick p.radio_t1 {
  display: block;
  float: left;
  width: 40%;
  box-sizing: border-box;
  padding: 10px;
  border-right: 0;
}
.pick p.radio_t3 {
  display: block;
  float: left;
  width: 60%;
  box-sizing: border-box;
  padding: 10px;
  border-left: none;
}
.pick div.pickPanel {
  background-color: #fff;
  padding: 0;
  text-align: left;
}
.pick .pick_checklist {
  clear: both;
  background-color: #fafafc;
  padding: 15px 0 15px 30px;
  border-bottom: 1px solid #ccc;
}
.pick .pop_pick_xb {
  width: 27px;
  height: 27px;
  position: absolute;
  left: 783px;
  top: 20px;
}
.pick .pop_pick_xb a {
  width: 100%;
  height: 100%;
  display: block;
}
/*간소화 팝업 end*/

/*추천친구 시락이*/
.recommend_sirak {
  position: absolute;
  top: 0;
  left: 85px;
  animation: siAirplane 3s infinite;
  animation-delay: 3s;
  animation-timing-function: ease;
  z-index: 9999;
}
.recommend_sirak span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 120px;
  padding: 8px;
  line-height: 14px;
  text-align: center;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0;
  color: #fff;
  border-radius: 6px;
  border: solid 1px #7a9dd4;
  background: #7a9dd4;
  box-sizing: border-box;
  font-family: "noto sans kr", "margungothic", sans-serif;
  animation: txtYtranslate 3s infinite;
  animation-delay: 0;
  animation-timing-function: ease;
}
.recommend_sirak span:after {
  content: "";
  position: absolute;
  top: 8px;
  left: 50%;
  margin-left: -63px;
  width: 5px;
  height: 5px;
  border: solid 1px #7a9dd4;
  border-left: solid 1px #7a9dd4;
  border-bottom: solid 1px #7a9dd4;
  transform: rotate(45deg);
  background: #7a9dd4;
}

@keyframes siAirplane {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}

/*도시락몰 추가 CSS*/

.inner .domallTitle {
  width: 30%;
  float: left;
}
.inner .inquiry {
  width: 70%;
  float: left;
  text-align: right;
  margin-top: 7px;
}
.inner .inquiry a {
  /* width:300px; padding:5px 10px; border-radius:5px; border:1px solid #547fc2; color:#547fc2; font-size:13px; */
  font-size: 12px;
  color: #939393;
}
.inner .inquiry a:hover {
  /* background-color: #547fc2;color: #fff; */
  color: #547fc2;
}
.dosirakmall {
  clear: both;
}
.dosirakmall ul {
  list-style: none outside none;
  padding-left: 0;
  margin: 0;
}
.dosirakmall .content-slider li {
  text-align: center;
}
.dosirakmall .content-slider h3 {
  margin: 0;
  padding: 70px 0;
}

.dosirakmall .refer {
  text-align: left;
  margin-top: -32px;
  color: #939393;
  font-size: 12px;
  width: 50%;
}
.dosirakmall .referRt {
  text-align: right;
  margin-top: -14px;
  width: 50%;
  float: right;
}
.dosirakmall .referRt a {
  width: 300px;
  padding: 5px 5px;
  border-radius: 5px;
  border: 1px solid #547fc2;
  color: #547fc2;
  font-size: 11px;
}
.dosirakmall .referRt a:hover {
  background-color: #547fc2;
  color: #fff;
}
.dosirakmall .box {
  padding: 0;
  box-shadow: none;
  border-radius: 0;
  box-sizing: border-box;
  border: 1px solid #dadada;
  overflow: hidden;
}
.dosirakmall .box p {
  font-size: 14px;
  padding: 30px 0;
  color: #939393;
}
.dosirakmall .photo {
  margin: 0;
}
.dosirakmall .box p .check {
  font-size: 16px;
  color: #757575;
}
.dosirakmall .box strong {
  color: #3d6eba;
}

.dosirakmall .box i {
  display: block;
  font-size: 12px;
  font-style: normal;
}

.lSSlideOuter .lSPager.lSpg {
  margin-top: 25px !important;
}

/* 도시락몰 추가에 따른 섹션별 배경색 변경*/
.section3,
.section3 .pic_tab,
.section5 {
  background-color: #f2f2f2;
}
.section4 {
  background-color: #fff;
}

/* 2018-12-20 ysb 친구초대 팝업 with.khj */
.recommendFloating .rePop {
  background-image: url(//d01.wifidosirak.com/v3/images/newMain/recommendFr_pop.png);
  position: relative;
  width: 177px;
  height: 189px;
}

.recommendFloating .rePop a {
  width: 177px;
  height: 189px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.recommendFloating .close {
  border: none;
  background: none;
  width: 46px;
  height: 46px;
  padding: 0;
  position: absolute;
  top: -7.5%;
  left: 77.5%;
  cursor: pointer;
  z-index: 1004;
}

.recommendFloating {
  position: fixed;
  bottom: 310px;
  left: 10.5%;
  z-index: 1003;
  max-width: 205px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  /* transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0); */
  transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
