<template>
    <div class="content container">
        <div class="wrap subPage">
            <div class="container">
                <!-- sub title -->
                <div class="subTitAr fW-R notranslate" v-html="$t('trans.t06_01')"></div>

                <top-rate-banner></top-rate-banner>

                <!-- 가입신청서 STEP -->
                <div class="subStepWrap">
                    <ul class="stepAr">
                        <li>
                            <span class="stepNum fW-L on notranslate">{{ $t('trans.t06_03') }}</span>
                        </li>
                        <li>
                            <span class="stepNum fW-L notranslate">{{ $t('trans.t07_01') }}</span>
                        </li>
                        <li>
                            <span class="stepNum fW-L notranslate">{{ $t('trans.t08_01') }}</span>
                        </li>
                    </ul>
                </div>

                <div style="clear: both;"></div>

                <!-- 서브페이지 Tab -->
                <div class="subTabAr">
                    <ul class="subTab">
                        <li
                            :class="['subTab-link fW-R notranslate', { current: selectedTab === 1 }]"
                            data-tab="up19"
                            @click="onChargeTab(1)"
                        >
                            {{ $t('trans.t06_04') }}<span></span>
                        </li>
                        <li
                            :class="['subTab-link fW-R notranslate', { current: selectedTab === 3 }]"
                            data-tab="foreigner"
                            @click="onChargeTab(3)"
                        >
                            {{ $t('trans.t06_05') }}<span></span>
                        </li>
                    </ul>
                </div>
                <!-- 가입신청서 컨텐츠 -->
                <!-- 만 19세 이상 -->
                <form @submit.prevent="onOutSubmit" data-vv-scope="frmOut">
                    <div :class="['subTab-content', { current: selectedTab === 1 }]" id="up19">
                        <div class="subPageAr">
                            <!-- 실명확인 -->
                            <div class="prod_option">
                                <div class="prod_subject">
                                    <span class="subjectTit fW-R notranslate">{{ $t('trans.t06_06') }}</span>
                                    <span class="subjectLine"></span>
                                </div>
                                <div class="prod_content document">
                                    <!-- 이름 -->
                                    <div class="inputAr notranslate">
                                        <input
                                            type="text"
                                            class="name"
                                            :placeholder="$t('trans.t06_07')"
                                            v-model="accName"
                                            v-validate="'required'"
                                            data-vv-name="accName"
                                            key="accName"
                                            :maxlength="25"
                                        />
                                    </div>
                                    <!-- 주민등록번호 -->
                                    <div class="inputAr">
                                        <div class="inpGroup">
                                            <select id="selectId" v-model.number="accCard" @change="onAccCardChange">
                                                <option value="1"
                                                    ><span class="notranslate">{{ $t('trans.t06_08') }}</span>
                                                </option>
                                                <option value="2"
                                                    ><span class="notranslate">{{ $t('trans.t06_09') }}</span>
                                                </option>
                                            </select>
                                            <div class="grouping selectInput notranslate">
                                                <input
                                                    type="text"
                                                    class="idNum"
                                                    v-model="accNum"
                                                    maxlength="13"
                                                    :placeholder="$t('trans.t06_10')"
                                                    v-validate="'required|numeric|length:13'"
                                                    data-vv-name="accNum"
                                                    key="accNum"
                                                />
                                                <!--<span class="id_alert">유효한 주민등록번호가 아닙니다.</span>-->
                                            </div>
                                        </div>
                                    </div>

                                    <!-- 주민등록증 -->
                                    <div id="identification1" style="display: none;">
                                        <!-- 신분증 이미지 -->
                                        <ul class="idCard">
                                            <li>
                                                <div
                                                    class="license"
                                                    onclick="document.querySelector('#inputOutImageCard').click()"
                                                >
                                                    <img
                                                        style="width:100%;"
                                                        class="preview"
                                                        v-show="picCard !== ''"
                                                        :src="picCard"
                                                    />
                                                    <span
                                                        class="liDesc notranslate"
                                                        v-if="picCard === ''"
                                                        v-html="$t('trans.t06_16')"
                                                    >
                                                    </span>
                                                    <input
                                                        id="inputOutImageCard"
                                                        type="file"
                                                        style="opacity: 0.0;"
                                                        @change="onCard"
                                                        capture="user"
                                                        accept=".png, .jpeg, .gif, .jpg"
                                                        ref="inputOutImageCard"
                                                    />
                                                </div>
                                            </li>
                                            <li>
                                                <!-- <div
                          class="license"
                          onclick="document.querySelector('#inputOutImageFace').click()"
                          :style="{backgroundImage:'url(' + picFace + ')'}"
                        > -->
                                                <div
                                                    class="license"
                                                    onclick="document.querySelector('#inputOutImageFace').click()"
                                                >
                                                    <img
                                                        style="width:100%;"
                                                        class="preview"
                                                        v-show="picFace !== ''"
                                                        :src="picFace"
                                                    />
                                                    <span
                                                        class="liDesc notranslate"
                                                        v-if="picFace === ''"
                                                        v-html="$t('trans.t06_17')"
                                                    ></span>
                                                    <input
                                                        id="inputOutImageFace"
                                                        type="file"
                                                        style="opacity: 0.0;"
                                                        capture="user"
                                                        @change="onFace"
                                                        accept=".png, .jpeg, .gif, .jpg"
                                                        ref="inputOutImageFace"
                                                    />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <!-- 운전면허증 -->
                                    <div id="identification2" style="display: none;">
                                        <!-- 신분증 이미지 -->
                                        <ul class="idCard">
                                            <li>
                                                <div class="license">
                                                    <span class="liDesc notranslate">{{ $t('trans.t06_16') }}</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="license">
                                                    <span class="liDesc notranslate">{{ $t('trans.t06_17') }}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div style="clear: both;"></div>
                                    <div class="btnAr" style="display: none;">
                                        <button
                                            class="btnNote fW-R half notranslate"
                                            type="button"
                                            @click="
                                                isCardShow = !isCardShow;
                                                isDim = isCardShow;
                                            "
                                        >
                                            {{ $t('trans.t06_18') }}
                                        </button>
                                        <button class="btnNote fW-R half notranslate" type="button">
                                            <a href="https://www.dosirakmobile.com/" target="_blank">{{
                                                $t('trans.t06_20')
                                            }}</a>
                                        </button>
                                    </div>
                                    <div class="btnAr">
                                        <button
                                            class="btnBefore fW-R notranslate"
                                            type="button"
                                            @click="$router.push({ name: 'Step1View' })"
                                        >
                                            {{ $t('trans.t06_26') }}
                                        </button>
                                        <button class="btnCertify fW-R notranslate" type="submit">
                                            {{ $t('trans.t06_27') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <!-- 외국인 -->
                <form @submit.prevent="onInSubmit" data-vv-scope="frmIn">
                    <div :class="['subTab-content', { current: selectedTab === 3 }]" id="foreigner">
                        <div class="subPageAr">
                            <!-- 외국인 실명확인 -->
                            <div class="prod_option">
                                <div class="prod_subject">
                                    <span class="subjectTit fW-R notranslate">{{ $t('trans.t06_06') }}</span>
                                    <span class="subjectLine"></span>
                                </div>
                                <div class="prod_content document">
                                    <!-- 외국인 가입자 정보 -->
                                    <div class="inputAr">
                                        <div class="inpGroup">
                                            <input
                                                type="text"
                                                class="name"
                                                :placeholder="$t('trans.t06_07')"
                                                v-model="accName"
                                                data-vv-name="accName"
                                                v-validate="'required'"
                                                key="accName"
                                                maxlength="25"
                                            />
                                            <div class="grouping nextLine">
                                                <select
                                                    v-model="accCountry"
                                                    data-vv-name="accCountry"
                                                    v-validate="'required'"
                                                    key="accCountry"
                                                >
                                                    <option value=""
                                                        ><span class="notranslate">{{ $t('trans.t06_11') }}</span>
                                                    </option>
                                                    <option
                                                        v-for="(item, index) in countrys"
                                                        :key="index"
                                                        :value="item.country_cd"
                                                        >{{ item.kr }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="inputAr">
                                        <div class="inpGroup">
                                            <!-- <input
                                        type="text"
                                        class="name"
                                        placeholder="여권번호"
                                    /> -->
                                            <select v-model.number="accCard" @change="onAccCardChange">
                                                <option value="3" selected
                                                    ><span class="notranslate">{{ $t('trans.t06_12') }}</span>
                                                </option>
                                                <option value="4" selected
                                                    ><span class="notranslate">{{ $t('trans.t06_14') }}</span>
                                                </option>
                                                <option v-if="googtrans == '/ko/en'" value="5" selected
                                                    ><span class="notranslate">{{ $t('trans.t21_20') }}</span>
                                                </option>
                                                <option value="8" selected
                                                    ><span class="notranslate">{{ $t('trans.t21_21') }}</span>
                                                </option>
                                                <option value="9" selected
                                                    ><span class="notranslate">{{ $t('trans.t21_22') }}</span>
                                                </option>
                                            </select>
                                            <div class="grouping selectInput" v-if="accCard === 3">
                                                <input
                                                    type="text"
                                                    class="idNum"
                                                    v-model="accPassport"
                                                    data-vv-name="accPassport"
                                                    v-validate="'required'"
                                                    maxlength="9"
                                                    :placeholder="$t('trans.t06_13')"
                                                    key="accPassport"
                                                />
                                            </div>
                                            <div class="grouping selectInput" v-if="accCard === 4">
                                                <input
                                                    type="text"
                                                    class="idNum"
                                                    v-model="accNum"
                                                    maxlength="13"
                                                    :placeholder="$t('trans.t06_15')"
                                                    v-validate="'required|numeric|length:13'"
                                                    data-vv-name="accNumAlien"
                                                    key="accNum"
                                                />
                                            </div>
                                            <div class="grouping selectInput" v-if="accCard === 5">
                                                <input
                                                    type="text"
                                                    class="idNum"
                                                    v-model="accNum"
                                                    maxlength="13"
                                                    :placeholder="$t('trans.t21_23')"
                                                    v-validate="'required|numeric|length:13'"
                                                    data-vv-name="accNumAlien"
                                                    key="accNum"
                                                />
                                            </div>
                                            <div class="grouping selectInput" v-if="accCard === 8">
                                                <input
                                                    type="text"
                                                    class="idNum"
                                                    v-model="accNum"
                                                    maxlength="13"
                                                    :placeholder="$t('trans.t21_24')"
                                                    v-validate="'required|numeric|length:13'"
                                                    data-vv-name="accNumAlien"
                                                    key="accNum"
                                                />
                                            </div>
                                            <div class="grouping selectInput" v-if="accCard === 9">
                                                <input
                                                    type="text"
                                                    class="idNum"
                                                    v-model="accNum"
                                                    maxlength="13"
                                                    :placeholder="$t('trans.t21_25')"
                                                    v-validate="'required|numeric|length:13'"
                                                    data-vv-name="accNumAlien"
                                                    key="accNum"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 신분증 이미지 -->
                                    <ul class="idCard" style="display: none;">
                                        <li>
                                            <div
                                                class="license"
                                                onclick="document.querySelector('#inputInImageCard').click()"
                                            >
                                                <img
                                                    style="width:100%;"
                                                    class="preview"
                                                    v-show="picCard !== ''"
                                                    :src="picCard"
                                                />
                                                <span
                                                    class="liDesc notranslate"
                                                    v-if="picCard === ''"
                                                    v-html="$t('trans.t06_16')"
                                                >
                                                </span>

                                                <input
                                                    id="inputInImageCard"
                                                    type="file"
                                                    style="opacity: 0.0;"
                                                    capture="user"
                                                    @change="onCard"
                                                    accept=".png, .jpeg, .gif, .jpg"
                                                />
                                            </div>
                                        </li>
                                        <li>
                                            <div
                                                class="license"
                                                onclick="document.querySelector('#inputInImageFace').click()"
                                            >
                                                <img
                                                    style="width:100%;"
                                                    class="preview"
                                                    v-show="picFace !== ''"
                                                    :src="picFace"
                                                />
                                                <span
                                                    class="liDesc notranslate"
                                                    v-if="picFace === ''"
                                                    v-html="$t('trans.t06_17')"
                                                >
                                                </span>
                                                <input
                                                    id="inputInImageFace"
                                                    type="file"
                                                    style="opacity: 0.0;"
                                                    capture="user"
                                                    @change="onFace"
                                                    accept=".png, .jpeg, .gif, .jpg"
                                                />
                                            </div>
                                        </li>
                                    </ul>
                                    <div style="clear: both;"></div>
                                    <div class="btnAr" style="display: none;">
                                        <button
                                            class="btnNote fW-R center notranslate"
                                            type="button"
                                            style="display:block"
                                            @click="isPassportShow = isDim = !isPassportShow"
                                        >
                                            {{ $t('trans.t06_19') }}
                                        </button>
                                    </div>
                                    <div class="btnAr">
                                        <button class="btnBefore notranslate" type="button" @click="$router.go(-1)">
                                            {{ $t('trans.t06_26') }}
                                        </button>
                                        <button class="btnCertify notranslate" type="submit">
                                            {{ $t('trans.t06_27') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <!-- 신분증 첨부 팝업 -->
            <div class="popWpper licensePop" v-show="isCardShow">
                <div class="popContainer center">
                    <button
                        class="close ic"
                        type="button"
                        @click="
                            isCardShow = false;
                            isDim = false;
                        "
                    >
                        닫기
                    </button>
                    <div class="popWP">
                        <div class="popContTit line notranslate">
                            <p class="tit fW-R">
                                {{ $t('trans.t06_21') }}
                            </p>
                            <!-- <p class="txt">와이파이도시락 회원 정보로 로그인 하시려면<br>와이드모바일 통합 회원에 동의 후 로그인 가능합니다.</p> -->
                        </div>
                        <div class="popBody">
                            <!-- pc -->
                            <ul class="licenseAr">
                                <li>
                                    <span class="titBlue fW-R notranslate">{{ $t('trans.t06_22') }}</span>
                                    <div class="imgAr">
                                        <img src="../../images/idCard.png" />
                                    </div>
                                </li>
                                <li>
                                    <span class="titBlue fW-R notranslate">{{ $t('trans.t06_23') }}</span>
                                    <div class="imgAr face">
                                        <img src="../../images/sirakphoto.png" />
                                    </div>
                                </li>
                            </ul>
                            <div style="clear: both"></div>
                            <div class="noticeAr notranslate" v-html="$t('trans.t06_24')"></div>
                            <!-- 선택 완료 버튼 -->
                            <a class="selectBtn fW-R notranslate" @click="isCardShow = isDim = false">{{
                                $t('trans.t06_25')
                            }}</a>
                        </div>
                    </div>
                </div>
                <div class="dim"></div>
            </div>

            <!-- 외국인 신분증 첨부 팝업 -->
            <div class="popWpper licensePop_foreigner" v-show="isPassportShow">
                <div class="popContainer center">
                    <button class="close ic" type="button" @click="isPassportShow = isDim = false">
                        닫기
                    </button>
                    <div class="popWP">
                        <div class="popContTit line notranslate">
                            <p class="tit fW-R">
                                {{ $t('trans.t06_21') }}
                            </p>
                            <!-- <p class="txt">와이파이도시락 회원 정보로 로그인 하시려면<br>와이드모바일 통합 회원에 동의 후 로그인 가능합니다.</p> -->
                        </div>
                        <div class="popBody">
                            <!-- pc -->
                            <ul class="licenseAr">
                                <li>
                                    <span class="titBlue fW-R notranslate">{{ $t('trans.t06_22') }}</span>
                                    <div class="imgAr">
                                        <img src="../../images/passport.png" />
                                    </div>
                                </li>
                                <li>
                                    <span class="titBlue fW-R notranslate">{{ $t('trans.t06_23') }}</span>
                                    <div class="imgAr face">
                                        <img src="../../images/sirakphoto.png" />
                                    </div>
                                </li>
                            </ul>
                            <div style="clear: both"></div>
                            <div class="noticeAr notranslate" v-html="$t('trans.t06_24')"></div>
                            <!-- 선택 완료 버튼 -->
                            <a
                                class="selectBtn fW-R notranslate"
                                @click="
                                    isPassportShow = false;
                                    isDim = false;
                                "
                                >{{ $t('trans.t06_25') }}</a
                            >
                        </div>
                    </div>
                </div>
                <div class="dim"></div>
            </div>
        </div>
    </div>
</template>

<script>
import TopRateBanner from '../../components/TopRateBanner';
import * as Enum from '../../js/enum';
import { orderMixin } from '../../mixin/storeMixin';
import * as blobUtil from 'blob-util';
import { imageEdit } from '../../api/common';
import { resizeImage } from '../../js/util';
import { countrys } from '../../api/Repo';
import * as common from '../../api/common';

export default {
    mixins: [orderMixin],
    components: {
        TopRateBanner,
    },
    data() {
        return {
            selectedTab: 1, // 선택탭
            isCardShow: false, // 신분증 첨부 유의사항 팝업
            isPassportShow: false, // 여권 첨부 유의사항 팝업
            picFace: '',
            picCard: '',
            cardStyle: {},
            faceStyle: {},
            countrys: countrys,
            cardBase64: '',
            faceBase64: '',
        };
    },
    methods: {
        async onCard(event) {
            const input = event.target;
            const self = this;

            if (!/\.(gif|jpg|jpeg|png)$/i.test(input.files[0].name)) {
                this.kakaoTrans('확장자 png, jpeg, gif, jpg만 가능합니다.').then((res) => alert(res));
                return;
            }

            try {
                if (input.files && input.files[0]) {
                    self.picCard = URL.createObjectURL(input.files[0]);
                    self.$nprogress.start();

                    // const data = new FormData();
                    // data.append('name', this.accName);
                    // data.append('type', 'card');
                    // data.append('', input.files[0])
                    // const resImage =  await common.postImageUpload(data);

                    const reader = new FileReader();
                    await resizeImage({
                        file: input.files[0],
                        maxSize: 3000,
                    }).then(function(resizedImage) {
                        sessionStorage.removeItem('imageCard');
                        reader.onload = async function(e) {
                            sessionStorage.setItem(
                                'imageCard',
                                `data:image/png;base64,${await blobUtil.blobToBase64String(resizedImage)}`
                            );
                        };
                        reader.readAsDataURL(input.files[0]);
                    });
                    this.$nprogress.done();
                }
            } catch (error) {
                console.log(error);
                this.$nprogress.done();
            }
        },
        async onFace(event) {
            const input = event.target;
            const self = this;
            if (!/\.(gif|jpg|jpeg|png)$/i.test(input.files[0].name)) {
                this.kakaoTrans('확장자 png, jpeg, gif, jpg만 가능합니다.').then((res) => alert(res));
                return;
            }
            try {
                if (input.files && input.files[0]) {
                    self.picFace = URL.createObjectURL(input.files[0]);
                    self.$nprogress.start();

                    const data = new FormData();
                    data.append('name', this.accName);
                    data.append('type', 'face');
                    data.append('', input.files[0]);
                    const resImage = await common.postImageUpload(data);

                    console.error(resImage);

                    const reader = new FileReader();
                    await resizeImage({
                        file: input.files[0],
                        maxSize: 3000,
                    }).then(function(resizedImage) {
                        reader.onload = async function(e) {
                            sessionStorage.removeItem('imageFace');
                            sessionStorage.setItem(
                                'imageFace',
                                `data:image/png;base64,${await blobUtil.blobToBase64String(resizedImage)}`
                            );
                        };

                        reader.readAsDataURL(input.files[0]);
                    });

                    this.$nprogress.done();
                }
            } catch (error) {
                this.$nprogress.done();
                alert(error);
            }
        },
        onChargeTab(payload) {
            this.selectedTab = payload;
            this.activeTab = payload;
            this.picCard = '';
            this.picFace = '';

            this.$validator.reset();

            // 외국인 여권으로 변경
            if (payload === 3) {
                this.MTS_ALIEN(true);
                this.accCard = 3;
                return;
            }

            // 기본 주민등록증으로 변경
            this.accCard = 1;

            // 내외국인유무
            this.MTS_ALIEN(false);

            //변경시 기존값 초기화
            this.MTS_ACC_DRIVER_NUM(''); //운전면허
            this.MTS_ACC_NUM(''); //주민등록증
            this.MTS_BIRTH(''); //생년월일
            this.MTS_ACC_PASSPORT(''); //여권번호
            this.MTS_COUNTRY(''); //국적
        },

        onAccCardChange(e) {
            //변경시 기존값 초기화
            this.MTS_ACC_DRIVER_NUM('');
            this.MTS_ACC_NUM('');
            this.MTS_BIRTH('');
            this.MTS_ACC_PASSPORT('');
        },
        async onOutSubmit() {
            const next = await this.$validator.validateAll('frmOut');
            if (next) {
                // if (!sessionStorage.getItem('imageCard') || !sessionStorage.getItem('imageFace')) {
                //     this.kakaoTrans('신분증 또는 본인 얼굴사진은 필수 정보입니다.').then((res) => alert(res));
                //     return;
                // }

                this.MTS_STEP2(true);
                await this.$router.push({ name: 'Step3View' });
                return;
            }

            this.kakaoTrans(this.errors.items[0].msg).then((res) => alert(res));
        },
        async onInSubmit() {
            const next = await this.$validator.validateAll('frmIn');

            if (next) {
                // if (!sessionStorage.getItem('imageCard') || !sessionStorage.getItem('imageFace')) {
                //     this.kakaoTrans('신분증 또는 본인 얼굴사진은 필수 정보입니다.').then((res) => alert(res));
                //     return;
                // }

                this.MTS_STEP2(true);
                await this.$router.push({ name: 'Step3View' });
                return;
            }

            this.kakaoTrans(this.errors.items[0].msg).then((res) => alert(res));
        },
    },
    mounted() {
        if (!this.OrderDataSet.isStep1) {
            this.$router.replace('/');
        }

        if (!sessionStorage.getItem('order')) {
            this.$router.replace({ name: 'Home' });
        }

        // 초기화
        this.accCard = 1;

        // 내국인으로 설정
        this.MTS_ALIEN(false);

        // 현재페이지 저장
        this.MTS_CURRENT_PAGE(this.$router.currentRoute.path);

        // 회원로그인 이름 바인딩
        if (this.memberInfo.member_login_type === Enum.loginType.EMAIL) {
            if (this.memberInfo.isLogin) {
                this.accName = this.memberInfo.member_name;
            }
        }

        if (sessionStorage.getItem('imageCard')) {
            this.picCard = sessionStorage.getItem('imageCard');
        }
        if (sessionStorage.getItem('imageFace')) {
            this.picFace = sessionStorage.getItem('imageFace');
        }
    },
    computed: {},
};
</script>

<style scoped>
/* .subPageAr .prod_option .prod_content .idCard li .license {
  background-size: cover;
  background-repeat: no-repeat;
  background-position:center; 

} */
</style>
