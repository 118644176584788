<template>
  <!-- 취소 안내 팝업 -->
  <div class="popWpper paymentSystemPop" style="display: block">
    <div class="popContainer center">
      <button class="close ic">닫기</button>
      <div class="popWP">
        <div class="popContTit line">
          <p class="tit">취소 안내</p>
        </div>
        <div class="popBody">
          <div class="noticeAr">
            <span class="nt_Txt">가입 신청이 즉시 취소됩니다.</span>
            <span class="nt_Txt"
              >취소된 신청서는 재 사용할 수 없으며, 필요하신 경우 가입신청서를 다시
              작성하셔야 합니다.</span
            >
            <span class="nt_Txt"
              >환불 방법은 결제 방법에 따라 상이하며, 아래 안내를 참고하시기
              바랍니다.</span
            >
            <span class="nt_Txt"
              >환불은 취소 접수일로부터 약 3~4일 이내 처리됩니다. (주말, 공휴일
              제외)</span
            >
          </div>
          <div class="appConfirm mT20">
            <span class="appTit">가입신청 정보</span>
            <ul>
              <li>
                <span class="confirmTit notranslate">{{$t('trans.t15_06')}}</span>
                <span class="confirmTxt">2021-05-01</span>
              </li>
              <li>
                <span class="confirmTit notranslate">{{$t('trans.t14_04')}}</span>
                <span class="confirmTxt">RV2353474</span>
              </li>
              <li>
                <span class="confirmTit notranslate">{{$t('trans.t04_03')}}</span>
                <span class="confirmTxt">신규가입</span>
              </li>
              <li>
                <span class="confirmTit notranslate">{{$t('trans.t01_09')}}</span>
                <span class="confirmTxt">15G+ 도시락팩</span>
              </li>
            </ul>
          </div>
          <div class="appConfirm mT20">
            <span class="appTit">결제/환불 정보</span>
            <ul>
              <li>
                <span class="confirmTit">결제금액</span>
                <span class="confirmTxt">0,000원</span>
              </li>
              <li>
                <span class="confirmTit notranslate">{{$t('trans.t09_08')}}</span>
                <span class="confirmTxt notranslate">{{$t('trans.t08_05')}}</span>
              </li>
              <li>
                <span class="confirmTit">환불예정금액</span>
                <span class="confirmTxt">0,000원</span>
              </li>
              <li>
                <span class="confirmTit">환불방법</span>
                <span class="confirmTxt">계좌입금</span>
              </li>
            </ul>
          </div>
          <div class="refundInfo">
            <span class="refundTit">환불 받으실 계좌 정보를 입력해주세요.</span>
            <div class="inputAr">
              <div class="inpGroup">
                <select>
                  <option><span class='notranslate'>{{$t('trans.t08_23')}}</span></option>
                  <option>신한</option>
                  <option>KB</option>
                  <option>우리</option>
                </select>
                <div class="grouping selectInput">
                  <input type="text" class="idNum" placeholder="예금주명" />
                </div>
              </div>
            </div>
            <div class="inputAr">
              <input type="text" class="full" placeholder="계좌번호 입력(숫자만)" />
            </div>
            <div class="inputAr">
              <select class="full">
                <option>취소 사유 선택</option>
                <option>취소 사유</option>
              </select>
            </div>
            <div class="inputAr">
              <textarea></textarea>
            </div>
          </div>
          <!-- 취소신청 버튼 -->
          <a class="selectBtn" href="#">취소신청</a>
        </div>
      </div>
    </div>
    <div class="dim"></div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
