<template>
  <div class="wrap subPage">
    <div class="container">
      <!-- sub title -->
      <div class="subTitAr fW-R">
        <h1 class='notranslate'>{{$t('trans.t13_01')}}</h1>
        <span class="subTit fW-L notranslate"
          >{{$t('trans.t13_02')}}</span
        >
      </div>
      <!-- 서브페이지 컨텐츠 -->
      <div class="subPageAr">
        <div class="findAr">
          <div class="findSelect">
            <input type="radio" id="findId" name="find" value="1" v-model="query" />
            <label for="findId" class="notranslate">{{$t('trans.t12_14')}}</label>
            <input type="radio" id="findPw" name="find" value="2" v-model="query" />
            <label for="findPw" class="notranslate">{{$t('trans.t12_15')}}</label>
          </div>

          <form>
            <div class="tabId" v-if="query === '1'">
              <span class="idNotice fW-R notranslate" v-html="$t('trans.t13_04')"
                ></span
              >
              <div class="btnWp">
                <button class="findBtn fW-R notranslate" type="button" @click="niceAuth">
                  {{$t('trans.t13_05')}}
                </button>
                <button class="findBtn fW-R gray notranslate">{{$t('trans.t13_06')}}</button>
              </div>
            </div>
          </form>

          <form @submit.prevent="onPwSubmit" data-vv-scope="frmPw">
            <div class="tabPw" v-if="query === '2'">
              <div class="inputAr">
                <span class="findPwTit fW-R notranslate">{{$t('trans.t13_08')}}</span>
                <div class="inpGroup">
                  <input
                    type="text"
                    class="findInput"
                    :placeholder="$t('trans.t13_09')"
                    @input="inputId"
                    data-vv-name="mail_account"
                    v-validate="'required|email'"
                    :value="id"
                  />
                  <div class="grouping nextLine">
                    <button class="certifyBtn fW-R notranslate" type="button" @click="niceAuth" :disabled="pwAuth">
                      {{$t('trans.t13_05')}}
                    </button>
                  </div>
                </div>
              </div>
              <div class="inputAr" v-if="pwAuth">
                <span class="findPwTit fW-R notranslate">{{$t('trans.t20_04')}}</span>
                <div class="inpGroup">
                  <input
                    type="password"
                    class="findInput deact"
                    placeholder="신규 비밀번호"
                    @input="inputPwFirst"
                    :value="pwFirst"
                    data-vv-name="member_pass"
                    v-validate="'required|min:10'"
                    ref="member_pass"
                  />
                  <div class="grouping nextLine">
                    <input
                      type="password"
                      class="findInput deact"
                      :placeholder="$t('trans.t20_05')"
                      :value="pwSecond"
                      @input="inputPwSecond"
                      data-vv-name="member_pass_confirm"
                      v-validate="'required|min:10|confirmed:member_pass'"
                    />
                  </div>
                </div>
              </div>
              <div class="btnWp" v-if="pwAuth">
                <button type="submit" class="findBtn fW-R">비밀번호 재설정하기</button>
                <button type="button" class="findBtn fW-R gray notranslate" @click="$router.go(-1)">{{$t('trans.t13_06')}}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <form id="niceInfo" name="niceInfo">
      <input type="hidden" id="AuthType" name="AuthType" value="" />
    </form>
  </div>
</template>

<script>

import { URL } from "../../js/enum";
import { fetchId, fetchAccountCheck, putPass } from "../../api/member.js";

const AUTH_TYPE = {
  ID: "1",
  PW: "2",
};

let niceInfoWindow = null;

export default {
  data() {
    return {
      query: AUTH_TYPE.ID,
      idAuth: false,
      pwAuth: false,
      id: "",
      pwFirst: "",
      pwSecond: "",
      sBirthDate: "",
      sErrorCode: "",
      sGender: "",
      sMobileNo: "",
      sName: "",
      sNationalInfo: "0",
      sRtnMsg: "",
    };
  },

  mounted() {
    if(this.$route.query.q) this.query = this.$route.query.q.toString();

    const self = this;

    window.addEventListener("message", function(e) {
      if (
        e.origin === URL.PAY ||
        e.origin === URL.PAYS
      ) {
        if (e.data.resultData) {
          if (e.data.resultData.sRtnMsg === "사용자 인증 성공") {
            self.sBirthDate = e.data.resultData.sBirthDate;
            self.sErrorCode = e.data.resultData.sErrorCode;
            self.sGender = e.data.resultData.sGender;
            self.sMobileNo = e.data.resultData.sMobileNo;
            self.sName = e.data.resultData.sName;
            self.sNationalInfo = e.data.resultData.sNationalInfo;
            self.sRtnMsg = e.data.resultData.sRtnMsg;

            self.success();

            return;
          }

          this.kakaoTrans("사용자 인증 실패").then((res) => alert(res));
        }
      }
    });
  },

  methods: {
    niceAuth() {
      const AuthType = "M";

      if (this.query === AUTH_TYPE.PW) {
        if (this.id === "") {
          this.kakaoTrans("이메일을 입력하세요.").then((res) => alert(res));
          return;
        }
      }

      const width = "550";
      const height = "550";

      if (!niceInfoWindow || (niceInfoWindow && niceInfoWindow.closed)) {
        niceInfoWindow = window.open(
          "",
          "popupChk",
          "top=" +
            ((screen.availHeight - height) / 2 - 40) +
            ", left=" +
            (screen.availWidth - width) / 2 +
            ", width=" +
            width +
            ", height=" +
            height +
            ", toolbar=0, directories=0, status=0, menubar=0, scrollbars=0, resizable=0"
        );

        document.niceInfo.action = `${URL.PAY}/NiceInfo/Index`;
        document.niceInfo.target = "popupChk";
        document.niceInfo.AuthType.value = AuthType;
        document.niceInfo.submit();
        
      } else {
        niceInfoWindow.focus();
      }
    },

    inputPwFirst(e) {
      this.pwFirst = e.target.value.trim();
    },

    inputPwSecond(e) {
      this.pwSecond = e.target.value.trim();
    },

    inputId(e) {
      this.id = e.target.value.trim();
    },

    async onPwSubmit() {
      const result = await this.$validator.validateAll("frmPw");

      if (!this.pwAuth) {
        this.kakaoTrans("휴대폰 인증은 필수입니다.").then((res) => alert(res));
        return;
      }

      if (result) {
        const checkId = await fetchAccountCheck({
          cell: this.sMobileNo,
          name: this.sName,
          id: this.id,
        });

        if (checkId.data.ResultCode) {
          const res = await putPass({
            member_seq: checkId.data.ResponseData,
            pass: this.pwFirst,
          });

          this.kakaoTrans(res.data.ResultMessage).then((res) => alert(res));

          if (res.data.ResultCode) {
            await this.$router.replace({ name: "LoginView" });
            return;
          }

          return;
        }

        this.kakaoTrans(checkId.data.ResultMessage).then((res) => alert(res));

        return;
      }

      this.kakaoTrans(this.errors.items[0].msg).then((res) => alert(res));
    },

    async success() {

      if (this.query == AUTH_TYPE.ID) {
        this.idAuth = true;
        const res = await fetchId({
          name: this.sName,
          cell: this.sMobileNo,
        });

        if (res.data.ResultCode) {
          await this.$router.replace({
            name: "MemberFindAccountView",
            query: {
              mail: encodeURIComponent(res.data.ResponseData.org_id),
              name: encodeURIComponent(this.sName),
            },
          });
        }

        return;
      }

      this.pwAuth = true;
    },
  },
};
</script>

<style></style>
