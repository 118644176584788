var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap subPage"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"subTitAr fW-R"},[_c('h1',{staticClass:"notranslate"},[_vm._v(_vm._s(_vm.$t('trans.t18_01')))]),_c('span',{staticClass:"subTit fW-L notranslate"},[_vm._v(_vm._s(_vm.$t('trans.t18_02')))])]),_c('div',{staticClass:"subPageAr"},[_c('div',{staticClass:"mypageAr"},[_c('div',{staticClass:"infoMod"},[_c('ul',[_c('li',[_c('span',{staticClass:"infoModTit fW-R notranslate"},[_vm._v(_vm._s(_vm.$t('trans.t16_04')))]),_c('span',{staticClass:"infoModTxt fW-L"},[_vm._v(_vm._s(_vm.member.member_id))])]),_c('li',[_c('span',{staticClass:"infoModTit fW-R notranslate"},[_vm._v(_vm._s(_vm.$t('trans.t06_07')))]),_c('span',{staticClass:"infoModTxt fW-L"},[_vm._v(_vm._s(_vm.member.member_name))])]),_c('li',[_c('span',{staticClass:"infoModTit fW-R notranslate"},[_vm._v(_vm._s(_vm.$t('trans.t18_05')))]),_c('span',{staticClass:"infoModTxt fW-L"},[_vm._v(_vm._s(_vm.member.member_cellnumber))]),_c('button',{staticClass:"btnChange fW-R fr notranslate",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({
                    name: 'MemberCellEditView',
                  })}}},[_vm._v(" "+_vm._s(_vm.$t('trans.t18_06'))+" ")])]),_c('li',[_c('span',{staticClass:"infoModTit fW-R notranslate"},[_vm._v(_vm._s(_vm.$t('trans.t08_17')))]),_c('span',{staticClass:"infoModTxt fW-L"},[_vm._v(_vm._s(_vm.member.member_number))])]),_c('li',[_c('span',{staticClass:"infoModTit fW-R notranslate"},[_vm._v(_vm._s(_vm.$t('trans.t18_08')))]),_c('button',{staticClass:"btnChange fW-R",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({
                    name: 'MemberPassEditView',
                  })}}},[_vm._v(" "+_vm._s(_vm.$t('trans.t18_06'))+" ")])]),_c('li',[_c('span',{staticClass:"infoModTit fW-R notranslate"},[_vm._v(_vm._s(_vm.$t('trans.t18_09')))]),_c('button',{class:[
                  'btnGender fW-R notranslate',
                  {
                    active: _vm.member.member_gender === '10',
                  },
                ],on:{"click":function($event){_vm.member.member_gender = '10'}}},[_vm._v(" "+_vm._s(_vm.$t('trans.t18_10'))+" ")]),_c('button',{class:[
                  'btnGender fW-R notranslate',
                  {
                    active: _vm.member.member_gender === '20',
                  },
                ],on:{"click":function($event){_vm.member.member_gender = '20'}}},[_vm._v(" "+_vm._s(_vm.$t('trans.t18_11'))+" ")])]),_c('li',[_c('span',{staticClass:"infoModTit fW-R notranslate"},[_vm._v(_vm._s(_vm.$t('trans.t18_12')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.member.member_email_send_yn),expression:"member.member_email_send_yn"}],attrs:{"type":"checkbox","id":"agreeMail","true-value":"Y","false-value":"N"},domProps:{"checked":Array.isArray(_vm.member.member_email_send_yn)?_vm._i(_vm.member.member_email_send_yn,null)>-1:_vm._q(_vm.member.member_email_send_yn,"Y")},on:{"click":function($event){_vm.member.member_email_send_yn === 'Y'
                    ? (_vm.member.member_email_send_yn = 'N')
                    : (_vm.member.member_email_send_yn = 'Y')},"change":function($event){var $$a=_vm.member.member_email_send_yn,$$el=$event.target,$$c=$$el.checked?("Y"):("N");if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.member, "member_email_send_yn", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.member, "member_email_send_yn", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.member, "member_email_send_yn", $$c)}}}}),_c('label',{staticClass:"agree fW-L notranslate",attrs:{"for":"agreeMail"}},[_vm._v(_vm._s(_vm.$t('trans.t07_34')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.member.member_sms_send_yn),expression:"member.member_sms_send_yn"}],attrs:{"type":"checkbox","id":"agreeSms","true-value":"Y","false-value":"N"},domProps:{"checked":Array.isArray(_vm.member.member_sms_send_yn)?_vm._i(_vm.member.member_sms_send_yn,null)>-1:_vm._q(_vm.member.member_sms_send_yn,"Y")},on:{"click":function($event){_vm.member.member_sms_send_yn === 'Y'
                    ? (_vm.member.member_sms_send_yn = 'N')
                    : (_vm.member.member_sms_send_yn = 'Y')},"change":function($event){var $$a=_vm.member.member_sms_send_yn,$$el=$event.target,$$c=$$el.checked?("Y"):("N");if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.member, "member_sms_send_yn", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.member, "member_sms_send_yn", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.member, "member_sms_send_yn", $$c)}}}}),_c('label',{staticClass:"agree fW-L",attrs:{"for":"agreeSms"}},[_vm._v("SMS")])]),_c('li',[_c('span',{staticClass:"infoModTit fW-R notranslate"},[_vm._v(_vm._s(_vm.$t('trans.t18_15')))]),_c('button',{class:['btnSns fW-R notranslate', { active: _vm.facebook }],attrs:{"type":"button"},on:{"click":function($event){return _vm.onSnsTran('F')}}},[_vm._v(" "+_vm._s(_vm.$t('trans.t18_16'))+" ")]),_c('button',{class:['btnSns fW-R notranslate', { active: _vm.kakao }],attrs:{"type":"button"},on:{"click":function($event){return _vm.onSnsTran('K')}}},[_vm._v(" "+_vm._s(_vm.$t('trans.t18_17'))+" ")]),_c('button',{class:['btnSns fW-R notranslate', { active: _vm.naver }],attrs:{"type":"button"},on:{"click":function($event){return _vm.onSnsTran('N')}}},[_vm._v(" "+_vm._s(_vm.$t('trans.t18_18'))+" ")])])])]),_c('div',{staticClass:"btnWp"},[_c('button',{staticClass:"btnCom fW-R gray",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({ name: 'MemberDropReasonView' })}}},[_vm._v(" "+_vm._s(_vm.$t('trans.t18_19'))+" ")]),_c('button',{staticClass:"btnCom fW-R notranslate",attrs:{"type":"button"},on:{"click":_vm.onMemberUpdate}},[_vm._v(" "+_vm._s(_vm.$t('trans.t18_20'))+" ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }