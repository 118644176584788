import NaverCallBackView from "../views/sns/NaverCallBackView";


export default[
    {
        path: "/sns/callback/navercallbackview",
        name: "NaverCallBackView",
        component: NaverCallBackView,
        meta: {
            transitionName:'slide'
        },

    }
]
