<template>
  <div class="popBody">
    <div class="searchAr">
      <!-- <select>
                <option>선택</option>
            </select> -->
      <input
        type="text"
        class="moSearch"
        @input="inputName"
        :bind="search"
        placeholder="단말기명"
      />
      <!-- <button class="moSearchBtn" @click="onSearch">검색</button> -->
    </div>
    <!-- pc -->
    <div class="popTb">
      <table>
        <thead>
          <tr>
            <th>No</th>
            <th>제조사</th>
            <th>단말기명</th>
            <th>모델명</th>
            <th>유심구분</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in terminalItems" :key="index">
            <td>{{ item.no }}</td>
            <td>{{ item.company }}</td>
            <td>{{ item.terminal_nm }}</td>
            <td>{{ item.model_nm }}</td>
            <td>{{ item.usim }}</td>
          </tr>
        </tbody>
      </table>
      
      <div class="page-wraper">
      <jw-pagination
        :items="getTerminalList"
        @changePage="onChangeTerminalPage"
        :labels="customLabels"
      ></jw-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import JwPagination from "jw-vue-pagination";

export default {
  name: "UsimOfTerminal",
  components: {
    JwPagination,
  },
  props: {
    terminalList: Array,
  },
  data() {
    return {
      terminalArray: this.terminalList,
      terminalItems: [],
      customLabels: {
        first: "처음",
        last: "끝",
        previous: "이전",
        next: "다음",
      },
      customStyles: {},
      search: "",
    };
  },
  methods: {
    onChangeTerminalPage(payload) {
      this.terminalItems = payload;
    },

    inputName(e) {
      this.search = e.target.value;
    },
  },
  computed: {
    getTerminalList() {
      if (this.search) {
        const searchRegExp = new RegExp(this.search, "i");
        return this.terminalArray.filter((e) => {
          return searchRegExp.test(e.terminal_nm);
        });
      }
      return this.terminalArray;
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}
::v-deep .pagination > li {
  display: inline;
}
::v-deep .pagination > li > a,
::v-deep .pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}
::v-deep .pagination > li:first-child > a,
::v-deep .pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
::v-deep .pagination > li:last-child > a,
::v-deep .pagination > li:last-child > span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
::v-deep .pagination > li > a:hover,
::v-deep .pagination > li > span:hover,
::v-deep .pagination > li > a:focus,
::v-deep .pagination > li > span:focus {
  color: #23527c;
  background-color: #eee;
  border-color: #ddd;
}
::v-deep .pagination > .active > a,
::v-deep .pagination > .active > span,
::v-deep .pagination > .active > a:hover,
::v-deep .pagination > .active > span:hover,
::v-deep .pagination > .active > a:focus,
::v-deep .pagination > .active > span:focus {
  z-index: 2;
  color: #fff;
  cursor: default;
  background-color: #337ab7;
  border-color: #337ab7;
}
::v-deep .pagination > .disabled > span,
::v-deep .pagination > .disabled > span:hover,
::v-deep .pagination > .disabled > span:focus,
::v-deep .pagination > .disabled > a,
::v-deep .pagination > .disabled > a:hover,
::v-deep .pagination > .disabled > a:focus {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}
::v-deep .pagination-lg > li > a,
::v-deep .pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 18px;
}
::v-deep .pagination-lg > li:first-child > a,
::v-deep .pagination-lg > li:first-child > span {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
::v-deep .pagination-lg > li:last-child > a,
::v-deep .pagination-lg > li:last-child > span {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
::v-deep .pagination-sm > li > a,
::v-deep .pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
}
::v-deep .pagination-sm > li:first-child > a,
::v-deep .pagination-sm > li:first-child > span {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
::v-deep .pagination-sm > li:last-child > a,
::v-deep .pagination-sm > li:last-child > span {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
::v-deep .pager {
  padding-left: 0;
  margin: 20px 0;
  text-align: center;
  list-style: none;
}
::v-deep .pager li {
  display: inline;
}
::v-deep .pager li > a,
::v-deep .pager li > span {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
}
::v-deep .pager li > a:hover,
::v-deep .pager li > a:focus {
  text-decoration: none;
  background-color: #eee;
}
::v-deep .pager .next > a,
::v-deep .pager .next > span {
  float: right;
}
::v-deep .pager .previous > a,
::v-deep .pager .previous > span {
  float: left;
}
::v-deep .pager .disabled > a,
::v-deep .pager .disabled > a:hover,
::v-deep .pager .disabled > a:focus,
::v-deep .pager .disabled > span {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
}

.page-wraper {
  padding: 10px;
}

</style>
