import {
    cloud,
    kakaoTrans,
    dosirakCall,
    payCall,
    axiosAll,
    dosirak
} from "./config";

// 아이피 조회
export function fetchUserIp() {
    return dosirak.post('/mvno/common/ip')
}

export function fetchKakaoTrans(payload) {
    var data = new FormData();
    data.append('q', payload.q);
    data.append('source_lang', payload.source_lang);
    data.append('target_lang', payload.target_lang);
    return kakaoTrans.post('https://dapi.kakao.com/v2/translation/translate', data)
}


export function request() {
    const dosirakAPI = dosirakCall.post('/auth/request')
    const payAPI = payCall.post('/api/Hyosung/request')
    return axiosAll([dosirakAPI, payAPI])
}

// mvno 사업자 목록
export function fetchMvnoCompany() {
    return dosirak.post('/mvno/common/mvnoCompanyList')
}

// 약관
export function fetchTerms(payload) {
    //return dosirak.post('/mvno/reservation/nc/terms')
    return dosirak.post('/mvno/common/termsList', payload)
}

// 대리점 정보
export function fetchShop() {
    return dosirak.post('/mvno/common/shop')
}

// 배송 정보
export function fetchDelivery() {
    return dosirak.post('/mvno/common/delivery')
}

// 배송, 대리점 정보 조회
export function fetchShopDelivery(payload) {
    const shop = dosirak.post('/mvno/common/shop', payload)
    const delivery = dosirak.post('/mvno/common/delivery')

    return axiosAll([shop, delivery])
}


// 이미지 업로드
export function postImageUpload(payload) {
    return dosirak.post('/mvno/reservation/image', payload)
}