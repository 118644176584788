<template>
  <div style="height:100%">
    {{ orderId }}
    <iframe
      id="easypay_frm"
      name="easypay_frm"
      width="100%"
      height="780px"
      src="about:blank"
    ></iframe>

    <form id="frm_pay" name="frm_pay" method="post">
      <input type="hidden" id="joinRoute" name="joinRoute" value="" />
      <input type="hidden" name="mgr_txtype" id="mgr_txtype" value="" />
      <input type="hidden" name="org_cno" id="org_cno" value="" />
      <input type="hidden" name="mgr_amt" id="mgr_amt" value="" />
      <input type="hidden" name="HdItemSeq" id="HdItemSeq" value="" />
      <input type="hidden" name="HdSelfReason" id="HdSelfReason" value="" />
      <input type="hidden" name="HdReasonSeq" id="HdReasonSeq" value="" />

      <input type="hidden" name="CST_MID" id="CST_MID" value="wide888" />
      <input type="hidden" name="LGD_TID" id="LGD_TID" value="" />
      <input type="hidden" name="CST_PLATFORM" id="CST_PLATFORM" value="service" />
      <input type="hidden" name="LGD_CANCELAMOUNT" id="LGD_CANCELAMOUNT" value="" />
      <input type="hidden" name="LGD_REMAINAMOUNT" id="LGD_REMAINAMOUNT" value="" />
      <input type="hidden" name="LGD_CANCELREASON" id="LGD_CANCELREASON" value="" />

      <input type="hidden" name="controlNo" id="controlNo" />
      <input type="hidden" name="payment" id="payment" />

      <input type="hidden" id="TravelInsurance" name="TravelInsurance" value="" />
      <input type="hidden" id="SafePlan" name="SafePlan" value="" />
      <input type="hidden" id="Type" name="Type" value="" />
      <input type="hidden" id="scheduleType" name="scheduleType" value="" />
      <input type="hidden" id="emp_seq" name="emp_seq" value="351" />
      <input type="hidden" id="TARGET_MODULE" name="TARGET_MODULE" value="mobile" />

      <input
        type="hidden"
        id="sp_mall_nm"
        name="sp_mall_nm"
        value="(주)와이드모바일"
      />
      <!--[선택]가맹점명 -->
      <input type="hidden" id="sp_order_no" name="sp_order_no" :value="orderId" />
      <!--[필수]가맹점 주문번호(인증응답) -->
      <input type="hidden" id="sp_currency" name="sp_currency" value="00" />
      <!--[필수]통화코드(수정불가) -->
      <input type="hidden" id="sp_return_url" name="sp_return_url" value="" />
      <!--[필수]가맹점 return URL -->
      <input type="hidden" id="sp_user_id" name="sp_user_id" value="" />
      <!--[선택]가맹점 고객ID -->
      <input
        type="hidden"
        id="sp_user_nm"
        name="sp_user_nm"
        :value="encodeURIComponent(accName)"
      />
      <!--[선택]가맹점 고객명 -->
      <input type="hidden" id="sp_user_mail" name="sp_user_mail" :value="accMail" />
      <!--[선택]가맹점 고객 E-mail -->
      <input type="hidden" id="sp_user_phone1" name="sp_user_phone1" value="" />
      <!--[선택]가맹점 고객 연락처1 -->
      <input type="hidden" id="sp_user_phone2" name="sp_user_phone2" value="" />
      <!--[선택]가맹점 고객 연락처2 -->
      <input type="hidden" id="sp_user_addr" name="sp_user_addr" value="" />
      <!--[선택]가맹점 고객 주소 -->
      <input type="hidden" id="sp_res_cd" name="sp_res_cd" value="" />
      <!-- [필수]응답코드        -->
      <input type="hidden" id="sp_res_msg" name="sp_res_msg" value="" />
      <!-- [필수]응답메시지      -->
      <input type="hidden" id="sp_tr_cd" name="sp_tr_cd" value="" />
      <!-- [필수]결제창 요청구분 -->
      <input type="hidden" id="sp_ret_pay_type" name="sp_ret_pay_type" value="" />
      <!-- [필수]결제수단        -->
      <input type="hidden" id="sp_mall_id" name="sp_mall_id" value="05528719" />
      <input type="hidden" id="sp_sessionkey" name="sp_sessionkey" value="" />
      <!-- [필수]세션키          -->
      <input type="hidden" id="sp_encrypt_data" name="sp_encrypt_data" value="" />
      <!-- [필수]암호화전문      -->
      <input type="hidden" id="sp_trace_no" name="sp_trace_no" value="" />
      <!-- [필수]추적번호      -->
      <input type="hidden" id="sp_card_req_type" name="sp_card_req_type" value="" />
      <!-- [필수]카드결제종류      -->
      <input type="hidden" id="sp_pay_type" name="sp_pay_type" value="81" />
      <input
        type="hidden"
        id="sp_product_nm"
        name="sp_product_nm"
        :value="encodeURIComponent(OrderDataSet.selectedRate.name)"
      />
      <input
        type="hidden"
        id="sp_product_amt"
        name="sp_product_amt"
        :value="OrderDataSet.totalAmount"
      />
      <input type="hidden" id="product_amt" name="product_amt" value="" />
      <input type="hidden" id="sp_charset" name="sp_charset" value="UTF-8" />
      <input type="hidden" id="sp_client_ip" name="sp_client_ip" value="" />
      <!-- [필수]사용자 IP   -->
      <input type="hidden" id="sp_usedcard_code" name="sp_usedcard_code" value="" />
      <!-- [필수]사용자 IP   -->
      <input
        type="hidden"
        id="sp_window_type"
        name="sp_window_type"
        value="iframe"
      />
      <input
        type="hidden"
        id="sp_top_window_url"
        name="sp_top_window_url"
        value=""
      />
      <input type="hidden" id="sp_lang_flag" name="sp_lang_flag" value="EN" />
    </form>
  </div>
</template>

<script>
import { orderMixin } from "../../mixin/storeMixin";
import { payBaseUrl, putImageUpload } from "../../api/pay";
import * as Enum from "../../js/enum";
import { orderCommit } from "../../api/order";

export default {
  mixins: [orderMixin],
  mounted() {
    const self = this;

    window.addEventListener(
      "message",
      function(e) {
        
        if (
          e.origin === Enum.URL.PAY ||
          e.origin === Enum.URL.PAYS ||
          e.origin === Enum.URL.PAY_TEST
        ) {
          if (e.data.res_cd === "0000") {
            self.commit();
            return;
          } else if(e.data.res_cd === "W002") {
            self.$router.replace({ name: "Step4View" }).catch((e) => { console.log(e)});
            return;
          } else {
            alert(e.data.res_msg)
            self.$router.replace({ name: "Step4View" }).catch((e) => { console.log(e)});
            return;
          }
        }
      },
      false
    );

    const frm_pay = document.getElementById("frm_pay");

    frm_pay.setAttribute("target", "easypay_frm");
    frm_pay.setAttribute("action", `${payBaseUrl()}/Easypay/MvnoMoReq`);
    frm_pay.submit();
  },

  methods: {
    //주문
    async commit() {
      try {
        const res = await orderCommit({
          order_id: this.OrderDataSet.encryptOrderId,
        });

        if (res.status === 200) {
          if (res.data.ResultCode) {
            const resImage = await putImageUpload({
              imageCard: sessionStorage.getItem("picCard"),
              imageFace: sessionStorage.getItem("picFace"),
              order_id: this.OrderDataSet.orderId,
            });

            if (this.memberInfo.isLogin) {
              localStorage.removeItem(this.memberInfo.member_seq);
            }

            this.$router.push({
              name: "CompletedView",
              query: {
                order: encodeURIComponent(this.OrderDataSet.encryptOrderId),
              },
            });

            this.MTS_ORDER_INIT();

            return;
          }
        } else {
          throw "예약 실패";
        }
      } catch (error) {
        alert(error);
        this.$router.replace({ name: "Step4View" });
      }
    },
  },
};
</script>

<style></style>
