<template>
  <div class="wrap subPage">
    <div class="container">
      <!-- sub title -->
      <div class="subTitAr fW-R">
        <h1 class='notranslate'>{{$t('trans.t01_04')}}</h1>
        <span class="subTit fW-L notranslate"
          >{{$t('trans.t16_02')}}</span
        >
      </div>
      <!-- 서브페이지 컨텐츠 -->
      <div class="subPageAr">
        <!-- 회원정보 -->
        <div class="prod_option" v-show="memberInfo.member_login_type !== 2">
          <div class="prod_subject">
            <span class="subjectTit fW-R notranslate">{{$t('trans.t16_03')}}</span>
            <span class="subjectLine"></span>
          </div>
          <div class="prod_content document">
            <div class="mypageWp">
              <div class="row">
                <span class="myinfoTit fW-R notranslate">{{$t('trans.t16_04')}}</span>
                <span class="myinfoTxt fW-L">{{ member.email }}</span>
              </div>
              <div class="row">
                <span class="myinfoTit fW-R notranslate">{{$t('trans.t07_13')}}</span>
                <select class="infoSelect">
                  <option>{{ member.cell }}</option>
                </select>
              </div>
              <div class="row">
                <button
                  class="btnFull fW-R notranslate"
                  type="button"
                  @click="$router.push({ name: 'MemberEditView' })"
                >
                  {{$t('trans.t16_06')}}
                </button>
                <!-- <router-link to="/member/update"></router-link> -->
              </div>
            </div>
          </div>
        </div>
        <!-- 가입정보 및 사용량 조회 -->
        <div class="prod_option">
          <div class="prod_subject">
            <span class="subjectTit fW-R notranslate"
              >{{$t('trans.t17_01')}}</span
            >
            <span class="subjectLine"></span>
          </div>
          <div class="prod_content document">
            
            <div class="mypageWp" v-if="order.length > 0">              
              <div class="row">
                <select
                  class="infoSelect"
                  @change="onChangeNumber"
                  v-model="selectedReservationId"
                >
                  <option value=""><span class="notranslate">{{$t('trans.t16_08')}}</span></option>
                  <option
                    :value="item.reserv_encrypt_id"
                    :key="item.reserv_encrypt_id"
                    v-for="item in order"
                    >{{ item.celnumber }}</option
                  >
                </select>
                <button class="btnInquiry fW-R fr notranslate" @click="onView">
                  {{$t('trans.t16_09')}}
                </button>
              </div>
              <div class="row" v-if="!isEmpty(selectedRevation)">
                <span class="prodTit">{{ selectedRevation.plan_name }}</span>
                <span class="prodTxt"
                  >데이터 {{ selectedRevation.data_text }} / 음성
                  {{ selectedRevation.voice_text }} / 문자
                  {{ selectedRevation.data_text }}</span
                >
              </div>
            </div>
            <div class="mypageWp" v-if="isAccount">
              <div class="row"><button type="button" @click="$router.push({name:'Step1View'})" class="btnFull fW-R"> 가입 신청하기</button></div>
              <div class="row">
                <span class="prodTxt fW-R" style="text-align:center">가입 내역이 없습니다.</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 부가서비스 신청/조회/해지 -->
        <div class="prod_option" v-show="!isEmpty(selectedExtra)">
          <div class="prod_subject">
            <span class="subjectTit fW-R extend notranslate"
              >{{$t('trans.t11_03')}}<em class="nextLine">조회</em></span
            >
            <!-- <span class="subjectTit extend">부가서비스<em class="nextLine">신청/조회/해지</em></span> -->
            <span class="subjectLine"></span>
          </div>
          <div class="prod_content document">
            <div class="mypageWp">
              <div class="row" v-for="(item, index) in selectedExtra" :key="index">
                <span class="myinfoTit extend">{{ item.extraservice }}</span>
                <span class="myinfoTxt fr">{{
                  item.amount === 0 ? "무료" : `${currency(item.amount)}원`
                }}</span>
              </div>

              <!-- <div class="row">
                            <button class="btnInquiry">조회/해지하기</button>
                            <button class="btnRequest">신규 서비스 신청하기</button>
                        </div> -->
            </div>
          </div>
        </div>
        <!-- 찜 리스트 -->
        <div class="prod_option" v-if="false">
          <div class="prod_subject">
            <span class="subjectTit fW-R">찜 리스트</span>
            <span class="subjectLine"></span>
          </div>
          <div class="prod_content document">
            <div class="mypageWp">
              <div class="row">
                <span class="myinfoTit fW-R fav">프리미엄 도시락팩 5G/일</span>
                <span class="myinfoTxt fW-L fr">66,000원</span>
              </div>
              <div class="row">
                <span class="myinfoTit fW-R fav">미니 도시락 1</span>
                <span class="myinfoTxt fW-L fr">3,300원</span>
              </div>
              <div class="row">
                <button class="btnFull fW-R">전체보기</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  fetchMypageMobile,
  fetchMypage,
  fetchOrderExtra,
  fetchOrder,
  fetchIdOrder,
} from "../../api/member";
import { loginType, snsType } from "../../js/enum";

export default {
  data() {
    return {
      member: {},
      reserv: [],
      numbers: [],
      extra: [],
      selectedNumber: "",
      selectedReservationId: "",
      selectedRevation: {},
      selectedExtra: [],
      order: [],
      orders: [],
      isAccount:false,
    };
  },

  methods: {
    async fetchMypage() {
      let res = [];
      if (
        this.memberInfo.member_login_type === loginType.EMAIL ||
        this.memberInfo.member_login_type === loginType.SNS
      ) {
        res = await fetchMypage({
          mem_seqnum: this.memberInfo.member_seq,
        });

        this.member = res.data.ResponseData.memberInfo[0] || {};
        this.reserv = res.data.ResponseData.reservInfo;
        this.numbers = res.data.ResponseData.reservInfo.map((item) => {
          return {
            celnumber: item.celnumber,
            reserv_id: item.reserv_id,
          };
        });

        const [order, extra] = await fetchIdOrder({
          member_id: this.memberInfo.member_seq,
        });

        this.order = order.data.ResponseData;
        this.extra = extra.data.ResponseData;
        this.isAccount = Object.keys(order.data.ResponseData) > 0 ? true : false
      } else {
        const [order, extra] = await fetchOrder({
          user_cell: this.memberInfo.member_mvno.opencelnumber,
          user_name: this.memberInfo.member_mvno.clientname,
        });

        this.order = order.data.ResponseData;
        //this.extra = extra.data.ResponseData;
      }

      /*
            if(res.data.ResultCode){
                if(this.memberInfo.member_login_type !== loginType.MOBILE){
                    this.member = res.data.ResponseData.memberInfo[0] || {}
                }
                
                this.reserv = res.data.ResponseData.reservInfo
                this.numbers = res.data.ResponseData.reservInfo.map(item => {
                    return {
                        celnumber: item.celnumber,
                        reserv_id: item.reserv_id
                    }

                })

                this.extra = res.data.ResponseData.extraInfo

                if(this.numbers.length > 0) {
                    this.selectedReservationId = this.numbers[0].reserv_id
                    this.selectedRevation = this.reserv.find(x => x.reserv_id === this.selectedReservationId)
                    this.httpExtra(this.selectedReservationId)
                }
                
            }
            */
    },

    onChangeNumber(e) {
      if (e.target.value !== "") {
        //부가서비스 제거 요청
        // this.selectedExtra = this.extra.filter(
        //   (x) => x.reserv_encrypt_id === e.target.value
        // );

        this.selectedRevation = this.order.find(
          (x) => x.reserv_encrypt_id === e.target.value
        );

        return;
      }

      this.selectedExtra = [];
      this.selectedRevation = {};
    },

    async httpExtra(payload) {
      const extraRes = await fetchOrderExtra({
        reservation_cd: payload,
      });

      this.selectedExtra = extraRes.data.ResponseData;
    },

    onView() {
      if (this.selectedReservationId) {
        this.$router.push({
          name: "UsageView",
          query: { rcd: this.selectedReservationId },
        });
      }
    },
  },

  mounted() {
    this.fetchMypage();
  },
};
</script>

<style></style>
