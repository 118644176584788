import Step1View from "@/views/order/Step1View";
import Step2View from "@/views/order/Step2View";
import Step3View from "@/views/order/Step3View";
import Step4View from "@/views/order/Step4View";
import CompletedView from "@/views/order/CompletedView"
import KiccPayView from "@/views/order/KiccPayView";
import KakaoPayView from "@/views/order/KakaoPayView"


export default[
    {
        path: "/order/step1view",
        name: "Step1View",
        component: Step1View,
        meta: {
            isStep: true,
            transitionName: 'slide'
        },
        beforeEnter(to, from, next) {
            next()
        }

    },
    {
        path: "/order/step2view",
        name: "Step2View",
        component: Step2View,
        meta: {
            isStep: true,
            transitionName: 'slide'
        },
        beforeEnter(to, from, next) {
            next()
        }
    },
    {
        path: "/order/step3view",
        name: "Step3View",
        component: Step3View,
        meta: {
            isStep: true,
            transitionName: 'slide'
        },
        beforeEnter(to, from, next) {
            next()
        }
    },
    {
        path: "/order/step4view",
        name: "Step4View",
        component: Step4View,
        meta: {
            isStep: true,
            transitionName: 'slide'
        },
        beforeEnter(to, from, next) {
            next()
        }
    }, {
        path: "/order/completedview",
        name: "CompletedView",
        component: CompletedView,
        meta: {
            transitionName: 'slide'
        }
    }, {
        path: "/order/kiccpayview",
        name: "KiccPayView",
        component: KiccPayView,
        meta: {
            isStep: true,
            transitionName: 'slide'
        }
    }, {
        path: "/order/kakaopayview",
        name: "KakaoPayView",
        component: KakaoPayView,
        meta: {
            isStep: true,
            transitionName: 'slide'
        }
    },
    {
        path: '*',
        redirect: "/"
    },

]
