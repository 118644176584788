<template>
  <div class="wrap subPage">
    <div class="container">
      <!-- sub title -->
      <div class="subTitAr">
        <h1 class="notranslate">
          {{ $t("trans.t12_16") }}</h1>
        <span class="subTit fW-L"
        >통합회원 가입으로 와이드모바일의 모든 서비스를 편리하게
          이용하세요.</span
        >
      </div>
      <!-- 가입신청서 STEP -->
      <div class="subStepWrap mT30">
        <ul class="stepAr">
          <li>
            <span class="stepNum">STEP1<b>약관동의</b></span>
          </li>
          <li>
            <span
                class="stepNum on">STEP2<b>본인인증</b></span>
          </li>
          <li>
            <span
                class="stepNum">STEP3<b>회원정보입력</b></span>
          </li>
        </ul>
      </div>
      <div style="clear: both;"></div>
      <!-- 서브페이지 컨텐츠 -->
      <div class="subPageAr">
        <div class="joinCertify">
          <h2
              class="fW-M notranslate"
              v-html="$t('trans.t13_04')"
          ></h2>
          <div class="btnAr">
            <div class="btnWp">
              <button
                  class="btnCertify mobile notranslate"
                  @click="onNiceAuth"
              >
                {{ $t("trans.t13_05") }}
              </button>
              <button
                  class="btnCertify cancle notranslate"
                  @click="$router.go(-1)"
              >
                {{ $t("trans.t13_06") }}
              </button>
            </div>
            <span
            >입력하신 이름/생년월일 및 휴대폰 번호는 한국
              모바일인증(KMC)을 통한 본인인증에 사용됩니다.</span
            >
            <span
            >[인증번호 받기]를 눌러 수신한 인증번호로 진행해
              주세요.</span
            >
          </div>

        </div>
      </div>
    </div>
    <form id="niceInfo" name="niceInfo">
      <input type="hidden" id="AuthType"
             name="AuthType" value="M"/>
    </form>
  </div>
</template>

<script>
import {URL} from "../../../js/enum";

const AUTH_TYPE = {
  ID: "1",
  PW: "2",
};

let niceInfoWindow = null;

export default {
  name: "joinstep2view",
  data() {
    return {
      idAuth: false,
      pwAuth: false,
      id: "",
      pwFirst: "",
      pwSecond: "",
      sBirthDate: "",
      sErrorCode: "",
      sGender: "",
      sMobileNo: "",
      sName: "",
      sNationalInfo: "0",
      sRtnMsg: "",
    };
  },

  methods: {
    onNiceAuth() {

      const width = "550";
      const height = "550";

      if (
          !niceInfoWindow ||
          (niceInfoWindow && niceInfoWindow.closed)
      ) {
        window.name = "niceWindow";
        niceInfoWindow = window.open(
            "",
            "popupChk",
            "top=" +
            ((screen.availHeight - height) / 2 - 40) +
            ", left=" +
            (screen.availWidth - width) / 2 +
            ", width=" +
            width +
            ", height=" +
            height +
            ", toolbar=0, directories=0, status=0, menubar=0, scrollbars=0, resizable=0"
        );

        document.niceInfo.action = `${URL.PAYS}/NiceInfo/Index`;
        document.niceInfo.target = "popupChk";
        document.niceInfo.AuthType.value = 'M';
        document.niceInfo.submit();
      } else {
        niceInfoWindow.focus();
      }
    },
  },

  mounted() {
    const self = this;
    window.addEventListener(
        "message",
        function (e) {
          console.log(e.data);
          if (e.origin === URL.PAY || e.origin === URL.PAYS) {
            if (e.data.resultData) {
              if (e.data.resultData.sRtnMsg === "사용자 인증 성공") {
                self.sBirthDate = e.data.resultData.sBirthDate;
                self.sErrorCode = e.data.resultData.sErrorCode;
                self.sGender = e.data.resultData.sGender;
                self.sMobileNo = e.data.resultData.sMobileNo;
                self.sName = e.data.resultData.sName;
                self.sNationalInfo = e.data.resultData.sNationalInfo;
                self.sRtnMsg = e.data.resultData.sRtnMsg;

                self.$router.replace({name:'JoinStep3View'})

                return;
              }

              this.kakaoTrans("사용자 인증 실패").then((res) =>
                  alert(res)
              );
            }
          }
        },
        false
    );

  },
}

</script>

<style></style>
