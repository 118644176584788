import Login from "../views/member/LoginView"
import MemberDrawAgree from "../views/member/MemberDrawAgreeView";
import MemberEditView from "../views/member/MemberEditView";
import MemberDrawComplete from "../views/member/MemberDrawCompleteView"
import MemberFind from "../views/member/MemberFindView"
import MemberFindAccount from "../views/member/MemberFindAccountView"
import MemberJoin from "../views/member/MemberJoinView"
import JoinStep1View from "../views/member/join/JoinStep1View"
import JoinStep2View from "../views/member/join/JoinStep2View"
import JoinStep3View from "../views/member/join/JoinStep3View"
import JoinStep4View from "../views/member/join/JoinStep4View"
import MemberPassEditView from "../views/member/MemberPassEditView"
import MemberCellEditView from "../views/member/MemberCellEditView"
import MemberDropReasonView from "../views/member/MemberDropReasonView"
import MemberDropView from "../views/member/MemberDropView";

function lasyView(view){
    return () => import(/* webpackChunkName: "view-[request]" */ `@/views/member/${view}.vue`)
}

export default[
    {
        path: '/member/loginview',
        name: 'LoginView',
        meta: {
            transitionName: 'slide'
        },
        component: lasyView('LoginView')
    },
    {
        path: '/member/memberdrawagreeview',
        name: 'MemberDrawAgreeView',
        component: lasyView('MemberDrawAgree'),
        meta: {
            isLogin: true,
            transitionName:'slide'
        }
    },
    {
        path: '/member/draw/memberdrawcompleteview',
        name: 'MemberDrawCompleteView',
        meta: {
            transitionName: 'slide'
        },
        component: lasyView('MemberDrawCompleteView')
    },
    {
        path: '/member/membereditview',
        name: 'MemberEditView',
        meta: {
            transitionName: 'slide'
        },
        component: lasyView('MemberEditView')
    }, {
        path: '/member/memberfindview',
        name: 'MemberFindView',
        meta: {
            transitionName: 'slide'
        },
        component: MemberFind
    }, {
        path: '/member/memberfindaccountview',
        name: 'MemberFindAccountView',
        meta: {
            transitionName: 'slide'
        },
        component: lasyView('MemberFindAccountView')
    }, {
        path: '/member/memberjoinview',
        name: 'MemberJoinView',
        meta: {
            transitionName: 'slide'
        },
        component: MemberJoin
    }, {
        path: '/member/join/joinstep1view',
        name: 'JoinStep1View',
        component: lasyView('JoinStep1View')
    }, {
        path: '/member/join/joinstep2view',
        name: 'JoinStep2View',
        meta: {
            transitionName: 'slide'
        },
        component: lasyView('JoinStep2View')
    }, {
        path: '/member/join/joinstep3view',
        name: 'JoinStep3View',
        meta: {
            transitionName: 'slide'
        },
        component: lasyView('JoinStep3View')
    }, {
        path: '/member/join/joinstep4view',
        name: 'JoinStep4View',
        meta: {
            transitionName: 'slide'
        },
        component: lasyView('JoinStep4View')
    }, {
        path: '/member/memberpasseditview',
        name: 'MemberPassEditView',
        component: MemberPassEditView,
        meta: {
            isLogin: true,
            transitionName:'slide'
        }
    }, {
        path: '/member/membercelleditview',
        name: 'MemberCellEditView',
        component: lasyView('MemberCellEditView'),
        meta: {
            isLogin: true,
            transitionName:'slide'
        }
    }, {
        path:'/member/drop/memberdropreasonview',
        name:'MemberDropReasonView',
        component: lasyView('MemberDropReasonView'),
        meta:{
            transitionName:'slide'
        }
    }, {
        path:'/member/drop/memberdropview',
        name:'MemberDropView',
        component: lasyView('MemberDropView'),
        meta:{
            transitionName:'slide'
        }
    },
    {
        path: '*',
        redirect: "/"
    },
]
