import { dosirak } from "./config"

// 충전잔액조회
export function fetchRemaining(payload){
  return dosirak.post('/mvno/telecentro/RemainingAmountSelect', payload)
}

// 가입자 조회
export function fetchTelecentroMember(payload){
  return dosirak.post('/mvno/telecentro/SubscriberSelect', payload)
}