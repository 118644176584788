export const mvnoPatterns = [
  {
    key: "1",
    value: "LG헬로비전"
  },
  {
    key: "2",
    value: "케이티엠모바일"
  },
  {
    key: "3",
    value: "에넥스텔레콤KT"
  },
  {
    key: "4",
    value: "SK텔링크[재판매]"
  }, {
    key: "5",
    value: "(주)알뜰폰"
  }, {
    key: "6",
    value: "(주)핀샷LG"
  }, {
    key: "7",
    value: "KCT"
  }, {
    key: "8",
    value: "KCT[재판매]"
  }, {
    key: "9",
    value: "KCTKT"
  }, {
    key: "10",
    value: "KT파워텔"
  }, {
    key: "11",
    value: "케이티스카이라이프"
  }, {
    key: "12",
    value: "LG헬로비전(KT)"
  }, {
    key: "13",
    value: "LG헬로비전(LG)"
  }, {
    key: "14",
    value: "LG헬로비전(SKT)"
  }, {
    key: "15",
    value: "MTT텔레콤"
  }, {
    key: "16",
    value: "금강방송"
  }, {
    key: "17",
    value: "남인천방송"
  }, {
    key: "18",
    value: "니즈텔레콤KT"
  }, {
    key: "19",
    value: "대성홀딩스"
  }, {
    key: "20",
    value: "드림라인"
  }, {
    key: "21",
    value: "드림에이치엔비LG"
  }, {
    key: "22",
    value: "레그원"
  }, {
    key: "23",
    value: "리브모바일LG"
  }, {
    key: "24",
    value: "머천드코리아KT"
  }, {
    key: "25",
    value: "머천드코리아LG"
  }, {
    key: "26",
    value: "미니게이트"
  }, {
    key: "27",
    value: "서경방송"
  }, {
    key: "28",
    value: "스마텔LG"
  }, {
    key: "29",
    value: "스마텔SKT"
  }, {
    key: "30",
    value: "씨엔커뮤니케이션"
  }, {
    key: "31",
    value: "아이원"
  }, {
    key: "32",
    value: "아이즈비전KT"
  }, {
    key: "33",
    value: "아이즈비전LG"
  }, {
    key: "34",
    value: "아이즈비전SKT"
  }, {
    key: "35",
    value: "앤알커뮤니케이션"
  }, {
    key: "36",
    value: "에넥스텔레콤(SKT망)"
  }, {
    key: "37",
    value: "에넥스텔레콤LG"
  }, {
    key: "38",
    value: "에스로밍"
  }, {
    key: "39",
    value: "에스원KT"
  }, {
    key: "40",
    value: "에스원LG"
  }, {
    key: "41",
    value: "에스원SKT"
  }, {
    key: "42",
    value: "에이씨엔코리아KT"
  }, {
    key: "43",
    value: "에이씨엔코리아LG"
  }, {
    key: "44",
    value: "여유텔레콤"
  }, {
    key: "45",
    value: "온세텔레콤"
  }, {
    key: "46",
    value: "온세텔레콤[재판매]"
  }, {
    key: "47",
    value: "와이엘랜드"
  }, {
    key: "48",
    value: "울산방송"
  }, {
    key: "49",
    value: "원텔레콤LG"
  }, {
    key: "50",
    value: "위너스텔"
  }, {
    key: "51",
    value: "유니컴즈KT"
  }, {
    key: "52",
    value: "유니컴즈LG"
  }, {
    key: "53",
    value: "유니컴즈SKT"
  }, {
    key: "54",
    value: "이마트LG"
  }, {
    key: "55",
    value: "이마트SKT"
  }, {
    key: "56",
    value: "이비카드KT"
  }, {
    key: "57",
    value: "이지모바일LG"
  }, {
    key: "58",
    value: "장성모바일한국"
  }, {
    key: "59",
    value: "제이씨티"
  }, {
    key: "60",
    value: "제주방송"
  }, {
    key: "61",
    value: "조이텔LG"
  }, {
    key: "62",
    value: "조이텔SKT"
  }, {
    key: "63",
    value: "착한통신"
  }, {
    key: "64",
    value: "케이디디아이코리아LG"
  }, {
    key: "65",
    value: "코드모바일"
  }, {
    key: "66",
    value: "큰사람KT"
  }, {
    key: "67",
    value: "큰사람LG"
  }, {
    key: "68",
    value: "큰사람SKT"
  }, {
    key: "69",
    value: "파인디지털"
  }, {
    key: "70",
    value: "푸른방송"
  }, {
    key: "71",
    value: "프리텔레콤KT"
  }, {
    key: "72",
    value: "프리텔레콤LGU+"
  }, {
    key: "73",
    value: "프리텔레콤SKT"
  }, {
    key: "74",
    value: "핀플레이KT"
  }, {
    key: "75",
    value: "핀플레이LG"
  }, {
    key: "76",
    value: "홈플러스(lLGU망)"
  }, {
    key: "77",
    value: "화인통신LG"
  }, {
    key: "78",
    value: "KPMOLG"
  }
]

export const countrys = [
  {
    country_cd: "KR",
    kr: "대한민국",
    en: "Republic of Korea"
  },
  {
    country_cd: "GH",
    kr: "가나",
    en: "Ghana"
  },
  {
    country_cd: "GA",
    kr: "가봉",
    en: "Gabon"
  },
  {
    country_cd: "GY",
    kr: "가이아나",
    en: "Guyana"
  }, {
    country_cd: "GM",
    kr: "감비아",
    en: "Gambia"
  }, {
    country_cd: "GG",
    kr: "건지섬",
    en: "Guernsey"
  }, {
    country_cd: "GP",
    kr: "과들루프",
    en: "Guadeloupe"
  }, {
    country_cd: "GT",
    kr: "과테말라",
    en: "Guatemala"
  }, {
    country_cd: "GU",
    kr: "괌",
    en: "Guam"
  }, {
    country_cd: "GD",
    kr: "그레나다",
    en: "Grenada"
  }, {
    country_cd: "GR",
    kr: "그리스",
    en: "Greece"
  }, {
    country_cd: "GL",
    kr: "그린란드",
    en: "Greenland"
  }, {
    country_cd: "GN",
    kr: "기니",
    en: "Guinea"
  }, {
    country_cd: "GW",
    kr: "기니비사우",
    en: "Guinea-Bissau"
  }, {
    country_cd: "NA",
    kr: "나미비아",
    en: "Namibia"
  }, {
    country_cd: "NR",
    kr: "나우루",
    en: "Nauru"
  }, {
    country_cd: "NG",
    kr: "나이지리아",
    en: "Nigeria"
  }, {
    country_cd: "AQ",
    kr: "남극",
    en: "Antarctica"
  }, {
    country_cd: "ZA",
    kr: "남아프리카 공화국",
    en: "South Africa"
  }, {
    country_cd: "NL",
    kr: "네덜란드",
    en: "Netherlands"
  }, {
    country_cd: "AN",
    kr: "네덜란드령 안틸레스",
    en: "Nederlandse Antillen"
  }, {
    country_cd: "NP",
    kr: "네팔",
    en: "Nepal"
  }, {
    country_cd: "NO",
    kr: "노르웨이",
    en: "Norway"
  }, {
    country_cd: "NF",
    kr: "노퍽 섬",
    en: "Norfuk Ailen"
  }, {
    country_cd: "NC",
    kr: "누벨칼레도니",
    en: "New Caledonia"
  }, {
    country_cd: "NZ",
    kr: "뉴질랜드",
    en: "New Zealand"
  }, {
    country_cd: "NU",
    kr: "니우에",
    en: "Niue"
  }, {
    country_cd: "NE",
    kr: "니제르",
    en: "Niger"
  }, {
    country_cd: "NI",
    kr: "니카라과",
    en: "Nicaragua"
  }, {
    country_cd: "DK",
    kr: "덴마크",
    en: "Danmark"
  }, {
    country_cd: "DO",
    kr: "도미니카공화국",
    en: "Dominican Republic"
  }, {
    country_cd: "DM",
    kr: "도미니카 연방",
    en: "Dominica"
  }, {
    country_cd: "DE",
    kr: "독일",
    en: "Germany"
  }, {
    country_cd: "TL",
    kr: "동티모르",
    en: "East Timor Leste"
  }, {
    country_cd: "LA",
    kr: "라오스",
    en: "Laos"
  }, {
    country_cd: "LR",
    kr: "라이베리아",
    en: "Republic of Liberia"
  }, {
    country_cd: "LV",
    kr: "라트비아",
    en: "Latvija"
  }, {
    country_cd: "RU",
    kr: "러시아",
    en: "Russia"
  }, {
    country_cd: "LB",
    kr: "레바논",
    en: "Lebanon"
  }, {
    country_cd: "LS",
    kr: "레소토",
    en: "Lesotho"
  }, {
    country_cd: "RE",
    kr: "레위니옹",
    en: "Reunion?"
  }, {
    country_cd: "RO",
    kr: "루마니아",
    en: "Romania"
  }, {
    country_cd: "LU",
    kr: "룩셈부르크",
    en: "Luxembourg"
  }, {
    country_cd: "RW",
    kr: "르완다",
    en: "Rwanda"
  }, {
    country_cd: "LY",
    kr: "리비아",
    en: "Libya"
  }, {
    country_cd: "LT",
    kr: "리투아니아",
    en: "Lithuania"
  }, {
    country_cd: "LI",
    kr: "리히텐슈타인",
    en: "Liechtenstein"
  }, {
    country_cd: "MG",
    kr: "마다가스카르",
    en: "Madagascar"
  }, {
    country_cd: "MQ",
    kr: "마르티니크",
    en: "Martinique"
  }, {
    country_cd: "MH",
    kr: "마셜 제도",
    en: "Marshall Is."
  }, {
    country_cd: "YT",
    kr: "마요트",
    en: "Mayotte"
  }, {
    country_cd: "MO",
    kr: "마카오",
    en: "Macau"
  }, {
    country_cd: "MK",
    kr: "북마케도니아",
    en: "North Macedonia"
  }, {
    country_cd: "MW",
    kr: "말라위",
    en: "Malawi"
  }, {
    country_cd: "MY",
    kr: "말레이시아",
    en: "Malaysia"
  }, {
    country_cd: "ML",
    kr: "말리",
    en: "Mali"
  }, {
    country_cd: "IM",
    kr: "맨섬",
    en: "Isle of Man"
  }, {
    country_cd: "MX",
    kr: "멕시코",
    en: "Mexico"
  }, {
    country_cd: "MC",
    kr: "모나코",
    en: "Monaco"
  }, {
    country_cd: "MA",
    kr: "모로코",
    en: "Morocco"
  }, {
    country_cd: "MU",
    kr: "모리셔스",
    en: "Mauritius"
  }, {
    country_cd: "MR",
    kr: "모리타니",
    en: "Mauritania"
  }, {
    country_cd: "MZ",
    kr: "모잠비크",
    en: "Mozambique"
  }, {
    country_cd: "ME",
    kr: "몬테네그로",
    en: "Montenegro"
  }, {
    country_cd: "MS",
    kr: "몬트세랫",
    en: "Montserrat"
  }, {
    country_cd: "MD",
    kr: "몰도바",
    en: "Moldova (the Republic of)"
  }, {
    country_cd: "MV",
    kr: "몰디브",
    en: "Maldives"
  }, {
    country_cd: "MT",
    kr: "몰타",
    en: "Malta"
  }, {
    country_cd: "MN",
    kr: "몽골",
    en: "Mongolia"
  }, {
    country_cd: "US",
    kr: "미국",
    en: "United States of America"
  }, {
    country_cd: "UM",
    kr: "미국령 군소 제도",
    en: "United States Minor Outlying Islands"
  }, {
    country_cd: "VI",
    kr: "미국령 버진아일랜드",
    en: "United States Virgin Islands"
  }, {
    country_cd: "MM",
    kr: "미얀마",
    en: "Republic of the Union of Myanmar"
  }, {
    country_cd: "FM",
    kr: "미크로네시아 연방",
    en: "Micronesia"
  }, {
    country_cd: "VU",
    kr: "바누아투",
    en: "Vanuatu"
  }, {
    country_cd: "BH",
    kr: "바레인",
    en: "Bahrain"
  }, {
    country_cd: "BB",
    kr: "바베이도스",
    en: "Barbados"
  }, {
    country_cd: "VA",
    kr: "바티칸 시국",
    en: "Vatican City"
  }, {
    country_cd: "BS",
    kr: "바하마",
    en: "Bahamas"
  }, {
    country_cd: "BD",
    kr: "방글라데시",
    en: "Bangladesh"
  }, {
    country_cd: "BM",
    kr: "버뮤다",
    en: "Bermuda ls."
  }, {
    country_cd: "BJ",
    kr: "베냉",
    en: "Benin"
  }, {
    country_cd: "VE",
    kr: "베네수엘라",
    en: "Venezuela (Bolivarian Republic of)"
  }, {
    country_cd: "VN",
    kr: "베트남",
    en: "Viet Nam"
  }, {
    country_cd: "BE",
    kr: "벨기에",
    en: "Belgium"
  }, {
    country_cd: "BY",
    kr: "벨라루스",
    en: "Belarus"
  }, {
    country_cd: "BZ",
    kr: "벨리즈",
    en: "Belize"
  }, {
    country_cd: "BA",
    kr: "보스니아 헤르체고비나",
    en: "Bosnia and Herzegovina"
  }, {
    country_cd: "BW",
    kr: "보츠와나",
    en: "Botswana"
  }, {
    country_cd: "BO",
    kr: "볼리비아",
    en: "Bolivia"
  }, {
    country_cd: "BI",
    kr: "부룬디",
    en: "Burundi"
  }, {
    country_cd: "BF",
    kr: "부르키나파소",
    en: "Burkina Faso"
  }, {
    country_cd: "BV",
    kr: "부베섬",
    en: "Bouvet Island"
  }, {
    country_cd: "BT",
    kr: "부탄",
    en: "Bhutan"
  }, {
    country_cd: "MP",
    kr: "북마리아나 제도",
    en: "Northern Mariana Is."
  }, {
    country_cd: "BG",
    kr: "불가리아",
    en: "Bulgaria"
  }, {
    country_cd: "BR",
    kr: "브라질",
    en: "Brazil"
  }, {
    country_cd: "BN",
    kr: "브루나이",
    en: "Brunei Darussalam"
  }, {
    country_cd: "WS",
    kr: "사모아",
    en: "Samoa"
  }, {
    country_cd: "SA",
    kr: "사우디아라비아",
    en: "Saudi Arabia"
  }, {
    country_cd: "GS",
    kr: "사우스조지아 사우스샌드위치 제도",
    en: "South Georgia and the South Sandwich Islands"
  }, {
    country_cd: "SM",
    kr: "산마리노",
    en: "San Marino"
  }, {
    country_cd: "ST",
    kr: "상투메 프린시페",
    en: "Sao Tome and Principe"
  }, {
    country_cd: "PM",
    kr: "생피에르 미클롱",
    en: "Saint Pierre and Miquelon"
  }, {
    country_cd: "EH",
    kr: "서사하라",
    en: "Western Sahara"
  }, {
    country_cd: "SN",
    kr: "세네갈",
    en: "Senegal"
  }, {
    country_cd: "RS",
    kr: "세르비아",
    en: "Serbia"
  }, {
    country_cd: "SC",
    kr: "세이셸",
    en: "Seychelles"
  }, {
    country_cd: "LS",
    kr: "세인트루시아",
    en: "Saint Lucia"
  }, {
    country_cd: "VC",
    kr: "세인트빈센트 그레나딘",
    en: "Saint Vincent and the Grenadines"
  }, {
    country_cd: "KN",
    kr: "세인트키츠 네비스",
    en: "Saint Kitts and Nevis"
  }, {
    country_cd: "SH",
    kr: "세인트헬레나",
    en: "Saint Helena, Ascension and Tristan da Cunha"
  }, {
    country_cd: "SO",
    kr: "소말리아",
    en: "Somalia"
  }, {
    country_cd: "SB",
    kr: "솔로몬 제도",
    en: "Solomon Islands"
  }, {
    country_cd: "SD",
    kr: "수단",
    en: "Sudan"
  }, {
    country_cd: "SR",
    kr: "수리남",
    en: "Suriname"
  }, {
    country_cd: "LK",
    kr: "스리랑카",
    en: "Sri Lanka"
  }, {
    country_cd: "SJ",
    kr: "스발바르 얀마옌",
    en: "Svalbard and Jan Mayen"
  }, {
    country_cd: "SE",
    kr: "스웨덴",
    en: "Sweden"
  }, {
    country_cd: "CH",
    kr: "스위스",
    en: "Switzerland"
  }, {
    country_cd: "ES",
    kr: "스페인",
    en: "Spain"
  }, {
    country_cd: "SK",
    kr: "슬로바키아",
    en: "Slovakia"
  }, {
    country_cd: "SI",
    kr: "슬로베니아",
    en: "Slovenia"
  }, {
    country_cd: "SY",
    kr: "시리아",
    en: "Syrian Arab Republic"
  }, {
    country_cd: "SL",
    kr: "시에라리온",
    en: "Sierra Leone"
  }, {
    country_cd: "SX",
    kr: "신트마르턴",
    en: "Sint Maarten"
  }, {
    country_cd: "SG",
    kr: "싱가포르",
    en: "Singapore"
  }, {
    country_cd: "AE",
    kr: "아랍에미리트",
    en: "United Arab Emirates"
  }, {
    country_cd: "AW",
    kr: "아루바",
    en: "Aruba"
  }, {
    country_cd: "AM",
    kr: "아르메니아",
    en: "Armenia"
  }, {
    country_cd: "AR",
    kr: "아르헨티나",
    en: "Argentina"
  }, {
    country_cd: "AS",
    kr: "아메리칸사모아",
    en: "American Samoa"
  }, {
    country_cd: "IS",
    kr: "아이슬란드",
    en: "Iceland"
  }, {
    country_cd: "HT",
    kr: "아이티",
    en: "Haiti"
  }, {
    country_cd: "IE",
    kr: "아일랜드",
    en: "Ireland"
  }, {
    country_cd: "AZ",
    kr: "아제르바이잔",
    en: "Azerbaijan"
  }, {
    country_cd: "AF",
    kr: "아프가니스탄",
    en: "Afghanistan"
  }, {
    country_cd: "AD",
    kr: "안도라",
    en: "Angola"
  }, {
    country_cd: "AL",
    kr: "알바니아",
    en: "Albania"
  }, {
    country_cd: "DZ",
    kr: "알제리",
    en: "Algeria"
  }, {
    country_cd: "AO",
    kr: "앙골라",
    en: "Angola"
  }, {
    country_cd: "AG",
    kr: "앤티가 바부다",
    en: "Antigua and Barbuda"
  }, {
    country_cd: "AI",
    kr: "앵귈라",
    en: "Anguilla"
  }, {
    country_cd: "ER",
    kr: "에리트레아",
    en: "Eritrea"
  }, {
    country_cd: "SZ",
    kr: "에스와티니",
    en: "Eswatini"
  }, {
    country_cd: "EE",
    kr: "에스토니아",
    en: "Estonia"
  }, {
    country_cd: "EC",
    kr: "에콰도르",
    en: "Ecuador"
  }, {
    country_cd: "ET",
    kr: "에티오피아",
    en: "Ethiopia"
  }, {
    country_cd: "SV",
    kr: "엘살바도르",
    en: "El Salvador"
  }, {
    country_cd: "GB",
    kr: "영국",
    en: "United Kingdom of Great Britain and Northern Ireland"
  }, {
    country_cd: "VG",
    kr: "영국령 버진아일랜드",
    en: "British Virgin Islands"
  }, {
    country_cd: "IO",
    kr: "영국령 인도양 지역",
    en: "British Indian Ocean Territory"
  }, {
    country_cd: "YE",
    kr: "예멘",
    en: "Yemen"
  }, {
    country_cd: "OM",
    kr: "오만",
    en: "Oman"
  }, {
    country_cd: "AU",
    kr: "오스트레일리아",
    en: "Australia"
  }, {
    country_cd: "AT",
    kr: "오스트리아",
    en: "Austria"
  }, {
    country_cd: "HN",
    kr: "온두라스",
    en: "Honduras"
  }, {
    country_cd: "AX",
    kr: "올란드 제도",
    en: "Aland Islands"
  }, {
    country_cd: "JO",
    kr: "요르단",
    en: "Jordan"
  }, {
    country_cd: "UG",
    kr: "우간다",
    en: "Uganda"
  }, {
    country_cd: "UY",
    kr: "우르과이",
    en: "Uruguay"
  }, {
    country_cd: "UZ",
    kr: "우즈베키스탄",
    en: "Uzbekistan"
  }, {
    country_cd: "UA",
    kr: "우크라이나",
    en: "Ukraine"
  }, {
    country_cd: "WF",
    kr: "왈리스 퓌튀나",
    en: "Wallis and Futuna"
  }, {
    country_cd: "IQ",
    kr: "이라크",
    en: "Iraq"
  }, {
    country_cd: "IR",
    kr: "이란",
    en: "Iran (Islamic Republic of)"
  }, {
    country_cd: "IL",
    kr: "이스라엘",
    en: "Israel"
  }, {
    country_cd: "EG",
    kr: "이집트",
    en: "Egypt"
  }, {
    country_cd: "IT",
    kr: "이탈리아",
    en: "Italy"
  }, {
    country_cd: "IN",
    kr: "인도",
    en: "India"
  }, {
    country_cd: "ID",
    kr: "인도네시아",
    en: "Indonesia"
  }, {
    country_cd: "JP",
    kr: "일본",
    en: "Japan"
  }, {
    country_cd: "JM",
    kr: "자메이카",
    en: "Jamaica"
  }, {
    country_cd: "ZM",
    kr: "잠비아",
    en: "Zambia"
  }, {
    country_cd: "JE",
    kr: "저지섬",
    en: "Jersey"
  }, {
    country_cd: "GQ",
    kr: "적도 기니",
    en: "Equatorial Guinea"
  }, {
    country_cd: "KP",
    kr: "조선민주주의인민공화국",
    en: "Korea (Democratic People's Republic of)"
  }, {
    country_cd: "GE",
    kr: "조지아",
    en: "Georgia"
  }, {
    country_cd: "CN",
    kr: "중국",
    en: "China"
  }, {
    country_cd: "CF",
    kr: "중앙아프리카 공화국",
    en: "Central African Republic"
  }, {
    country_cd: "GI",
    kr: "지부티",
    en: "Djibouti"
  }, {
    country_cd: "GI",
    kr: "지브롤터",
    en: "Gibraltar"
  }, {
    country_cd: "ZW",
    kr: "짐바브웨",
    en: "Zimbabwe"
  }, {
    country_cd: "TD",
    kr: "차드",
    en: "Chad"
  }, {
    country_cd: "CZ",
    kr: "체코",
    en: "Czechia"
  }, {
    country_cd: "CL",
    kr: "칠레",
    en: "Chile"
  }, {
    country_cd: "CM",
    kr: "카메룬",
    en: "Cameroon"
  }, {
    country_cd: "CV",
    kr: "카보베르데",
    en: "Cabo Verde"
  }, {
    country_cd: "KZ",
    kr: "카자흐스탄",
    en: "Kazakhstan"
  }, {
    country_cd: "QA",
    kr: "카타르",
    en: "Qatar"
  }, {
    country_cd: "KH",
    kr: "캄보디아",
    en: "Cambodia"
  }, {
    country_cd: "CA",
    kr: "캐나다",
    en: "Canada"
  }, {
    country_cd: "KE",
    kr: "케냐",
    en: "Kenya"
  }, {
    country_cd: "KY",
    kr: "케이맨 제도",
    en: "Cayman Islands"
  }, {
    country_cd: "KM",
    kr: "코모로",
    en: "Comoros"
  }, {
    country_cd: "CR",
    kr: "코스타리카",
    en: "Costa Rica"
  }, {
    country_cd: "CC",
    kr: "코코스 제도",
    en: "Cocos (Keeling) Islands"
  }, {
    country_cd: "CI",
    kr: "코트디부아르",
    en: "Cote d'Ivoire"
  }, {
    country_cd: "CO",
    kr: "콜롬비아",
    en: "Colombia"
  }, {
    country_cd: "CG",
    kr: "콩고 공화국",
    en: "Congo"
  }, {
    country_cd: "CD",
    kr: "콩고 민주 공화국",
    en: "Congo (the Democratic Republic of the)"
  }, {
    country_cd: "CU",
    kr: "쿠바",
    en: "Cuba"
  }, {
    country_cd: "KW",
    kr: "쿠웨이트",
    en: "Kuwait"
  }, {
    country_cd: "CK",
    kr: "쿡 제도",
    en: "Cook Islands"
  }, {
    country_cd: "HR",
    kr: "크로아티아",
    en: "Croatia"
  }, {
    country_cd: "CX",
    kr: "크리스마스 섬",
    en: "Christmas Island"
  }, {
    country_cd: "KG",
    kr: "키르기스스탄",
    en: "Kyrgyzstan"
  }, {
    country_cd: "KI",
    kr: "키리바시",
    en: "Kiribati"
  }, {
    country_cd: "CY",
    kr: "키프로스",
    en: "Cyprus"
  }, {
    country_cd: "TW",
    kr: "타이완",
    en: "Taiwan"
  }, {
    country_cd: "TJ",
    kr: "타지키스탄",
    en: "Tajikistan"
  }, {
    country_cd: "TZ",
    kr: "탄자니아",
    en: "Tanzania, the United Republic of"
  }, {
    country_cd: "TH",
    kr: "태국",
    en: "Thailand"
  }, {
    country_cd: "TC",
    kr: "터크스 케이커스 제도",
    en: "Turks and Caicos Islands"
  }, {
    country_cd: "TR",
    kr: "터키",
    en: "Turkey"
  }, {
    country_cd: "TG",
    kr: "토고",
    en: "Togo"
  }, {
    country_cd: "TK",
    kr: "토켈라우",
    en: "Tokelau"
  }, {
    country_cd: "TO",
    kr: "통가",
    en: "Tonga"
  }, {
    country_cd: "TM",
    kr: "투르크메니스탄",
    en: "Turkmenistan"
  }, {
    country_cd: "TV",
    kr: "투발루",
    en: "Tuvalu"
  }, {
    country_cd: "TN",
    kr: "튀니지",
    en: "Tunisia"
  }, {
    country_cd: "TT",
    kr: "트리니다드 토바고",
    en: "Trinidad and Tobago"
  }, {
    country_cd: "PA",
    kr: "파나마",
    en: "Panama"
  }, {
    country_cd: "PY",
    kr: "파라과이",
    en: "Paraguay"
  }, {
    country_cd: "PK",
    kr: "파키스탄",
    en: "Pakistan"
  }, {
    country_cd: "PG",
    kr: "파푸아뉴기니",
    en: "Papua New Guinea"
  }, {
    country_cd: "PW",
    kr: "팔라우",
    en: "Palau"
  }, {
    country_cd: "PS",
    kr: "팔레스타인",
    en: "Palestine, State of"
  }, {
    country_cd: "FO",
    kr: "페로 제도",
    en: "Faroe Islands"
  }, {
    country_cd: "PE",
    kr: "페루",
    en: "Peru"
  }, {
    country_cd: "PT",
    kr: "포르투갈",
    en: "Portugal"
  }, {
    country_cd: "FK",
    kr: "포클랜드 제도",
    en: "Falkland Islands (Malvinas)"
  }, {
    country_cd: "PL",
    kr: "폴란드",
    en: "Poland"
  }, {
    country_cd: "PR",
    kr: "푸에르토리코",
    en: "Puerto Rico"
  }, {
    country_cd: "FR",
    kr: "프랑스",
    en: "France"
  }, {
    country_cd: "GF",
    kr: "프랑스령 기아나",
    en: "French Guiana"
  }, {
    country_cd: "TF",
    kr: "프랑스령 남방 및 남극",
    en: "French Southern Territories"
  }, {
    country_cd: "PF",
    kr: "프랑스령 폴리네시아",
    en: "French Polynesia"
  }, {
    country_cd: "FJ",
    kr: "피지",
    en: "Fiji"
  }, {
    country_cd: "FI",
    kr: "핀란드",
    en: "Finland"
  }, {
    country_cd: "PH",
    kr: "필리핀",
    en: "Philippines"
  }, {
    country_cd: "PN",
    kr: "핏케언 제도",
    en: "Pitcairn"
  }, {
    country_cd: "HM",
    kr: "허드 맥도널드 제도",
    en: "Heard Island and McDonald Islands"
  }, {
    country_cd: "HU",
    kr: "헝가리",
    en: "Hungary"
  }, {
    country_cd: "HK",
    kr: "홍콩",
    en: "Hong Kong"
  }
]
