<template>
  <div class="wrap subPage" ref="document">
    <div class="container">
      <!-- sub title -->
      <div class="subTitAr">
        <h1 class='notranslate'>{{$t('trans.t09_01')}}</h1>
        <!--<span class="subTit fW-L">해피콜(1566-9071)을 통해 가입신청이 완료 되오니 꼭 전화를 받아주세요.</span>-->
      </div>
      <div style="clear: both;"></div>
      <!-- 서브페이지 컨텐츠 -->
      <div class="subPageAr">
        <!-- 신청 내역 -->
        <div class="prod_option" v-if="order">
          <div class="prod_subject">
            <span class="subjectTit fW-R" :key="$t('trans.t09_02')">{{$t('trans.t09_02')}}</span>
            <span class="subjectLine"></span>
          </div>
          <div></div>
          <div class="prod_content document">
            <div class="appConfirm">
              <ul>
                <li>
                  <span class="confirmTit fW-R notranslate" :key="$t('trans.t05_03')">{{$t('trans.t05_03')}}</span>
                  <span class="confirmTxt">{{ order.subtype }}</span>
                </li>
                <li>
                  <span class="confirmTit fW-R notranslate" :key="$t('trans.t04_06')">{{$t('trans.t04_06')}}</span>
                  <span class="confirmTxt">{{ order.name }}</span>
                </li>
                <li>
                  <span class="confirmTit fW-R notranslate" :key="$t('trans.t05_04')">{{$t('trans.t05_04')}}</span>
                  <span class="confirmTxt">{{ order.simtype }}</span>
                </li>
                <li>
                  <span class="confirmTit fW-R notranslate" :key="$t('trans.t11_03')">{{$t('trans.t11_03')}}</span>
                  <span class="confirmTxt">{{ extraString }}</span>
                </li>
                <li>
                  <span class="confirmTit fW-R notranslate" :key="$t('trans.t05_08')">{{$t('trans.t05_08')}}</span>
                  <span class="confirmTxt" :key="order.amount"
                    >{{ order.amount | currency }}원</span
                  >
                </li>
                <li>
                  <span class="confirmTit fW-R">{{$t('trans.t09_08')}}</span>
                  <span class="confirmTxt">{{
                    order.paytypename
                  }}</span>
                </li>
                <li v-show="order.bankname">
                  <span class="confirmTit fW-R">은행/계좌</span>
                  <span class="confirmTxt">{{
                    `${order.bankname}/${order.accountnumber}`
                  }}</span>
                </li>
                <li>
                  <span class="confirmTit fW-R notranslate">{{$t('trans.t09_09')}}</span>
                  <span class="confirmTxt">{{
                    order.autopay_yn === "Y" ? $t('trans.t08_27') : "미신청"
                  }}</span>
                </li>
                <li>
                  <span class="confirmTit fW-R notranslate">{{ $t('trans.t21_10')}}</span>
                  <span class="confirmTxt notranslate">{{
                    order.req_turn
                  }} {{ $t('trans.t21_11')}}({{ order.use_day }}{{$t('trans.t21_13')}})</span>
                </li>
                <li>
                  <span class="confirmTit fW-R notranslate">{{ $t('trans.t21_12') }}</span>
                  <span class="confirmTxt">{{ order.discount_amt | currency }}원</span>
                </li>                                
              </ul>
            </div>
          </div>
        </div>

        <div class="prod_option">
          <div class="prod_subject">
            <span class="subjectTit fW-R notranslate"
              >{{$t('trans.t09_10')}}</span
            >
            <span class="subjectLine"></span>
          </div>
          <div class="prod_content document">
            <span class="conTxt fW-R notranslate" v-html="$t('trans.t09_11')"
              ></span
            >
            <button class="btnWide fW-R notranslate" @click="ContractPDF">
              {{$t('trans.t09_12')}}
            </button>
            <button class="btnWide fW-R notranslate" @click="AccountPDF">
              {{$t('trans.t09_13')}}
            </button>
            <button
              class="btnWide fW-R"
              @click="DebitPDF"
              v-show="isAutoPay"
            >
              자동결제 동의서
            </button>
          </div>
        </div>
      </div>
    </div>
    <application-doc
      v-bind:order_id="$route.query.order"
    ></application-doc>
    <direct-debit-doc
      v-bind:order_id="$route.query.order"
    ></direct-debit-doc>
    <contract-doc v-bind:order_id="$route.query.order"></contract-doc>
  </div>
</template>

<script>
import ApplicationDoc from "../../components/ApplicationDoc";
import DirectDebitDoc from "../../components/DirectDebitDoc";
import ContractDoc from "../../components/ContractDoc";
import { eventBus } from "../../js/eventBus";
import { fetchOrder } from "../../api/order";
import * as Util from "../../js/util";

export default {
  name: "CompletedView",
  data() {
    return {
      order: [],
      extra: [],
      extraString: "",
    };
  },

  components: {
    ApplicationDoc,
    ContractDoc,
    DirectDebitDoc,
  },

  async beforeRouteEnter(to, from, next) {
    //네이버 전환스크립트
    if (typeof wcs != "undefined") {
      if (!wcs_add) var wcs_add = {};
      wcs_add["wa"] = "s_22b5388c1089";

      let _nasa = {};
      _nasa["cnv"] = wcs.cnv(
        "1",
        Util.aesDecrypt(to.query.amt, "user_amt")
      );

      wcs_do(_nasa);
    }

    next();
  },

  methods: {
    async fetchMyOrder() {
      const self = this;
      const [order, extra] = await fetchOrder({
        orderId: decodeURIComponent(this.$route.query.order.toString()),
      });

      const arr = extra.data.ResponseData.map(function(x) {
        if (x.amount === 0) {
          return `${x.productname}(무료)`;
        }

        return `${x.productname}(월 ${self.currency(x.amount)} 원)`;
      });

      this.extraString = arr.toString();
      this.order = order.data.ResponseData;
      this.extra = extra.data.ResponseData;

      //구글 전환(MO)
      if (this.isMobile) {
        this.$gtag.event("conversion", {
          send_to: "AW-396164207/gstHCPaEqv8BEO_487wB",
          value: order.data.ResponseData.amount,
          currency: "KRW",
          transaction_id: "",
        });
      } else {
        //구글 전환(PC)
        this.$gtag.event("conversion", {
          send_to: "AW-396164207/j9URCOnQjv4BEO_487wB",
          value: order.data.ResponseData.amount,
          currency: "KRW",
          transaction_id: "",
        });
      }
    },

    ready() {
      const oid = Util.getUrlParameter("oid");
      const amount = Util.aesDecrypt(
        Util.getUrlParameter("amt"),
        "user_amt"
      );

      window.DaumConversionDctSv = `type=P,orderID=${oid},amount=${amount}`;
      window.DaumConversionAccountID = "2wclSbO6eXIjn8qE7EN8rw00";

      if (
        typeof DaumConversionScriptLoaded == "undefined" &&
        location.protocol != "file:"
      ) {
        var DaumConversionScriptLoaded = true;
      }

      let script = document.createElement("script");
      script.src =
        "https://t1.daumcdn.net/cssjs/common/cts/vr200/dcts.js";
        script.defer = true
      document.body.appendChild(script);

      fbq('track', 'Purchase', {
        value: amount,
        currency: 'KRW',
      });
    },

    currency(payload) {
      return String(payload).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    AccountPDF() {
      //온라인가입신청서
      eventBus.$emit("downApplication")
    },

    ContractPDF() {
      // 무선표준 안내계약서
      eventBus.$emit("downContrace")
    },

    //자동이체동의서
    DebitPDF() {
      eventBus.$emit("downDebit")
    },
  },

  created() {
    this.fetchMyOrder()
    this.ready()
  },

  computed: {
    isAutoPay() {
      return this.order.autopay_yn === "Y" ? true : false;
    },
  },
};
</script>

<style></style>
