<template>
  <div class="wrap subPage">
    <div class="container">
      <!-- sub title -->
      <div class="subTitAr fW-R">
        <h1 class='notranslate'>{{$t('trans.t12_16')}}</h1>
        <span class="subTit fW-L"
          >통합회원 가입으로 와이드모바일의 모든 서비스를 편리하게 이용하세요.</span
        >
      </div>
      <!-- 서브페이지 컨텐츠 -->
      <div class="subPageAr">
        <div class="memberJoin">
          <h2 class="fW-R">와이드모바일 통합회원가입</h2>
          <span class="joinNote fW-L"
            >통합회원으로 가입하시면 도시락모바일을 포함해 와이파이도시락,
            도시락유심, 여행보험도시락 등 다양한 서비스를 하나의 통합 아이디로
            편리하게 이용하실 수 있습니다.</span
          >
          <span class="joinNote fW-L"
            >회원으로 가입하시면 가입정보 및 요금조회 등 더욱 스마트한 통신 서비스를
            경험할 수 있습니다.</span
          >
          <span class="joinNote fW-L"
            >만 14세 미만 고객은 회원가입 서비스를 제공하지 않습니다.</span
          >
          <span class="joinNote fW-L">통합회원 이용가능 사이트</span>
          <div class="memberSite">
            <span
              ><a href=""><img src="../../images/grayMobile.png"/></a
            ></span>
            <span
              ><a href="https://www.wifidosirak.com" target="_blank"
                ><img src="../../images/grayDosirak.png"/></a
            ></span>
            <span
              ><a href="https://www.dosiraksim.com" target="_blank"
                ><img src="../../images/grayUsim.png"/></a
            ></span>
            <span
              ><a href="https://www.insudosirak.com" target="_blank"
                ><img src="../../images/grayTravel.png"/></a
            ></span>
          </div>
          <button class="memberJoinBtn fW-R">
            <router-link :to="{ name: 'JoinStep1View' }"
              >통합 회원 가입하기</router-link
            >
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
